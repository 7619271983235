import React from "react";
import "./SelectPrescription.css";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";

const SelectPrescription = () => {
  const navigate = useNavigate();
  const data = [
    {
      Lorem: "Paracetamol 500mg PO QID X 7 days",
    },
    {
      Lorem: "Amoxicillin 500 mg PO TID X 7 day",
    },
    {
      Lorem: "Crestor 10 mg PO OD X 30 days",
    },
  ];
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Select prescription"}
        style={{ color: "white" }}
      />
      <section className="PrescriptionDetail-main-section">
        <div className="PrescriptionDetail-main-div">
          {data.map((itm, index) => {
            return (
              <>
                <div className="PrescriptionDetail-thard">
                  <span className="Lorem-main-span">{itm.Lorem}</span>
                  <div>
                    <input className="Sunita-main-check" type="checkbox" />
                  </div>
                </div>
                <div className="PrescriptionDetail-main-line mt-4"></div>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default SelectPrescription;
