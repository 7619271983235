import React from "react";
import Header from "../../Components/Header/Header";
import Fetchdata from "../../Constant/Fetchdata";
import "./RefillRequest.css";
import { PRESCRIPTIONREQUESTAPI } from "../../Constant/Apiconstant";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../Constant/Loader";

const RefillRequest = () => {
  const { response, isloading } = Fetchdata(PRESCRIPTIONREQUESTAPI, {}, "GET");
  // console.log(response);
  const navigate = useNavigate();

  const handleView = (item) => {
    navigate("/Prescription", {
      state: {
        item,
      },
    });
  };
  return (
    <>
      <Header text={"Refill request"} onClick={() => navigate(-1)} />
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <div className="refillcontentparentdiv">
          {!response?.data ? (
            <h1 className="text-center">{response?.message}</h1>
          ) : (
            <>
              {response?.data?.map((item) => {
                const formated = Dateformat(item.notificationSendAt);
                return (
                  <div className="refillcontent">
                    <img src={item.senderId_image} alt="" />
                    <div>
                      <p>{item.notificationBody}</p>
                      <span>{formated}</span>
                      <Buttton
                        style={{ backgroundColor: Colors.blue, width: "100px" }}
                        onClick={() => handleView(item)}
                      >
                        View
                      </Buttton>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RefillRequest;
