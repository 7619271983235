import React from "react";
import Fetchdata from "../../Constant/Fetchdata";
import { CMSTERMSCONDITIONAPI } from "../../Constant/Apiconstant";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../Constant/Loader";

const TermsCondition = () => {
  const { response, isloading } = Fetchdata(CMSTERMSCONDITIONAPI, {}, "GET");
  const navigate = useNavigate();

  return (
    <>
      <Header text={"Terms condition"} onClick={() => navigate(-1)} />
      <div className="p-3">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <p
            style={{ fontSize: "25px" }}
            dangerouslySetInnerHTML={{ __html: response?.data?.text }}
          ></p>
        )}
      </div>
    </>
  );
};

export default TermsCondition;
