import React, { useState } from "react";
import "./CorporateLoginPopup.css";
import close from "../../Assets/Images/search_ic@3x.png";
import { motion } from "framer-motion";
import Fetchdata from "../../Constant/Fetchdata";
import { CorporateLoginApi } from "../../Constant/Apiconstant";
import { useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
const CorporateLoginPopup = ({
  text,
  Popup,
  setPopup,
  handleCloseeee,
  Error,
  setError,
}) => {
  const data = [
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
    {
      Organization: "Organization Name",
    },
  ];

  const { response } = Fetchdata(CorporateLoginApi, "GET");

  // console.log(response, "first");

  const navigate = useNavigate();

  const handleId = (Item) => {
    navigate("/Login", {
      state: {
        Item,
      },
    });
  };
  return (
    <>
      <Modal show={Popup} size="md" centered>
        <div>
          <Modal.Header
            style={{
              display: "flex",
              placeItems: "end",
              width: "100% ",
              alignItems: "center",
              justifyContent: "end",
              border: "0",
            }}
            closeButton
            onClick={() => setPopup(false)}
          >
            <div>
              <span className="Organizations-main-span">
                {" "}
                Find Organizations
              </span>
            </div>
          </Modal.Header>
        </div>
        <Modal.Header
          style={{
            display: "grid",
            placeItems: "end",
            width: "100% ",
            justifyContent: "center",
            border: "0",
          }}
        >
          <input className="search-main-Organizations" type="search" />
        </Modal.Header>
        <div className="expalin-div-sec">
          {response?.data?.map((Item, Index) => {
            // console.log(Item._id, "Item");
            return (
              <div
                onClick={() => handleId(Item._id)}
                key={Index}
                className="Organization-sapn-div"
              >
                <span className="Organization-sapn"> {Item?.hospitalName}</span>
                <div className="line-div"></div>
              </div>
            );
          })}
        </div>
      </Modal>
      {/* <div className="model">
        <motion.div
          key={"model"}
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="Pcustomizemodel"
        >
          <div className="cardcontent" style={{ borderRadius: "10px" }}>
            <div className="closebtn" onClick={onClick}>
              <span className="Find-main-span">Find Organizations</span>
              <div className="close-main-input">
                <img className="close-serach-img" src={close} alt="" />
                <input className="popup-search" type="search" />
              </div>
            </div>

            {children}
          </div>
        </motion.div>
      </div> */}
    </>
  );
};

export default CorporateLoginPopup;
