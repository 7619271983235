import React, { useState, useEffect } from "react";
import "./Availability.css";
import axios from "../../Constant/ApiUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Thisispaid from "../../Doctor/Thisispaid/Thisispaid";
import { AvailablityApi } from "../../Constant/Apiconstant";
import { VirtualTimesApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Fetchdata from "../../Constant/Fetchdata";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
const Availablity = () => {
  const { t } = useTranslation();

  const [isMonthUnavailable, setIsMonthUnavailable] = useState(false);
  const [adsdataPop, setAdsdataPop] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  // console.log(selectedDays, "selectedDays");
  const [selectedTimes, setSelectedTimes] = useState([]);
  // console.log(selectedTimes, "selectedDays");
  const [isChecked, setIsChecked] = useState(false);
  const [showVirtualTimes, setShowVirtualTimes] = useState(false);
  const [showInPersonTimes, setShowInPersonTimes] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  // usestate for virtual or inperson
  const [virtualTimes, setVirtualTimes] = useState([]);
  const [inPersonTimes, setInPersonTimes] = useState([]);

  const data = JSON.parse(localStorage.getItem("Nezziedata"));
  const handleCloseeee = () => {
    setAdsdataPop(true);
  };

  const header = Headers();

  const { response } = Fetchdata(AvailablityApi, header, "GET");

  // console.log(response, "response");
  // const addavailiblity = async () => {
  //   if (selectedDays.length === 0 || selectedTimes.length === 0) {
  //     toast.error("Please select both day and time", {
  //       position: "top-right",
  //       theme: "colored",
  //     });
  //     return;
  //   }
  //   try {
  //     const res = await axios.post(
  //       "avaibillity/addavaibillity",
  //       {
  //         availableOn: selectedDays,
  //         session: virtualTimes.map((time) => ({
  //           mettingType: "virtual",
  //           slotTime: time,
  //         })),
  //         ratePerHours: "13",
  //       },
  //       {
  //         headers: {
  //           "Content-type": "application/json",
  //           Accept: "application/json",
  //           Authorization: data.token,
  //         },
  //       }
  //     );
  //     if (res.data.data === "Avaibility Set Successfully") {
  //       toast.success(res.data.message, {
  //         position: "top-right",
  //         theme: "colored",
  //       });
  //     } else {
  //       toast.success(res.data.message, {
  //         position: "top-right",
  //         theme: "colored",
  //       });
  //     }
  //     setSelectedDays([]);
  //     setVirtualTimes([]);
  //     setSelectedTimes([]);
  //     console.log(res);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  const onSubmit = async () => {
    try {
      const res = await axios.post(
        VirtualTimesApi,
        {
          availableOn: selectedDays,
          appointmentType: "virtual",
          sessi2ons: JSON.stringify(selectedTimes),
          isSameForWeekends: isChecked,
        },

        header
      );
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      }

      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
    }
  };

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // console.log(days);
  const times = [
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 AM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 PM",
  ];

  useEffect(() => {
    // Set virtual time slots active when component
    setShowVirtualTimes(true);
  }, []);

  const handleDayClick = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
    // console.log("slected day", day);
  };

  const handleTimeClick = (time) => {
    // Check if virtual times are shown, then update virtualTimes state
    if (showVirtualTimes) {
      setVirtualTimes((prevTimes) =>
        prevTimes.includes(time)
          ? prevTimes.filter((t) => t !== time)
          : [...prevTimes, time]
      );
      // console.log("selected", time);
    }

    // Check if in-person times are shown, then update inPersonTimes state
    if (showInPersonTimes) {
      setInPersonTimes((prevTimes) =>
        prevTimes.includes(time)
          ? prevTimes.filter((t) => t !== time)
          : [...prevTimes, time]
      );
    }

    setSelectedTimes((prevTimes) =>
      prevTimes.includes(time)
        ? prevTimes.filter((t) => t !== time)
        : [...prevTimes, time]
    );
  };
  const toggleButton = () => {
    setIsToggled((prevIsToggled) => (prevIsToggled ? 0 : 1));
  };

  // console.log(isChecked, "isChecked");
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const navigate = useNavigate();
  // console.log(isToggled);
  return (
    <>
      {" "}
      <Header onClick={() => navigate(-1)} text={"Availablity"} />{" "}
      <section className="Availablity-main-section">
        <div className="height-Availablity-main-div">
          <div className="available-sapan-90-div">
            <span className="available-sapan-90">{t("Available Days")}</span>
          </div>
          <div className="days-button-div">
            {response?.data?.availableOn.map((day) => (
              <button
                key={day}
                className={`days-button-on ${
                  selectedDays.includes(day) ? "active" : ""
                }`}
                onClick={() => handleDayClick(day)}
              >
                {t(day)}
              </button>
            ))}
          </div>

          <div className="Whole-Month-and-togle-divv">
            <div className="Whole-unavail-divv">
              <span className="Whole-unavail-spaan-tagg">
                Same timing for weekdays
              </span>
            </div>
            <label className="switch">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div>
            <button className="Virtual-main-btn">Virtual</button>
            <button onClick={handleCloseeee} className="Person-main-btn">
              in Person
            </button>
          </div>
          {showVirtualTimes || showInPersonTimes ? (
            <div className="time-slots">
              {response?.data?.sessions?.map((time) => (
                <>
                  <button
                    className={`days-button ${
                      selectedTimes.includes(time) ? "active" : ""
                    }`}
                    onClick={() => handleTimeClick(time)}
                  >
                    {time.slotTime}
                  </button>
                </>
              ))}
            </div>
          ) : null}

          <div>
            <button className="save-button-090" onClick={onSubmit}>
              {t("Save")}
            </button>{" "}
          </div>
        </div>
        {adsdataPop && (
          <Thisispaid
            adsdataPop={adsdataPop}
            setAdsdataPop={setAdsdataPop}
            text={"Enter Name of Medicine"}
          />
        )}
      </section>
    </>
  );
};

export default Availablity;
