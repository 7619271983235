import React, { useContext, useState } from "react";
import "./RateDoctor.css";
import Modal from "react-bootstrap/Modal";
// import img from "../../Assets/Images/LoginDocter.jpg";
import { BsFillStarFill } from "react-icons/bs";
import axios from "../../Constant/ApiUrl";
import { ReviewRating } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Loader from "../../Constant/Loader";
import { toast } from "react-toastify";
import { GlobalContext } from "../../Context/GlabalContext";
import { useNavigate } from "react-router-dom";

const RateDoctor = ({ show, setShow, maindataitm, PationData }) => {
  // const handleShow = () => {
  //   setShow(true);
  // };

  // const handleDoneClick = () => {
  //   handleClose(); // Close the modal after performing actions
  // };
  const navigate = useNavigate();
  // console.log(PationData, "PationData");

  // const user = PationData?.data?.doctor_id;
  // console.log(user);
  const { setPatcutCall } = useContext(GlobalContext);
  const [selected, setSelected] = useState(1);
  const [review, setreview] = useState("");
  const [loading, setloading] = useState(false);
  const StarGenerator = (numofstar = 5) => {
    // console.log(selected, "seleted");
    return Array(numofstar)
      .fill()
      .map((item, i) => (
        <Star
          key={i}
          selected={selected > i}
          onSelected={() => setSelected(i + 1)}
        />
      ));
  };

  const Star = ({ selected, onSelected }) => {
    return (
      <BsFillStarFill
        className="fastar-mnb"
        color={selected ? "goldenrod" : "#E0DBCE"}
        onClick={onSelected}
      />
    );
  };
  const handleCloseee = () => {
    if (show) {
      setShow(false);
    } else {
      navigate("/home-page");
    }
  };

  // console.log(maindataitm);

  const header = Headers();

  const handleSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        ReviewRating,
        {
          doctorId: maindataitm?.appointment?.doctor_id?._id,
          appointmentId: maindataitm?.appointment?._id,
          waitingRoomId: maindataitm?.waitingRoomId,
          rating: selected,
          review: review,
        },
        header
      );
      // console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        setPatcutCall(false);
        setShow(false);
      } else if (res.data.status === "failed") {
        toast.error(res.data.message);
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <>
      {/* <button onClick={handleShow}>Open Modal</button> */}
      <Modal show={show} size="md" centered>
        <Modal.Header
          style={{
            display: "grid",
            placeItems: "end",
            width: "100% ",
            justifyContent: "end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="thard-div-rating">
          <div className="svg-chaing">
            <div className="us-usa-div">
              <span></span>
              <span className="Doctor-span-Skip">Rate this Doctor</span>
              <span onClick={handleCloseee} className="usa-span-Skip">
                Skip
              </span>
            </div>
            <div className="login-img-used">
              <img
                className="Linnie-main-img"
                src={
                  maindataitm?.appointment?.doctor_id?.basicInfo?.image ||
                  maindataitm?.doctor_id?.basicInfo?.image
                }
                alt=""
              />
              <span className="Malone-linnie-sapan">
                {maindataitm?.appointment?.doctor_id?.basicInfo?.firstName ||
                  maindataitm?.doctor_id?.basicInfo?.fullName}
                {maindataitm?.appointment?.doctor_id?.basicInfo?.lastName}
              </span>
            </div>
          </div>
          <div className="mt-4 star-rating-div">{StarGenerator(5)}</div>
          <div className="text-span-div">
            <div className="span-you-div">
              <span className="Doctor-span-Skip text-center">
                Write your review
              </span>
            </div>
            <textarea
              id="w3review"
              name="message2"
              rows="4"
              className="here-input-2   mt-2"
              cols="50"
              placeholder="Write here"
              onChange={(e) => setreview(e.target.value)}
            ></textarea>
          </div>
          <div className="mt-4">
            <button
              type="button"
              onClick={handleSubmit}
              className="Submit-mbtn-with-pop"
            >
              Submit
            </button>
          </div>
        </div>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-Torso-main-div"
            style={{ borderRadius: "10px" }}
          ></div>
        </div>
      </Modal>
      {loading && <Loader />}
    </>
  );
};

export default RateDoctor;
