import React, { useContext, useEffect, useState } from "react";
import card from "../../Assets/Images/cardpoint.png";
// import Card2 from "../../Assets/Images/cardpoint2.png";

import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import DataSuccessfully from "../../Doctor/DataSuccessfully/DataSuccessfully";
// import Loader from "../../Constant/Loader";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { Icons } from "../../Constant/Icons";
import Loader, { SimpleLoader } from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import axios from "../../Constant/ApiUrl";
import { Headers } from "../../Constant/Fetchdata";
import { FaRegFilePdf } from "react-icons/fa6";
import {
  PATIENTCARDLISTAPI,
  //   prescriptionAppointmentApi,
  SickNotePaymentApi,
} from "../../Constant/Apiconstant";
import { lightGreen } from "@mui/material/colors";
import { toast } from "react-toastify";

const SickNotePayment = () => {
  const route = useLocation();
  const maindata = route?.state?.maindataitm;

  // console.log(maindata, "maindata");
  const [loading, setloading] = useState(false);

  const [PDfres, setPDfres] = useState("");
  // console.log(PDfres, "PDfres");
  const [Popup, setPopup] = useState(false);
  const [selectedcard, setselectedcard] = useState({
    cardid: "",
    isSelected: null,
  });

  // console.log(selectedcard, "selectedcard");

  const { precriptionselecteddata, setprecriptionselecteddata } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const { response, isloading } = Fetchdata(PATIENTCARDLISTAPI);

  useEffect(() => {
    setprecriptionselecteddata((prev) => ({
      ...prev,
      cardID: selectedcard.cardid,
    }));
  }, [selectedcard]);
  const header = Headers();
  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SickNotePaymentApi,
        {
          appointmentId: maindata?.appointment?._id,
          cardID: selectedcard?.cardid,
          sickNoteAmount: maindata?.appointment?.consultation_fee,
          sickNoteCurrency: maindata?.appointment?.payment_currency,
        },
        header
      );
      if (res.status === 200 && res.data.status === "success") {
        setPDfres(res.data.data.sickNotePdf);
        setPopup(true);
        // toast.success(res.data.message);
      } else if (res.data.status === "failed") {
        toast.error(res.data.message);
      }
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const generatePdfLink = (pdfLink) => (
    <a href={pdfLink}>
      <FaRegFilePdf className="Sick-NOte-mmain" />
    </a>
  );

  return (
    <>
      <Header onClick={() => navigate(-1)} text={"SickNote payment"} />

      <section className="Notifications-main-section">
        <div className="Notifications-main-div">
          {/* <div className="payment-span-divv-head">
            <span className="Payment-Options-span">Credit/Debit Cards</span>
          </div> */}
          {isloading ? (
            <SimpleLoader style={{ height: "300px" }} />
          ) : (
            <div className="pymaint-div-thard">
              {response?.data?.map((item, index) => {
                return (
                  <div
                    className="payment-input67"
                    onClick={() =>
                      setselectedcard({ isSelected: index, cardid: item.id })
                    }
                    key={item.id}
                  >
                    <img src={card} alt="" className="visaimg-img" />
                    <span className="card-span-divv">
                      XXXX XXXX XXXX{item.last4}
                    </span>
                    <div className="dustbin-logo-black">
                      <span>
                        {selectedcard.isSelected === index
                          ? Icons.cardselecticon
                          : null}
                      </span>
                      {/* <span>{Icons.dottedicon}</span> */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="d-flex gap-2 mb-2 align-items-center mt-3">
            <Buttton
              style={{
                border: `1px solid ${Colors.blue}`,
                backgroundColor: "white",
                color: Colors.blue,
                width: "150px",
              }}
              onClick={() => navigate("/Addcard")}
            >
              Add new card
            </Buttton>
            <Buttton
              onClick={onSubmit}
              style={{ backgroundColor: Colors.blue, width: "150px" }}
            >
              Pay
            </Buttton>
          </div>
        </div>
      </section>
      {loading && <Loader />}
      {Popup && (
        <DataSuccessfully
          Popup={Popup}
          handleCloseeee={generatePdfLink(PDfres)}
          setPopup={setPopup}
          text={
            "You can now see the sick note.An email has been sent to your registered email address"
          }
        />
      )}
    </>
  );
};

export default SickNotePayment;
