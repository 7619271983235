import React from "react";

import Bookedimg from "../../Assets/Images/successfully_illustration@3x.png";
import { useNavigate } from "react-router-dom";
const Pymentsucssas = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="Congratulations-main-section">
        <div className="Congratulations-main-div">
          <div className="thard-con-div">
            <div>
              <img className="con-img-w" src={Bookedimg} alt="" />
            </div>
            <div className="mt-4">
              <span className="failed-span">Congratulations!</span>
            </div>
            <div className="successfully-div mt-3">
              <span className="like-span">
                You have successfully booked your accommodation.
              </span>
            </div>
            <div className="mt-4">
              <button onClick={() => navigate("/home-page")} className="ok-btn">
                OK
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pymentsucssas;
