import "./Thisispaid.css";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const Thisispaid = ({
  text,
  adsdataPop,
  handleCloseeee1,
  // setAdsdataPop,
  // id,
}) => {
  const Okid = "12";

  const handleid = async () => {
    navigate("/ContactUs", {
      state: {
        Okid,
      },
    });
  };

  const navigate = useNavigate();
  return (
    <>
      <Modal show={adsdataPop} size="md" centered>
        <Modal.Header
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-Sick-con-div-been">
              <div className="Physician-div">
                <span>{text}</span>
              </div>
            </div>
          </div>
          <div className="No-contact-main-btn-div">
            <button
              onClick={handleCloseeee1}
              className="No-contact-main-btn  mt-3 mb-3"
            >
              No
            </button>
            <button
              onClick={handleid}
              className="No-contact-main-btn  mt-3 mb-3"
            >
              Contact
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Thisispaid;
