/* eslint-disable no-undef */
import { combineReducers } from "redux";
import SessionReducer from "./SessionReducer";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/es/storage";

const persistConfig = {
  key: "session",
  storage: localStorage,
  whitelist: ["session"],
};

const appreducer = combineReducers({
  session: SessionReducer,
});

const persistedReducer = persistReducer(persistConfig, appreducer);

export default (state, action) => {
  return persistedReducer(state, action);
};

export const registrationReducer = () => {
  switch (action.type) {
    case "UPDATE_STEP1_DATA":
      return { ...state, step1Data: action.payload };
    case "UPDATE_STEP2_DATA":
      return { ...state, step2Data: action.payload };
    case "UPDATE_STEP3_DATA":
      return { ...state, step3Data: action.payload };

    default:
      return state;
  }
};
