import React, { useState, useEffect } from "react";
import axios from "../../Constant/ApiUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Thisispaid from "../../Doctor/Thisispaid/Thisispaid";
import { AvailablityApi } from "../../Constant/Apiconstant";
import { VirtualTimesApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Fetchdata from "../../Constant/Fetchdata";
import Loader from "../../Constant/Loader";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import DataSuccessfully from "../../Doctor/DataSuccessfully/DataSuccessfully";

const SetAvailablity = () => {
  const { t } = useTranslation();

  const [adsdataPop, setAdsdataPop] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  // console.log(selectedDays, "selectedDays");
  const [selectedTimes, setSelectedTimes] = useState([]);
  // console.log(selectedTimes, "selectedDays");
  const [isChecked, setIsChecked] = useState(false);
  const [showVirtualTimes, setShowVirtualTimes] = useState(false);
  const [showInPersonTimes, setShowInPersonTimes] = useState(false);
  // console.log(showVirtualTimes, "showVirtualTimes");
  // console.log(showInPersonTimes, "showInPersonTimes");
  const [isToggled, setIsToggled] = useState(false);
  const Mydata = store.getState().session[KEY_USER_DATA];
  const [Popup, setPopup] = useState(false);
  // console.log(Mydata, "Mydata");
  const [loading, setloading] = useState(false);
  const [activeButton, setActiveButton] = useState("virtual");
  const [virtualTimes, setVirtualTimes] = useState([]);
  const [inPersonTimes, setInPersonTimes] = useState([]);

  // console.log("Mydata.inPersonApporovel:", Mydata.inPersonApporovel);
  const handleCloseeee = () => {
    if (!Mydata.inPersonApporovel) {
      setAdsdataPop(true);
    } else {
      setShowInPersonTimes(true);
    }
  };

  const header = Headers();

  const { response } = Fetchdata(AvailablityApi, header, "GET");
  // console.log(response, "response");
  // console.log(response?.data?.sessions, "response");
  // console.log(response?.data?.sessions, "response");

  useEffect(() => {
    if (response?.data?.availableOn) {
      setSelectedDays(response?.data?.availableOn);
      setSelectedTimes(response?.data?.sessions);
    }
  }, [response]);

  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        VirtualTimesApi,
        {
          availableOn: selectedDays,
          appointmentType: activeButton,
          timeSlotDuration: "30 minutes",
          sessions: JSON.stringify(selectedTimes),
          isSameForWeekends: isChecked,
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        setPopup(true);
        navigate("/myprofile");
      }

      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  // console.log(selectedDays, "selectedDays");
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // console.log(days);
  const times = [
    "01:00 AM",
    "01:15 AM",
    "01:30 AM",
    "01:45 AM",
    "02:00 AM",
    "02:15 AM",
    "02:30 AM",
    "02:45 AM",
    "03:00 AM",
    "03:15 AM",
    "03:30 AM",
    "03:45 AM",
    "04:00 AM",
    "04:15 AM",
    "04:30 AM",
    "04:45 AM",
    "05:00 AM",
    "05:15 AM",
    "05:30 AM",
    "05:45 AM",
    "06:00 AM",
    "06:15 AM",
    "06:30 AM",
    "06:45 AM",
    "07:00 AM",
    "07:15 AM",
    "07:30 AM",
    "07:45 AM",
    "08:00 AM",
    "08:15 AM",
    "08:30 AM",
    "08:45 AM",
    "09:00 AM",
    "09:15 AM",
    "09:30 AM",
    "09:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
    "10:45 AM",
    "11:00 AM",
    "11:15 AM",
    "11:30 AM",
    "11:45 AM",
    "12:00 AM",
    "12:15 AM",
    "12:30 AM",
    "12:45 AM",
    "01:00 PM",
    "01:15 PM",
    "01:30 PM",
    "01:45 PM",
    "02:00 PM",
    "02:15 PM",
    "02:30 PM",
    "02:45 PM",
    "03:00 PM",
    "03:15 PM",
    "03:30 PM",
    "03:45 PM",
    "04:00 PM",
    "04:15 PM",
    "04:30 PM",
    "04:45 PM",
    "05:00 PM",
    "05:15 PM",
    "05:30 PM",
    "05:45 PM",
    "06:00 PM",
    "06:15 PM",
    "06:30 PM",
    "06:45 PM",
    "07:00 PM",
    "07:15 PM",
    "07:30 PM",
    "07:45 PM",
    "08:00 PM",
    "08:15 PM",
    "08:30 PM",
    "08:45 PM",
    "09:00 PM",
    "09:15 PM",
    "09:30 PM",
    "09:45 PM",
    "10:00 PM",
    "10:15 PM",
    "10:30 PM",
    "10:45 PM",
    "11:00 PM",
    "11:15 PM",
    "11:30 PM",
    "11:45 PM",
    "12:00 PM",
    "12:15 PM",
    "12:30 PM",
    "12:45 PM",
  ];

  useEffect(() => {
    // Set virtual time slots active when component
    setShowVirtualTimes(true);
  }, []);

  const handleDayClick = (day) => {
    setSelectedDays((prevDays) => {
      if (prevDays.includes(day)) {
        return prevDays.filter((i) => i != day);
      } else {
        return [...prevDays, day];
      }
    });
  };
  const handleTimeClick = (time, sessionType) => {
    const newTime = { slotTime: time, slotType: sessionType };

    setSelectedTimes((prevTimes) => {
      const existingTimeIndex = prevTimes.findIndex(
        (t) =>
          t.slotTime === newTime.slotTime && t.slotType === newTime.slotType
      );

      if (existingTimeIndex !== -1) {
        return prevTimes.filter(
          (t) =>
            !(
              t.slotTime === newTime.slotTime && t.slotType === newTime.slotType
            )
        );
      } else {
        return [...prevTimes, newTime];
      }
    });

    // Update virtual and in-person times separately if needed
    if (sessionType === "virtual") {
      setVirtualTimes((prevTimes) =>
        prevTimes.includes(newTime)
          ? prevTimes.filter((t) => t !== newTime)
          : [...prevTimes, newTime]
      );
    } else if (sessionType === "inperson") {
      setInPersonTimes((prevTimes) =>
        prevTimes.includes(newTime)
          ? prevTimes.filter((t) => t !== newTime)
          : [...prevTimes, newTime]
      );
    }
  };

  const toggleButton = (buttonType) => {
    setIsToggled(false);
    setActiveButton(buttonType);

    if (buttonType === "virtual") {
      setShowVirtualTimes(true);
      setShowInPersonTimes(false);
    } else if (buttonType === "inperson") {
      setShowVirtualTimes(false);
      setShowInPersonTimes(true);
      if (!Mydata.inPersonApporovel) {
        setAdsdataPop(true);
      } else {
        setShowInPersonTimes(true);
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const navigate = useNavigate();
  const handleCloseeee1 = () => {
    if (adsdataPop) {
      setAdsdataPop(false);
      setShowVirtualTimes(true);
      setShowInPersonTimes(false);
      setActiveButton("virtual");
    }
  };
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Availablity"} />{" "}
      <section className="Availablity-main-section">
        <div className="Main-avlebal-pop">
          <div className="available-sapan-90-div">
            <span className="available-sapan-90">{t("Available Days")}</span>
          </div>
          <div className="days-button-div-second">
            {days.map((day) => (
              <button
                key={day}
                className={`days-button-on ${
                  selectedDays.includes(day) ? "active" : ""
                }`}
                onClick={() => handleDayClick(day)}
              >
                {t(day)}
              </button>
            ))}
          </div>
          <div className="Whole-Month-and-togle-divv">
            <div className="Whole-unavail-divv">
              <span className="Whole-unavail-spaan-tagg">
                Same timing for weekdays
              </span>
            </div>
            <label className="switch">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div>
            <button
              className={`Person-main-btnop ${
                activeButton === "virtual" ? "active" : ""
              }`}
              onClick={() => toggleButton("virtual")}
            >
              Virtual
            </button>
            <button
              className={`Person-main-btnop ${
                activeButton === "inperson" ? "active" : ""
              }`}
              onClick={() => toggleButton("inperson")}
            >
              In Person
            </button>
          </div>
          {showVirtualTimes && (
            <div className="time-slots-avlebalhai">
              {times.map((time) => (
                <button
                  key={time}
                  className={`days-button ${
                    selectedTimes.some(
                      (t) => t.slotType === "virtual" && t.slotTime === time
                    )
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleTimeClick(time, "virtual")}
                >
                  {time}
                </button>
              ))}
            </div>
          )}
          {showInPersonTimes && (
            <div className="time-slots-avlebalhai">
              {times.map((time) => (
                <button
                  key={time}
                  className={`days-button ${
                    selectedTimes.some(
                      (t) => t.slotType === "inperson" && t.slotTime === time
                    )
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    handleTimeClick(time, isToggled ? " inperson" : "inperson")
                  }
                >
                  {time}
                </button>
              ))}
            </div>
          )}
          <div>
            <button className="save-button-090" onClick={onSubmit}>
              {t("Save")}
            </button>{" "}
          </div>
        </div>
      </section>
      {adsdataPop && (
        <Thisispaid
          adsdataPop={adsdataPop}
          setAdsdataPop={setAdsdataPop}
          handleCloseeee1={handleCloseeee1}
          text={"Enter Name of Medicine"}
        />
      )}
      {Popup && (
        <DataSuccessfully
          Popup={Popup}
          handleCloseeee={() => navigate("/DoctorBook")}
          setPopup={setPopup}
          text={"Doctor Book updated Successfully"}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default SetAvailablity;
