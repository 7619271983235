import React, { useState } from "react";
import "./Healthcard-CreditCard.css";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { HEALTHCARDDATAAPI } from "../../Constant/Apiconstant";
import { paymenttype } from "../../Constant/DummyData";
import { Colors } from "../../Constant/Colors";
// import PaymentMethod from "../PaymentMethod/PaymentMethod";
import Buttton from "../../Components/Button/Buttton";
import { SimpleLoader } from "../../Constant/Loader";
import AddModel from "./AddModel";
import PendingInsurance from "../PendingInsurance/PendingInsurance";
import HelthCardPayment from "../HelthCardPayment/HelthCardPayment";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
const HealthcardCreditcard = () => {
  const [selected, setselected] = useState(0);
  const [showmodel, setshowmodel] = useState(false);
  const navigate = useNavigate();
  const { response, isloading } = Fetchdata(HEALTHCARDDATAAPI);
  const Mydata = store.getState().session[KEY_USER_DATA];

  //   console.log(showmodel);

  const handlenavigate = (num) => {
    if (num === 2) {
      navigate("/Addcard");
    } else if (num === 1) {
      navigate("/Addhealthcard");
    } else if (num === 3) {
      navigate("/HealthInsurance");
    }
  };

  const insuranceshow = Mydata?.hospital_id;
  let slicee = insuranceshow !== undefined ? "" : "0,2";
  const numberArray = slicee.split(",").map(Number);
  const slicedPaymentTypes = paymenttype.slice(numberArray[0], numberArray[1]);

  return (
    <>
      <Header text={"HealthCard/CreditCard"} onClick={() => navigate(-1)} />
      <form className="mt-3 w-100 h-100 px-3 d-flex flex-column align-items-center g-3">
        <div className="paymenttype">
          {slicedPaymentTypes?.map((item, index) => {
            return (
              <div
                style={{
                  backgroundColor: selected === index ? Colors.blue : null,
                }}
                onClick={() => setselected(index)}
                key={item.id}
              >
                <h5
                  style={{
                    color: selected === index ? "#ffffff" : null,
                    textAlign: "center",
                  }}
                >
                  {item.name}
                </h5>
              </div>
            );
          })}
        </div>
        {selected === 0 ? (
          <>
            {isloading ? (
              <SimpleLoader style={{ height: "300px" }} />
            ) : (
              <>
                {response?.data === undefined ? (
                  <div
                    style={{ height: "300px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <h1 className="text-center">No Cards Found!</h1>
                  </div>
                ) : (
                  <div className="healthcarddivparent">
                    {response?.data?.map((item) => {
                      return (
                        <div className="FindMD-img-contain">
                          <div className="healthcarddiv" key={item._id}>
                            <img src={item.image} alt="" />
                            <div>
                              <h4>{item.PatientName}</h4>
                              <h5>
                                Card Number:{" "}
                                <span>{item.healthCardNumber}</span>{" "}
                              </h5>
                              <h5>
                                Version Code <span>{item.versionCode}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </>
        ) : selected === 1 ? (
          <>
            <HelthCardPayment />
          </>
        ) : (
          <PendingInsurance />
        )}
        <Buttton
          onClick={() => setshowmodel(true)}
          style={{ backgroundColor: Colors.blue, margin: "1rem 1rem" }}
          type={"button"}
        >
          Add New
        </Buttton>
      </form>
      {showmodel && (
        <AddModel
          btn1={"Add New Health Card"}
          btn2={"Add New Credit Card"}
          btn3={"Add Insurance"}
          handleClick={handlenavigate}
          showmodel={showmodel}
          setshowmodel={setshowmodel}
        />
      )}
    </>
  );
};

export default HealthcardCreditcard;
