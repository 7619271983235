import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";

const DataSuccessfully = ({
  text,
  Popup,
  setPopup,
  handleCloseeee,
  Error,
  PatcutCall,
  setPatcutCall,
  setError,
}) => {
  return (
    <>
      <Modal show={Popup || Error || PatcutCall} size="md" centered>
        <Modal.Header
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-Sick-con-div-been">
              <div className="Physician-div">
                <span>{text}</span>
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={handleCloseeee}
              className="available-mbtn-with-pop mt-3 mb-3"
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DataSuccessfully;
