import { Button, Dialog, DialogActions } from "@mui/material";
import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./RejectionPopup.css";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../Context/GlabalContext";

const RejectionPopup = ({
  PopupName,
  setPopupName,
  handleCloseeee,
  placetext,
  lebaltext,
  Spantext,
}) => {
  const { setresion, resion } = useContext(GlobalContext);
  const [error, setError] = useState(false);

  // console.log(resion, "resion");

  const handleNoteChange = (event) => {
    const inputValue = event.target.value;

    setresion(inputValue);

    setError(inputValue.trim() === "");
  };

  return (
    <>
      <Modal show={PopupName} size="md" centered>
        <Modal.Header
          //   onClick={() => setPopupName(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",

            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <span> {Spantext}</span>
          <div
            className="Reason-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <span className="w-100">{lebaltext}</span>
            <textarea
              id="w3review"
              rows="4"
              className="Sick-notes-input-2  mt-2"
              cols="50"
              placeholder={placetext}
              name="Reason"
              value={resion}
              onChange={handleNoteChange}
            ></textarea>
            <div className="w-80">
              {error && (
                <p className="error-message-Note">Note cannot be empty</p>
              )}
            </div>
          </div>

          <div>
            <button
              onClick={handleCloseeee}
              className="available-mbtn-with-pop mt-3 mb-3"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RejectionPopup;
