import React, { useEffect, useState } from "react";
import "./SelectLab.css";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Cardimg from "../../Assets/Images/ForgotPasswordImg.avif";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
const SelectLab = () => {
  const data = [
    {
      img: Cardimg,
      locationimg: location,
      Name: "Excelsior Diagnostics",

      loction: "2184 Eglinton Avenue, Toronto",
    },
    {
      img: Cardimg,
      locationimg: location,
      Name: "Stream Laboratory",

      loction: "2184 Eglinton Avenue, Toronto",
    },
    {
      img: Cardimg,
      locationimg: location,
      Name: "Excelsior Diagnostics",

      loction: "2184 Eglinton Avenue, Toronto",
    },
    {
      img: Cardimg,
      locationimg: location,
      Name: "Stream Laboratory",

      loction: "2184 Eglinton Avenue, Toronto",
    },
    {
      img: Cardimg,
      locationimg: location,
      Name: "Excelsior Diagnostics",

      loction: "2184 Eglinton Avenue, Toronto",
    },
    {
      img: Cardimg,
      locationimg: location,
      Name: "Willie Chambers",

      loction: "2184 Eglinton Avenue, Toronto ",
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Select lab"}
        style={{ color: "white" }}
      />
      <section className="FindMD-main-section">
        <div className="FindMD-main-div">
          {data.map((itm, index) => {
            return (
              <div className="FindMD-img-contain">
                <div>
                  <img className="Cardimg-docter" src={itm.img} alt="" />
                </div>
                <div className="Dorothy-Lucas-sapn-div">
                  <div className="Lucas-awy-main-div">
                    <div className="Dorothy-extra-div">
                      <span className="Dorothy-sapn">{itm.Name}</span>
                    </div>
                  </div>
                  <div className="">
                    <img
                      className="time-img-css"
                      src={itm.locationimg}
                      alt=""
                    />
                    <span className="Am-pm-span"> {itm.loction}</span>
                  </div>
                  <div className="mt-3">
                    <button className="SelectLab-sand-btn">Send</button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default SelectLab;
