import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// import close from "../../Assets/Images/search_ic@3x.png";
// import { motion } from "framer-motion";
import { GlobalContext } from "../../Context/GlabalContext";
import axios from "../../Constant/ApiUrl";
import {
  completeAppointment,
  doctorwaitingRoomComplete,
  patientwaitingRoomComplete,
} from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import { toast } from "react-toastify";
import { KEY_USER_DATA, Meetingdata } from "../../Redux/Appconstant";
import { store } from "../../Redux/store";
import Loader from "../../Constant/Loader";
import { useDispatch } from "react-redux";
import { setSessionField } from "../../Redux/SessionAction.js";

const CutVedioCallpopup = ({
  text,
  waitingRoomId,
  isSimpleCall,
  appointmentID,
}) => {
  // const route = useLocation();
  // const WatingroomID = route?.state?.maindata;
  const userdata = store.getState().session[KEY_USER_DATA];

  // console.log(isSimpleCall);
  const header = Headers();
  const { CutVedio, setCutVedio, setisCutcall } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const handleCloseeee = () => {
  //   navigate(-1);
  //   // setCutVedio(false);
  // };

  const waitingcompleteappointment =
    userdata?.basicInfo?.role === "physician"
      ? doctorwaitingRoomComplete
      : patientwaitingRoomComplete;

  const simpleappointmentcomplete = completeAppointment;

  const mainurl = isSimpleCall
    ? simpleappointmentcomplete
    : waitingcompleteappointment;

  const WatingroomApi = async () => {
    const param = {
      waitingRoomID: waitingRoomId,
      status: "complete",
    };
    const simpleAppointmentparam = {
      appointmentId: appointmentID,
      status: "complete",
    };
    setloading(true);
    try {
      const res = await axios.post(
        mainurl,
        isSimpleCall ? simpleAppointmentparam : param,
        header
      );
      // console.log(res);
      setloading(false);
      if (
        res.status === 200 &&
        res.data.message === "Appointment completed successfully"
      ) {
        setCutVedio(false);
        setisCutcall(true);
        dispatch(setSessionField(Meetingdata, null));
        toast.success(res.data.message);
        localStorage.removeItem("kittoken");
        localStorage.setItem("isJoined", false);
      } else if (
        res.status === 200 &&
        res.data.message === "Something Went Wrong, Please Try Again Later"
      ) {
        toast.error(res.data.message);
        // setCutVedio(false);
        // localStorage.removeItem("kittoken");
        // localStorage.setItem("isJoined", false);
      }
      //   setresponse(res.data);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <>
      <Modal show={CutVedio} size="md" centered>
        <Modal.Header
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-Sick-con-div-been">
              <div className="Physician-div">
                <span>{text}</span>
              </div>
            </div>
          </div>
          <div className="No-contact-main-btn-div">
            <button
              onClick={() => {
                // navigate(-1);
                setCutVedio(false);
              }}
              className="No-contact-main-btn  mt-3 mb-3"
            >
              No
            </button>
            <button
              onClick={WatingroomApi}
              className="No-contact-main-btn  mt-3 mb-3"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      {loading && <Loader />}
    </>
  );
};

export default CutVedioCallpopup;
