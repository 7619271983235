import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../Redux/store";
import { KEY_USER_DATA, Meetingdata } from "../Redux/Appconstant";
import { CiEdit } from "react-icons/ci";
import axios from "../Constant/ApiUrl";
import { Headers } from "../Constant/Fetchdata";
import {
  StopTimeGet,
  StopTimeSet,
  doctorjoinigwaitingRoom,
  patientjoinigwaitingRoom,
} from "../Constant/Apiconstant";
import { toast } from "react-toastify";
import { GlobalContext } from "../Context/GlabalContext";
// import CutVedioCallpopup from "../Doctor/CutVedioCallpopup/CutVedioCallpopup";
import { useDispatch } from "react-redux";
import { setSessionField } from "../Redux/SessionAction";
import "./Videocall.css";

export function getUrlParams(url = window.location.href) {
  let urlStr = url.split("?")[1];
  return new URLSearchParams(urlStr);
}

const ZegoCloudVideoCall = () => {
  const route = useLocation();
  const header = Headers();
  // const [minuteFromApi, setMinuteFromApi] = useState(15);
  // const initialTime = minuteFromApi * 60 * 1000; // 15 minutes in milliseconds
  const [timeRemaining, setTimeRemaining] = useState();
  const [isStart, setisStart] = useState(false);
  // const [timerStarted, setTimerStarted] = useState(false);
  const minutes = Math.floor(timeRemaining / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  // console.log(timeRemaining);

  useEffect(() => {
    if (isStart) {
      if (timeRemaining <= 0)
        // const miliseconds =
        // If the timer has ended, do nothing
        return;
      // Create an interval that updates every second
      const interval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [timeRemaining, isStart]);

  const [isJoined, setisJoined] = useState(
    localStorage.getItem("isJoined") === "true" ? true : false
  );
  const {
    setCutVedio,
    isCutcall,
    setratingpopup,
    videocallroomid,
    setvideocallroomid,
  } = useContext(GlobalContext);
  useEffect(() => {
    localStorage.setItem("isJoined", isJoined);
  }, [isJoined]);

  useEffect(() => {
    if (isCutcall) {
      // toast.success("Meeting end");
      localStorage.removeItem("kittoken");
      navigate(-1);
      setisJoined(false);
    }
  }, [isCutcall]);

  const maindata = route?.state?.itm;
  const userdata = store.getState().session[KEY_USER_DATA];
  const dispatch = useDispatch();
  // const meetingleavedata = store.getState().session[Meetingdata];
  // console.log(maindata);

  // console.log(minutes);

  // console.log(seconds);

  // const handleEdit = () => {
  //   dispatch(setSessionField(Meetingdata, maindata));
  //   navigate("/Notes");
  // };

  const StoTimeApi = async () => {
    try {
      const res = await axios.post(
        StopTimeSet,
        {
          appointmentID: maindata?._id,
          videoStopTime: Math.floor(timeRemaining / (1000 * 60)),
        },
        header
      );
      // console.log(res, "set time in zego");
    } catch (error) {
      console.log(error);
    }
  };
  const TimegetApi = async () => {
    try {
      const res = await axios.post(
        StopTimeGet,
        {
          appointmentID: maindata?._id,
        },
        header
      );
      if (res.data.data) {
        setTimeRemaining(res.data.data * 60 * 1000);
        setisStart(true);
        // setMinuteFromApi(res.data.data);
      }
      // console.warn("minute he ye", res);
      // setTimeRemaining(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const waitingroomapi =
  //   userdata?.basicInfo?.role === "physician"
  //     ? doctorjoinigwaitingRoom
  //     : patientjoinigwaitingRoom;

  // const [response, setresponse] = useState();
  // console.log(response);
  // const WatingroomApi = async (itm) => {
  //   try {
  //     const res = await axios.post(
  //       waitingroomapi,
  //       {
  //         waitingRoomId: maindata?._id || maindata?.waitingRoomId,
  //         patientId: maindata?.patient_id?._id || maindata?.doctor_id?._id,
  //       },
  //       header
  //     );
  //     if (res?.data) {
  //       setresponse(res.data);
  //     }
  //     // console.log(res, "main res.................");
  //     // console.log(res, "resmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // console.log(minutes);
  const appid = process.env.REACT_APP_APPID;
  const serversec = process.env.REACT_APP_SERVERKEY;
  const navigate = useNavigate();

  // function generateChatId(a = "", b = "") {
  //   return b > a ? `${a}-${b}` : `${b}-${a}`;
  // }

  const roomid = maindata?._id;
  // const roomid =
  //   userdata?.basicInfo?.role === "physician"
  //     ? generateChatId(userdata?._id, maindata?.patient_id?._id)
  //     : generateChatId(userdata?._id, maindata?.doctor_id?._id);
  // console.log(roomid);
  useEffect(() => {
    setvideocallroomid(roomid);
  }, [roomid]);

  let MyMeeting = async (element) => {
    // generate Kit Token
    // if (roomid) {
    const appID = parseInt(appid) || 1;
    const serverSecret = serversec || "";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      videocallroomid,
      userdata?.basicInfo?._id,
      `${
        userdata?.basicInfo?.role === "physician"
          ? `Dr ${userdata?.basicInfo?.fullName}`
          : userdata?.basicInfo?.fullName
      }`,
      seconds
      // parseInt(timeRemaining)
    );

    if (!isJoined && kitToken) {
      localStorage.setItem("kittoken", kitToken);
    }
    const localtoken = localStorage.getItem("kittoken");
    // console.log(localtoken);
    // Create instance object from Kit Token.
    if (videocallroomid) {
      const zp = ZegoUIKitPrebuilt.create(localtoken);
      // console.log(minutes, seconds);
      if (minutes === 0 && seconds === 0) {
        zp.destroy();
        toast.success("Meeting time end");
        localStorage.removeItem("kittoken");
        navigate(-1);
        setvideocallroomid(null);
        setisJoined(false);
      }
      // start the call
      zp.joinRoom({
        container: element,
        onJoinRoom: () => {
          TimegetApi();
          // WatingroomApi();
          setisJoined(true);
          // console.log(zp.getRoomID());
        },
        showLeaveRoomConfirmDialog: false,
        showScreenSharingButton: false,
        onUserLeave: async () => {
          try {
            await setratingpopup(true);
            if (userdata?.basicInfo?.role === "physician") {
              if (maindata?.waitingRoomAppointment?._id) {
                await navigate("/WaitingRoomss");
              } else {
                await navigate("/Upcoming-Appointment");
              }
              dispatch(setSessionField(Meetingdata, maindata));
              setCutVedio(true);
              await StoTimeApi();
              localStorage.setItem("isJoined", false);
              setvideocallroomid(null);
              await zp.destroy();
            } else if (userdata?.basicInfo?.role === "patient") {
              if (maindata?.waitingRoomId) {
                await navigate("/waitingroom");
              } else {
                await navigate("/UpcomingAppointment");
              }
              await dispatch(setSessionField(Meetingdata, maindata));
              // setCutVedio(true);
              await toast.success("Your appointment is complete");
              await StoTimeApi();
              await zp.destroy();
              await localStorage.setItem("isJoined", false);
              await setvideocallroomid(null);
            }
          } catch (error) {
            console.log("error");
          }
        },
        onLeaveRoom: async () => {
          try {
            await setratingpopup(true);
            if (userdata?.basicInfo?.role === "physician") {
              if (maindata?.waitingRoomAppointment?._id) {
                await navigate("/WaitingRoomss");
              } else {
                await navigate("/Upcoming-Appointment");
              }
              await dispatch(setSessionField(Meetingdata, maindata));
              await setCutVedio(true);
              await StoTimeApi();
              await localStorage.setItem("isJoined", false);
              await setvideocallroomid(null);
              await zp.destroy();
            } else if (userdata?.basicInfo?.role === "patient") {
              if (maindata?.waitingRoomId) {
                await navigate("/waitingroom");
              } else {
                await navigate("/UpcomingAppointment");
              }
              await dispatch(setSessionField(Meetingdata, maindata));
              // setCutVedio(true);
              await toast.success("Your appointment is complete");
              await StoTimeApi();
              await zp.destroy();
              await localStorage.setItem("isJoined", false);
              await setvideocallroomid(null);
            }
          } catch (error) {
            console.log("error");
          }
        },
        showRoomTimer: true,
        showLeavingView: false,
        showPreJoinView: false,
        scenario: {
          mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
        },
      });
      // }
    }
  };

  return (
    <div className="myCallContainermain">
      <div className="myCallContainer position-relative" ref={MyMeeting}></div>
      {userdata?.basicInfo?.role === "physician" && (
        <a
          href="/admin/Notes"
          target="_blank"
          className="editbtn"
          // onClick={handleEdit}
        >
          {<CiEdit color="#ffffff" />}
        </a>
      )}
    </div>
  );
};

export default ZegoCloudVideoCall;
