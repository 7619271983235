import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import Lower from "../../Assets/Images/lower_limbs@2x.png";
import Signature from "../Signature/Signature";
import { useFormik } from "formik";
import "./Xrayultrasound.css";
import { useNavigate, useLocation } from "react-router-dom";
import { XRayultrasound, SendtolebPostApi } from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import Header from "../../Components/Header/Header";

import {
  GENERAL,
  PELVIS,
  OBSTETRICAL,
  SMALLPARTS,
  MUSCULOSKELETAL,
  USGUIDEDPROCEDURES,
  VASCULAR,
  ABDOMINAL,
  CHEST,
  HEADNeck,
  LOWEREXTREMITIES,
  LOWEREXTREMITIESTO,
  SPINEPELVIS,
  UPPEREXTREMITIES,
  UPPEREXTREMITIESTO,
  UPPEREXTREMITIESTHARD,
  BONEMINERALDENSITY,
} from "../../Constant/DummyData";
import { Headers } from "../../Constant/Fetchdata";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import SendtoLebModel from "../SendtoLebModel/SendtoLebModel";

const initialValues = {
  DateTest: "",
  DateLMNP: "",
  Pregnant1: "",
  Beanswered: "",
  CreatinineLevel: "",
  Allergies1: "",
  otherVGENERAL: "",
  isMUSCULOSKELETAL: "",
  isotherVASCULAR: "",
  isotherUPPER: "",
  Mammogram: false,
  MammogramR: false,
  MammogramL: false,
  Implants: false,
  OntarioL: false,
  OntarioR: false,
  LegLengths: false,
  SkeletalSuevey: false,
  BoneAge: false,
  OtherBone: false,
  isotherBone: "",
  SendtoPatient: false,
  SendtoLab: false,
};

const onSubmit = (values) => {
  // Your logic for form submission
  // console.log("Form submitted with values:", values);
};

const Xrayultrasound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const itm = location?.state?.id;
  console.log(itm?._id, "route id");
  const [valuesuu, setValuesuu] = useState({
    OtherBone: false,
    isotherVASCULAR: "",
  });

  const handleChangetype = (event) => {
    const { name, value, type, checked } = event.target;

    setValuesuu((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //------------------ textaraa -----------------------//
  const [IsotherVGENERAL, setIsotherGENERAL] = useState(false);
  const [isOtherBoneChecked, setOtherBoneChecked] = useState(false);

  // console.log(IsotherVGENERAL, "IsotherVGENERAL");
  // console.log(IsotherVGENERAL, "IsotherVGENERAL");
  const [otherMUSCULOSKELETAL, setotherMUSCULOSKELETAL] = useState(false);
  const [otherVASCULAR, setotherVASCULAR] = useState(false);
  const [otherUPPER, setotherUPPER] = useState(false);
  // Api data ka liya bane huve state////////////////////////////////////////////////////////////////
  const [isGENERAL, setisGENERAL] = useState(GENERAL);
  const [isvascular, setisvascular] = useState(VASCULAR);
  const [isPELVIS, setisPELVIS] = useState(PELVIS);
  const [isSMALLPARTS, setisSMALLPARTS] = useState(SMALLPARTS);
  const [isOBSTETRICAL, setisOBSTETRICAL] = useState(OBSTETRICAL);
  const [isChecked, setIsChecked] = useState(false);
  const [isABDOMINAL, setisABDOMINAL] = useState(ABDOMINAL);
  const [isHEADNeck, setisHEADNeck] = useState(HEADNeck);
  const [isCHEST, setisCHEST] = useState(CHEST);

  const [isSPINEPELVIS, setisSPINEPELVIS] = useState(SPINEPELVIS);

  // console.log(isChecked, "sins");
  const handleCheckboxChange = () => {
    setIsChecked((prevValue) => !prevValue);
  };

  const [isUPPEREXTREMITIESTHARD, setisUPPEREXTREMITIESTHARD] = useState(
    UPPEREXTREMITIESTHARD
  );
  const [isMUSCULOSKELETAL, setisMUSCULOSKELETAL] = useState(MUSCULOSKELETAL);
  const [lgShow, setLgShow] = useState(false);
  // const [isextremities, setisextremities] = useState(LOWEREXTREMITIES);
  // const [istoes, setistoes] = useState(LOWEREXTREMITIESTO);

  const [isextremities, setisextremities] = useState(LOWEREXTREMITIES);

  const [istoes, setistoes] = useState(LOWEREXTREMITIESTO);
  const [updatedRowObject, setUpdatedRowObject] = useState({
    lower_extremities: { toes_option: {} },
  });
  // console.log(updatedRowObject, "updatedRowObject");
  const [isupperextremities, setisupperextremities] =
    useState(UPPEREXTREMITIES);

  const [iSUPPEREXTREMITIESTO, setiSUPPEREXTREMITIESTO] =
    useState(UPPEREXTREMITIESTO);

  const [UpdatedRowEXTREMITIES, setUpdatedRowEXTREMITIES] = useState({
    upper_extremities: { finger_option: {} },
  });
  // console.log(UpdatedRowEXTREMITIES, "updatedRowObject");
  const [isBONEMINERALDENSITY, setisBONEMINERALDENSITY] =
    useState(BONEMINERALDENSITY);
  // Api data ka liya bane huve state end ////////////////////////////////////////////////////////////////
  // console.log(ItsPregnant, "ItsPregnant");
  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  const [ItsPregnant, setItsPregnant] = useState("");
  // console.log(ItsPregnant, "ItsPregnant");
  const [Itcontarst, setItcontarst] = useState("");
  const [loading, setloading] = useState(false);
  // console.log(Itcontarst, "Itcontarst");
  const [fields, setFields] = useState([{ value: "", id: 0 }]);
  const [fields2, setFields2] = useState([{ value: "", id: 0 }]);
  // console.log(fields2,"kamil")

  const CallID = localStorage.getItem("CallNoteId");

  // console.log(CallID, "CallID");
  const handleAdd = useCallback(() => {
    const values = [...fields];
    values.push({
      value: null,
      id: values.length,
    });
    setFields(values);
  }, [fields]);

  const handleAdd3 = useCallback(() => {
    const values = [...fields2];
    values.push({
      value: null,
      id: values.length,
    });
    setFields2(values);
  }, [fields2]);

  function handledeleted(id) {
    // console.log("id", id);
    setFields((data) => {
      return data.filter((item) => {
        // console.log("iteam", item.id);
        return item.id != id;
      });
    });
  }
  function handledeleted2(id) {
    // console.log("id", id);
    setFields2((data) => {
      return data.filter((item) => {
        // console.log("iteam", item.id);

        return item.id != id;
      });
    });
  }
  const handleChangeinput2 = useCallback(
    (i, event) => {
      // console.log(i);
      const values = [...fields2];
      values[i].value = event.target.value;
      setFields2(values);
    },
    [fields2]
  );
  const handleChangeinput = useCallback(
    (i, event) => {
      // console.log(i);
      const values = [...fields];
      values[i].value = event.target.value;
      setFields(values);
    },
    [fields]
  );

  const handleRadioClick = (name) => {
    setItsPregnant(name);
    setItcontarst(name);
  };

  const [rowObject, setRowObject] = useState({
    general: {},
    pelvis: {},
    OBSTETRICAL: {},
    small_parts: {
      groin_option: {},
    },
    musculoskeletal: {},
    vasculars: {},
    Abdominal: {},
    Chest: {
      ribs_option: {},
    },
    HEADNecks: {},
    // extremities: {},
    // toes: {},
    // UpdatedRowEXTREMITIES: {},
    Spinepelvis: {},
    // Upperextremities: {},
    // Upperexrremi: {},
    UPPEREXTREMITIESTHARD: {},
    BONEMINERALDENSITY: {},
    // updatedRowObject: {},
  });
  // console.log(rowObject, "rowObject");
  const [checkboxdata, setcheckboxdata] = useState([]);
  const { SigUrl } = useContext(GlobalContext);
  // console.log(SigUrl, "SigUrl");
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };

  //------------------ textaraa opan state -----------------------//
  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  // console.log(values.MammogramR, "Mammogram");
  const header = Headers();
  // console.log(header, "header");

  const LebtextApi = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SendtolebPostApi,
        {
          appointmentID: itm?._id || savedRatingId || CallID,
          email: EmailGo,
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        XRayultrasound,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          x_ray_ultrasound: {
            pregnant: ItsPregnant,
            date_LMNP: values.DateLMNP,
            creatinine_level: values.CreatinineLevel,
            date_of_test: values.DateTest,
            contarst_allergies: Itcontarst,
            ultrasound: {
              general_ultrasound: rowObject.general,
              other_text: values.otherVGENERAL,
              pelvis: rowObject.pelvis,
              obstetrical: rowObject.OBSTETRICAL,
              small_parts: rowObject?.small_parts,
              // groin_option: rowObject.groin_option,

              musculoskeletal: rowObject?.musculoskeletal,

              us_guided_procedures: {
                biopsy_thyroid_FNA: isChecked,
              },
              vascular: {
                vascular: rowObject.vasculars,
                other_text: values.isotherVASCULAR,
              },
            },
            x_ray: {
              abdominal: rowObject.Abdominal,
              chest: rowObject.Chest,
              head_neck: rowObject.HEADNecks,
              upper_extremities: UpdatedRowEXTREMITIES.upper_extremities,
              lower_extremities: updatedRowObject.lower_extremities,
              spine_pelvis: rowObject.Spinepelvis,
              leg_lengths: values.LegLengths,
              skeletal_survey: values.SkeletalSuevey,
              bone_age: values.BoneAge,
              other: values.OtherBone,
              other_text: values.isotherBone,
            },
            mammography_bone_mineral_density: {
              mammogram: values.Mammogram,
              mammogram_option: {
                r: values.MammogramL,
                l: values.MammogramR,
              },
              implants: values.Implants,
              ontario_breast_screening_program_OBSP_50_74_yrs_of_age: {
                r: values.OntarioL,
                l: values.OntarioR,
              },
              bone_mineral_density: rowObject.BONEMINERALDENSITY,
            },
            drSinature_image: SigUrl,
            sendLaboratory: values.SendtoLab,
            sendPatient: values.SendtoPatient,
          },
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        LebtextApi();
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }

      // console.log(res, "Raja");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const updateIsUpperExtremities = (index, side) => {
    setisupperextremities((prevIsUpperExtremities) => {
      const updatedIsUpperExtremities = [...prevIsUpperExtremities];
      const item = updatedIsUpperExtremities[index];
      item[`isSelected${side}`] = !item[`isSelected${side}`];
      return updatedIsUpperExtremities;
    });
  };

  // Function to update the state of iSUPPEREXTREMITIESTO array
  const updateiSUPPEREXTREMITIESTO = (index) => {
    setiSUPPEREXTREMITIESTO((previSUPPEREXTREMITIESTO) => {
      const updatediSUPPEREXTREMITIESTO = [...previSUPPEREXTREMITIESTO];
      updatediSUPPEREXTREMITIESTO[index].isSelected =
        !updatediSUPPEREXTREMITIESTO[index].isSelected;
      return updatediSUPPEREXTREMITIESTO;
    });
  };

  useEffect(() => {
    // Update Upperextremities in updatedRowEXTREMITIES
    const updatedUpperextremities = {
      ...UpdatedRowEXTREMITIES.upper_extremities,
    };

    isupperextremities.forEach((item) => {
      updatedUpperextremities[item.backEndNameLeft] = item.isSelectedLeft;
      updatedUpperextremities[item.backEndNameRight] = item.isSelectedRight;
    });

    // Update Upperexrremi in Upperextremities
    const updatedUpperexrremi = { ...updatedUpperextremities.finger_option };

    iSUPPEREXTREMITIESTO.forEach((item) => {
      updatedUpperexrremi[item.backEndName] = item.isSelected;
    });

    updatedUpperextremities.finger_option = updatedUpperexrremi;

    // Set the updatedRowEXTREMITIES state
    setUpdatedRowEXTREMITIES({
      upper_extremities: updatedUpperextremities,
    });
  }, [isupperextremities, iSUPPEREXTREMITIESTO]);

  const updateIsExtremities = (index, side) => {
    setisextremities((prevIsExtremities) => {
      const updatedIsExtremities = [...prevIsExtremities];
      const item = updatedIsExtremities[index];
      item[`isSelected${side}`] = !item[`isSelected${side}`];
      return updatedIsExtremities;
    });
  };

  // Function to update the state of istoes array

  const updateIsToes = (index) => {
    setistoes((prevIsToes) => {
      const updatedIsToes = [...prevIsToes];
      updatedIsToes[index].isSelected = !updatedIsToes[index].isSelected;
      return updatedIsToes;
    });
  };

  useEffect(() => {
    // Update extremities in updatedRowObject
    const updatedExtremities = { ...updatedRowObject.lower_extremities };

    isextremities.forEach((item) => {
      updatedExtremities[item.backEndNameLeft] = item.isSelectedLeft;
      updatedExtremities[item.backEndNameRight] = item.isSelectedRight;
    });

    // Update toes in extremities
    const updatedToes = { ...updatedExtremities.toes_option };

    istoes.forEach((item) => {
      updatedToes[item.backEndName] = item.isSelected;
    });

    updatedExtremities.toes_option = updatedToes;

    // Set the updatedRowObject state
    setUpdatedRowObject({
      lower_extremities: updatedExtremities,
    });
  }, [isextremities, istoes]);

  useEffect(() => {
    const updatedRowObject = { ...rowObject };

    isGENERAL.forEach((item) => {
      updatedRowObject.general[item.backEndName] = item.isSelected;
    });

    isPELVIS.forEach((item) => {
      updatedRowObject.pelvis[item.backEndName] = item.isSelected;
    });

    isOBSTETRICAL.forEach((item) => {
      updatedRowObject.OBSTETRICAL[item.backEndName] = item.isSelected;
    });

    isMUSCULOSKELETAL.forEach((item) => {
      updatedRowObject.musculoskeletal[item.backEndNameLeft] =
        item.isSelectedLeft;
    });
    isMUSCULOSKELETAL.forEach((item) => {
      updatedRowObject.musculoskeletal[item.backEndNameRight] =
        item.isSelectedRight;
    });

    isvascular.forEach((item) => {
      updatedRowObject.vasculars[item.backEndName] = item.isSelected;
    });
    isABDOMINAL.forEach((item) => {
      updatedRowObject.Abdominal[item.backEndName] = item.isSelected;
    });
    isHEADNeck.forEach((item) => {
      updatedRowObject.HEADNecks[item.backEndName] = item.isSelected;
    });

    isSPINEPELVIS.forEach((item) => {
      updatedRowObject.Spinepelvis[item.backEndName] = item.isSelected;
    });

    isBONEMINERALDENSITY.forEach((item) => {
      updatedRowObject.BONEMINERALDENSITY[item.backEndName] = item.isSelected;
    });

    isUPPEREXTREMITIESTHARD.forEach((item) => {
      updatedRowObject.UPPEREXTREMITIESTHARD[item.backEndName] =
        item.isSelected;
    });

    const groinOptionL = isSMALLPARTS.find((item) => item.backEndName === "l");
    const groinOptionR = isSMALLPARTS.find((item) => item.backEndName === "r");
    if (groinOptionL) {
      updatedRowObject.small_parts.groin_option.l = groinOptionL.isSelected;
    }
    if (groinOptionR) {
      updatedRowObject.small_parts.groin_option.r = groinOptionR.isSelected;
    }
    isSMALLPARTS.forEach((item) => {
      if (item.backEndName !== "l" && item.backEndName !== "r") {
        updatedRowObject.small_parts[item.backEndName] = item.isSelected;
      }
    });

    const CHESTOptionL = isCHEST.find((item) => item.backEndName === "l");
    const CHESTOptionR = isCHEST.find((item) => item.backEndName === "r");
    if (CHESTOptionL) {
      updatedRowObject.Chest.ribs_option.l = CHESTOptionL.isSelected;
    }
    if (CHESTOptionR) {
      updatedRowObject.Chest.ribs_option.r = CHESTOptionR.isSelected;
    }
    isCHEST.forEach((item) => {
      if (item.backEndName !== "l" && item.backEndName !== "r") {
        updatedRowObject.Chest[item.backEndName] = item.isSelected;
      }
    });

    setRowObject(updatedRowObject);
  }, [
    isGENERAL,
    isPELVIS,
    isSMALLPARTS,
    isOBSTETRICAL,
    isMUSCULOSKELETAL,
    isvascular,
    isABDOMINAL,
    isCHEST,
    isHEADNeck,
    isextremities,
    istoes,
    isSPINEPELVIS,
    isupperextremities,
    iSUPPEREXTREMITIESTO,
    isUPPEREXTREMITIESTHARD,
    isBONEMINERALDENSITY,
  ]);

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const hendelPhae = () => {
    // console.log(item, "item");
    navigate("/Sendtoleb", {
      state: {
        itm,
      },
    });
  };
  const hendelElectro = () => {
    setLgShow(false);
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"X-ray/Ultrasound"}
        style={{ color: "white" }}
      />
      <section className="Xrayultrasound-main-section">
        <form onSubmit={handleSubmit} className="Xrayultrasound-main-div">
          <div className="mt-5">
            <span className="GENERAL-main-span">PATIENT INFORMATION</span>
          </div>

          <div className="mt-3">
            <span>Date of Test</span>
            <input
              placeholder="13 May 1995"
              className="Date-of-Birth-input-Test mt-2"
              type="date"
              max="9999-12-31"
              name="DateTest"
              value={values.DateTest}
              onBlur={handleBlur}
              onChange={handleChange}
              pattern="\d{4}-\d{2}-\d{2}"
            />
          </div>
          <div className="mt-3">
            <span>Date of LMNP</span>
            <input
              placeholder="13 May 1995"
              className="Date-of-Birth-input-Test mt-2"
              type="date"
              max="9999-12-31"
              name="DateLMNP"
              value={values.DateLMNP}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="mt-3 Pregnant-yes-no-div">
            <span>Pregnant ?</span>
            <div className="Pregnant-main-yes-no-div">
              <div className="mt-3 no-Yes-main-div">
                <input
                  className="redio-ok-name"
                  name="Pregnant1"
                  id="1"
                  type="radio"
                  value="yes"
                  onClick={() => handleRadioClick("yes")}
                />
                <span>Yes</span>
              </div>
              <div className="mt-3 no-Yes-main-div">
                <input
                  className="redio-ok-name"
                  name="Pregnant1"
                  id="1"
                  type="radio"
                  value="no"
                  onClick={() => handleRadioClick("no")}
                />
                <span>No</span>
              </div>
            </div>
          </div>
          <div className="">
            <div className="span-you-div">
              <span className="">
                Significatn Clinical History / Clinical questions to
              </span>
            </div>
            <div className="Be-answered-area-main">
              <span>be answered </span>
              <textarea
                id="w3review"
                name="Beanswered"
                rows="4"
                className="here-input-2-answered   mt-2"
                cols="50"
                placeholder="Write here"
                value={values.Beanswered}
                onBlur={handleBlur}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <div className="mt-3">
            <span>Creatinine Level</span>
            <input
              placeholder="Creatinine Level"
              className="Date-of-Birth-input-Test mt-2"
              type="number"
              name="CreatinineLevel"
              value={values.CreatinineLevel}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="mt-3 Pregnant-yes-no-div">
            <span>Contarst Allergies ?</span>
            <div className="Pregnant-main-yes-no-div">
              <div className="mt-3 no-Yes-main-div">
                <input
                  className="redio-ok-name"
                  name="Allergies1"
                  id="1"
                  type="radio"
                  value="yes"
                  onClick={() => handleRadioClick("yes")}
                />
                <span>Yes</span>
              </div>
              <div className="mt-3 no-Yes-main-div">
                <input
                  className="redio-ok-name"
                  name="Allergies1"
                  id="1"
                  type="radio"
                  value="no"
                  onClick={() => handleRadioClick("no")}
                />
                <span>No</span>
              </div>
            </div>
          </div>
          <div className="mt-4 ULTRASOUND-main-span-div">
            <span className="ULTRASOUND-spn">ULTRASOUND</span>
          </div>
          <div className="checkboxparent mt-5 ">
            <div className="media-screen-ULTRASOUND">
              <div className="ULTRASOUND-section-div">
                <div>
                  <span className="GENERAL-main-span">GENERAL ULTRASOUND</span>
                  {isGENERAL.map((item, index) => {
                    return (
                      <div className="checkdivmain" key={index}>
                        <input
                          className="Pregnant-yes-no"
                          type="checkbox"
                          id={item.id}
                          name="vehicle1"
                          onClick={() => {
                            if (item.id === 5) {
                              setIsotherGENERAL(!IsotherVGENERAL);
                            }
                            setisGENERAL((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={item.isSelected ? true : false}
                          onChange={handlechange}
                        />
                        <span className="chack-in-span">{item.txt}</span>
                      </div>
                    );
                  })}
                  <div>
                    {IsotherVGENERAL ? (
                      <textarea
                        id="w3review"
                        name="otherVGENERAL"
                        rows="3"
                        className="here-input-2-answered   mt-2"
                        cols="50"
                        placeholder="Write here"
                        value={values.otherVGENERAL}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></textarea>
                    ) : null}
                  </div>
                  <div className="m-2"></div>
                </div>

                <div className="checkboxparent ">
                  <span className="GENERAL-main-span">PELVIS</span>
                  {isPELVIS.map((item, index) => {
                    return (
                      <div className="checkdivmain" key={index}>
                        <input
                          className="Pregnant-yes-no"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          onClick={() => {
                            setisPELVIS((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={item.isSelected ? true : false}
                          onChange={handlechange}
                        />
                        <span className="chack-in-span">{item.txt}</span>
                      </div>
                    );
                  })}

                  <div className="m-2"></div>
                </div>

                <div className="checkboxparent  ">
                  <span className="GENERAL-main-span">OBSTETRICAL</span>
                  {isOBSTETRICAL.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisOBSTETRICAL((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="checkboxparent">
                  <span className="GENERAL-main-span">OBSTETRICAL</span>
                  {isSMALLPARTS.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisSMALLPARTS((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className="m-2"></div>
                </div>
                <div className="checkboxparent">
                  <span className="GENERAL-main-span">OBSTETRICAL</span>
                  <div className="mt-3 no-Yes-main-div">
                    <span>Left</span>
                    <span>Right</span>
                  </div>
                  {isMUSCULOSKELETAL.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id={`checkbox_${item.id}_2`} // Same id used here
                              name={`checkbox_${item.id}_2`}
                              onClick={() => {
                                setisMUSCULOSKELETAL((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelectedLeft: !temp2.isSelectedLeft,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelectedLeft ? true : false}
                              onChange={handlechange}
                            />

                            {item.id && item.id !== 37 && (
                              <input
                                className="Pregnant-yes-no"
                                type="checkbox"
                                id={`checkbox_${item.id}_2`}
                                name={`checkbox_${item.id}_2`}
                                onClick={() => {
                                  if (item.id === 37) {
                                    setotherMUSCULOSKELETAL(
                                      !otherMUSCULOSKELETAL
                                    );
                                  }
                                  setisMUSCULOSKELETAL((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelectedRight: !temp2.isSelectedRight,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelectedRight ? true : false}
                                onChange={handlechange}
                              />
                            )}
                            {/* {item.id === 35 && (
                              <input
                                className="Pregnant-yes-no"
                                type="checkbox"
                                id={`checkbox_${item.id}_3`}
                                name={`checkbox_${item.id}_3`}
                              />
                            )} */}
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div>
                    {otherMUSCULOSKELETAL ? (
                      <textarea
                        id="w3review"
                        name="isMUSCULOSKELETAL"
                        rows="3"
                        className="here-input-2-answered   mt-2"
                        cols="50"
                        placeholder="Write here"
                        value={values.isMUSCULOSKELETAL}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></textarea>
                    ) : null}
                  </div>
                  <div className="m-2"></div>
                </div>
                <div className="checkboxparent">
                  <span className="GENERAL-main-span">
                    US GUIDED PROCEDURES
                  </span>
                  {USGUIDEDPROCEDURES.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className="checkdivmain">
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="checkboxparent">
                  <span className="GENERAL-main-span">
                    US GUIDED PROCEDURES
                  </span>
                  {isvascular.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className="checkdivmain">
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                if (item.id === 47) {
                                  setotherVASCULAR(!otherVASCULAR);
                                }
                                setisvascular((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div>
                    {otherVASCULAR ? (
                      <textarea
                        id="w3review"
                        name="isotherVASCULAR"
                        rows="3"
                        className="here-input-2-answered   mt-2"
                        cols="50"
                        placeholder="Write here"
                        value={values.isotherVASCULAR}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></textarea>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="XRAY-main-span-div-perant">
              <div className="mt-4 XRAY-main-span-div">
                <span className="ULTRASOUND-spn">X_RAY</span>
              </div>
            </div>
            <div className="media-screen-ULTRASOUND">
              <div className="ULTRASOUND-section-div">
                <div className="checkboxparent mt-5 ">
                  <span className="GENERAL-main-span">ABDOMINAL</span>
                  {isABDOMINAL.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className="checkdivmain">
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisABDOMINAL((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="checkboxparent mt-5 ">
                  <span className="GENERAL-main-span">CHEST</span>
                  {isCHEST.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisCHEST((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="checkboxparent mt-5 ">
                  <span className="GENERAL-main-span">HEAD & Neck</span>
                  {isHEADNeck.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisHEADNeck((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="checkboxparent mt-5 ">
                  <span className="GENERAL-main-span">LOWER EXTREMITIES</span>
                  <div className="mt-3 no-Yes-main-div">
                    <span>Left</span>
                    <span>Right</span>
                  </div>
                  {isextremities.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisextremities((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelectedLeft: !temp2.isSelectedLeft,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelectedLeft ? true : false}
                              onChange={handlechange}
                            />

                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisextremities((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelectedRight: !temp2.isSelectedRight,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelectedRight ? true : false}
                              onChange={handlechange}
                            />

                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="LOWEREXTREMITIESTO-main-div">
                    {istoes.map((item, index) => {
                      return (
                        <>
                          <div key={index}>
                            <div
                              className={`LOWEREXTREMITIESTO-check ${item.cardClass}`}
                            >
                              <input
                                className="Pregnant-yes-no"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                onClick={() => {
                                  setistoes((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />
                              <span className="chack-in-span">{item.txt}</span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="m-2"></div>
                </div>
                <div className="checkboxparent mt-5 ">
                  <span className="GENERAL-main-span"> SPINE & PELVIS</span>
                  {isSPINEPELVIS.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisSPINEPELVIS((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="checkboxparent mt-5 ">
                  <span className="GENERAL-main-span">UPPER EXTREMITIES</span>
                  <div className="mt-3 no-Yes-main-div">
                    <span>Left</span>
                    <span>Right</span>
                  </div>
                  {isupperextremities.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisupperextremities((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelectedLeft: !temp2.isSelectedLeft,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelectedLeft ? true : false}
                              onChange={handlechange}
                            />

                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisupperextremities((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelectedRight: !temp2.isSelectedRight,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelectedRight ? true : false}
                              onChange={handlechange}
                            />

                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="LOWEREXTREMITIESTO-main-div">
                    {iSUPPEREXTREMITIESTO.map((item, index) => {
                      return (
                        <>
                          <div key={index}>
                            <div
                              className={`LOWEREXTREMITIESTO-check ${item.cardClass}`}
                            >
                              <input
                                className="Pregnant-yes-no"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                onClick={() => {
                                  setiSUPPEREXTREMITIESTO((preval) => {
                                    let temp = [...preval];
                                    let temp2 = temp[index];
                                    temp2 = {
                                      ...temp2,
                                      isSelected: !temp2.isSelected,
                                    };
                                    temp[index] = temp2;
                                    return temp;
                                  });
                                }}
                                checked={item.isSelected ? true : false}
                                onChange={handlechange}
                              />

                              <span className="chack-in-span">{item.txt}</span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  <div>
                    {otherUPPER ? (
                      <textarea
                        id="w3review"
                        name="isotherUPPER"
                        rows="3"
                        className="here-input-2-answered   mt-2"
                        cols="50"
                        placeholder="Write here"
                        value={values.isotherUPPER}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></textarea>
                    ) : null}
                  </div>
                  <div className="m-2"></div>
                </div>
                <div className="checkboxparent mt-4">
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="LegLengths"
                      value={values.LegLengths}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Leg Lengths</span>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="SkeletalSuevey"
                      value={values.SkeletalSuevey}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Skeletal Suevey</span>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="BoneAge"
                      value={values.BoneAge}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Bone Age</span>
                  </div>
                  <div>
                    <div className="checkdivmain">
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="OtherBone"
                        checked={valuesuu.OtherBone}
                        onChange={handleChangetype}
                      />
                      <span className="chack-in-span">Other</span>
                    </div>

                    {valuesuu.OtherBone && (
                      <textarea
                        id="w3review"
                        name="isotherBone"
                        rows="3"
                        className="here-input-2-answered mt-2"
                        cols="50"
                        placeholder="Write here"
                        value={values.isotherBone}
                        onChange={handleChange}
                      ></textarea>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="XRAY-main-span-div-perant">
              <div className="mt-4 MAMMOGRAPHY-main-span-div">
                <span className="ULTRASOUND-spn">
                  MAMMOGRAPHY & BONE MINERAL DENSITY
                </span>
              </div>
            </div>
            <div className="media-screen-ULTRASOUND">
              <div className="ULTRASOUND-section-div-MAMMOGRAPHY">
                <div className="mt-5">
                  <div>
                    <div className="checkdivmain">
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="Mammogram"
                        value={values.Mammogram}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span className="chack-in-span">Mammogram</span>
                    </div>
                  </div>

                  <div className="Left-And-Rghit">
                    <div>
                      <div className="Left-Mammogram-div">
                        <input
                          className="Pregnant-yes-no"
                          type="checkbox"
                          id="vehicle1"
                          name="MammogramL"
                          value={values.MammogramL}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <span className="chack-in-span">L</span>
                      </div>
                    </div>
                    <div>
                      <div className="Left-Mammogram-div">
                        <input
                          className="Pregnant-yes-no"
                          type="checkbox"
                          id="vehicle1"
                          name="MammogramR"
                          value={values.MammogramR}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <span className="chack-in-span">R</span>
                      </div>
                    </div>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Implants"
                      value={values.Implants}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Implants</span>
                  </div>
                  <div className="mt-3 mb-3">
                    <span>
                      Ontario Breast Screening Program (OBSP) 50-74 yrs of age{" "}
                    </span>
                  </div>
                  <div className="Lower-main-lmbs">
                    <img className="Lower-amin-img" src={Lower} alt="" />

                    <div className="Left-Lower-Rghit">
                      <div>
                        <div className="Left-Mammogram-div">
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="OntarioL"
                            value={values.OntarioL}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="chack-in-span">L</span>
                        </div>
                      </div>
                      <div>
                        <div className="Left-Mammogram-div">
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="OntarioR"
                            value={values.OntarioR}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <span className="chack-in-span">R</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkboxparent mt-5 ">
                  <span className="GENERAL-main-span"> SPINE & PELVIS</span>
                  {isBONEMINERALDENSITY.map((item, index) => {
                    return (
                      <>
                        <div key={index}>
                          <div className={`checkdivmain ${item.cardClass}`}>
                            <input
                              className="Pregnant-yes-no"
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onClick={() => {
                                setisBONEMINERALDENSITY((preval) => {
                                  let temp = [...preval];
                                  let temp2 = temp[index];
                                  temp2 = {
                                    ...temp2,
                                    isSelected: !temp2.isSelected,
                                  };
                                  temp[index] = temp2;
                                  return temp;
                                });
                              }}
                              checked={item.isSelected ? true : false}
                              onChange={handlechange}
                            />
                            <span className="chack-in-span">{item.txt}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="SendtoPatient"
                      value={values.SendtoPatient}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Send to Patient</span>
                  </div>
                  <div onClick={() => setLgShow(true)} className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="SendtoLab"
                      value={values.SendtoLab}
                      checked={values.SendtoLab ? true : false}
                      onChange={(e) =>
                        setFieldValue("SendtoLab", e.target.checked)
                      }
                      onBlur={handleBlur}
                    />
                    <span className="chack-in-span">Send to Lab</span>
                  </div>
                </div>
                <Signature />
              </div>
            </div>
          </div>
          <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
            Submit
          </Button>
        </form>
      </section>
      <SendtoLebModel
        onHide={() => {
          setFieldValue("SendtoLab", false);
          setLgShow(false);
        }}
        lgShow={lgShow}
        setLgShow={setLgShow}
        hendelElectro={hendelElectro}
      />
      {loading && <Loader />}
    </>
  );
};

export default Xrayultrasound;
