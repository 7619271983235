import React from "react";
import "./HealthDataDetail.css";
import Header from "../../Components/Header/Header";
// import Avtar from "../../Assets/Images/default_pic_ic@3x.png";
import { useNavigate } from "react-router";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { Icons } from "../../Constant/Icons";

const HealthDataDetail = () => {
  const Mydata = store.getState().session[KEY_USER_DATA];
  // console.log(Mydata);
  const navigate = useNavigate();

  return (
    <>
      <Header
        style={{ gap: "1rem" }}
        text={"Profile"}
        extrafield={<Profile Mydata={Mydata} />}
        onClick={() => navigate("/home-page")}
        secondtext={Icons.editicon}
        secondClick={() => navigate("/UpdetProfile")}
      />
      <section className="HealthDataDetail-main-section">
        <div className="HealthDataDetail-main-div py-3">
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Medical condition</span>
            <span>{Mydata?.healthData?.medicalCondition}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">
              Past medical condition
            </span>
            <span>{Mydata?.healthData?.pastMedicalCondition || "N/A"}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Allergies</span>
            <span>{Mydata?.healthData?.allergies}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Medication</span>
            <span>{Mydata?.healthData?.marijuana}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Smoking</span>
            <span>{Mydata?.healthData?.smoking}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Alcohol</span>
            <span>{Mydata?.healthData?.alcohol}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Marijuana</span>
            <span>{Mydata?.healthData?.marijuana}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <Buttton
            onClick={() => navigate("/familymember")}
            style={{ backgroundColor: Colors.blue, marginTop: "1rem" }}
          >
            Family members
          </Buttton>
        </div>
      </section>
    </>
  );
};

export default HealthDataDetail;

const Profile = ({ Mydata }) => {
  // console.log(Mydata);
  return (
    <div className="d-flex w-100 align-items-start justify-content-start gap-4">
      <div style={{ width: "80px", height: "80px" }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
          src={Mydata?.basicInfo?.image}
          alt=""
        />
      </div>
      <div className="profiledetail">
        <span>{Mydata?.basicInfo?.fullName}</span>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>Height</span>
            <h5>{Mydata?.healthData?.height?.value}</h5>
          </div>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>Weight</span>
            <h5>
              {Mydata?.healthData?.weight?.value}{" "}
              {Mydata?.healthData?.weight?.unit}
            </h5>
          </div>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>BMI</span>
            <h5>{parseInt(Mydata?.healthData?.BMI).toFixed(2)}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
