import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import close from "../../Assets/Images/search_ic@3x.png";
import { motion } from "framer-motion";
import { GlobalContext } from "../../Context/GlabalContext";

const CardiologestPop = ({ text, setCardpop, Cardpop }) => {
  const navigate = useNavigate();
  const { Consultation, selectedsymtom, setselectedsymtom, OtherData } =
    useContext(GlobalContext);

  const handleCloseeee = () => {
    setCardpop(false);
    setselectedsymtom("");
    navigate("/");
  };
  return (
    <>
      <Modal show={Cardpop} size="md" centered>
        <Modal.Header
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-Sick-con-div-been">
              <div className="Physician-div">
                <span>{text}</span>
              </div>
            </div>
          </div>
          <div className="No-contact-main-btn-div">
            <button
              onClick={handleCloseeee}
              className="No-contact-main-btn  mt-3 mb-3"
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CardiologestPop;
