import React, { useContext, useEffect, useState } from "react";
import "./CTscenMRI.css";
import { IoSearchOutline } from "react-icons/io5";
import EnterMedicineName from "../EnterMedicineName/EnterMedicineName";
import { GETMEDICINEAPI } from "../../Constant/Apiconstant";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../Context/GlabalContext";
import Fetchdata from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
const CTscenMRI = () => {
  const [PopupName, setPopupName] = useState(false);
  const [activeTab, setActiveTab] = useState("search");
  const [currentPage, setCurrentPage] = useState(1); // Set the default active tab to "search"
  const { setMedicine } = useContext(GlobalContext);

  const [drugNames, setDrugNames] = useState([]);

  // console.log(drugNames, "drugNames");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  const handleTabClick = (tabName) => {
    setActiveTab(tabName === activeTab ? null : tabName);
  };

  const handleClick = () => {
    setPopupName(true);
  };

  const handleClickmadi = (medicineName) => {
    setMedicine(medicineName);
    navigate("/PrescriptionNotes");
  };

  const { response, getdata } = Fetchdata(GETMEDICINEAPI, {}, "GET");
  // console.log(response, "response");

  const data = [
    {
      medicineName: "dolo",
    },
    {
      medicineName: "one-p",
    },
    {
      medicineName: "den-p",
    },
  ];

  // const apiUrl = "https://dailymed.nlm.nih.gov/dailymed/services/v2/drugnames";
  // fetch(apiUrl)
  //   .then((response) => {
  //     if (!response.ok) {
  //       throw new Error(`Network response was not ok: ${response.status}`);
  //     }
  //     return response.json();
  //   })
  //   .then((data) => {
  //     console.log("API response:", data);
  //     // Handle the data as needed
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching data:", error);
  //   });

  const apidatas = async () => {
    try {
      const response = await axios.get(
        "https://dailymed.nlm.nih.gov/dailymed/services/v2/drugnames",
        {}
      );
      // console.log(response);
    } catch (error) {
      console.log("errors75", error);
    }
  };
  useEffect(() => {
    apidatas();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const apiUrl = `https://dailymed.nlm.nih.gov/dailymed/services/v2/drugnames.json?page=${pageNumber}&pagesize=${pageSize}&drug_name=${searchText}`;
  //       const response = await fetch(apiUrl);

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       const data = await response.json();
  //       const fetchedDrugNames = data.results.map((drug) => drug.name);
  //       setDrugNames(fetchedDrugNames);
  //     } catch (error) {
  //       console.error("Error fetching data from API:", error);
  //     }
  //   };

  //   fetchData();
  // }, [pageSize, pageNumber, searchText]);

  return (
    <>
      <section className="ReferralDoctor-main-section">
        <div className="ReferralDoctor-main-div">
          <div>
            <button
              className="handleTabClick-search"
              onClick={() => handleTabClick("search")}
              // activeClassName="active"
            >
              Search
            </button>
            <button
              className="handleTabClick-search"
              // activeClassName="active"
              onClick={() => handleTabClick("myMedicine")}
            >
              My medicine
            </button>
          </div>

          {activeTab === "search" && (
            <div className="IoSearchOutline-search-com">
              <div>
                <div className="mt-4 IoSearchOutline-main-div-in">
                  <IoSearchOutline className="IoSearchOutline-main" />
                  <input
                    className="IoSearchOutline-main-input"
                    placeholder="search"
                    type="search"
                  />
                </div>
                {data.map((itm, index) => {
                  return (
                    <div className="mt-2 w-100">
                      <span
                        onClick={() => handleClickmadi(itm.medicineName)}
                        className="test-medical"
                      >
                        {itm.medicineName}
                      </span>
                    </div>
                  );
                })}
              </div>

              <div className="line-haghit-div"></div>

              <div>
                <button onClick={handleClick} className="Add-search-New">
                  Add New
                </button>
              </div>
            </div>
          )}
          {activeTab === "myMedicine" && (
            <>
              {response?.data?.map((itm, index) => {
                return (
                  <div key={index} className="IoSearchOutline-search-com">
                    <div className="mt-2 w-100">
                      <span
                        onClick={() => handleClickmadi(itm.medicineName)}
                        className="test-medical"
                      >
                        {itm.medicineName}
                      </span>
                    </div>
                    <div className="line-haghit-div"></div>
                  </div>
                );
              })}
              <div>
                <button onClick={handleClick} className="Add-search-New">
                  Add New
                </button>
              </div>
            </>
          )}
        </div>
        {PopupName && (
          <EnterMedicineName
            PopupName={PopupName}
            setPopupName={setPopupName}
            getdata={getdata}
            text={"Enter Name of Medicine"}
          />
        )}
      </section>
    </>
  );
};

export default CTscenMRI;
