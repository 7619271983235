import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { RejectListApi } from "../../Constant/Apiconstant";
import Fetchdata from "../../Constant/Fetchdata";
import { Headers } from "../../Constant/Fetchdata";
import "./PrescriptionRejectList.css";
import { SimpleLoader } from "../../Constant/Loader";
const PrescriptionRejectList = () => {
  const header = Headers();
  const navigate = useNavigate();
  const location = useLocation();
  const [RejectMedicin, setRejectMedicin] = useState([]);
  const RejecteMedicin = location?.state?.Rejectitm;
  // console.log(RejecteMedicin, "RejecteMedicin");

  const params = {
    prescriptionAppointment_id: RejecteMedicin.prescriptionAppointment_id,
    appointmentId: RejecteMedicin.appointment_id,
  };
  const { response, isloading } = Fetchdata(
    RejectListApi,
    params,

    "POST"
  );

  useEffect(() => {
    setRejectMedicin(response?.data);
  }, [response]);

  // console.log(response, "response");
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Rejected medicines & reasons"}
      />
      <section className="Notifications-main-section">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <div className="Reject-main-div">
            {RejectMedicin?.map((itm, index) => {
              {
                /* console.log(itm, "itm"); */
              }
              return (
                <>
                  <div className="Medicine-Name-dr-reject">
                    <span className="problem-span-Notifications">
                      Medicine Name :{""}{" "}
                      {`${itm.nameOfMedicine} ${itm.duration} ${itm.doseUnit} ${itm.frequency} x ${itm.duration} ${itm.durationUnit}`}
                    </span>

                    <span>Rejected Reason : {itm?.cancelledReason}</span>
                  </div>
                  <div className="Notifications-d-s-line mb-3"></div>
                </>
              );
            })}
          </div>
        )}
      </section>
    </>
  );
};

export default PrescriptionRejectList;
