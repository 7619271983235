import React, { useContext, useEffect, useState } from "react";
import "./UpdetHealthDataDetailInformation.css";
import Loader from "../../Constant/Loader";
import { useFormik } from "formik";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import { Icons } from "../../Constant/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import axios from "../../Constant/ApiUrl";
import { UpdetInformationApi } from "../../Constant/Apiconstant";
// import { Headers } from "../../Constant/Fetchdata";
import { GlobalContext } from "../../Context/GlabalContext";
import { Contenttype, Headers } from "../../Constant/Fetchdata";
import { toast } from "react-toastify";

const UpdetHealthDataDetailInformation = () => {
  const location = useLocation();
  const UpdetInformation = location.state?.UpdetData;
  // console.log(UpdetInformation, "UpdetInformation");

  const [smoking, setsmmoking] = useState();
  const [alcohol, setalcohol] = useState();
  const [marijauna, setmarijauna] = useState();
  const [loading, setloading] = useState(false);
  const Mydata = store.getState().session[KEY_USER_DATA];

  const { steps, setsteps, UpdetDataProfile } = useContext(GlobalContext);

  // const { setregiaterdata, Registerdata } = useContext(GlobalContext);

  // console.log(UpdetDataProfile, "UpdetDataProfile");

  // console.log(Mydata);
  const navigate = useNavigate();

  const initialValues = {
    Img: UpdetInformation?.basicInfo?.image,
    Weight: "",
    unitone: "",
    Height: "",
    unittow: "",
    bmi: "",
    MedicalCondition: "",
    Allergies: "",
    Medication: "",
    smoking: "",
    alcohol: "",
    marijauna: "",
  };

  useEffect(() => {
    setsmmoking(UpdetInformation?.healthData?.smoking);
    setalcohol(UpdetInformation?.healthData?.alcohol);
    setmarijauna(UpdetInformation?.healthData?.marijuana);
    setFieldValue("Weight", UpdetInformation?.healthData?.weight?.value);
    setFieldValue("unitone", UpdetInformation?.healthData?.weight?.unit);
    setFieldValue("unittow", UpdetInformation?.healthData?.height?.unit);
    setFieldValue("Height", UpdetInformation?.healthData?.height?.value);
    setFieldValue("bmi", UpdetInformation?.healthData?.BMI);
    setFieldValue(
      "MedicalCondition",
      UpdetInformation?.healthData?.medicalCondition
    );
    setFieldValue("Allergies", UpdetInformation?.healthData?.allergies);
    setFieldValue("Medication", UpdetInformation?.healthData?.medication);
  }, [Mydata]);

  // console.log(Mydata);
  const header = Headers();

  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();

    formdata.append("familyMemberId", UpdetInformation?._id);
    formdata.append("weight", values?.Weight);
    formdata.append("weightUnit", values?.unitone);
    formdata.append("height", values?.Height);
    formdata.append("heightUnit", values?.unittow);
    formdata.append("BMI", values?.bmi);
    formdata.append("medicalCondition", values?.MedicalCondition);
    formdata.append("medication", values?.Medication);
    formdata.append("allergies", values?.Allergies);
    formdata.append("smoking", smoking);
    formdata.append("alcohol", alcohol);
    formdata.append("marijuana", marijauna);

    try {
      const res = await axios.post(UpdetInformationApi, formdata, header);
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.message);
      } else if (res.data.status == "success" && res.status == 200) {
        toast.success(res.data.message);
        navigate("/familymember");
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: HealthDataInformationSchemas,
    onSubmit,
  });
  return (
    <>
      <div className="All-OtherInformation-perfect-divv">
        <div className="All-OtherInformation-perfect-second-divv">
          <div className="Profile-extra-div">
            <div className="Profile-main-Your">
              <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                {Icons.backarrowblack}
              </span>
              {/* <h6 className="mt-2">Step  to 3</h6> */}
              <span className="Order-history-span">
                Health Data Information
              </span>
              <span className="Upload-profile">
                Type in your health data to get better consultants from doctor.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="second-div mt-4">
            <img
              src={UpdetInformation?.basicInfo?.image}
              alt=""
              width="100px"
              height="100px"
              className="UpdetInformation-kiya-muja"
            />
            <div className="Health-input-div-main">
              <div className="hoooo mt-5">
                <div className="Yes-and-No-name">
                  <div className="Full-all-Name-input">
                    <span className="">Weight</span>
                    <div className="full-name-bug-div">
                      <div className="rectangle-Weight mt-2">
                        <input
                          className="Weight-input-css"
                          type="number"
                          placeholder="Weight"
                          name="Weight"
                          value={values.Weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    {errors.Weight && touched.Weight ? (
                      <p className="bmi-Weight mt-2">{errors.Weight} </p>
                    ) : null}
                  </div>
                  <div className="Unit-kg-selact">
                    <span className="">Unit</span>
                    <select
                      className="Volvo-selact-value mt-2"
                      name="unitone"
                      id="cars"
                      value={values.unitone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select</option>
                      <option value="kg">Kg</option>
                    </select>
                    {errors.unitone && touched.unitone ? (
                      <p className="bmi-Weight mt-2">{errors.unitone} </p>
                    ) : null}
                  </div>
                </div>
                <div className="Yes-and-No-name mt-4">
                  <div className="Full-all-Name-input">
                    <span className="">Height</span>
                    <div className="full-name-bug-div">
                      <div className="rectangle-Weight mt-2">
                        <input
                          className="Weight-input-css"
                          type="number"
                          placeholder="Height"
                          name="Height"
                          value={values.Height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    {errors.Height && touched.Height ? (
                      <p className="bmi-Weight mt-2">{errors.Height} </p>
                    ) : null}
                  </div>
                  <div className="Unit-kg-selact">
                    <span className="">Unit</span>
                    <select
                      className="Volvo-selact-value mt-2"
                      name="unittow"
                      id="cars"
                      value={values.unittow}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select</option>
                      <option value="m">m</option>
                    </select>
                    {errors.unittow && touched.unittow ? (
                      <p className="bmi-Weight mt-2">{errors.unittow} </p>
                    ) : null}
                  </div>
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">BMI</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="kg/m2"
                      name="bmi"
                      value={values.bmi}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.bmi && touched.bmi ? (
                    <p className="bmi-Weight mt-2">{errors.bmi} </p>
                  ) : null}
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">Medical Condition (Optional)</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Medical Condition"
                      name="MedicalCondition"
                      value={values.MedicalCondition}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">Allergies</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Allergies"
                      name="Allergies"
                      value={values.Allergies}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.Allergies && touched.Allergies ? (
                    <p className="bmi-Weight mt-2">{errors.Allergies}</p>
                  ) : null}
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">Medication (Optional)</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Medication"
                      name="Medication"
                      value={values.Medication}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="condition-div-yes-no">
                  <div className="Gender-Male-Female-div mt-3">
                    <span>Smoking</span>
                    <div className="Gender-main-div mt-2">
                      <button
                        style={{
                          backgroundColor:
                            smoking === "yes" ? "#FFB802" : "white",
                          color: smoking === "yes" ? "#ffffff" : "",
                        }}
                        type="button"
                        name="smoking"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("smoking", "yes");
                          setsmmoking("yes");
                        }}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            smoking === "no" ? "#FFB802" : "white",
                          color: smoking === "no" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("smoking", "no");
                          setsmmoking("no");
                        }}
                      >
                        No
                      </button>
                    </div>
                    {errors.smoking ? (
                      <p className="bmi-Weight mt-2">{errors.smoking} </p>
                    ) : null}
                  </div>
                  <div className="Gender-Male-Female-div mt-3">
                    <span>Alcohol</span>
                    <div className="Gender-main-div mt-2">
                      <button
                        style={{
                          backgroundColor:
                            alcohol === "yes" ? "#FFB802" : "white",
                          color: alcohol === "yes" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("alcohol", "yes");
                          setalcohol("yes");
                        }}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            alcohol === "no" ? "#FFB802" : "white",
                          color: alcohol === "no" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        name="alcohol"
                        onClick={() => {
                          setFieldValue("alcohol", "no");
                          setalcohol("no");
                        }}
                      >
                        No
                      </button>
                    </div>
                    {errors.alcohol ? (
                      <p className="bmi-Weight mt-2">{errors.alcohol}</p>
                    ) : null}
                  </div>
                  <div className="Gender-Male-Female-div mt-3">
                    <span>Marijuana</span>
                    <div className="Gender-main-div mt-2">
                      <button
                        style={{
                          backgroundColor:
                            marijauna === "yes" ? "#FFB802" : "white",
                          color: marijauna === "yes" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("marijauna", "yes");
                          setmarijauna("yes");
                        }}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            marijauna === "no" ? "#FFB802" : "white",
                          color: marijauna === "no" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("marijauna", "no");
                          setmarijauna("no");
                        }}
                        name="marijauna"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        No
                      </button>
                    </div>
                    {errors.marijauna ? (
                      <p className="bmi-Weight mt-2">{errors.marijauna} </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button type="submit" className="Submit-Main-btn-Bim">
                  Submit
                </button>
              </div>
            </div>
          </form>
          <div>
            <img className="Medical-next-blue" src={Blue} alt="" />
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default UpdetHealthDataDetailInformation;
