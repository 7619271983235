import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Buttton from "../../Components/Button/Buttton";
import ForgotImg from "../../Assets/Images/ForgotPasswordImg.avif";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import LoginLogo from "../../Assets/Images/login_logo@3x.png";
import { Colors } from "../../Constant/Colors";
import axios from "../../Constant/ApiUrl";
import { DOCTOROTPVERIFY, OTPVERIFY } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import { toast } from "react-toastify";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import { getStoredState } from "redux-persist";
import { store } from "../../Redux/store";
import { useNavigate } from "react-router-dom";
import Loader from "../../Constant/Loader";
import "./Otp.css";
const OtpInpuT = () => {
  const [otp, setOtp] = useState("");
  const [loading, setloading] = useState(false);
  const Mydata = store.getState().session[KEY_USER_DATA];
  const stylee = {
    display: "flex",
    gap: "1rem",
  };

  const inputstyle = {
    color: "#000000",
    width: "40px",
    height: "40px",
  };
  const navigate = useNavigate();
  const header = Headers();
  const onSubmit = async (e) => {
    setloading(true);
    let url =
      Mydata.basicInfo.role === "patient"
        ? OTPVERIFY
        : Mydata.basicInfo.role === "physician"
        ? DOCTOROTPVERIFY
        : null;
    e.preventDefault();
    try {
      const res = await axios.post(url, { otp: otp }, header);
      // console.log(res);
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.message);
      } else if (res.data.status == "success") {
        toast.success(res.data.message);
        if (Mydata?.basicInfo?.role === "patient") {
          navigate("/home-page");
        } else if (Mydata?.basicInfo?.role === "physician") {
          navigate("/Congratulation");
        }
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    // <div>
    //   <div className="main-blue-and-img">
    //     <div>
    //       <img className="Otp-imge" src={ForgotImg} alt="" />
    //     </div>
    //     <div className="mian-cunten-and-blue">
    //       <img className="Blue-and-and" src={Blue} alt="" />
    //       <div className="LoginLogo-div-main-foorget">
    //         <div className="LoginLogo-mtd-logo">
    //           <img className="LOgin-Logo" src={LoginLogo} alt="" />
    //         </div>
    //         <div>
    //           <span className="Forgot-Password-span12">Verify OTP</span>
    //         </div>
    //         <div className="Forgot-Password-span1244-div">
    //           <span className="Forgot-Password-span1244">
    //             Just input the code which we sent you on <br />
    //             <b>{Mydata?.basicInfo?.email}</b>
    //           </span>
    //         </div>
    //         <div className="fielddiv">
    //           <div className="d-flex px-2">
    //             <OtpInput
    //               value={otp}
    //               inputType="number"
    //               containerStyle={stylee}
    //               onChange={setOtp}
    //               numInputs={6}
    //               inputStyle={inputstyle}
    //               // renderSeparator={<span>-</span>}
    //               renderInput={(props) => <input {...props} />}
    //             />
    //           </div>
    //           <Buttton
    //             onClick={onSubmit}
    //             style={{
    //               backgroundColor: otp.length < 6 ? "grey" : Colors.blue,
    //             }}
    //             disable={otp.length < 6 ? "disabled" : ""}
    //           >
    //             Verify
    //           </Buttton>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div>
      <section className="ForgotPassword-main-section">
        <form className="ForgotPassword-main-div">
          <div>
            <img className="otp-ForgotImg-img" src={ForgotImg} alt="" />
          </div>
          <div>
            <div className="width-percentage-div">
              <img className="otp-blue" src={Blue} alt="" />
              <div className="LoginLogo-div-main-foorget">
                <div className="LoginLogo-mtd-logo">
                  <img className="LOgin-Logo" src={LoginLogo} alt="" />
                </div>
                <div>
                  <span className="Forgot-Password-span12">Verify OTP</span>
                </div>
                <div className="Forgot-Password-span1244-div">
                  <span className="Forgot-Password-span1244">
                    Just input the code which we sent you on <br />
                    <b>{Mydata?.basicInfo?.email}</b>
                  </span>
                </div>
                <div className="fielddiv">
                  <div className="d-flex px-2 mb-5">
                    <OtpInput
                      value={otp}
                      inputType="number"
                      containerStyle={stylee}
                      onChange={setOtp}
                      numInputs={6}
                      inputStyle={inputstyle}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <Buttton
                    onClick={onSubmit}
                    style={{
                      backgroundColor: otp.length < 6 ? "grey" : Colors.blue,
                    }}
                    disable={otp.length < 6 ? "disabled" : ""}
                  >
                    Verify
                  </Buttton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      {loading && <Loader />}
    </div>
  );
};

export default OtpInpuT;
