import React, { useEffect } from "react";
import Routes from "./Routes";
import { GlobalContextProvider } from "./Context/GlabalContext";
import { Provider } from "react-redux";
import { pStore, store } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
// import { getToken } from "firebase/messaging";
// import { messaging } from "./Firebase";
// import "webrtc-adapter";

const App = () => {
  // async function reqastPermition() {
  //   const permition = await Notification.requestPermission();
  //   if (permition === "granted") {
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BAOq11JG-cww7tMnpfYoykDkYd21WmR8-NsueyWK1TaX8wT4mUzPmOg5l5qpZAwpQSM__Cs39k_b7S8R9uxYGYs",
  //     });
  //     localStorage.setItem("FCM", token);

  //     console.log(token, "token");
  //   } else if (permition === "denied") {
  //   }
  // }

  // useEffect(() => {
  //   reqastPermition();
  // }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={pStore}>
          <GlobalContextProvider>
            <Routes />
            <ToastContainer />
          </GlobalContextProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
