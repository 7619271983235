import React, { useContext, useEffect, useState } from "react";
import "./UpcomingAppointment.css";
import location from "../../Assets/Images/location_ic_sm@3x.png";
// import Cardimg from "../../Assets/Images/ForgotPasswordImg.avif";
import Countdwon from "../../Assets/Images/countdown_blue_ic@3x.png";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Fetchdata from "../../Constant/Fetchdata";
import { UPCOMINGAPPOINTMENTLISTAPI } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
import moment from "moment";
// import CutVedioCallpopup from "../../Doctor/CutVedioCallpopup/CutVedioCallpopup";
import { GlobalContext } from "../../Context/GlabalContext";
import { store } from "../../Redux/store";
import { KEY_USER_DATA, Meetingdata } from "../../Redux/Appconstant";
import RateDoctor from "../RateDoctor/RateDoctor";

const UpcomingAppointment = () => {
  const { ratingpopup, setratingpopup, isCutcall, setisCutcall } =
    useContext(GlobalContext);

  const { response, isloading, getdata } = Fetchdata(
    UPCOMINGAPPOINTMENTLISTAPI,
    {},
    "GET"
  );

  useEffect(() => {
    getdata();
  }, [ratingpopup]);

  useEffect(() => {
    if (isCutcall) {
      getdata();
      setisCutcall(false);
    }
  }, [isCutcall]);

  const maindata = store.getState().session[Meetingdata];
  // const userdata = store.getState().session[KEY_USER_DATA];
  // console.log(response);

  const [countdown, setCountdown] = useState("");

  let targetTime;
  const [timeRemaining, setTimeRemaining] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  // const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  // const hours = Math.floor(
  //   (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  // );
  // const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  // console.log(seconds);
  useEffect(() => {
    const targetDate = new Date(targetTime);
    const now = new Date();

    const timeDifference = targetDate - now;

    const intervalId = setInterval(() => {
      const updatedNow = new Date();
      const updatedDifference = targetDate - updatedNow;
      if (updatedDifference <= 0) {
        clearInterval(intervalId);
        setCountdown("Countdown expired");
      } else {
        const hours = Math.floor(
          (updatedDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (updatedDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((updatedDifference % (1000 * 60)) / 1000);
        setCountdown(`${hours}h: ${minutes}m: ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetTime]);

  const navigate = useNavigate();

  const hendelData = (itm) => {
    navigate("/UpcomingAppointmentCount", {
      state: {
        itm,
      },
    });
  };

  return (
    <>
      <Header
        onClick={() => navigate("/home-page")}
        text={"Upcoming appointment"}
      />
      <section className="FindMD-main-section">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <>
            {response?.data ? (
              <div className="FindMD-main-div">
                {response?.data?.map((itm, index) => {
                  targetTime = itm.utc_time;
                  const curantdate = moment.utc().format();
                  const endDatetime = moment.utc(
                    targetTime,
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  const startDatetime = moment.utc(
                    curantdate,
                    "YYYY-MM-DD HH:mm:ss"
                  );

                  const timeDifference = moment.duration(
                    endDatetime.diff(startDatetime)
                  );

                  const updatedTimeDifference = timeDifference.add(
                    15,
                    "minutes"
                  );

                  const diffFormatted = `${updatedTimeDifference.days()} D : ${updatedTimeDifference.hours()} H : ${updatedTimeDifference.minutes()} M : ${updatedTimeDifference.seconds()} S:`;
                  return (
                    <div
                      onClick={() => hendelData(itm)}
                      // onClick={hendelData(itm)}
                      className="FindMD-img-contain"
                      key={itm._id}
                    >
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={itm.doctor_id.basicInfo.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Lucas-awy-main-div">
                          <div className="Dorothy-extra-div">
                            <div className="d-h-m-s-div">
                              <span>
                                <img
                                  className="Countdwon-img-sapn"
                                  src={Countdwon}
                                  alt=""
                                />

                                {diffFormatted}
                              </span>
                            </div>
                            <span className="Dorothy-sapn">
                              {itm.doctor_id.basicInfo.fullName}
                            </span>
                            <span className="General-span">
                              {itm.doctor_id.speciality}
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <img className="time-img-css" src={location} alt="" />
                          <span className="Am-pm-span">
                            {" "}
                            {itm.doctor_id.basicInfo.location}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <h1 className="text-center pt-4">{response?.message}</h1>
            )}
          </>
        )}
      </section>
      {ratingpopup && (
        <RateDoctor
          show={ratingpopup}
          setShow={setratingpopup}
          handleCloseee={() => setratingpopup(false)}
          // PationData={PationData}
          maindataitm={maindata}
        />
      )}
    </>
  );
};

export default UpcomingAppointment;
