import { Button, Dialog, DialogActions } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";

const AddModel = ({
  showmodel,
  setshowmodel,
  btn1,
  handleClick,
  btn2,
  btn3,
}) => {
  const navigate = useNavigate();
  const Mydata = store.getState().session[KEY_USER_DATA];

  // console.log(Mydata);

  return (
    <div>
      <Dialog open={showmodel}>
        <DialogActions>
          <div className="addcardmodel">
            <Button onClick={() => handleClick(1)} color="primary" autoFocus>
              {btn1}
            </Button>
            <Button onClick={() => handleClick(2)} color="primary" autoFocus>
              {btn2}
            </Button>
            {Mydata?.hospital_id && (
              <Button onClick={() => handleClick(3)} color="primary" autoFocus>
                {btn3}
              </Button>
            )}
            <Button
              onClick={() => setshowmodel(false)}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddModel;
