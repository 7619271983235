import React, {
  useState,
  useEffect,
  // useCallback,
  // useRef,
  useContext,
} from "react";
import {
  Patient,
  PatientTo,
  SYMPTOMS,
  SYMPTOMSTo,
  SYMPTOMSTherd,
  WORKING,
  MEDICAL,
  PATIENT,
  // SPECIMAN,
  SleepStudy,
} from "../../Constant/DummyData";
import Signature from "../Signature/Signature";
import "./OtherForm.css";
import { useFormik } from "formik";
import { OtherFormApi, SendtolebPostApi } from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
import { Headers } from "../../Constant/Fetchdata";
import Button from "../../Components/Button/Buttton";
import { useNavigate, useLocation } from "react-router-dom";
import { Colors } from "../../Constant/Colors";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import SendtoLebModel from "../SendtoLebModel/SendtoLebModel";
import Header from "../../Components/Header/Header";

const initialValues = {
  after: "",
  Physician: "",
  referring: "",
  Familydoctor: "",
  IsotherPatient: "",
  specifyText: "",
  Significantly: false,
  overHeight: "",
  overWeight: "",
  otherSYMPTOMSTo: "",
  otherTextPatient: "",
  otherMEDICAL: "",
  currentpressure: "",
  Bipap: false,
  IPAPcmH2O: "",
  EPAPcmH2O: "",
  SendtoPatient: false,
  SendtoLab: false,
};

const OtherForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const itm = location?.state?.id;
  // console.log(itm?._id, "route id");
  const [IsotherPatient, setIsotherPatient] = useState(false);
  const [IsotherPatientTO, setIsotherPatientTO] = useState(false);
  const [IsotherSYMPTOMSTo, setIsotherSYMPTOMSTo] = useState(false);
  const [IsotherPatientTherd, setIsotherPatientTherd] = useState(false);
  const [IsotherMEDICAL, setIsotherMEDICAL] = useState(false);
  const [IsotherPATIENT, setIsotherPATIENT] = useState(false);
  const [loading, setloading] = useState(false);
  const [IsSleepStudy, setIsSleepStudy] = useState(SleepStudy);
  const [IsPatient, setIsPatient] = useState(Patient);
  const [IsSYMPTOMS, setIsSYMPTOMS] = useState(SYMPTOMS);
  const [IsSYMPTOMSTo, setIsSYMPTOMSTo] = useState(SYMPTOMSTo);

  const [IsSYMPTOMSTherd, setIsSYMPTOMSTherd] = useState(SYMPTOMSTherd);
  const [IsWORKING, setIsWORKING] = useState(WORKING);
  const [IsMEDICAL, setIsMEDICAL] = useState(MEDICAL);
  const [IsPATIENT, setIsPATIENT] = useState(PATIENT);
  const { SigUrl } = useContext(GlobalContext);
  const [lgShow, setLgShow] = useState(false);
  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  // console.log(EmailGo, "EmailGo");

  // console.log(SigUrl, "SigUrl");
  const [checkboxdata, setcheckboxdata] = useState([]);
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };

  const [rowObject, setRowObject] = useState({
    SleepStudymap: {},
    Patientmap: {},
    SYMPTOMSmap: {},
    SYMPTOMStomap: {},
    SYMPTOMSTherdmap: {},
    WORKINGmap: {},
    MEDICALmap: {},
    PATIENTmap: {},
  });

  // console.log(rowObject, "rowObjectwObject");
  useEffect(() => {
    const updatedRowObject = { ...rowObject };
    IsSleepStudy.forEach((item) => {
      updatedRowObject.SleepStudymap[item.backEndName] = item.isSelected;
    });
    IsPatient.forEach((item) => {
      updatedRowObject.Patientmap[item.backEndName] = item.isSelected;
    });

    IsSYMPTOMS.forEach((item) => {
      updatedRowObject.SYMPTOMSmap[item.backEndName] = item.isSelected;
    });
    IsSYMPTOMSTo.forEach((item) => {
      updatedRowObject.SYMPTOMStomap[item.backEndName] = item.isSelected;
    });
    IsSYMPTOMSTherd.forEach((item) => {
      updatedRowObject.SYMPTOMSTherdmap[item.backEndName] = item.isSelected;
    });
    IsWORKING.forEach((item) => {
      updatedRowObject.WORKINGmap[item.backEndName] = item.isSelected;
    });
    IsMEDICAL.forEach((item) => {
      updatedRowObject.MEDICALmap[item.backEndName] = item.isSelected;
    });
    IsPATIENT.forEach((item) => {
      updatedRowObject.PATIENTmap[item.backEndName] = item.isSelected;
    });

    setRowObject(updatedRowObject);
  }, [
    IsSleepStudy,
    IsPatient,
    IsSYMPTOMS,
    IsSYMPTOMSTo,
    IsSYMPTOMSTherd,
    IsWORKING,
    IsMEDICAL,
    IsPATIENT,
  ]);

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");

  const CallID = localStorage.getItem("CallNoteId");

  // console.log(CallID, "CallID");
  const header = Headers();

  const LebtextApi = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SendtolebPostApi,
        {
          appointmentID: itm?._id || savedRatingId || CallID,
          email: EmailGo,
          formKry: "sleep_study",
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        OtherFormApi,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          sleep_study: {
            ...rowObject.SleepStudymap,
            after_hours_cell: values.after,
            referrering_physicians_name: values.referring,
            hysicians_billing_number: values.Physician,
            family_doctor: values.Familydoctor,
            patient_should_be_able_to_care_for_self_in_sleep_lab: {
              ...rowObject.Patientmap,
              other_text: values.IsotherPatient,
              specify: values.specifyText,
            },
            symptoms_leading_to_referral: {
              ...rowObject.SYMPTOMSmap,

              significantly_overweight_BMI_30: values.Significantly,
              significantly_overweight_BMI_30_height: values.overHeight,
              significantly_overweight_BMI_30_weight: values.overWeight,
              ...rowObject.SYMPTOMStomap,
              ...rowObject.Patientmap,
              other_text: values.otherTextPatient,
              oxygen_value: values.otherSYMPTOMSTo,
            },
            working_diagnosis: {
              ...rowObject.WORKINGmap,
            },
            other_medical_diagnoses: {
              ...rowObject.MEDICALmap,
              other_specify: values.otherMEDICAL,
              ...rowObject.PATIENTmap,
              patient_is_currently_on_CPAP_current_pressure_text:
                values.currentpressure,
              BIPAP: values.Bipap,
              BIPAP_IPAP_value: values.IPAPcmH2O,
              BIPAP_EPAP_value: values.EPAPcmH2O,
            },
            drSinature_image: SigUrl,
            sendPatient: values.SendtoPatient,
            sendLaboratory: values.SendtoLab,
          },
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        LebtextApi();
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
    }
  };

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const hendelElectro = () => {
    setLgShow(false);
    setFieldValue("SendtoLab", false);
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Other form"}
        style={{ color: "white" }}
      />
      <section className="Cardiology-main-sec">
        <form onSubmit={handleSubmit} className="Cardiology-main-div">
          <div className="Reason-main-History-div">
            <div className="w-100">
              <span className="Reason-main-span">Sleep Study Requisition</span>
              <div className="mt-3">
                <span className="GENERAL-main-span">PATIENT INFORMATION</span>
                {IsSleepStudy.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          setIsSleepStudy((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
                <div className="AFTER-main-input mt-3">
                  <span className="chack-in-span">AFTER HOURS/CELL</span>
                  <input
                    className="Date-of-Birth-input-LMNP"
                    type="text"
                    name="after"
                    value={values.after}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="AFTER-main-input mt-3">
                  <span className="chack-in-span">
                    REFERRING PHYSICIAN'S NAME
                  </span>
                  <input
                    className="Date-of-Birth-input-LMNP"
                    type="text"
                    name="referring"
                    value={values.referring}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="AFTER-main-input mt-3">
                  <span className="chack-in-span">
                    PHYSICIAN'S BILLING NUMBER:
                  </span>
                  <input
                    className="Date-of-Birth-input-LMNP"
                    type="text"
                    name="Physician"
                    value={values.Physician}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="AFTER-main-input mt-3">
                  <span className="chack-in-span">
                    FAMILY DOCTOR(if different to referring doctor):
                  </span>
                  <input
                    className="Date-of-Birth-input-LMNP"
                    type="text"
                    name="Familydoctor"
                    value={values.Familydoctor}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <div className="Patient-main-span-div mt-5">
                    <span className="ULTRASOUND-spn">
                      Patient should be able to care for self in sleep lab.
                    </span>
                  </div>
                  <div>
                    {IsPatient.map((item, index) => {
                      return (
                        <div className="checkdivmain" key={index}>
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              if (item.id === 222) {
                                setIsotherPatient(!IsotherPatient);
                              }
                              setIsPatient((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      );
                    })}
                    <div>
                      {IsotherPatient ? (
                        <textarea
                          id="w3review"
                          name="IsotherPatient"
                          rows="3"
                          className="Other-input-2-answered   mt-2"
                          cols="50"
                          placeholder="Write here"
                          value={values.IsotherPatient}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></textarea>
                      ) : null}
                    </div>
                    <div className="m-2"></div>
                  </div>
                  <div>
                    {PatientTo.map((item, index) => {
                      return (
                        <div className="checkdivmain" key={index}>
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              if (item.id === 223) {
                                setIsotherPatientTO(!IsotherPatientTO);
                              }
                            }}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      );
                    })}
                    <div>
                      {IsotherPatientTO ? (
                        <textarea
                          id="w3review"
                          name="specifyText"
                          rows="3"
                          className="Other-input-2-answered   mt-2"
                          cols="50"
                          placeholder="Write here"
                          value={values.specifyText}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></textarea>
                      ) : null}
                    </div>
                    <div className="m-2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100">
              <div className="SYMPTOMS-main-span-div ">
                <span className="ULTRASOUND-spn">
                  SYMPTOMS LEADING TO REFERRAL:
                </span>
              </div>
              {IsSYMPTOMS.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setIsSYMPTOMS((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="Significantly"
                  value={values.Significantly}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="chack-in-span">
                  Significantly overWeight (BMI 30)
                </span>
              </div>
              <div className="all-input-and-span">
                <input
                  placeholder="Height"
                  name="overHeight"
                  id="1"
                  type="number"
                  className="Height-Wight-main-input"
                  value={values.overHeight}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <input
                  className="Height-Wight-main-input"
                  placeholder="Wight"
                  name="overWeight"
                  id="1"
                  type="number"
                  value={values.overWeight}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div>
                {IsSYMPTOMSTo.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          if (item.id === 233) {
                            setIsotherSYMPTOMSTo(!IsotherSYMPTOMSTo);
                          }
                          setIsSYMPTOMSTo((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
                <div>
                  {IsotherSYMPTOMSTo ? (
                    <input
                      placeholder="Oxygen"
                      type="number"
                      className="Date-of-Birth-input-LMNP mt-3"
                      name="otherSYMPTOMSTo"
                      value={values.otherSYMPTOMSTo}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  ) : null}
                </div>
                <div className="m-2"></div>
              </div>
              <div>
                {IsSYMPTOMSTherd.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          if (item.id === 234) {
                            setIsotherPatientTherd(!IsotherPatientTherd);
                          }
                          setIsSYMPTOMSTherd((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
                <div>
                  {IsotherPatientTherd ? (
                    <textarea
                      id="w3review"
                      rows="3"
                      className="Other-input-2-answered   mt-2"
                      cols="50"
                      placeholder="Write here"
                      name="otherTextPatient"
                      value={values.otherTextPatient}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></textarea>
                  ) : null}
                </div>
                <div className="m-2"></div>
              </div>
              <div className="DIAGNOSES-main-span-div mt-3">
                <span className="ULTRASOUND-spn">WORKING DIAGNOSIS:</span>
              </div>
              <div>
                {IsWORKING.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          setIsWORKING((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-100">
              <div className="MEDICAL-main-span-div">
                <span className="ULTRASOUND-spn">OTHER MEDICAL DIAGNOSES</span>
              </div>
              <div>
                {IsMEDICAL.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          if (item.id === 244) {
                            setIsotherMEDICAL(!IsotherMEDICAL);
                          }
                          setIsMEDICAL((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
                <div>
                  {IsotherMEDICAL ? (
                    <textarea
                      id="w3review"
                      name="otherMEDICAL"
                      rows="3"
                      className="Other-input-2-answered   mt-2"
                      cols="50"
                      placeholder="Write here"
                      value={values.otherMEDICAL}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    ></textarea>
                  ) : null}
                </div>
                <div className="m-2"></div>
              </div>
              <div className="DIAGNOSES2-main-span-div mt-4"></div>
              <div>
                {IsPATIENT.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          if (item.id === 248) {
                            setIsotherPATIENT(!IsotherPATIENT);
                          }
                          setIsPATIENT((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
                <div>
                  {IsotherPATIENT ? (
                    <input
                      placeholder="current pressure"
                      type="number"
                      className="Date-of-Birth-input-LMNP mt-3"
                      name="currentpressure"
                      value={values.currentpressure}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  ) : null}
                </div>
                <div className="m-2"></div>
              </div>

              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="Bipap"
                  value={values.Bipap}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="chack-in-span">BIPAP</span>
              </div>
              <div className="all-input-and-span">
                <input
                  placeholder="IPAP cm H2O"
                  // name="Exercise"
                  id="1"
                  type="number"
                  className="Height-Wight-main-input"
                  name="IPAPcmH2O"
                  value={values.IPAPcmH2O}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <input
                  className="Height-Wight-main-input"
                  placeholder="EPAP cm H2O"
                  id="1"
                  type="number"
                  name="EPAPcmH2O"
                  value={values.EPAPcmH2O}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="SendtoPatient"
                  checked={values.SendtoPatient}
                  value={values.SendtoPatient}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="chack-in-span">Send to Patient</span>
              </div>
              <div onClick={() => setLgShow(true)} className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="SendtoLab"
                  value={values.SendtoLab}
                  checked={values.SendtoLab ? true : false}
                  onChange={(e) => setFieldValue("SendtoLab", e.target.checked)}
                  onBlur={handleBlur}
                />
                <span className="chack-in-span">Send to Lab</span>
              </div>
              <Signature />
              <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
                Submit
              </Button>
            </div>
          </div>
        </form>
        <SendtoLebModel
          onHide={() => {
            setFieldValue("SendtoLab", false);
            setLgShow(false);
          }}
          lgShow={lgShow}
          setLgShow={setLgShow}
          hendelElectro={hendelElectro}
        />
      </section>
      {loading && <Loader />}
    </>
  );
};

export default OtherForm;
