import React from "react";
import "./Header.css";
import { Icons } from "../../Constant/Icons";

const Header = ({
  text,
  extrafield,
  onClick,
  style,
  secondtext,
  secondClick,
}) => {
  return (
    <div className="headercontainer" style={style}>
      <div className="header">
        <span onClick={onClick}>{Icons.backarrowwhite}</span>
        <span>{text}</span>
        <span onClick={secondClick}>{secondtext}</span>
      </div>
      <div style={{ alignSelf: "flex-start", width: "100%" }}>{extrafield}</div>
    </div>
  );
};

export default Header;
