import React, { useState, useRef, useContext } from "react";
import { Modal } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Signature.css";
import { GlobalContext } from "../../Context/GlabalContext";
import { UplodeImg } from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
import { Contenttype, Headers } from "../../Constant/Fetchdata";
import Loader from "../../Constant/Loader";

const Signature = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signatureSaved, setSignatureSaved] = useState(false);
  // const [modalSigPadSaved, setmodalSigPadSaved] = useState();
  const [loading, setloading] = useState(false);

  const { SigPadSaved, setSigPadSaved } = useContext(GlobalContext);
  // console.log(SigPadSaved, "SigPadSaved");
  const { setSigUrl, SigUrl } = useContext(GlobalContext);
  const modalSigPad = useRef(null);
  const [drawingStack, setDrawingStack] = useState([]);
  const [currentDrawingIndex, setCurrentDrawingIndex] = useState(-1);
  // console.log(SigUrl, "SigUrl");
  function clearSignature() {
    modalSigPad.current.clear();
    setDrawingStack([]);
    setCurrentDrawingIndex(-1);
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function saveSignature() {
    const isEmpty = modalSigPad.current.isEmpty();
    if (isEmpty) {
      alert("Please draw your signature before saving.");
      return;
    }

    const dataURL = modalSigPad.current.toDataURL();
    const file = dataURLtoFile(dataURL, "signature.png");
    setSignatureSaved(true);
    setIsModalOpen(false);
    setSigPadSaved(file);
    GetUrl(file);
  }

  function undoSignature() {
    if (currentDrawingIndex > 0) {
      setCurrentDrawingIndex(currentDrawingIndex - 1);
      modalSigPad.current.fromData(drawingStack[currentDrawingIndex - 1]);
    }
  }

  function redoSignature() {
    if (currentDrawingIndex < drawingStack.length - 1) {
      setCurrentDrawingIndex(currentDrawingIndex + 1);
      modalSigPad.current.fromData(drawingStack[currentDrawingIndex + 1]);
    }
  }

  function showSignature() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    // Reset the signature saved state when closing the modal
    setSignatureSaved(false);
  }

  function handleDrawEnd() {
    const updatedStack = drawingStack.slice(0, currentDrawingIndex + 1);
    updatedStack.push(modalSigPad.current.toData());
    setDrawingStack(updatedStack);
    setCurrentDrawingIndex(currentDrawingIndex + 1);
  }
  const header = Headers(Contenttype.formdata);
  const GetUrl = async (file) => {
    setloading(true);

    const formdata = new FormData();
    formdata.append("image", file);
    try {
      const res = await axios.post(UplodeImg, formdata, header);
      setloading(false);
      // console.log(res);
      setSigUrl(res.data.image);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <>
      <section className="Save-signatur-and-show">
        <div className="Save-signatur-and-div">
          <div className="showSignature-div-ok" onClick={showSignature}>
            <span className="Signature-span-jii">Signature</span>
            <div className="entarsignatur-div">
              {signatureSaved && (
                <img
                  src={SigUrl}
                  alt="Saved Signature"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              )}
            </div>
          </div>

          <Modal show={isModalOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Signature Preview</Modal.Title>
              <div className="Signature-Undo">
                <span onClick={undoSignature}>Undo</span>
                <span onClick={redoSignature}>Redo</span>
                <span onClick={clearSignature}>Clear</span>
              </div>
            </Modal.Header>
            <Modal.Body>
              <SignaturePad
                canvasProps={{ width: 420, height: 190 }}
                ref={(ref) => (modalSigPad.current = ref)}
                onEnd={handleDrawEnd}
              />
              <div className="saveSignature-main">
                <button
                  className="Done-main-button-sig"
                  onClick={saveSignature}
                  // onChange={(e) => GetUrl(e.target.d)}
                >
                  Done
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>
      {loading && <Loader />}
    </>
  );
};

export default Signature;
