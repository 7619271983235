import React, { useState, useEffect } from "react";
// import location from "../../Assets/Images/location_ic_sm@3x.png";
import Cardimg from "../../Assets/Images/Doctorsto.avif";
import "./DoctorBook.css";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { DoctorBookAPI } from "../../Constant/Apiconstant";
import Fetchdata from "../../Constant/Fetchdata";
import { SimpleLoader } from "../../Constant/Loader";
import { Icons } from "../../Constant/Icons";
import { toast } from "react-toastify";

const DoctorBook = () => {
  const location = useLocation();
  const Path = location?.state?.IDReferr;
  // console.log(Path?.Pathname, "Path");
  const navigate = useNavigate();
  const [doctorBook, setDoctorBook] = useState();

  // const [DoctorEmail, setDoctorEmail] = useState({
  //   DocEmail: "",
  //   isSelected: null,
  // });

  const [IdPathMain, setIdPathMain] = useState({
    // Docteremail: DoctorEmail.DocEmail,
    DocEmail: "",
    isSelected: null,
    IdPath: Path?.IdPath,
  });

  // console.log(DoctorEmail, "DoctorEmail");
  // console.log(IdPathMain, "selectedcard");
  const { response, isloading } = Fetchdata(DoctorBookAPI, {}, "GET");
  // console.log(Path, "doctorBook");
  useEffect(() => {
    if (response?.data) {
      // console.log("response.data", response.data);
      setDoctorBook(response?.data);
    }
  }, [response]);
  // console.log(response, "no");

  const handleid = async (item) => {
    // console.log(item);
    navigate("/AddDoctorInformation", {
      state: {
        item,
      },
    });
  };

  const handlSelectEmail = async () => {
    if (IdPathMain.DocEmail === "") {
      toast.error("Select Doctor");
    } else {
      navigate("/ReferralDoctor", {
        state: {
          IdPathMain,
        },
      });
    }
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Doctor book"}
        style={{ color: "white" }}
      />
      <section className="FindMD-main-section">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <div className="FindMD-main-div">
            {doctorBook?.length > 0
              ? doctorBook.map((itm, index) => {
                  // console.log("itm.image", itm.image);
                  return (
                    <div
                      onClick={() =>
                        setIdPathMain({
                          isSelected: index,
                          DocEmail: itm?.email,
                          IdPath: Path?.IdPath,
                        })
                      }
                      className="FindMD-img-contain"
                      key={index}
                    >
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={itm?.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Lucas-awy-main-div">
                          <div className="Dorothy-extra-div-svg-book">
                            <span className="Dorothy-sapn">{itm?.name}</span>
                            {Path?.Pathname !== "/ReferralDoctor" ? (
                              <svg
                                onClick={() => handleid(itm)}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <title>
                                  E1CB1A32-449A-417A-B326-5FD72A554AECsvg/
                                </title>
                                <g
                                  id="Page-1"
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="Health-Data-Detail"
                                    transform="translate(-335.000000, -48.000000)"
                                  >
                                    <g
                                      id="profile_edit_ic"
                                      transform="translate(335.000000, 48.000000)"
                                    >
                                      <polygon
                                        id="Path"
                                        points="0 0 24 0 24 24 0 24"
                                      ></polygon>
                                      <path
                                        d="M6.414,16 L16.556,5.858 L15.142,4.444 L5,14.586 L5,16 L6.414,16 Z M7.243,18 L3,18 L3,13.757 L14.435,2.322 C14.8254999,1.93161806 15.4585001,1.93161806 15.849,2.322 L18.678,5.151 C19.0683819,5.54149985 19.0683819,6.17450015 18.678,6.565 L7.243,18 Z M3,20 L21,20 L21,22 L3,22 L3,20 Z"
                                        id="Shape"
                                        fill="#16151d"
                                        fillRule="nonzero"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            ) : (
                              <span>
                                {IdPathMain?.isSelected === index
                                  ? Icons.cardselecticon
                                  : null}
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <span className="Am-pm-span">{itm?.speciality}</span>
                        </div>
                        <div className="">
                          <span className="Am-pm-span">Email: </span>
                          <span className="Am-pm-span"> {itm?.email}</span>
                        </div>
                        <div>
                          <span className="Am-pm-span">Created by : </span>
                          <span className="Am-pm-span"> {itm?.created_by}</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        )}
        <div className="mb-5 d-flex gap-2">
          <button
            onClick={() => navigate("/TowAddDoctorInformation")}
            className="Update-Register-karo"
          >
            Add New
          </button>
          {Path?.Pathname == "/ReferralDoctor" ? (
            <button onClick={handlSelectEmail} className="Update-Register-karo">
              Next
            </button>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default DoctorBook;
