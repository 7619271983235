import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Addcard from "../../Patient/AddCard/Addcard";

const Stripewraper = () => {
  const stripepromise = loadStripe(
    "pk_test_51OVXPSKo0LfdMvC8ljXcCS3ozJ73TGnbXB6KB5HFKQX9cVJqp7Br4eiIlVhri0dkKIHLXmZgqFYpTEwQkKUBfjzN00y8Ok58iF"
  );
  return (
    <div>
      <Elements stripe={stripepromise}>
        <Addcard />
      </Elements>
    </div>
  );
};

export default Stripewraper;
