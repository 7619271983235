import React, { useEffect, useState, useContext, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./Prescription.css";
import Header from "../../Components/Header/Header";
import { json, useLocation, useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import {
  GETMEDICINEAPI,
  GETREQUESTEDPRESCRIPTIONAPI,
  PRESCRIPTIONMASTERAPI,
  PrescriptionNotesApi,
  RejactPrescription,
  EditPrescriptionNotesApi,
  ApprovalPrescription,
  getMedicineApi,
} from "../../Constant/Apiconstant";
import { Icons } from "../../Constant/Icons";
import Loader, { SimpleLoader } from "../../Constant/Loader";
import { Formik, useFormik } from "formik";
import { GlobalContext } from "../../Context/GlabalContext";
import Serachmedicinepop from "../Serachmedicinepop/Serachmedicinepop";
import { IoSearchOutline } from "react-icons/io5";
import EnterMedicineName from "../EnterMedicineName/EnterMedicineName";
// import { GETMEDICINEAPI } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { Backdrop, CircularProgress } from "@mui/material";

import moment from "moment";
import Signature from "../Signature/Signature";
import { toast } from "react-toastify";
import RejectionPopup from "../RejectionPopup/RejectionPopup";

const Prescription = () => {
  const route = useLocation();
  const location = useLocation();
  const id = location?.state?.id;
  // console.log(id?._id, "id");
  const [precription, setprecription] = useState([]);

  // console.log(precription, "precription.............");
  const { Medicine } = useContext(GlobalContext);
  const [PopupName, setPopupName] = useState(false);
  const [PopupRej, setPopupRej] = useState(false);
  const [activeTab, setActiveTab] = useState("search");
  const [currentPage, setCurrentPage] = useState(1); // Set the default active tab to "search"
  const { setMedicine } = useContext(GlobalContext);
  const [drugNames, setDrugNames] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setloading] = useState(false);
  const { SigUrl } = useContext(GlobalContext);
  const [pagecount, setpagecount] = useState(1);
  const [keyword, setkeyword] = useState("");

  // console.log(SigUrl, "SigUrl");

  // console.log(drugNames, "drugNames");
  const { setRejactId, RejactId } = useContext(GlobalContext);

  // console.log(RejactId, "RejactId");
  const { setresion, resion } = useContext(GlobalContext);
  // console.log(resion, "bbhbnnb");
  const [Popup, setPopup] = useState(false);
  // console.log(Medicine, "Docdata");
  // console.log(precription, "precription");
  const maindata = route?.state?.item;

  const Loc = location?.state?.NotiPath;
  const Notitm = location?.state?.Notitm;
  // console.log(Notitm, "Notitm");
  // console.log(Loc, "Loc");
  // console.log(maindata, "maindata");
  const navigate = useNavigate();

  const initialValues = {
    medicine: [],
    SendtoParmacy: false,
    SendtoPatient: false,
  };

  const params = {
    appointmentId: maindata?.appointment_id || Notitm?.appointment_id,
    prescriptionAppointment_id:
      maindata?.prescriptionAppointment_id ||
      Notitm?.prescriptionAppointment_id,
    prescriptionIds: maindata?.prescriptionIds || Notitm?.prescriptionIds,
  };

  const { response: okdata, isloading } = Fetchdata(
    GETREQUESTEDPRESCRIPTIONAPI,
    params,
    "POST"
  );

  useEffect(() => {
    setprecription(okdata?.data);

    okdata?.data?.forEach((element) => {
      setFieldValue("medicine", element?.nameOfMedicine);
    });
  }, [okdata]);

  // console.log(okdata, "okdata");

  const { response: medicineDATA } = Fetchdata(GETMEDICINEAPI, {}, "GET");
  const { response: masterDATA } = Fetchdata(PRESCRIPTIONMASTERAPI, {}, "GET");
  const header = Headers();
  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        RejactPrescription,
        {
          prescriptionAppointment_id:
            Notitm?.prescriptionAppointment_id ||
            maindata?.prescriptionAppointment_id,
          prescriptionId: RejactId,
          cancelledReason: resion,
          status: "cancelled",
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
      if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        setPopupRej(false);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const ApruApi = async () => {
    try {
      const res = await axios.post(
        ApprovalPrescription,
        {
          prescription_id:
            Notitm?.prescriptionAppointment_id ||
            maindata?.prescriptionAppointment_id,
          status: "approved",
        },
        header
      );

      // console.log(res, "jvcccfh");
      if (res?.data?.status == "failed") {
        toast.error(res?.data?.message);
      } else if (res?.status === 200 && res?.data?.status === "success") {
        Acsapt();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const NotiAdd = async () => {
  //   setloading(true);
  //   try {
  //     const res = await axios.post(
  //       PrescriptionNotesApi,
  //       {
  //         appointmentId: Notitm?.appointment_id,
  //         prescription: {
  //           prescriptionData: JSON.stringify(precription),
  //           drSinature_image: SigUrl,
  //           sendPatient: values?.SendtoPatient,
  //           sendPharmacy: values?.SendtoParmacy,
  //           prescriptionDate: formattedDate,
  //         },
  //       },
  //       header
  //     );
  //     if (res?.data?.status == "failed") {
  //       toast.error(res?.data?.status);
  //     } else if (res?.status === 200 && res?.data?.status === "success") {
  //       toast.success(res?.data?.message);
  //       navigate("/Notifications");
  //     }
  //     setloading(false);
  //     console.log(res, "Add............");
  //   } catch (error) {
  //     console.log(error);
  //     setloading(false);
  //   }
  // };

  const Acsapt = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        EditPrescriptionNotesApi,
        {
          appointmentId: maindata?.appointment_id || Notitm?.appointment_id,
          prescription: {
            prescriptionData: JSON.stringify(precription),
            drSinature_image: SigUrl,
            sendPatient: values?.SendtoPatient,
            sendPharmacy: values?.SendtoParmacy,
            prescriptionDate: formattedDate,
          },
        },
        header
      );
      if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        navigate("/Notifications");
      }
      setloading(false);
      // console.log(res, "Edit note...............");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const formattedDate = moment(currentDate).format("YYYY-MM-DD");

  // console.log(formattedDate, "formattedDate");
  const { handleSubmit, values, handleChange, handleBlur, setFieldValue } =
    useFormik({
      onSubmit,
      initialValues: initialValues,
    });

  useEffect(() => {
    const storedPrescriptions = localStorage.getItem("prescriptions");
    if (storedPrescriptions) {
      setprecription(JSON.parse(storedPrescriptions));
    } else {
      setprecription([
        {
          nameOfMedicine: Medicine,
          PRN: false,
          dose: "",
          doseUnit: "",
          route: "",
          frequency: "",
          duration: "",
          durationUnit: "",
        },
      ]);
    }
  }, [Medicine]);

  const addMore = () => {
    setprecription((prev) => [
      ...prev,
      {
        nameOfMedicine: "",
        PRN: false,
        dose: "",
        doseUnit: "",
        route: "",
        frequency: "",
        duration: "",
        durationUnit: "",
      },
    ]);
  };

  const deleteprescription = (item, index) => {
    setPopupRej(true);
    setRejactId(item?._id);
    const updatedPrescriptions = [...precription];

    updatedPrescriptions.splice(index, 1);

    setprecription(updatedPrescriptions);
  };

  const handleMedicineChange = (index, field, value) => {
    setprecription((prev) => {
      const updatedPrescription = [...prev];
      updatedPrescription[index] = {
        ...updatedPrescription[index],
        [field]: value,
      };
      return updatedPrescription;
    });
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName === activeTab ? null : tabName);
  };

  const handleClick = () => {
    setPopupName(true);
  };
  const [index, seined] = useState(0);

  const handleClickmadi = (medicineName) => {
    // setMedicine(medicineName);
    setprecription((prv) => {
      let temp = [...prv];
      temp[index].nameOfMedicine = medicineName;
      return temp;
    });
    // console.log(medicineName);
    setPopup(false);
    // navigate("/PrescriptionNotes");
  };

  const Medyy = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        getMedicineApi,
        {
          pageLimit: 10,
          page: pagenumber,
          drug_name: keyword,
        },
        header
      );

      setloading(false);
      // console.log(res, "jvcccfh");

      setDrugNames(res.data.data.data);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const pagenumber = useRef(1);
  const totalpage = useRef(0);

  const handlescroll = () => {
    try {
      if (drugNames) {
        if (
          window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight
        ) {
          pagenumber.current = pagenumber.current + 1;
          setpagecount((prev) => prev + 1);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setkeyword("");
    window.addEventListener("scroll", handlescroll);
    return () => {
      window.removeEventListener("scroll", handlescroll);
    };
  }, []);

  useEffect(() => {
    Medyy();
  }, [pagecount]);

  const debouncedelay = 10;
  useEffect(() => {
    let timer;
    if (keyword) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        Medyy();
      }, debouncedelay);
    } else if (keyword == "") {
      Medyy();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [keyword]);

  const { response, getdata } = Fetchdata(GETMEDICINEAPI, {}, "GET");
  // console.log(response, "response");

  const data = [
    {
      medicineName: "dolo",
    },
    {
      medicineName: "one-p",
    },
    {
      medicineName: "den-p",
    },
  ];
  const apidatas = async () => {
    try {
      const response = await axios.get(
        "https://dailymed.nlm.nih.gov/dailymed/services/v2/drugnames",
        {}
      );

      setloading(false);
      // console.log(response);
    } catch (error) {
      console.log("errors75", error);
      setloading(false);
    }
  };
  useEffect(() => {
    apidatas();
  }, []);
  const showmodal = (index) => {
    setPopup(true);
    seined(index);
  };
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Prescription"}
        style={{ color: "white" }}
      />
      <form onSubmit={handleSubmit} className="Prescription-main-section mb-5">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <div className="Prescription-main-div">
            {!okdata?.data ? (
              <h1>{okdata?.message}</h1>
            ) : (
              <div className="d-flex flex-column gap-3">
                {precription.map((item, index) => (
                  <div className="d-flex flex-column" key={index}>
                    <span
                      className="align-self-end"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteprescription(item, index)}
                    >
                      X
                    </span>

                    <div className="d-flex flex-column">
                      <span className="Medicine-sab-input-span">
                        Name of Medicine
                      </span>
                      <input
                        id="Dose"
                        className="handleMedicineChange-main"
                        onChange={(e) =>
                          handleMedicineChange(
                            index,
                            "nameOfMedicine",
                            e.target.value
                          )
                        }
                        onClick={() => {
                          showmodal(index);
                        }}
                        value={precription[index].nameOfMedicine}
                        placeholder="Enter Name"
                        name={`nameOfMedicine${[index]}`}
                        type="text"
                      />
                    </div>
                    <div className="Yes-and-No-name mt-3">
                      <div className="Unit-kg-selact">
                        <span className="Medicine-sab-input-span">Dose</span>

                        <input
                          className="Enter-Dose-value-number mt-2"
                          name={`dose
                          ${[index]}`}
                          type="number"
                          value={precription[index].dose}
                          placeholder="selact Duration"
                          id="Dose"
                          onChange={(e) =>
                            handleMedicineChange(index, "dose", e.target.value)
                          }
                        />
                      </div>
                      <div className="Unit-kg-selact">
                        <span className="Medicine-sab-input-span">Unit</span>
                        <select
                          className="Enter-Dose-value mt-2"
                          name={`doseUnit
                          ${[index]}`}
                          value={precription[index].doseUnit}
                          id="Dose"
                          onChange={(e) =>
                            handleMedicineChange(
                              index,
                              "doseUnit",
                              e.target.value
                            )
                          }
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          {masterDATA?.data?.doseUnit.map((dose) => (
                            <option value={dose?.name} key={dose?._id}>
                              {dose?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="Yes-and-No-name mt-3">
                      <div className="Unit-kg-selact">
                        <span className="Medicine-sab-input-span">
                          Frequency
                        </span>
                        <div className="d-flex w-100 align-content-center gap-4">
                          <select
                            className="Enter-Dose-value-Dose mt-2"
                            name={`frequency${[index]}`}
                            value={precription[index].frequency}
                            id="Dose"
                            onChange={(e) =>
                              handleMedicineChange(
                                index,
                                "frequency",
                                e.target.value
                              )
                            }
                          >
                            <option value="" selected disabled>
                              Select Frequency
                            </option>
                            {masterDATA?.data?.freuqency?.map((freuqency) => (
                              <option
                                value={freuqency?.name}
                                key={freuqency?._id}
                              >
                                {freuqency?.name}
                              </option>
                            ))}
                          </select>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleMedicineChange(
                                  index,
                                  "PRN",
                                  e.target.checked
                                )
                              }
                              checked={item.PRN}
                              style={{ scale: "1.7" }}
                            />
                            <span className="Medicine-sab-input-span">PRN</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Unit-kg-selact">
                      <span className="Medicine-sab-input-span">Route</span>
                      <select
                        className="Enter-Dose-value-Route mt-2"
                        name={`route${[index]}`}
                        value={precription[index].route}
                        id="Dose"
                        onChange={(e) =>
                          handleMedicineChange(index, "route", e.target.value)
                        }
                      >
                        <option value="" selected disabled>
                          Select Route
                        </option>
                        {masterDATA?.data?.routesData?.map((route) => (
                          <option value={route?.name} key={route?._id}>
                            {route?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="Yes-and-No-name mt-3">
                      <div className="Unit-kg-selact">
                        <span className="Medicine-sab-input-span">
                          Duration
                        </span>
                        <input
                          className="Enter-Dose-value-number mt-2"
                          name={`duration${[index]}`}
                          value={precription[index].duration}
                          type="number"
                          placeholder="selact Duration"
                          // id="Dose"
                          onChange={(e) =>
                            handleMedicineChange(
                              index,
                              "duration",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="Unit-kg-selact">
                        <span className="Medicine-sab-input-span">Unit</span>
                        <select
                          className="Enter-Dose-value mt-2"
                          name={`durationUnit${[index]}`}
                          value={precription[index].durationUnit}
                          id="Dose"
                          onChange={(e) =>
                            handleMedicineChange(
                              index,
                              "durationUnit",
                              e.target.value
                            )
                          }
                        >
                          <option value="" selected disabled>
                            Select Unit
                          </option>
                          {["Day", "Week", "Month", "Year"].map(
                            (durationUnit) => (
                              <option
                                value={durationUnit?.name}
                                key={durationUnit}
                              >
                                {durationUnit}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {/* <div className="Unit-kg-selact">
                    <span className="Medicine-sab-input-span">Unit</span>
                    <select
                      className="Enter-Dose-value mt-2"
                      name="durationUnit"
                      id="Dose"
                      onChange={(e) =>
                        handleMedicineChange(0, "durationUnit", e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Select Unit
                      </option>
                      <option value="Day">Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                      <option value="Year">Year</option>
                    </select>
                  </div> */}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="d-flex flex-column ">
          <div className="Duration-add-more">
            <span onClick={addMore}>{Icons?.addbluebtn}</span>
            <span className="New-ard-add-span" onClick={addMore}>
              Add More
            </span>
          </div>
          <div className="Patient-main-div">
            <input
              className="main-input-chek"
              type="checkbox"
              name="SendtoPatient"
              value={values?.SendtoPatient}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <span className="Organization-sapn"> Send to Patient</span>
          </div>

          <div
            onClick={() => navigate("/SelectedPharmacy")}
            className="Patient-main-div"
          >
            <input
              className="main-input-chek"
              type="checkbox"
              name="SendtoParmacy"
              value={values?.SendtoParmacy}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <span className="Organization-sapn"> Send to Parmacy</span>
          </div>
        </div>
        <Signature />
        {Loc === "/Notifications" ? (
          <Button onClick={ApruApi} style={{ backgroundColor: Colors.blue }}>
            Accept Submit
          </Button>
        ) : (
          <Button
            onClick={Acsapt}
            type="submit"
            style={{ backgroundColor: Colors.blue }}
          >
            Accept Submit
          </Button>
        )}
      </form>

      <Modal show={Popup} size="md" centered>
        <Modal.Header
          style={{
            display: "grid",
            placeItems: "end",
            width: "100% ",
            justifyContent: "end",
            border: "0",
          }}
          closeButton
          onClick={() => setPopup(false)}
        ></Modal.Header>
        <section className="ReferralDoctor-main-section">
          <div className="ReferralDoctor-main-div">
            <div className="Search-main-buton-v">
              <button
                className="handleTabClick-search"
                onClick={() => handleTabClick("search")}
                // activeClassName="active"
              >
                Search
              </button>
              <button
                className="handleTabClick-search"
                // activeClassName="active"
                onClick={() => handleTabClick("myMedicine")}
              >
                My medicine
              </button>
            </div>

            {activeTab === "search" && (
              <div className="IoSearchOutline-search-com">
                <div>
                  <div className="mt-4 IoSearchOutline-main-div-in">
                    <IoSearchOutline className="IoSearchOutline-main" />
                    <input
                      className="IoSearchOutline-main-input"
                      placeholder="search"
                      type="search"
                    />
                  </div>
                  {drugNames.map((itm, index) => {
                    {
                      /* console.log(itm.drug_name); */
                    }
                    return (
                      <>
                        <div key={index} className="mt-2 w-100">
                          <span
                            onClick={() => handleClickmadi(itm?.drug_name)}
                            className="test-medical"
                          >
                            {itm?.drug_name}
                          </span>
                        </div>
                        <div className="line-haghit-div"></div>
                      </>
                    );
                  })}
                </div>

                <div className="line-haghit-div"></div>

                <div>
                  <button onClick={handleClick} className="Add-search-New">
                    Add New
                  </button>
                </div>
              </div>
            )}
            {activeTab === "myMedicine" && (
              <>
                {response?.data?.map((itm, index) => {
                  return (
                    <div key={index} className="IoSearchOutline-search-com">
                      <div className="mt-2 w-100">
                        <span
                          onClick={() => handleClickmadi(itm?.medicineName)}
                          className="test-medical"
                        >
                          {itm?.medicineName}
                        </span>
                      </div>
                      <div className="line-haghit-div"></div>
                    </div>
                  );
                })}
                <div>
                  <button onClick={handleClick} className="Add-search-New">
                    Add New
                  </button>
                </div>
              </>
            )}
          </div>
          {PopupName && (
            <EnterMedicineName
              PopupName={PopupName}
              setPopupName={setPopupName}
              getdata={getdata}
              text={"Enter Name of Medicine"}
            />
          )}
        </section>
      </Modal>
      <RejectionPopup
        PopupName={PopupRej}
        setPopupName={setPopupRej}
        handleCloseeee={() => onSubmit("")}
        placetext={"plac"}
        text={"Enter Name of Medicine"}
        Spantext={"Rejection Reason"}
        lebaltext={"Write Review"}
      />
      {loading && <Loader />}
    </>
  );
};

export default Prescription;
