import React, { useState, useEffect, useCallback, useRef } from "react";
import "./HIVForm.css";
import { useFormik } from "formik";
import {
  Type,
  Tests,
  checkapply,
  Lasttest,
  Ethnicity,
  RiskFactors,
  Sexwith,
} from "../../Constant/DummyData";
import axios from "../../Constant/ApiUrl";
import { HIVFormApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
const initialValues = {
  CountryofBirth: "",
  Collectiondate: "",
};

const HIVForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const itm = location?.state?.id;
  // console.log(itm?._id, "route id");
  const [loading, setloading] = useState(false);
  const [isType, setisType] = useState(Type);
  const [isTests, setisTests] = useState(Tests);
  const [ischeckapply, setischeckapply] = useState(checkapply);
  const [isLasttest, setisLasttest] = useState(Lasttest);
  const [isEthnicity, setisEthnicity] = useState(Ethnicity);
  const [isRiskFactors, setisRiskFactors] = useState(RiskFactors);
  const [isSexwith, setisSexwith] = useState(Sexwith);

  const [checkboxdata, setcheckboxdata] = useState([]);
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };

  const [rowObject, setRowObject] = useState({
    Typemap: {},
    Testsmap: {},
    checkapplymap: {},
    Lasttestmap: {},
    Ethnicitymap: {},
    RiskFactorsmap: {},
    Sexwithmap: {},
  });

  // console.log(rowObject, "rowObject");
  useEffect(() => {
    const updatedRowObject = { ...rowObject };
    isType.forEach((item) => {
      updatedRowObject.Typemap[item.backEndName] = item.isSelected;
    });
    isTests.forEach((item) => {
      updatedRowObject.Testsmap[item.backEndName] = item.isSelected;
    });
    ischeckapply.forEach((item) => {
      updatedRowObject.checkapplymap[item.backEndName] = item.isSelected;
    });

    isLasttest.forEach((item) => {
      updatedRowObject.Lasttestmap[item.backEndName] = item.isSelected;
    });
    isEthnicity.forEach((item) => {
      updatedRowObject.Ethnicitymap[item.backEndName] = item.isSelected;
    });
    isRiskFactors.forEach((item) => {
      updatedRowObject.RiskFactorsmap[item.backEndName] = item.isSelected;
    });
    isSexwith.forEach((item) => {
      updatedRowObject.Sexwithmap[item.backEndName] = item.isSelected;
    });
    setRowObject(updatedRowObject);
  }, [isType, isTests, ischeckapply, isLasttest, isEthnicity, isRiskFactors]);

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  const CallID = localStorage.getItem("CallNoteId");

  const header = Headers();
  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        HIVFormApi,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          ontarioHivForm: {
            // submitter: {
            //   name: "akif khan",
            //   address: "bapunagar",
            //   city_and_province: "ahmedabad",
            //   courier_code: "380024",
            //   postal_code: "380024",
            //   submitter_lab_no_number_if_applicable: "test",
            //   clinician_initial_surname_and_OHIP_CPSO_No: "test",
            //   telephone: "798654908675",
            //   fax: "579865467890",
            // },
            // patientInformation: {
            //   medical_record_no: "5467890876534",
            //   PHO_study_or_program_no_ifapplicable: "654678907654",
            // },
            // cc_Doctor_qualified_health_care_provider_information: {
            //   name: "test",
            //   telephone: "test",
            //   lab_clinic_name: "54980",
            //   fax: "569087654",
            //   CPSO_No: "546789654",
            //   address: "test",
            //   postal_code: "76867",
            // },
            country_of_birth: values.CountryofBirth,
            race_ethnicity_check_all_that_apply: {
              ...rowObject.Ethnicitymap,
            },
            risk_factors_check_all_tha_apply: {
              ...rowObject.RiskFactorsmap,
              Sex_with_a_person_who_was_known_to_be: {
                ...rowObject.Sexwithmap,
              },
            },
            specimen_details: {
              collection_date_of_specimen: values.Collectiondate,
              type_of_specimen: {
                ...rowObject.Typemap,
              },
              tests_requested: {
                ...rowObject.Testsmap,
              },
              comments: "test",
            },
            reason_for_test_check_all_that_apply: {
              ...rowObject.checkapplymap,
            },
            previous_test_information: {
              last_test_result: {
                ...rowObject.Lasttestmap,
              },
            },
          },
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const { values, handleBlur, touched, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  return (
    <>
      <section className="Cardiology-main-sec">
        <form onSubmit={handleSubmit} className="Cardiology-main-div">
          <div className="Reason-main-History-div">
            <div className="w-100">
              <div>
                <span className="Reason-Check-span ">PATIENT INFORMATION</span>
              </div>
              <div className="Country-main-input mt-3">
                <span className="chack-in-span">Country of Birth</span>
                <input
                  placeholder=""
                  type="text"
                  className="Birth-of-Birth-input-LMNP mt-2"
                  name="CountryofBirth"
                  value={values.CountryofBirth}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="Speciman-main-Details-div mt-4">
                <span className="ULTRASOUND-spn">Specimen Details </span>
              </div>
              <div className="Country-main-input mt-4">
                <span className="chack-in-span">
                  Collection date of speciman (yyy/mm/dd):
                </span>
                <input
                  placeholder=""
                  type="date"
                  max="9999-12-31"
                  className="Birth-of-Birth-input-LMNP mt-2"
                  name="Collectiondate"
                  value={values.Collectiondate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="specimen-Type-span-div mt-4">
                <span className="ULTRASOUND-spn">Type of specimen:</span>
              </div>
              {isType.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setisType((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div className="specimen-Type-span-div mt-4">
                <span className="ULTRASOUND-spn">Tests requested:</span>
              </div>
              {isTests.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setisTests((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div className="check-Type-span-div mt-5">
                <span className="ULTRASOUND-spn">
                  Reason for Test (check all that apply )
                </span>
              </div>
              <div className="checkboxparent mt-4">
                {ischeckapply.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className={`checkdivmain ${item.cardClass}`}>
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              setischeckapply((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="m-2"></div>
              </div>
            </div>

            <div className="w-100">
              <div className="Information-Type-span-div ">
                <span className="ULTRASOUND-spn">
                  Previous Test Information
                </span>
              </div>
              <div className="specimen-Type-span-div mt-4">
                <span className="ULTRASOUND-spn">Last test result:</span>
              </div>
              {isLasttest.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setisLasttest((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div className="Ethnicity-main-Details-div mt-4">
                <span className="ULTRASOUND-spn">
                  Race Ethnicity (check all that apply)
                </span>
              </div>
              {isEthnicity.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setisEthnicity((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
            </div>
            <div className="">
              <div className="Factors-Type-span-div">
                <span className="ULTRASOUND-spn">
                  Risk Factors (check all that apply)
                </span>
              </div>
              {isRiskFactors.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setisRiskFactors((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div className="specimen-Type-span-div mt-4">
                <span className="ULTRASOUND-spn">
                  Sex with a person who was known to be:
                </span>
              </div>
              <div className="mb-5">
                {isSexwith.map((item, index) => {
                  return (
                    <div className="checkdivmain " key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          setisSexwith((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
              </div>
              <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
                Submit
              </Button>
            </div>
          </div>
        </form>
      </section>
      {loading && <Loader />}
    </>
  );
};

export default HIVForm;
