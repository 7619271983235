export const Set_Session_Field = "Set_Session_Field";

export const setSessionField = (key, value) => {
  return {
    type: Set_Session_Field,
    payload: {
      key,
      value,
    },
  };
};
