import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useDropzone } from "react-dropzone";
import { FiPlusCircle } from "react-icons/fi";
import { FaRegFileAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import "./MyComponent.css";

const MyComponent = () => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [currentPdfFile, setCurrentPdfFile] = useState(null);

  useEffect(() => {
    if (pdfFiles.length > 0) {
      setCurrentPdfFile(pdfFiles[pdfFiles.length - 1]);
    }
  }, [pdfFiles]);

  const onDrop = (acceptedFiles) => {
    const selectedPdfFiles = acceptedFiles.filter(
      (file) => file.type === "application/pdf"
    );

    if (selectedPdfFiles.length > 0) {
      setPdfFiles([...pdfFiles, ...selectedPdfFiles]);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };
  const handleRemovePdf = (index) => {
    const updatedPdfFiles = [...pdfFiles];
    updatedPdfFiles.splice(index, 1);
    setPdfFiles(updatedPdfFiles);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf",
  });

  return (
    <div>
      <div className="mt-4">
        <input {...getInputProps()} />

        <div {...getRootProps()} className="FiPlusCircle-main-div">
          <FiPlusCircle className="FiPlusCircle-amin" />
        </div>
      </div>

      {currentPdfFile && (
        <div>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist/build/pdf.worker.min.js`}
          >
            {({ data, error, loading }) => (
              <div>
                {loading && <p>Loading...</p>}
                {error && <p>Error loading PDF: {error.message}</p>}
                {data && <Viewer fileUrl={currentPdfFile.preview} />}
              </div>
            )}
          </Worker>
        </div>
      )}

      {pdfFiles.length > 0 && (
        <div>
          <div className="FiPlusCircle-and-FaRegFileAlt">
            {pdfFiles.map((file, index) => (
              <>
                <div className="handleRemovePdf-main">
                  <div className="FiPlusCircle-main-div">
                    <FaRegFileAlt className="FaRegFileAlt-amin" />
                  </div>
                  <MdCancel
                    className="MdCancel-main"
                    onClick={() => handleRemovePdf(index)}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyComponent;
