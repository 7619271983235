import React, { useState } from "react";
import "./BookAnAppointment1.css";
import Calender from "../Calender/Calender";
import Virtual from "../../Assets/Images/virtual_appointment_ic@3x.png";
import Person from "../../Assets/Images/in_person_appointment_ic@3x.png";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
const BookAnAppointment1 = () => {
  const [isVirtualVisible, setVirtualVisible] = useState(true);
  const [isInPersonVisible, setInPersonVisible] = useState(false);

  const showVirtualAppointment = () => {
    setVirtualVisible(true);
    setInPersonVisible(false);
  };

  const showInPersonAppointment = () => {
    setVirtualVisible(false);
    setInPersonVisible(true);
  };
  const navigate = useNavigate();
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Book an appointment"} />
      <section className="BookAnAppointment1-main-section">
        <div className="BookAnAppointment1-main-div">
          <Calender />
          <div className="mt-4 appointment-main-div">
            <span>Only virtual appointment for the same day</span>
          </div>
          {isInPersonVisible && (
            <div
              className="Virtual-Appointment-main-div mt-4"
              id="inPersonAppointmentDiv"
            >
              <span className="Select-Appointment-type">
                Select Appointment Type
              </span>
              <div className="dono-ka-he-main-div mt-3">
                <div className="Virtual-and-Person-Appointment-di">
                  <img
                    className="Person-main-img-Virtual"
                    src={Virtual}
                    alt=""
                  />
                  <span className="Appointment-spacealitty">
                    Virtual Appointment
                  </span>
                </div>
                <div className="Virtual-and-Person-Appointment-di">
                  <img
                    className="Person-main-img-Virtual"
                    src={Person}
                    alt=""
                  />
                  <span className="Appointment-spacealitty">
                    In Person Appointment
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="Select-Doctor-Type-div mt-4">
            <span className="Select-Appointment-type">Select Doctor Type</span>
            <div className="radio-bttn-div-any mt-3">
              <input
                id="1"
                className="type-radio-in"
                type="radio"
                onClick={showVirtualAppointment}
                name="doctorType"
              />
              <label htmlFor="1" className="Available-any-sapn">
                Any Available Doctor
              </label>

              <div className="radio-bttn-div-any">
                <input
                  id="2"
                  className="type-radio-in"
                  type="radio"
                  name="doctorType"
                  onClick={showInPersonAppointment}
                />
                <label htmlFor="2" className="Available-any-sapn">
                  Choose Doctor
                </label>
              </div>
            </div>
          </div>
          <div className="Select-Appointment-time-div mt-5">
            <span className="Select-Appointment-type">
              {" "}
              Select Appointment Time
            </span>
            <input
              placeholder="Select Appointment Time"
              className="time-input-main-he"
              type="time"
            />
          </div>
          <div className="mt-5 w-100">
            <button className="Next-btn-Appointment"> Next</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookAnAppointment1;
