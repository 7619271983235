import React, { useState, useEffect, useContext } from "react";
import "./WaitingRoomss.css";
// import location from "../../Assets/Images/location_ic_sm@3x.png";
// import Health from "../../Assets/Images/HealthDataImg.avif";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import lyer from "../../Assets/Images/dark_layer@3x.png";
import Lock from "../../Assets/Images/lock_ic@3x.png";
import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import {
  StopTimeSet,
  StopTimeGet,
  WaitingRoomAPI,
  WaitingRoomssApi,
  doctorstartCallwaitingRoom,
} from "../../Constant/Apiconstant";
import CutVedioCallpopup from "../CutVedioCallpopup/CutVedioCallpopup";
import { GlobalContext } from "../../Context/GlabalContext";
import Loader, { SimpleLoader } from "../../Constant/Loader";
import axios from "../../Constant/ApiUrl";
import { store } from "../../Redux/store";
import { KEY_USER_DATA, Meetingdata } from "../../Redux/Appconstant";
import { formatISO } from "date-fns";

const WaitingRoomss = () => {
  const [Doctorroom, setDoctorroom] = useState();
  const {
    CutVedio,
    setCutVedio,
    setvideocallstartbydoctor,
    isCutcall,
    setisCutcall,
    setratingpopup,
  } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const { response, isloading, getdata } = Fetchdata(
    WaitingRoomssApi,
    {},
    "GET"
  );
  const maindata = store.getState().session[Meetingdata];
  const userdata = store.getState().session[KEY_USER_DATA];

  useEffect(() => {
    if (response?.data) {
      setDoctorroom(response.data);
    }
    if (isCutcall) {
      getdata();
      setisCutcall(false);
    }
  }, [response, isCutcall]);

  // console.log(response, "response");
  const navigate = useNavigate();

  const handleView = (itm) => {
    navigate("/CompleteAppointmentDetail", {
      state: {
        itm,
      },
    });
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const currentTime = new Date();
  const formattedTime = currentTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  // const formattedDate = formatISO(currentTime, {
  //   representation: "complete",
  // });

  const header = Headers();

  const StoTimeApi = async (time, id) => {
    // console.log(time, "time");
    try {
      const res = await axios.post(
        StopTimeSet,
        {
          appointmentID: id,
          videoStopTime: time,
        },
        header
      );
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const TimegetApi = async (id) => {
    try {
      const res = await axios.post(
        StopTimeGet,
        {
          appointmentID: id,
        },
        header
      );
      if (res.data.data === "" || res.data.data === null) {
        // setTimeRemaining(res.data.data);
        // console.log("null");
        StoTimeApi("15", id);
        // console.log(res.data.data);
      } else {
        // console.log("success");
        StoTimeApi(res.data.data, id);
        // setTimeRemaining(formattedDate);
      }
      // console.log(res);
      // console.warn("minute he ye", res);
      // setTimeRemaining(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStart = async (itm) => {
    setloading(true);
    setratingpopup(false);
    localStorage.setItem("Videoitm", JSON.stringify(itm));
    try {
      const res = await axios.post(
        doctorstartCallwaitingRoom,
        {
          waitingRoomId:
            itm?.waitingRoomAppointment?._id ||
            itm?.waitingRoomAppointment?._id,
          patientId: itm?.patient_id?._id
            ? itm?.patient_id?._id
            : itm?.patient_id,
          startDateCall: currentDate,
          startTimeCall: formattedTime,
        },
        header
      );
      setloading(false);
      if (
        res.status === 200 &&
        res.data.message === "Appointment Started Successfully"
      ) {
        setvideocallstartbydoctor(true);
        TimegetApi(itm._id);
        navigate("/meeting", {
          state: {
            itm,
          },
        });
        // WatingroomApi(itm);
      }
      // console.log(res, "caleeeee");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <>
      <Header onClick={() => navigate("/Home")} text={"Waiting room"} />
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <>
          <section className="FindMD-main-section">
            {response?.data ? (
              <div className="FindMD-main-div">
                {Doctorroom?.map((itm, index) => {
                  return (
                    <div key={index} className="FindMD-img-contain">
                      <div className="Health-lyer-dark-main-div">
                        <img
                          className="dark-docter"
                          src={itm?.patient_id?.basicInfo?.image}
                          alt=""
                        />
                        {itm.unlock ? (
                          ""
                        ) : (
                          <div>
                            <img
                              className="dark-lyer-docter"
                              src={lyer}
                              alt=""
                            />
                            <img
                              className="dark-Lockyer-docter"
                              src={Lock}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Height-awy-main-div">
                          <div className="Dorothy-extra-div">
                            <span className="Dorothy-sapn">
                              {itm?.patient_id?.basicInfo?.fullName}
                            </span>
                          </div>
                          <div className="Height-w-bmi-div mt-2">
                            <div className="Height-Weight-main-div">
                              <span>Height</span>
                              <span>
                                {itm?.patient_id?.healthData?.height?.value}{" "}
                                {itm?.patient_id?.healthData?.height?.unit}
                              </span>
                            </div>
                            <div className="Height-Weight-main-div">
                              <span>Weight</span>
                              {itm?.patient_id?.healthData?.weight?.value}{" "}
                              {itm?.patient_id?.healthData?.weight?.unit}
                            </div>
                            <div className="Height-Weight-main-div">
                              <span>BMI</span>
                              <span>
                                {" "}
                                {parseInt(
                                  itm?.patient_id?.healthData?.BMI
                                ).toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="mt-3  dono-he-buton-div">
                            <button
                              onClick={() => handleView(itm)}
                              className="Detail-main-butn"
                            >
                              View Detail
                            </button>
                            {itm.unlock ? (
                              <button
                                onClick={() => handleStart(itm)}
                                className="Start-button-main"
                              >
                                Start
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="FindMD-img-contain">
            <div className="Health-lyer-dark-main-div">
              <img className="dark-docter" src={Health} alt="" />
              <div>
                <img className="dark-lyer-docter" src={lyer} alt="" />
                <img className="dark-Lockyer-docter" src={Lock} alt="" />
              </div>
            </div>
            <div className="Dorothy-Lucas-sapn-div">
              <div className="Height-awy-main-div">
                <div className="Dorothy-extra-div">
                  <span className="Dorothy-sapn">Todd Duncan</span>
                </div>
                <div className="Height-w-bmi-div mt-2">
                  <div className="Height-Weight-main-div">
                    <span>Height</span>
                    <span className="hagghit-span-and">5.2</span>
                  </div>
                  <div className="Height-Weight-main-div">
                    <span>Weight</span>
                    <span className="hagghit-span-and">53 kg</span>
                  </div>
                  <div className="Height-Weight-main-div">
                    <span>BMI</span>
                    <span className="hagghit-span-and">30</span>
                  </div>
                </div>
              </div>
              <div className="mt-3 dono-he-buton-div">
                <button className="Detail-blur-main-butn">View Detail</button>
                <button className="Start-blur-button-main">Start</button>
              </div>
            </div>
          </div> */}
              </div>
            ) : (
              <h1 className="text-center pt-4">{response?.message}</h1>
            )}
          </section>
        </>
      )}
      {CutVedio && (
        <CutVedioCallpopup
          CutVedio={CutVedio}
          setCutVedio={setCutVedio}
          isSimpleCall={maindata?.waitingRoomAppointment ? false : true}
          waitingRoomId={
            userdata?.basicInfo?.role === "physician"
              ? maindata?.waitingRoomAppointment?._id
              : maindata?.waitingRoomId
          }
          appointmentID={maindata?._id}
          text={"Are you sure your appoinment is completed ?"}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default WaitingRoomss;
