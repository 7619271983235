import React from "react";
import "./HealthData.css";
// import location from "../../Assets/Images/location_ic_sm@3x.png";
// import Health from "../../Assets/Images/HealthDataImg.avif";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { HEALTHCARDDATAAPI } from "../../Constant/Apiconstant";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import { SimpleLoader } from "../../Constant/Loader";

const HealthData = () => {
  const Mydata = store.getState().session[KEY_USER_DATA];
  const { response, isloading } = Fetchdata(HEALTHCARDDATAAPI, {}, "GET");
  // console.log(response);
  const navigate = useNavigate();

  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Health data"} />
      <section className="FindMD-main-section">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <>
            {!response?.data ? (
              <h1 className="text-center pt-4">{response?.message}</h1>
            ) : (
              <div className="FindMD-main-div">
                {response?.data?.map((itm, index) => {
                  return (
                    <div className="FindMD-img-contain" key={index}>
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={itm.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Height-awy-main-div">
                          <div className="Dorothy-extra-div">
                            <span className="Dorothy-sapn">
                              {itm.PatientName}
                            </span>
                          </div>
                          <div className="Height-w-bmi-div mt-4">
                            <div className="Height-Weight-main-div">
                              <span>Height</span>
                              <span className="hagghit-span-and">
                                {Mydata.healthData.height.value}"
                              </span>
                            </div>
                            <div className="Height-Weight-main-div">
                              <span>Weight</span>
                              <span className="hagghit-span-and">
                                {Mydata.healthData.weight.value}{" "}
                                {Mydata.healthData.weight.unit}
                              </span>
                            </div>
                            <div className="Height-Weight-main-div">
                              <span>BMI</span>
                              <span className="hagghit-span-and">
                                {Mydata.healthData.BMI}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default HealthData;
