import React, { useRef, useState, useEffect, useContext } from "react";
import { AppoinmentCalenderApi } from "../../Constant/Apiconstant";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-datepicker/dist/react-datepicker.css";
import Fetchdata from "../../Constant/Fetchdata";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./AppoinmentCalender.css";
import moment from "moment";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "moment-timezone";

// import SwiperCore, {Navigation, Thumbs} from "swiper";

// SwiperCore.use([Navigation, Thumbs]);

function generateMonthDates(year, month) {
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const dates = [];
  for (
    let date = new Date(firstDay);
    date <= lastDay;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(new Date(date));
  }
  return dates;
}

const AppoinmentCalender = () => {
  const [image, setimage] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  // console.log(moment(selectedDate).format("DD-MM-YYYY"), "selectedDate");
  const [endDate, setEndDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState(image[0]);
  const [current, setcurrent] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const navigate = useNavigate();
  const route = useLocation();
  const maindata = route?.state.itm;
  // console.log(maindata, "hdhh");
  const totalSlides = 15;
  const btnref = useRef(null);

  if (startDate === null || startDate === undefined) {
    const currentDate = new Date();
    setStartDate(currentDate);
  }
  const monthDates = generateMonthDates(
    currentMonth.getFullYear(),
    currentMonth.getMonth()
  );
  // console.log(selectedDates);
  useEffect(() => {
    const currentDate = new Date();
    setStartDate(currentDate); // Set the startDate to the current date
  }, []);

  const convertToEST = (date) => {
    return moment(date).tz("America/New_York").format("DD-MM-YYYY"); // Convert to EST
  };
  const convertedDates = selectedDates.map((date) => {
    let dd = new Date(date);
    let my = moment().set({
      date: dd.getDate(),
      month: dd.getMonth(),
      year: dd.getFullYear(),
    });
    // console.log("Mydate", my.format("DD-MM-YYYY"));
    return moment(my).tz("America/New_York").format("DD-MM-YYYY");
  });

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1
    );
    setCurrentMonth(nextMonth);
    btnref.current.swiper.slideNext();
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1
    );
    setCurrentMonth(prevMonth);
    btnref.current.swiper.slidePrev();
  };

  const handleNextSlide = () => {
    const nextSlide = currentSlide + 1;

    if (nextSlide < totalSlides) {
      setCurrentSlide(nextSlide);
    } else {
      setIsDisabled(true);
    }
  };
  const length = image.length;
  const nextslide = () => {
    btnref.current.swiper.slideNext();
    handleNextSlide();
    setcurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevslide = () => {
    btnref.current.swiper.slidePrev();
    setcurrent(current === 0 ? length - 1 : current - 1);
  };
  const handleChange = (e) => {
    setIsOpen(true);
    setStartDate(e);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleDateClick = (date) => {
    const isPastDate = date < new Date();
    const isSelected =
      selectedDate && selectedDate.getTime() === date.getTime();

    if (!isPastDate) {
      setSelectedDate(isSelected ? null : date);
      // console.log(
      //   "Selected Date:",
      //   isSelected ? null : moment(date).format("DD-MM-YYYY")
      // );
    }
  };
  // Get the user's time zone offset in minutes
  var timeZoneOffset = new Date().getTimezoneOffset();

  // Convert the offset to hours and minutes
  var hours = Math.floor(Math.abs(timeZoneOffset) / 60);
  var minutes = Math.abs(timeZoneOffset) % 60;

  // Determine if the offset is positive or negative
  var offsetSign = timeZoneOffset > 0 ? "-" : "+";

  // Format the time zone string (e.g., "+05:30" or "-03:00")
  var timeZoneString =
    offsetSign + ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);

  // console.log("User Time Zone: GMT" + timeZoneString);

  const currentDate = new Date();
  const currentDateIndex = monthDates.findIndex(
    (date) =>
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate()
  );
  // console.log(maindata._id, "ooooooooo");
  const params = {
    doctorID: maindata._id,
    date: moment(selectedDate).format("DD-MM-YYYY").toString(),
    userTimeZone: maindata.basicInfo.userTimeZone,
  };
  const { response } = Fetchdata(AppoinmentCalenderApi, params, "POST");
  console.log(response, "iiiiiiiiiii");
  return (
    <>
      <div className="Calender_main_divv">
        <div className="Calender_Month_secondary_divv">
          <div className="July_main_divv">
            <div className="July_main_tagg_divv">
              <button className="Arrow_btnn_left" onClick={handlePrevMonth}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_15_2222)">
                    <path
                      d="M10.8283 12L15.7783 7.04999L14.3643 5.63599L8.00032 12L14.3643 18.364L15.7783 16.95L10.8283 12Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_15_2222">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="matrix(-1 0 0 1 24 0)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <div className="MAin_claender_divv">
                <span className="July_main_tagg_text_divv">
                  {currentMonth.toLocaleString("default", {
                    month: "long",
                  })}{" "}
                  {currentMonth.getFullYear()}
                </span>
              </div>
              <button className="Arrow_btnn_left" onClick={handleNextMonth}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_15_2219)">
                    <path
                      d="M13.1717 12L8.22168 7.04999L9.63568 5.63599L15.9997 12L9.63568 18.364L8.22168 16.95L13.1717 12Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_15_2219">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div className="view_detail_main_divv">
              <div className="View_imgg_Full_month" onClick={handleClick}>
                <div className="Feed_imgg_divv"></div>
                <div
                  className="Feed_spann_divv"
                  onClick={() => navigate("/Viewmonthlymenu")}
                >
                  <span className="Feed_span_text_divv"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Swiper_Calender_Month_secondary_divv">
          <div className="sliderdiv">
            <button
              className="left-swiper-btn mt-4"
              // onClick={() => btnref.current.swiper.slidePrev()}
              onClick={prevslide}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_15_2226)">
                  <path
                    d="M11.8282 12L14.6572 9.17202L13.2432 7.75702L9.00023 12L13.2432 16.243L14.6572 14.828L11.8282 12Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15_2226">
                    <rect
                      width="24"
                      height="24"
                      fill="black"
                      transform="matrix(-1 0 0 1 24 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={12}
              centeredSlides={true} // Set centeredSlides to true
              initialSlide={currentDateIndex !== -1 ? currentDateIndex : 0}
              ref={btnref}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              breakpoints={{
                // when window width is >= 428px
                1280: {
                  slidesPerView: 12,
                },
                540: {
                  slidesPerView: 6,
                },
                360: {
                  slidesPerView: 4,
                },
              }}
            >
              <div className="overflowdiv">
                {monthDates.map((date, index) => {
                  const isPastDate = date < new Date();
                  const dateClasses = isPastDate ? "greyed-out" : "";
                  const isSelected =
                    selectedDate && selectedDate.getTime() === date.getTime();

                  return (
                    <SwiperSlide key={`${date.getTime()}-${index}`}>
                      <div
                        className={`Twent_july_spann_divv_swiper mt-4
          ${dateClasses}
          ${isSelected ? "selected-date" : ""}`}
                        onClick={() => handleDateClick(date)}
                      >
                        <div className="twenty_spann_divv">
                          <span className="twenty_spann_span">
                            {date.getDate()}
                          </span>
                        </div>
                        <div className="JULY_spann_divv">
                          <span className="July_spann_span">
                            {date.toLocaleString("default", { month: "short" })}
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>

            <button
              className="left-swiper-btn mt-4"
              // onClick={() => btnref.current.swiper.slideNext()}
              onClick={nextslide}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_15_2232)">
                  <path
                    d="M12.1718 12L9.34277 9.17202L10.7568 7.75702L14.9998 12L10.7568 16.243L9.34277 14.828L12.1718 12Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15_2232">
                    <rect width="24" height="24" fill="black" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
        {/* <div className="Order_meal_next_btnn mt-5">
          <div className="Check_button_next_order" onClick={() => filterdate()}>
            Check Orders
          </div>
        </div> */}
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#fd3e6c",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default AppoinmentCalender;
