import React from "react";
import Header from "../../Components/Header/Header";

import { useNavigate, useLocation } from "react-router";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
// import Buttton from "../../Components/Button/Buttton";
// import { Colors } from "../../Constant/Colors";
import { Icons } from "../../Constant/Icons";

const UpdetHealthDataDetail = () => {
  const location = useLocation();
  const UpdetData = location.state?.item;
  // console.log(UpdetData, "itm");
  const Mydata = store.getState().session[KEY_USER_DATA];
  // console.log(Mydata);
  const navigate = useNavigate();
  return (
    <>
      <Header
        style={{ gap: "1rem" }}
        text={"Health data detail"}
        extrafield={<Profile UpdetData={UpdetData} />}
        onClick={() => navigate(-1)}
        secondtext={Icons.editicon}
        secondClick={() =>
          navigate("/UpdetHealthDataDetailInformation", {
            state: {
              UpdetData,
            },
          })
        }
      />

      <section className="HealthDataDetail-main-section">
        <div className="HealthDataDetail-main-div py-3">
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Relation</span>
            <span>{UpdetData?.relationship}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Medical Condition</span>
            <span>{UpdetData?.healthData?.medicalCondition}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">
              Past Medical Condition
            </span>
            <span>{UpdetData?.healthData?.pastMedicalCondition || "N/A"}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Allergies</span>
            <span>{UpdetData?.healthData?.allergies}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Medication</span>
            <span>{UpdetData?.healthData?.marijuana}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Smoking</span>
            <span>{UpdetData?.healthData?.smoking}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Alcohol</span>
            <span>{UpdetData?.healthData?.alcohol}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Marijuana</span>
            <span>{UpdetData?.healthData?.marijuana}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
        </div>
      </section>
    </>
  );
};

export default UpdetHealthDataDetail;

const Profile = ({ UpdetData }) => {
  // console.log(UpdetData, "UpdetDatapr");
  return (
    <div className="d-flex w-100 align-items-start justify-content-start gap-4">
      <div style={{ width: "80px", height: "80px" }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
          src={UpdetData?.basicInfo?.image}
          alt=""
        />
      </div>
      <div className="profiledetail">
        <span>{UpdetData?.basicInfo?.fullName}</span>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>Height</span>
            <h5>{UpdetData?.healthData?.height?.value}</h5>
          </div>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>Weight</span>
            <h5>
              {UpdetData?.healthData?.weight?.value}{" "}
              {UpdetData?.healthData?.weight?.unit}
            </h5>
          </div>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>BMI</span>
            <h5>{UpdetData?.healthData?.BMI}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
