import React, { useState, useEffect, useContext, Fragment } from "react";
import "./ClinicalNote.css";
import Header from "../../Components/Header/Header";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { Eventoutcome } from "../../Constant/DummyData";
import axios from "../../Constant/ApiUrl";
import { ClinicalNoteApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Loader from "../../Constant/Loader";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import DataSuccessfully from "../DataSuccessfully/DataSuccessfully";
import { GlobalContext } from "../../Context/GlabalContext";
import { toast } from "react-toastify";
const initialValues = {
  SubjectiveNote: "",
  ObjectiveNote: "",
  AssessmentNote: "",
  PlanNote: "",
  personin: false,
  Virtualin: false,
  Referredin: false,
  Disconnectedin: false,
  Endedin: false,
  Appoinmentin: false,
};
const ClinicalNote = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const itm = location?.state?.id;
  // console.log(itm?._id, "route id");

  const [isEventoutcome, setisEventoutcome] = useState(Eventoutcome);
  const [loading, setloading] = useState(false);

  const [Popup, setPopup] = useState(false);
  const [Error, setError] = useState(false);

  const [selectedValue, setSelectedValue] = useState("");
  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [checkboxdata, setcheckboxdata] = useState([]);
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };

  const [rowObject, setRowObject] = useState({
    Eventoutcome: {},
  });
  // console.log(rowObject, "rowObject");
  useEffect(() => {
    const updatedRowObject = { ...rowObject };
    isEventoutcome.forEach((item) => {
      updatedRowObject.Eventoutcome[item.backEndName] = item.isSelected;
    });

    setRowObject(updatedRowObject);
  }, [isEventoutcome]);

  const handleCheckboxChange = (index) => {
    setisEventoutcome((prevValues) => {
      // Count the number of selected checkboxes
      const selectedCount = prevValues.filter((item) => item.isSelected).length;

      // Toggle the selected state only if the user has selected less than 2 checkboxes or if the checkbox being clicked is already selected
      if (selectedCount < 2 || prevValues[index].isSelected) {
        return prevValues.map((item, i) => {
          if (i === index) {
            return { ...item, isSelected: !item.isSelected };
          } else {
            return item;
          }
        });
      } else {
        setError(true);
        return prevValues;
      }
    });
  };
  const CallID = localStorage.getItem("CallNoteId");
  const header = Headers();
  const onSubmit = async () => {
    setloading(true);
    // console.log("ccccc");
    try {
      // console.log("run");
      const res = await axios.post(
        ClinicalNoteApi,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          clinicalNote: {
            completeAppointment: values?.Appoinmentin,
            appoinmentType: selectedValue,
            subjective: values?.SubjectiveNote,
            objective: values?.ObjectiveNote,
            assessment: values?.AssessmentNote,
            plan: values?.PlanNote,
            eventOutcome: {
              ...rowObject?.Eventoutcome,
            },
          },
        },

        header
      );
      if (res.status === 200 && res.data.status === "success") {
        // setPopup(true);
        toast.success(res.data.message);
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }
      // console.log(res, "jvcccfh");
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const { values, handleBlur, touched, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Clinical note"}
        style={{ color: "white" }}
      />
      <section>
        <form onSubmit={handleSubmit}>
          <div className="Profile-extra-div">
            <div className="Profile-main-Your">
              <span className="Order-history-span">
                Questionnaire Submitted
              </span>
              <span className="Upload-profile">
                Choose reason or consultation. Head & Neck
              </span>
              <span className="mt-4 Upload-profile">
                Is your problem companied by new and unfamiliar symptoms such:
                Headache, Dizziness, Ear arch
              </span>
            </div>
          </div>
          <div className="Dizziness-unfamiliar-line mt-3"></div>
          <div className="second-div ">
            <div className="Health-input-div-main">
              <div className="text-span-div">
                <div className="span-Subjective-div">
                  <span className="Doctor-span-Skip">Subjective</span>
                </div>
                <textarea
                  id="w3review"
                  name="SubjectiveNote"
                  rows="4"
                  className="Subjective-input-2   mt-2"
                  cols="50"
                  placeholder="Enter Note"
                  value={values.SubjectiveNote}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="text-span-div">
                <div className="span-Subjective-div">
                  <span className="Doctor-span-Skip">Objective</span>
                </div>
                <textarea
                  id="w3review"
                  name="ObjectiveNote"
                  rows="4"
                  className="Subjective-input-2   mt-2"
                  cols="50"
                  placeholder="Enter Note"
                  value={values.ObjectiveNote}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="text-span-div">
                <div className="span-Subjective-div">
                  <span className="Doctor-span-Skip">Assessment</span>
                </div>
                <textarea
                  id="w3review"
                  name="AssessmentNote"
                  rows="4"
                  className="Subjective-input-2   mt-2"
                  cols="50"
                  placeholder="Enter Note"
                  value={values.AssessmentNote}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="text-span-div">
                <div className="span-Subjective-div">
                  <span className="Doctor-span-Skip">Plan</span>
                </div>
                <textarea
                  id="w3review"
                  name="PlanNote"
                  rows="4"
                  className="Subjective-input-2   mt-2"
                  cols="50"
                  placeholder="Enter Note"
                  value={values.PlanNote}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="Appoinment-Type-main">
                <span className="Doctor-span-Skip-Type w-100">
                  Appoinment Type
                </span>
                <select
                  className="Video-and-Audio-class mt-3"
                  name="cars"
                  id="cars"
                  onChange={handleSelectChange}
                  value={selectedValue}
                >
                  <option value="" selected disabled>
                    Select Type
                  </option>
                  <option value="Video">Video</option>
                  <option value="Audio">Audio</option>
                  <option value="inPerson">In Person</option>
                </select>
              </div>
              <div className="outcome-Event-div">
                <div className="span-Subjective-div">
                  <span className="Doctor-span-Skip">Event outcome</span>
                </div>

                {isEventoutcome.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <div className={`outcome-main-div ${item.cardClass}`}>
                        <span className="Organization-sapn">{item.txt}</span>
                        <input
                          className="main-input-chek"
                          type="checkbox"
                          id={`checkbox-${index}`}
                          name={`checkbox-${index}`}
                          onClick={() => handleCheckboxChange(index)}
                          checked={item.isSelected}
                        />
                      </div>

                      <div className="Dizziness-unfamiliar-line mt-3"></div>
                    </Fragment>
                  );
                })}
              </div>
              <div className="outcome-main-div-Appoinmentin">
                <input
                  className="main-input-chek"
                  type="checkbox"
                  name="Appoinmentin"
                  value={values.Appoinmentin}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="Organization-sapn">Appoinment Complete </span>
              </div>
              <div className="mt-4 mb-5">
                <button type="submit" className="Submit-Main-btn-Bim">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>

        {Popup && (
          <DataSuccessfully
            Popup={Popup}
            setPopup={setPopup}
            handleCloseeee={() => setPopup(false)}
            text={"Data Successfully Saved"}
          />
        )}
        {Error && (
          <DataSuccessfully
            Error={Error}
            setError={setError}
            handleCloseeee={() => setError(false)}
            text={"Yow can select maximum two event outcome."}
          />
        )}
      </section>

      {loading && <Loader />}
    </>
  );
};

export default ClinicalNote;
