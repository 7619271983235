import React from "react";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";

const OtherDiseasesPsychiatry = () => {
  const navigate = useNavigate();
  const data = [
    {
      Organization: "Depression",
    },
    {
      Organization: "Anxiety",
    },
    {
      Organization: "Sleep issues",
    },
    {
      Organization: "ADHD",
    },
    {
      Organization: "Bipolar disorder",
    },
    {
      Organization: "Anorexia",
    },
    {
      Organization: "Bulimia",
    },
  ];
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Book an appointment"} />
      <div className="expalin-div-sec">
        <div
          className="cardcontent-Torso-main-div"
          style={{ borderRadius: "10px" }}
        >
          <div className="closebtn">
            <span className="Find-main-span">
              Now choose the reason for your consultation?
            </span>

            {data.map((Item, Index) => {
              return (
                <div className="Organization-sapn-div">
                  <div className="Dizziness-main-div-itm">
                    <span className="Dizziness-sapn"> {Item.Organization}</span>
                    <input className="main-input-chek" type="checkbox" />
                  </div>
                  <div className="line-div-Dizziness"></div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <button className="Done-mbtn-with-pop mt-3 mb-3">Continue</button>
        </div>
      </div>
    </>
  );
};

export default OtherDiseasesPsychiatry;
