import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import card from "../../Assets/Images/card_bg@3x.png";
import "./AddCard.css";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import axios from "../../Constant/ApiUrl";
import { SAVECARDAPI } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";

const Addcard = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  // const initialValues = {
  //   Cardholder: "Name",
  //   CardNumber: "",
  //   CVV: "",
  //   Expirydate: "",
  // };

  // const { handleChange, handleBlur, values } = useFormik({
  //   initialValues: initialValues,
  // });
  const header = Headers();
  const stripe = useStripe();
  const element = useElements();

  const Addcard = async (e) => {
    e.preventDefault();
    const card = element.getElement(CardElement);
    // console.log(card);
    const result = await stripe.createToken(card);
    // console.log(result);
    if (result.error) {
      toast.error(result.error);
    } else {
      setloading(true);
      try {
        const res = await axios.post(
          SAVECARDAPI,
          {
            cardToken: result.token.id,
          },
          header
        );
        // console.log(res);
        setloading(false);
        if (res.data.status === "failed") {
          toast.error(res.data.message);
        } else if (res.status === 200 && res.data.status === "success") {
          toast.success(res.data.message);
          navigate(-1);
        }
      } catch (error) {
        console.log(error);
        setloading(false);
      }
    }
  };

  // console.log(values.CardNumber);
  return (
    <>
      <Header text={"Add new card"} onClick={() => navigate(-1)} />
      <form onSubmit={Addcard} className="addcardcontainer">
        {/* <div className="carddetail">
          <img src={card} alt="" />
          <div className="effects">
            <span></span>
            <span></span>
          </div>
          <div className="detailss">
            <h3>{values.Cardholder}</h3>
            <h3>{values.CardNumber}</h3>
            <div>
              <div>
                <span>Expiry date</span>
                <h5 style={{ color: "#ffffff" }}>{values.Expirydate}</h5>
              </div>
              <div>
                <span>CVV</span>
                <span style={{ borderBottom: "1px solid grey" }}>
                  {values.CVV}
                </span>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="fielddivcard">
          <span>Card Holder Name</span>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.Cardholder}
            name={"Cardholder"}
          />
        </div> */}

        <div className="stripelement">
          {/* <div className="Name-MAIN-CARD">
            <span> Name on Card</span>
            <input
              className="main-Name-on-cardin"
              type="text"
              placeholder="Name on Card"
            />
          </div> */}
          <span>Card number</span>
          {/* <Input
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.CardNumber}
            name={"CardNumber"}
            type={"number"}
          /> */}
          <CardElement options={{ hidePostalCode: true }} />
        </div>
        {/* <div className="d-flex align-items-center flex-column w-100">
          <div className="fielddivcard">
            <span>Expiry Date</span>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Expirydate}
              name={"Expirydate"}
              type={"month"}
            />
          </div>
          <div className="fielddivcard">
            <span>CVV</span>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.CVV}
              name={"CVV"}
              type={"number"}
            />
          </div>
        </div> */}
        <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
          Add
        </Button>
      </form>
      {loading && <Loader />}
    </>
  );
};

export default Addcard;
