import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Input from "../../Components/Input/Input";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import axios from "../../Constant/ApiUrl";
import { CMSCONTACTUSAPI } from "../../Constant/Apiconstant";
import Loader from "../../Constant/Loader";
import { toast } from "react-toastify";
import { ContactUsSchemas } from "../../Schemas";

const ContactUs = () => {
  const navigate = useNavigate();
  const Mydata = store.getState().session[KEY_USER_DATA];
  const [loading, setloading] = useState(false);

  const route = useLocation();
  const maindata = route?.state?.Okid;
  // console.log(Mydata?.basicInfo.role, "Okid");
  // console.log(maindata);

  const initialValues = {
    user_id: "",
    userType: "",
    title: "",
    description: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    setloading(true);
    try {
      const res = await axios.post(CMSCONTACTUSAPI, {
        user_id: Mydata?._id,
        userType: Mydata?.basicInfo.role == "physician" ? "doctor" : "patient",
        title: values.title,
        description: values.description,
      });
      // console.log(res, "maindata");
      if (res.data.status == "success" && res.status == 200) {
        resetForm();
        toast.success(res.data.message);
        if (maindata === "12") {
          navigate("/Doctorschedule");
        } else if (Mydata?.basicInfo.role === "patient") {
          navigate("/home-page");
        } else {
          navigate("/Home");
        }
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    onSubmit,
    initialValues: initialValues,
    validationSchema: ContactUsSchemas,
  });
  // console.log(values.title, values.description, "dono");
  return (
    <>
      <Header text={"Contact us"} onClick={() => navigate(-1)} />
      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column gap-3 align-items-center w-100 p-3"
      >
        <div className="fieldvalue w-100">
          <span>Title</span>
          <Input
            onChange={handleChange}
            name={"title"}
            value={values.title}
            style={{ width: "100%" }}
            placeholder={"Title"}
          />
          {errors.title && touched.title ? (
            <p className="mastu-to-eroor">{errors.title}</p>
          ) : null}
        </div>
        <div className="fieldvalue w-100">
          <span>Description</span>
          <Input
            onChange={handleChange}
            name={"description"}
            value={values.description}
            style={{ width: "100%" }}
            placeholder={"Write here..."}
          />
          {errors.description && touched.description ? (
            <p className="mastu-to-eroor">{errors.description}</p>
          ) : null}
        </div>
        <Buttton type={"submit"} style={{ backgroundColor: Colors.blue }}>
          Submit
        </Buttton>
      </form>
      {loading && <Loader />}
    </>
  );
};

export default ContactUs;
