import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import {
  SPECIMAN,
  PATIENTSETTING,
  Reason,
  Clinical,
  Hepatitis,
  HepatitisTo,
} from "../../Constant/DummyData";
import "./GeneralPublicHealthForm.css";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../Constant/ApiUrl";
import {
  GeneralPublicHealthFormApi,
  SendtolebPostApi,
} from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import SendtoLebModel from "../SendtoLebModel/SendtoLebModel";

const initialValues = {
  DateCollected: "",
  DateOnset: "",
  SendtoPatient: false,
  SendtoLab: false,
};

const GeneralPublicHealthForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const itm = location?.state?.id;
  // console.log(itm?._id, "route id");
  const [IsotherSPECIMAN, setIsotherSPECIMAN] = useState(false);
  const [loading, setloading] = useState(false);
  const [IsSPECIMAN, setIsSPECIMAN] = useState(SPECIMAN);
  const [IsPATIENTSETTING, setIsPATIENTSETTING] = useState(PATIENTSETTING);
  const [IsReason, setIsReason] = useState(Reason);
  const [IsClinical, setIsClinical] = useState(Clinical);
  const [IsHepatitisTo, setIsHepatitisTo] = useState(HepatitisTo);
  const [lgShow, setLgShow] = useState(false);
  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  // console.log(EmailGo, "EmailGo");
  const [selectedOption, setSelectedOption] = useState({
    index: null,
    immune_status: false,
    acute_infection: false,
    chronic_infection: false,
  });

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  const CallID = localStorage.getItem("CallNoteId");

  const header = Headers();

  const LebtextApi = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SendtolebPostApi,
        {
          appointmentID: itm?._id || savedRatingId || CallID,
          email: EmailGo,
          formKry: "ontarioGeneralPublicHealthForm",
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const onSubmit = async () => {
    setloading(true);
    try {
      // console.log("rrr");
      const res = await axios.post(
        GeneralPublicHealthFormApi,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          ontarioGeneralPublicHealthForm: {
            // submitter: {
            //   name: "test",
            //   address: "test",
            //   city_and_province: "test`",
            //   courier_code: "test",
            //   postal_code: "test",
            // },
            // patientInformation: {
            //   medical_record_no: "567896732",
            //   submitter_lab_no: "65789i",
            //   Public_Health_Unit_Outbreak_No: "5r6tyui",
            // },
            // clinician_initial_surname_and_OHIP_CPSO_No: {
            //   telephone: "345465768798765434",
            //   fax: "45678978656",
            // },
            // cc_Doctor_qualified_health_care_provider_information: {
            //   name: "test",
            //   telephone: "567890756",
            //   lab_clinic_name: "test",
            //   fax: "56789076",
            //   CPSO_No: "8675789",
            //   address: "test",
            //   postal_code: "test",
            // },
            // public_health_investigator_information: {
            //   name: "test",
            //   health_unit: "567890876",
            //   telephone: "8765467890",
            //   fax: "o78657890",
            // },
            tests_requested_please_see_descriptions_on_reverse: {
              // enter_test_description_below: "test",
              hepatitis_serology: {
                immune_status: selectedOption.immune_status,
                acute_infection: selectedOption.acute_infection,
                chronic_infection: selectedOption.chronic_infection,
                ...rowObject.HepatitisTomap,
              },
            },
            specimen_type_and_site: {
              ...rowObject.SPECIMAmap,
              patient_setting: {
                ...rowObject.PATIENTSETTINGmap,
              },
            },
            Reason_for_Test: {
              ...rowObject.Reasonmap,
              date_collected: values.DateCollected,
              onset_collected: values.DateOnset,

              clinical_information: {
                ...rowObject.Clinicalmap,
              },
            },
            sendPatient: values.SendtoPatient,
            sendLaboratory: values.sendLaboratory,
          },
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        LebtextApi();
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const [checkboxdata, setcheckboxdata] = useState([]);
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };

  const [rowObject, setRowObject] = useState({
    SPECIMAmap: {},
    PATIENTSETTINGmap: {},
    Reasonmap: {},
    Clinicalmap: {},
    HepatitisTomap: {},
  });
  // console.log(rowObject, "rowObject");
  useEffect(() => {
    const updatedRowObject = { ...rowObject };
    IsSPECIMAN.forEach((item) => {
      updatedRowObject.SPECIMAmap[item.backEndName] = item.isSelected;
    });
    IsPATIENTSETTING.forEach((item) => {
      updatedRowObject.PATIENTSETTINGmap[item.backEndName] = item.isSelected;
    });
    IsReason.forEach((item) => {
      updatedRowObject.Reasonmap[item.backEndName] = item.isSelected;
    });
    IsClinical.forEach((item) => {
      updatedRowObject.Clinicalmap[item.backEndName] = item.isSelected;
    });

    IsHepatitisTo.forEach((item) => {
      updatedRowObject.HepatitisTomap[item.backEndName] = item.isSelected;
    });

    setRowObject(updatedRowObject);
  }, [IsSPECIMAN, IsPATIENTSETTING, IsReason, IsClinical, IsHepatitisTo]);

  const handleCheckboxChange = (item, index) => {
    setSelectedOption({
      index: index,
      immune_status: index == 0 ? true : false,
      acute_infection: index == 1 ? true : false,
      chronic_infection: index == 2 ? true : false,
    });
  };

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const hendelElectro = () => {
    setLgShow(false);
  };
  // console.log(values.SendtoPatient, "alues.SendtoPatient");
  return (
    <>
      <section className="Cardiology-main-sec">
        <form onSubmit={handleSubmit} className="Cardiology-main-div">
          <div className="Reason-main-History-div">
            <div className="w-100">
              <div className="Speciman-main-span-div">
                <span className="ULTRASOUND-spn">Specimen Type and Site</span>
              </div>
              <div>
                {IsSPECIMAN.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          if (item.id === 258) {
                            setIsotherSPECIMAN(!IsotherSPECIMAN);
                          }
                          setIsSPECIMAN((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
                <div>
                  {IsotherSPECIMAN ? (
                    <textarea
                      id="w3review"
                      name="message2"
                      rows="3"
                      className="Other-input-2-answered   mt-2"
                      cols="50"
                      placeholder="Write here"
                    ></textarea>
                  ) : null}
                </div>
                <div className="m-2"></div>
              </div>
              <div className="Setting-main-span-div mt-3">
                <span className="ULTRASOUND-spn">Patient Setting</span>
              </div>
              {IsPATIENTSETTING.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setIsPATIENTSETTING((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
            </div>
            <div className="w-100">
              <div className="Reason-main-Test-div ">
                <span className="ULTRASOUND-spn">Reason for Test</span>
              </div>
              <div>
                {IsReason.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          setIsReason((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}

                <div className="m-2"></div>
              </div>

              <div className="mt-3">
                <span className="hors-taventh">Date Collected</span>
                <input
                  placeholder="13 May 1995"
                  className="Date-of-Birth-input-LMNP mt-2"
                  type="date"
                  max="9999-12-31"
                  name="DateCollected"
                  value={values.DateCollected}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>

              <div className="mt-3">
                <span className="hors-taventh">Onset Date</span>
                <input
                  placeholder="13 May 1995"
                  className="Date-of-Birth-input-LMNP mt-2"
                  type="date"
                  max="9999-12-31"
                  name="DateOnset"
                  value={values.DateOnset}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="Setting-main-span-div mt-4">
                <span className="ULTRASOUND-spn">Clinical Information</span>
              </div>
              {IsClinical.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setIsClinical((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
            </div>

            <div className="w-100">
              <div className="Hepatitis-main-span-div">
                <span className="ULTRASOUND-spn">Hepatitis Serology</span>
              </div>
              <div className="mt-3">
                <span className="Reason-Check-span ">
                  Reason for test (Check only one box):
                </span>
              </div>
              {Hepatitis.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id={`checkbox_${index}`}
                      name="hepatitisOptions"
                      checked={selectedOption.index === index}
                      onChange={() => handleCheckboxChange(item, index)}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div className="mt-3">
                <span className="Reason-Check-span ">
                  Indicate specific viruses (check all that apply):
                </span>
              </div>
              {IsHepatitisTo.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        setIsHepatitisTo((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div className="mt-3">
                <span className="Reason-Check-span ">
                  *Testing only available for acute or chronic infection; no
                  test for determining immunity ro HCV is currently available
                </span>
              </div>
              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="SendtoPatient"
                  value={values.SendtoPatient}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="chack-in-span">Send to Patient</span>
              </div>
              <div
                onClick={() => setLgShow(true)}
                className="checkdivmain mb-5"
              >
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="SendtoLab"
                  value={values.SendtoLab}
                  onBlur={handleBlur}
                  checked={values.SendtoLab ? true : false}
                  onChange={(e) => setFieldValue("SendtoLab", e.target.checked)}
                />
                <span className="chack-in-span">Send to Lab</span>
              </div>
              <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
                Submit
              </Button>
            </div>
          </div>
        </form>
      </section>
      <SendtoLebModel
        onHide={() => {
          setFieldValue("SendtoLab", false);
          setLgShow(false);
        }}
        lgShow={lgShow}
        setLgShow={setLgShow}
        hendelElectro={hendelElectro}
      />
      {loading && <Loader />}
    </>
  );
};

export default GeneralPublicHealthForm;
