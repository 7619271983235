import React, { useContext, useEffect, useState } from "react";
// import "./BookAnAppointment5.css";
import Fetchdata from "../../Constant/Fetchdata";
import { GETSYMTOMSAPI } from "../../Constant/Apiconstant";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
// import AddModel from "../Healthcard-CreditCard/AddModel";
import CardiologestPop from "../CardiologestPop/CardiologestPop";

const Cardiologest = () => {
  const route = useLocation();
  const maindata = route?.state?.maindata;

  // console.log(maindata, "maindata");

  const navigate = useNavigate();
  const [Cardpop, setCardpop] = useState(false);
  const [symptoms, setsymtoms] = useState([]);
  // console.log(symptoms);
  const { Consultation, selectedsymtom, setselectedsymtom } =
    useContext(GlobalContext);
  const [showmodel, setsshowmodel] = useState(false);
  const { response, isloading } = Fetchdata(
    GETSYMTOMSAPI,
    { doctor_id: maindata?._id },
    "POST"
  );
  // console.log(response);

  useEffect(() => {
    setsymtoms(response?.symptoms);
  }, [response]);

  const handleclick = () => {
    navigate("/BookandAppoinmentSlote", {
      state: {
        maindata,
        selectedsymtom,
      },
    });
  };

  const handleselected = (item, index) => {
    setselectedsymtom((prev) => {
      const isItemInArray = prev.includes(item?.symptomsName);
      if (isItemInArray) {
        // If the item is already selected, remove it from the array
        const updatedSelection = prev.filter(
          (selectedItem) => selectedItem !== item?.symptomsName
        );
        return updatedSelection;
      } else {
        // If the item is not selected, add it to the array
        if (prev.length >= 5) {
          // Limit the selection to 5 items
          return prev;
        } else {
          const updatedSelection = [...prev, item?.symptomsName];
          return updatedSelection;
        }
      }
    });
  };
  return (
    <>
      <div className="expalin-div-sec">
        <Header onClick={() => navigate(-1)} text={"Family physician"} />
        <div
          className="cardcontent-Torso-main-div"
          style={{ borderRadius: "10px" }}
        >
          <div className="closebtn">
            <span className="Find-main-span">
              Is your problem accompanied by new and unfamiliar symptoms such
              as:
            </span>
            <span className="mt-3 major-main-doc mb-3">
              Select major 5 problems
            </span>
            {isloading ? (
              <SimpleLoader />
            ) : (
              <>
                {symptoms?.map((item, index) => {
                  return (
                    <>
                      <div key={item} className="Organization-sapn-div">
                        <div className="Dizziness-main-div-itm">
                          <span className="Dizziness-sapn">
                            {item.symptomsName}
                          </span>
                          <input
                            onClick={() => handleselected(item, index)}
                            className="main-input-chek"
                            type="checkbox"
                            checked={selectedsymtom.includes(item.symptomsName)}
                            disabled={selectedsymtom.length >= 5 && ""}
                          />
                        </div>
                        <div className="line-div-Dizziness"></div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div>
          <button
            onClick={handleclick}
            disabled={selectedsymtom.length === 0 ? "disable" : ""}
            className="Done-mbtn-with-pop mt-3 mb-3"
          >
            Continue
          </button>
        </div>
      </div>

      {Cardpop && (
        <CardiologestPop
          Cardpop={Cardpop}
          setCardpop={setCardpop}
          text={
            "The patient that the appointment cannot be completed because this is an emergency situation that require in person assessment and that they need to go straight to an emergency room. "
          }
        />
      )}
    </>
  );
};

export default Cardiologest;
