import React, { useState } from "react";
import "./SickNotePopup.css";
import Modal from "react-bootstrap/Modal";

const SickNotePopup = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleDoneClick = () => {
    handleClose(); // Close the modal after performing actions
  };
  return (
    <>
      <button onClick={handleShow}>Open Modal</button>
      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Header
          closeButton
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-Sick-con-div-been">
              <span className="">
                Doctor’s/Sick note is an uninsured service that requires payment
                from patient. Patient has been notified.
              </span>

              <div className="w-100">
                <div className="Patient-bono-main-div">
                  <input className="main-input-chek" type="checkbox" />
                  <span className="Organization-sapn"> Pro-bono</span>
                </div>
                <div className="Physician-div">
                  <div className="immediately-div">
                    <span>
                      Physician waves the fee for the note - become available
                      immediately
                    </span>
                  </div>
                </div>
              </div>
              <div className="Patient-bono-main-div">
                <input className="main-input-chek" type="checkbox" />
                <span className="Organization-sapn"> Pro-bono</span>
              </div>
              <div className="Physician-div">
                <div className="immediately-div">
                  <span>
                    Physician waves the fee for the note - become available
                    immediately
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button className="available-mbtn-with-pop mt-3 mb-3">
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SickNotePopup;
