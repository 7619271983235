import React, { useState } from "react";
import "./Torso.css";
import Modal from "react-bootstrap/Modal";
import close from "../../Assets/Images/search_ic@3x.png";
import { motion } from "framer-motion";

const Torso = () => {
  const [show, setShow] = useState(false);

  const data = [
    {
      Organization: "Cough",
    },
    {
      Organization: "Difficulty in breathing",
    },
    {
      Organization: "Chest pain/tightness",
    },
    {
      Organization: "Shortness on breath",
    },
    {
      Organization: "Fever",
    },
  ];
  const AbdomenPelvic = [
    {
      Organization: "Nausea/vomiting",
    },
    {
      Organization: "Diarrhea",
    },
    {
      Organization: "Constipation",
    },
    {
      Organization: "Abdominal pain/ heartburns",
    },
    {
      Organization: "Painful urination",
    },
    {
      Organization: "Difficulty passing urine",
    },
    {
      Organization: "Erectile dysfunction",
    },
    {
      Organization: "Anal pain",
    },
    {
      Organization: "Poor urine stream/urine hesitancy",
    },
    {
      Organization: "Vaginal discharge/bleeding",
    },
    {
      Organization: "Penal discharge",
    },
    {
      Organization: "Vaginal itching",
    },
  ];
  const Back = [
    {
      Organization: "Upper back pain",
    },
    {
      Organization: "Lower back pain",
    },
    {
      Organization: "Rash",
    },
  ];

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleDoneClick = () => {
    handleClose(); // Close the modal after performing actions
  };

  return (
    <>
      <button onClick={handleShow}>Open Modal</button>
      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Header
          closeButton
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="closebtn">
              <span className="Find-main-span">Torso</span>

              {data.map((Item, Index) => {
                return (
                  <div className="Organization-sapn-div">
                    <div className="checkbox-main-div-itm">
                      <span className="Organization-sapn">
                        {" "}
                        {Item.Organization}
                      </span>
                      <input className="main-input-chek" type="checkbox" />
                    </div>
                    <div className="line-div"></div>
                  </div>
                );
              })}
              <div className="AbdomenPelvic-main-span-k">
                <span>AbdomenPelvic</span>
              </div>
              {AbdomenPelvic.map((Item, Index) => {
                return (
                  <div className="Organization-AbdomenPelvic-main-div">
                    <span className="Organization-sapn">
                      {" "}
                      {Item.Organization}
                    </span>
                    <input className="main-input-chek" type="checkbox" />
                  </div>
                );
              })}
              <div className="line-div mt-3"></div>

              <div className="AbdomenPelvic-main-span-k">
                <span>Back</span>
              </div>
              {Back.map((Item, Index) => {
                return (
                  <div className="Organization-AbdomenPelvic-main-div">
                    <span className="Organization-sapn">
                      {" "}
                      {Item.Organization}
                    </span>
                    <input className="main-input-chek" type="checkbox" />
                  </div>
                );
              })}
              <div className="line-div mt-3"></div>
            </div>
          </div>
          <div>
            <button className="Done-mbtn-with-pop mt-3 mb-3">Done</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Torso;
