import React from "react";
import "./SelectProfile.css";
import Profileimg from "../../Assets/Images/LoginDocter.jpg";
import Header from "../../Components/Header/Header";
import { json, useLocation, useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { FAMILYMEMBERLIST } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";

const SelectProfile = () => {
  const { response, isloading } = Fetchdata(FAMILYMEMBERLIST, {}, "GET");

  const route = useLocation();
  const maindata = route?.state?.maindata;

  // console.log(maindata, "maindata");
  // console.log(response);

  const navigate = useNavigate();
  const handleProfileClick = (selectedId, itm) => {
    // console.log(itm, "ditm");
    localStorage.setItem("selectedProfileId", JSON.stringify(selectedId));

    // console.log(selectedId, "selectedId");
    if (maindata) {
      navigate("/Cardiologest", {
        state: {
          maindata,
        },
      });
    } else {
      navigate("/BookAnAppointment", {
        state: {
          selectedId,
        },
      });
      // navigate("/BookAnAppointment");
    }
  };
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Select profile & book appointment"}
      />
      <section className="FindMD-main-section">
        <div className="SelectProfile-main-div">
          {isloading ? (
            <SimpleLoader style={{ height: "300px" }} />
          ) : (
            <div className="Profileimg-Pratt-div">
              {response?.data?.map((itm, index) => {
                return (
                  <div
                    className="SelectProfileImg-and-name"
                    // onClick={() => navigate("/BookAnAppointment")}
                    onClick={() => handleProfileClick(itm)}
                    key={index}
                  >
                    <img
                      className="Profileimg-main-img"
                      src={itm.basicInfo.image}
                      alt=""
                    />
                    <span className="Lena-Pratt-span">
                      {itm.basicInfo.fullName}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SelectProfile;
