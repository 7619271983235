import React, { useContext, useEffect, useState } from "react";
import "./BookAnAppointment5.css";
import Fetchdata from "../../Constant/Fetchdata";
import { MASTERDATAAPI } from "../../Constant/Apiconstant";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import AddModel from "../Healthcard-CreditCard/AddModel";
import { store } from "../../Redux/store";
import { setSessionField } from "../../Redux/SessionAction";
import { NUMBER } from "../../Redux/Appconstant";
import CardiologestPop from "../CardiologestPop/CardiologestPop";
import { toast } from "react-toastify";

const BookAnAppointment5 = () => {
  const navigate = useNavigate();
  const [symptoms, setsymtoms] = useState([]);
  // const [selectedButton, setSelectedButton] = useState(null);
  const { Consultation, selectedsymtom, setselectedsymtom, OtherData } =
    useContext(GlobalContext);
  const [showmodel, setsshowmodel] = useState(false);
  const [Cardpop, setCardpop] = useState(false);
  const { response, isloading } = Fetchdata(MASTERDATAAPI, "POST");
  // console.log(selectedsymtom, "selectedsymtom");

  useEffect(() => {
    // Reset selected symptoms when component mounts or becomes visible again
    setselectedsymtom([]);
  }, []);

  useEffect(() => {
    if (OtherData === "Other") {
      setsymtoms(response?.data?.other);
    } else {
      setsymtoms(response?.data?.bodyPart);
    }
  }, [response]);

  const handleclick = () => {
    setsshowmodel(true);
  };

  const handleselected = (item) => {
    // console.log(item, "simtams");
    if (
      item.symptomsName === "Difficulty in breathing" ||
      item.symptomsName === "Chest pain/tightness"
    ) {
      setCardpop(true);
    }
    if (
      selectedsymtom.length >= 5 ||
      selectedsymtom.includes(item?.symptomsName)
    ) {
      const newdata = selectedsymtom.filter((it) => it !== item.symptomsName);
      // console.log(newdata);
      setselectedsymtom(newdata);
      // return;
    } else {
      setselectedsymtom((prev) => [...prev, item?.symptomsName]);
    }
  };

  const handlenavigate = (num) => {
    store.dispatch(setSessionField(NUMBER, num));
    if (num === 1) {
      navigate("/Payment", { state: { selectedsymtom, num } });
    } else if (num === 2) {
      navigate("/FindMDHead", { state: { selectedsymtom, num } });
    }
  };

  // console.log(Consultation);

  useEffect(() => {
    if (!Consultation) {
      navigate("/BookAnAppointment/Reasonforconsultation");
      toast.error("Please select reason");
    }
  }, []);

  return (
    <>
      <div className="expalin-div-sec">
        <Header onClick={() => navigate(-1)} text={Consultation} />
        <div
          className="cardcontent-Torso-main-div"
          style={{ borderRadius: "10px" }}
        >
          <div className="closebtn">
            <span className="Find-main-span">
              Is your problem accompanied by new and unfamiliar symptoms such
              as:
            </span>
            <span className="mt-3 major-main-doc mb-3">
              Select major 5 problems
            </span>
            {isloading ? (
              <SimpleLoader />
            ) : (
              <>
                {symptoms
                  ?.filter((item) => item?.bodyPartName === Consultation)
                  .map((item) =>
                    item?.symptoms?.map((_item, index) => (
                      <div key={index} className="Organization-sapn-div">
                        <div className="Dizziness-main-div-itm">
                          <span className="Dizziness-sapn">
                            {_item.symptomsName}
                          </span>
                          <input
                            onClick={() => handleselected(_item, index)}
                            className="main-input-chek"
                            type="checkbox"
                            checked={selectedsymtom.includes(
                              _item.symptomsName
                            )}
                            disabled={selectedsymtom.length >= 5 && ""}
                          />
                        </div>
                        <div className="line-div-Dizziness"></div>
                      </div>
                    ))
                  )}
              </>
            )}
          </div>
        </div>
        <div>
          <button
            onClick={handleclick}
            disabled={selectedsymtom.length === 0 ? "disable" : ""}
            className="Done-mbtn-with-pop mt-3 mb-3"
          >
            Continue
          </button>
        </div>
      </div>
      {showmodel && (
        <AddModel
          showmodel={showmodel}
          setshowmodel={setsshowmodel}
          btn1={"Any Available Doctor"}
          btn2={"Choose Doctor"}
          handleClick={handlenavigate}
        />
      )}

      {Cardpop && (
        <CardiologestPop
          Cardpop={Cardpop}
          setCardpop={setCardpop}
          text={
            "The patient that the appointment cannot be completed because this is an emergency situation that require in person assessment and that they need to go straight to an emergency room. "
          }
        />
      )}
    </>
  );
};

export default BookAnAppointment5;
