import React from "react";
import "./MyProfileLicence.css";
const MyProfileLicence = ({ response }) => {
  // console.log(response);
  return (
    <>
      <section>
        <div>
          <div className="Professional-Licence-div mt-3">
            <span className="age-main-span-add">Country</span>
            <span className="age-main-span-add">Registering body</span>
            <span className="age-main-span-add">Status</span>
            <span className="age-main-span-add">License No</span>
          </div>
          {response?.licence?.map((item, index) => {
            return (
              <div className="No-body-div mt-3" key={index}>
                <div className="Professional-body-div">
                  <span>{item.country}</span>
                  <span>{item.licenceAuthority}</span>
                  <span
                    style={{
                      backgroundColor: item.isApporovedByAdmin
                        ? "#00c853"
                        : "#ffff99",
                      color: item.isApporovedByAdmin ? "#ffffff" : "#000000",
                    }}
                  >
                    {item.isApporovedByAdmin ? "Approved" : "Pending"}
                  </span>
                  <span>{item.licensingNumber}</span>
                </div>
                <div className="Notifications-d-s-line mt-3"></div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default MyProfileLicence;
