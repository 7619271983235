import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
} from "react";
import Modal from "react-bootstrap/Modal";
import Header from "../../Components/Header/Header";
import { json, useLocation, useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import {
  GETMEDICINEAPI,
  // GETREQUESTEDPRESCRIPTIONAPI,
  PRESCRIPTIONMASTERAPI,
  PrescriptionNotesApi,
  SelectedPharmacyPost,
  getMedicineApi,
} from "../../Constant/Apiconstant";
import { Icons } from "../../Constant/Icons";
import Loader from "../../Constant/Loader";
import { useFormik } from "formik";
import { GlobalContext } from "../../Context/GlabalContext";
// import Serachmedicinepop from "../Serachmedicinepop/Serachmedicinepop";
import { IoSearchOutline } from "react-icons/io5";
import EnterMedicineName from "../EnterMedicineName/EnterMedicineName";
// import { GETMEDICINEAPI } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
// import { Backdrop, CircularProgress } from "@mui/material";
// import DataSuccessfully from "../../Doctor/DataSuccessfully/DataSuccessfully";
// const [Popup, setPopup] = useState(false);
import moment from "moment";
import Signature from "../Signature/Signature";
import { toast } from "react-toastify";
import SendToPharmacy from "../SendToPharmacy/SendToPharmacy";
import "./PrescriptionNotes.css";

const PrescriptionNotes = ({ text, handleCloseeee }) => {
  const location = useLocation();
  const itm = location?.state?.id;

  const [precription, setprecription] = useState([
    {
      nameOfMedicine: "",
      PRN: false,
      dose: "",
      doseUnit: "",
      route: "",
      frequency: "",
      duration: "",
      durationUnit: "",
    },
  ]);
  const [pagecount, setpagecount] = useState(1);
  const [keyword, setkeyword] = useState("");
  // console.log(pagecount, "pagecount");
  // console.log(keyword, "keyword");
  // const [searchValue, setSearchValue] = useState("");
  const { Medicine } = useContext(GlobalContext);
  const [PopupName, setPopupName] = useState(false);
  const [activeTab, setActiveTab] = useState("search");
  // const [currentPage, setCurrentPage] = useState(1);
  const { setMedicine, EmailGo, setEmailGo, SigUrl } =
    useContext(GlobalContext);
  const [drugNames, setDrugNames] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  // console.log(drugNames, "drugNames");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setloading] = useState(false);
  // console.log(drugNames, "drugNames");
  // const [Popup, setPopup] = useState(false);

  const [Popup1, setPopup1] = useState(false);

  // console.log(SigUrl, "SigUrl");
  // console.log(Medicine, "Docdata");
  // console.log(precription, "precription");
  // const maindata = route?.state?.item;
  const navigate = useNavigate();

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");

  const initialValues = {
    medicine: [],
    SendtoParmacy: false,
    SendtoPatient: false,
  };

  const CallID = localStorage.getItem("CallNoteId");
  const pharmacyApi = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SelectedPharmacyPost,
        {
          appointmentID: itm?._id || savedRatingId || CallID,
          email: EmailGo,
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  // const { response: medicineDATA } = Fetchdata(GETMEDICINEAPI, {}, "GET");
  const { response: masterDATA } = Fetchdata(PRESCRIPTIONMASTERAPI, {}, "GET");

  const header = Headers();
  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        PrescriptionNotesApi,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          prescription: {
            prescriptionData: JSON.stringify(precription),
            drSinature_image: SigUrl,
            sendPatient: values?.SendtoPatient,
            sendPharmacy: values?.SendtoParmacy,
            prescriptionDate: formattedDate,
          },
        },
        header
      );
      if (res?.data?.status == "failed") {
        toast.error(res?.data?.status);
      } else if (res?.status === 200 && res?.data?.status === "success") {
        toast.success(res?.data?.message);
        pharmacyApi();
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }

      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formattedDate = moment(currentDate).format("YYYY-MM-DD");

  // console.log(formattedDate, "formattedDate");
  const { handleSubmit, values, setFieldValue, handleChange, handleBlur } =
    useFormik({
      onSubmit,
      initialValues: initialValues,
    });

  useEffect(() => {
    const storedPrescriptions = localStorage.getItem("prescriptions");
    if (storedPrescriptions) {
      setprecription(JSON.parse(storedPrescriptions));
    } else {
      setprecription([
        {
          nameOfMedicine: Medicine,
          PRN: false,
          dose: "",
          doseUnit: "",
          route: "",
          frequency: "",
          duration: "",
          durationUnit: "",
        },
      ]);
    }
  }, [Medicine]);

  const addMore = () => {
    setprecription((prev) => [
      ...prev,
      {
        nameOfMedicine: "",
        PRN: false,
        dose: "",
        doseUnit: "",
        route: "",
        frequency: "",
        duration: "",
        durationUnit: "",
      },
    ]);
  };

  const hendelElectro = () => {
    setLgShow(false);
  };
  const deleteprescription = (item, index) => {
    const updatedPrescriptions = [...precription];

    updatedPrescriptions.splice(index, 1);

    setprecription(updatedPrescriptions);
  };

  const handleMedicineChange = (index, field, value) => {
    setprecription((prev) => {
      const updatedPrescription = [...prev];
      updatedPrescription[index] = {
        ...updatedPrescription[index],
        [field]: value,
      };
      return updatedPrescription;
    });
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName === activeTab ? null : tabName);
  };

  const handleClick = () => {
    setPopupName(true);
  };
  const [index, seined] = useState(0);

  const handleClickmadi = (medicineName) => {
    // setMedicine(medicineName);
    setprecription((prv) => {
      let temp = [...prv];
      temp[index].nameOfMedicine = medicineName;
      return temp;
    });
    // console.log(medicineName);
    setPopup1(false);
    // navigate("/PrescriptionNotes");
  };

  const Medyy = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        getMedicineApi,
        {
          pageLimit: 10,
          page: pagecount,
          drug_name: keyword,
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
      setDrugNames(res.data.data.data);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  // const params = {
  //   pageLimit: 10,
  //   page: 1,
  //   drug_name: "",
  // };

  // const {
  //   response: Medicin,
  //   isloading,
  //   getdata: Medyy,
  // } = Fetchdata(getMedicineApi, params, "GET");

  // const pagenumber = useRef(1);
  // const totalpage = useRef(0);

  // const handlescroll = () => {
  //   try {
  //     if (drugNames) {
  //       if (
  //         window.innerHeight + document.documentElement.scrollTop + 1 >=
  //         document.documentElement.scrollHeight
  //       ) {
  //         pagenumber.current = pagenumber.current + 1;
  //         setpagecount((prev) => prev + 1);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   setkeyword("");
  //   window.addEventListener("scroll", handlescroll);
  //   return () => {
  //     window.removeEventListener("scroll", handlescroll);
  //   };
  // }, []);

  useEffect(() => {
    Medyy();
  }, [pagecount]);

  const debouncedelay = 500;
  useEffect(() => {
    let timer;
    if (keyword) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        Medyy();
      }, debouncedelay);
    } else if (keyword == "") {
      Medyy();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [keyword]);

  const { response, getdata } = Fetchdata(GETMEDICINEAPI, {}, "GET");

  // console.log(response, "response");
  // console.log(Medicin, "Medicin");

  // const data = [
  //   {
  //     medicineName: "dolo",
  //   },
  //   {
  //     medicineName: "one-p",
  //   },
  //   {
  //     medicineName: "den-p",
  //   },
  // ];
  // const apidatas = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://dailymed.nlm.nih.gov/dailymed/services/v2/drugnames",
  //       {}
  //     );

  //     setloading(false);
  //     console.log(response);
  //   } catch (error) {
  //     console.log("errors75", error);
  //     setloading(false);
  //   }
  // };
  // useEffect(() => {
  //   apidatas();
  // }, []);

  const showmodal = (index) => {
    setPopup1(true);
    seined(index);
  };

  // const hendelPhae = () => {
  //   // console.log(item, "item");
  //   navigate("/SelectedPharmacy", {
  //     state: {
  //       itm,
  //     },
  //   });
  // };

  const divref = useRef();
  // const handlescolldiv = () => {
  //   if (divref.current) {
  //     const scrooll = divref.current.scrollTop;
  //     // console.log(scrooll);
  //     console.log(divref.current);
  //     if (scrooll === 144.8000030517578) {
  //       pagenumber.current = pagenumber.current + 1;
  //       setpagecount((prev) => prev + 1);
  //       setTimeout(() => {
  //         divref.current.scrollTop = 0;
  //       }, 1000);
  //     }
  //   }
  // };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Prescription"}
        style={{ color: "white" }}
      />
      <form onSubmit={handleSubmit} className="Prescription-main-section mb-5">
        <div className="Prescription-main-div">
          <div className="d-flex flex-column gap-3">
            {precription.map((item, index) => (
              <div className="d-flex flex-column" key={index}>
                {index !== 0 && (
                  <span
                    className="align-self-end"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteprescription(item, index)}
                  >
                    X
                  </span>
                )}
                <div className="d-flex flex-column">
                  <span className="Medicine-sab-input-span">
                    Name of Medicine
                  </span>
                  <input
                    id="Dose"
                    className="handleMedicineChange-main"
                    onChange={(e) =>
                      handleMedicineChange(
                        index,
                        "nameOfMedicine",
                        e.target.value
                      )
                    }
                    onClick={() => {
                      showmodal(index);
                    }}
                    value={precription[index].nameOfMedicine}
                    placeholder="Enter Name"
                    name={`nameOfMedicine${[index]}`}
                    type="text"
                  />
                </div>
                <div className="Yes-and-No-name mt-3">
                  <div className="Unit-kg-selact">
                    <span className="Medicine-sab-input-span">Dose</span>

                    <input
                      className="Enter-Dose-value-number mt-2"
                      name="DoseUnit"
                      type="number"
                      placeholder="selact Duration"
                      id="Dose"
                      onChange={(e) =>
                        handleMedicineChange(index, "doseUnit", e.target.value)
                      }
                    />
                  </div>
                  <div className="Unit-kg-selact">
                    <span className="Medicine-sab-input-span">Unit</span>
                    <select
                      className="Enter-Dose-value mt-2"
                      name="Dose"
                      id="Dose"
                      onChange={(e) =>
                        handleMedicineChange(index, "dose", e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                      {masterDATA?.data?.doseUnit.map((dose) => (
                        <option value={dose?.name} key={dose?._id}>
                          {dose?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="Yes-and-No-name mt-3">
                  <div className="Unit-kg-selact">
                    <span className="Medicine-sab-input-span">Frequency</span>
                    <div className="d-flex w-100 align-content-center gap-4">
                      <select
                        className="Enter-Dose-value-Dose mt-2"
                        name="Dose"
                        id="Dose"
                        onChange={(e) =>
                          handleMedicineChange(
                            index,
                            "frequency",
                            e.target.value
                          )
                        }
                      >
                        <option value="" selected disabled>
                          Select Frequency
                        </option>
                        {masterDATA?.data?.freuqency?.map((freuqency) => (
                          <option value={freuqency.name} key={freuqency?._id}>
                            {freuqency?.name}
                          </option>
                        ))}
                      </select>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleMedicineChange(index, "PRN", e.target.checked)
                          }
                          checked={item.PRN}
                          style={{ scale: "1.7" }}
                        />
                        <span className="Medicine-sab-input-span">PRN</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Unit-kg-selact">
                  <span className="Medicine-sab-input-span">Route</span>
                  <select
                    className="Enter-Dose-value-Route mt-2"
                    name="Dose"
                    id="Dose"
                    onChange={(e) =>
                      handleMedicineChange(index, "route", e.target.value)
                    }
                  >
                    <option value="" selected disabled>
                      Select Route
                    </option>
                    {masterDATA?.data?.routesData?.map((route) => (
                      <option value={route?.name} key={route?._id}>
                        {route?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="Yes-and-No-name mt-3">
                  <div className="Unit-kg-selact">
                    <span className="Medicine-sab-input-span">Duration</span>
                    <input
                      className="Enter-Dose-value-number mt-2"
                      name="Duration"
                      type="number"
                      placeholder="selact Duration"
                      id="Dose"
                      onChange={(e) =>
                        handleMedicineChange(index, "duration", e.target.value)
                      }
                    />
                  </div>
                  <div className="Unit-kg-selact">
                    <span className="Medicine-sab-input-span">Unit</span>
                    <select
                      className="Enter-Dose-value mt-2"
                      name="durationUnit"
                      id="Dose"
                      onChange={(e) =>
                        handleMedicineChange(
                          index,
                          "durationUnit",
                          e.target.value
                        )
                      }
                    >
                      <option value="" selected disabled>
                        Select Unit
                      </option>
                      {["Day", "Week", "Month", "Year"].map((durationUnit) => (
                        <option value={durationUnit?.name} key={durationUnit}>
                          {durationUnit}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="Unit-kg-selact">
                    <span className="Medicine-sab-input-span">Unit</span>
                    <select
                      className="Enter-Dose-value mt-2"
                      name="durationUnit"
                      id="Dose"
                      onChange={(e) =>
                        handleMedicineChange(0, "durationUnit", e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Select Unit
                      </option>
                      <option value="Day">Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                      <option value="Year">Year</option>
                    </select>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex flex-column mb-5">
          <div className="Duration-add-more">
            <span onClick={addMore}>{Icons?.addbluebtn}</span>
            <span className="New-ard-add-span" onClick={addMore}>
              Add More
            </span>
          </div>
          <div className="Patient-main-div">
            <input
              className="main-input-chek"
              type="checkbox"
              name="SendtoPatient"
              value={values?.SendtoPatient}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <span className="Organization-sapn"> Send to Patient</span>
          </div>
          <div onClick={() => setLgShow(true)} className="Patient-main-div">
            <input
              className="main-input-chek"
              type="checkbox"
              name="SendtoParmacy"
              // value={values?.SendtoParmacy}
              checked={values.SendtoParmacy ? true : false}
              onChange={(e) => setFieldValue("SendtoParmacy", e.target.checked)}
              onBlur={handleBlur}
            />
            <span className="Organization-sapn"> Send to Parmacy</span>
          </div>
        </div>
        <Signature />
        <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
          Submit
        </Button>
      </form>
      {loading && <Loader />}
      <Modal show={Popup1} size="md" centered>
        <Modal.Header
          style={{
            display: "grid",
            placeItems: "end",
            width: "100% ",
            justifyContent: "end",
            border: "0",
          }}
          closeButton
          onClick={() => setPopup1(false)}
        ></Modal.Header>
        <section className="ReferralDoctor-main-section">
          <div className="ReferralDoctor-main-div">
            <div className="Search-main-buton-v">
              <button
                className="handleTabClick-search"
                onClick={() => handleTabClick("search")}
                // activeClassName="active"
              >
                Search
              </button>
              <button
                className="handleTabClick-search"
                // activeClassName="active"
                onClick={() => handleTabClick("myMedicine")}
              >
                My medicine
              </button>
            </div>

            {activeTab === "search" && (
              <div className="IoSearchOutline-search-com">
                <div>
                  <div className="mt-4 IoSearchOutline-main-div-in">
                    <IoSearchOutline className="IoSearchOutline-main" />
                    <input
                      className="IoSearchOutline-main-input"
                      placeholder="search"
                      type="search"
                      onChange={(e) => setkeyword(e.target.value)}
                    />
                  </div>
                  <div
                    style={{ height: "400px", overflowY: "scroll" }}
                    // onScroll={handlescolldiv}
                    ref={divref}
                  >
                    {drugNames.map((itm, index) => {
                      // console.log(itm.drug_name);
                      return (
                        <Fragment key={index}>
                          <div key={index} className="mt-2 w-100">
                            <span
                              onClick={() => handleClickmadi(itm?.drug_name)}
                              className="test-medical"
                            >
                              {itm?.drug_name}
                            </span>
                          </div>
                          <div className="line-haghit-div"></div>
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="d-flex align-items-center justify-content-end mt-2 gap-2">
                    <button
                      className="paginationbtn"
                      onClick={() => {
                        if (pagecount === 1) {
                          return null;
                        } else {
                          setpagecount((prev) => prev - 1);
                        }
                      }}
                    >
                      {"-"}
                    </button>
                    <span>{pagecount}</span>
                    <button
                      onClick={() => setpagecount((prev) => prev + 1)}
                      className="paginationbtn"
                    >
                      {"+"}
                    </button>
                  </div>
                </div>
                <div className="line-haghit-div"></div>
                <div>
                  <button onClick={handleClick} className="Add-search-New">
                    Add New
                  </button>
                </div>
              </div>
            )}
            {activeTab === "myMedicine" && (
              <>
                {response?.data?.map((itm, index) => {
                  return (
                    <div key={index} className="IoSearchOutline-search-com">
                      <div className="mt-2 w-100">
                        <span
                          onClick={() => handleClickmadi(itm?.medicineName)}
                          className="test-medical"
                        >
                          {itm?.medicineName}
                        </span>
                      </div>
                      <div className="line-haghit-div"></div>
                    </div>
                  );
                })}
                <div>
                  <button onClick={handleClick} className="Add-search-New">
                    Add New
                  </button>
                </div>
              </>
            )}
          </div>
          {PopupName && (
            <EnterMedicineName
              PopupName={PopupName}
              setPopupName={setPopupName}
              getdata={getdata}
              text={"Enter Name of Medicine"}
            />
          )}
        </section>
      </Modal>
      <SendToPharmacy
        onHide={() => {
          setFieldValue("SendtoParmacy", false);
          setLgShow(false);
        }}
        lgShow={lgShow}
        setLgShow={setLgShow}
        hendelElectro={hendelElectro}
      />
    </>
  );
};

export default PrescriptionNotes;
