import React from "react";
import "./Button.css";

const Buttton = ({ children, onClick, style, disable, Class, type }) => {
  return (
    <>
      <button
        className={`main-button`}
        onClick={onClick}
        style={style}
        disabled={disable}
        type={type}
      >
        {children}
      </button>
    </>
  );
};

export default Buttton;
