import React from "react";
import "./MyProfileProfessionalInfoDoctor.css";
import { useNavigate } from "react-router-dom";
const MyProfileProfessionalInfoDoctor = ({ response }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="BillingDoctor-main-section">
        <div className="BillingDoctor-main-div-stap-to">
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Speciality</span>
            <span>{response?.speciality}</span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Qualification</span>
            <span>{response?.qualification}</span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="Email-and-dorothy-price  mt-3">
            <div className="profile-age-div">
              <span className="age-main-span-add">Location</span>
              <span>{response?.basicInfo.location}</span>
            </div>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add"> Address</span>
            <span>{response?.basicInfo.address}</span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add"> Total Experience</span>
            <span>{response?.totalExperience} Years</span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="GeneralInfo-main-Change-btn-div">
            <button
              onClick={() => navigate("/ChangePassword")}
              className="GeneralInfo-main-Change"
            >
              Change password
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyProfileProfessionalInfoDoctor;
