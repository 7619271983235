import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBOl7P1azqmofdEXZn7Nop_WsFzgpODbH8",
  authDomain: "sonorous-haven-304311.firebaseapp.com",
  projectId: "sonorous-haven-304311",
  storageBucket: "sonorous-haven-304311.appspot.com",
  messagingSenderId: "440283041344",
  appId: "1:440283041344:web:2f936d48bcc439e11e99a5",
  measurementId: "G-CSEPFEBFB6",
};

const app = initializeApp(firebaseConfig);
const social = getAuth(app);
const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();
export { social, provider };
export const messaging = getMessaging(app);
