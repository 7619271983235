export function Dateformat(timestamp, options = {}) {
  const date = new Date(timestamp);

  //   const months = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  // const month = months[date.getMonth()];
  // const year = date.getFullYear();
  // const day = date.getDate();
  let hour = date.getHours();
  // const minute = date.getMinutes();
  // const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let formattedDate = "";
  if (options.showYear) {
    formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      timeZone: userTimeZone,
    }).format(date);
  } else if (options.showHour) {
    formattedDate = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: userTimeZone,
    }).format(date);
  } else {
    formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: userTimeZone,
    }).format(date);
  }

  return formattedDate;
}
