import React, { useState, useEffect } from "react";
import "./BillingDoctor.css";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { GETPAYMENTHISTORYAPI } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";

const BillingDoctor = () => {
  const navigate = useNavigate();
  const [selecteddate, setselecteddate] = useState({
    start_date: "",
    end_date: "",
  });
  const params = {
    start_date: "",
    end_date: "",
  };
  const { response, isloading } = Fetchdata(
    GETPAYMENTHISTORYAPI,
    params,
    "POST"
  );

  const daysofweek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Billing"}
        style={{ color: "white" }}
      />
      <section className="BillingDoctor-main-section">
        <div className="BillingDoctor-main-div">
          <div className="BillingDoctor-therd-div">
            <div className="input-date-main-div">
              <span className="Organization-sapn">From Date</span>
              <input
                placeholder="Select Date"
                className="Date-From-Birth-input"
                type="date"
                name="date"
                pattern="\d{4}-\d{2}-\d{2}"
                max="9999-12-31"
                min="1000-01-01"
                onChange={(e) =>
                  setselecteddate({ start_date: e.target.value })
                }
              />
            </div>
            <div className="input-date-main-div">
              <span className="Organization-sapn">To Date</span>
              <input
                placeholder="Select Date"
                className="Date-From-Birth-input"
                type="date"
                name="date"
                max="9999-12-31"
                pattern="\d{4}-\d{2}-\d{2}"
                onChange={(e) => setselecteddate({ end_date: e.target.value })}
              />
            </div>
          </div>
          <div className="mt-4">
            <span className="Transaction-ke-history-he">
              Transaction history
            </span>
          </div>
          {isloading ? (
            <SimpleLoader style={{ height: "300px" }} />
          ) : (
            <>
              {!response?.data ? (
                <h1 className="mt-3">{response?.message}</h1>
              ) : (
                <>
                  {response?.data?.map((item, index) => {
                    const inputDate = new Date(item.appointment_date);
                    const dayOfMonth = inputDate.getDate();
                    const dayOfWeek = daysofweek[inputDate.getDay()];
                    return (
                      <div className="Wed-date-div-main-Green mt-4" key={index}>
                        <div className="Transaction-main-history">
                          <div className="Wed-date-div">
                            <span className="fifth-span">{dayOfMonth}</span>
                            <span className="wed-thas-tus-span">
                              {dayOfWeek}
                            </span>
                          </div>
                          <div className="Hattie-main-div">
                            <span className="Hattie-amin-span">
                              {item.patientName}
                            </span>
                            <span className="">{item.stripe_date}</span>
                          </div>
                        </div>
                        <div className="dolur-pirfthiton">
                          <span className="main-span-doluor">
                            {item.appointment_currency}{" "}
                            {item.appointment_amount.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default BillingDoctor;
