import React, { useState } from "react";
import "./SentLabPopup.css";
import Modal from "react-bootstrap/Modal";
import Done from "../../Assets/Images/done_green_tick_ic@3x.png";

const SentLabPopup = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleDoneClick = () => {
    handleClose(); // Close the modal after performing actions
  };
  return (
    <>
      <button onClick={handleShow}>Open Modal</button>
      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Header
          closeButton
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-con-div">
              <div className="mt-4">
                <img className="tick-green-color" src={Done} alt="" />
              </div>
              <div className="successfully-div mt-3">
                <span className="like-span">
                  Test details has been sent to the lab successfully.
                </span>
              </div>
            </div>
          </div>
          <div>
            <button className="Done-mbtn-with-pop mt-3 mb-3">Ok</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SentLabPopup;
