import Header from "../../Components/Header/Header";
import bodyimage from "../../Assets/Images/male_body@3x.png";
import bodyimagefemale from "../../Assets/Images/female_body@3x.png";
import "./BookAnAppointment.css";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlabalContext";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";

const BookAnAppointment3 = () => {
  const { setConsultation } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();

  const GenderData = location?.state?.selectedId;
  // console.log(GenderData.basicInfo.gender, "GenderData");

  // const Mydata = store.getState().session[KEY_USER_DATA];
  // console.log(Mydata);
  const handleImageClick = (event) => {
    setConsultation(event.target.id);
    setTimeout(() => {
      navigate("/BookAnAppointment/Reasonforconsultation");
    }, 100);
    // console.log(event.target.id);
    // const image = event.target;
    // const rect = image.getBoundingClientRect();
    // const clickX = event.clientX - rect.left;
    // const clickY = event.clientY - rect.top;
    // console.log(`Clicked at (${clickX}, ${clickY})`);
  };
  // console.log(Mydata?.basicInfo?.gender);
  return (
    <div className="bookappointmentthree mobileview w-100">
      <Header text={"Book an appointment"} onClick={() => navigate(-1)} />
      <div className="bookappointmentchild  px-3">
        <p>
          Hello, Let’s start by <br /> determining your symptoms. <br /> Where
          does it hurt?
        </p>
        <div className="consultation">
          {GenderData?.basicInfo?.gender == "female" ? (
            <img src={bodyimagefemale} alt="" />
          ) : GenderData?.basicInfo?.gender == "male" ? (
            <img src={bodyimage} alt="" />
          ) : null}
        </div>
        <span
          className={
            GenderData?.basicInfo?.gender == "female" ? "item1female" : "item1"
          }
          id="Head & Neck"
          onClick={handleImageClick}
        ></span>
        <span
          // className="item2"
          className={
            GenderData.basicInfo.gender == "female" ? "femaleitem2" : "item2"
          }
          id="Torso"
          onClick={handleImageClick}
        ></span>
        <span
          className="item3"
          id="Lower limbs"
          onClick={handleImageClick}
        ></span>
        <span
          // className="item4"
          className={
            GenderData.basicInfo.gender == "female" ? "femaleitem4" : "item4"
          }
          id="Upper limbs"
          onClick={handleImageClick}
        ></span>
        <span
          className={
            GenderData.basicInfo.gender == "female" ? "femaleitem5" : "item5"
          }
          // className="item5"
          id="Upper limbs"
          onClick={handleImageClick}
        ></span>
      </div>
    </div>
  );
};

export default BookAnAppointment3;
