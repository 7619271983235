import React from "react";
import Header from "../../Components/Header/Header";
import Fetchdata from "../../Constant/Fetchdata";
import { FAMILYMEMBERLIST } from "../../Constant/Apiconstant";
import { useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../Constant/Loader";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";

const FamilyMember = () => {
  const { response, isloading } = Fetchdata(FAMILYMEMBERLIST, {}, "GET");
  const navigate = useNavigate();

  const handleclick = (item) => {
    // console.log(item);
    navigate("/UpdetHealthDataDetail", {
      state: {
        item,
      },
    });
  };
  // console.log(response);
  return (
    <>
      <Header onClick={() => navigate("/Profile")} text={"Family members"} />
      <section>
        <div className="w-100 mt-3 d-flex flex-column p-4">
          {isloading ? (
            <SimpleLoader style={{ height: "300px" }} />
          ) : (
            <div className="familymembers">
              {response?.data?.map((itm, index) => {
                return (
                  <div
                    className="FindMD-img-contain "
                    onClick={() => handleclick(itm)}
                  >
                    <div style={{ width: "80px", height: "80px" }}>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                        src={itm.basicInfo.image}
                        alt=""
                      />
                    </div>
                    <div className="profiledetail">
                      <h5 style={{ color: "#000000", fontSize: "16px" }}>
                        {itm.basicInfo.fullName}
                      </h5>
                      <span style={{ color: "#000000" }}>
                        <b>Relation</b>: {itm.relationship}
                      </span>
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <div>
                          <span style={{ color: "grey", fontSize: "15px" }}>
                            Height
                          </span>
                          <h5 style={{ color: "#000000", fontSize: "16px" }}>
                            {itm.healthData.height.value}
                          </h5>
                        </div>
                        <div>
                          <span style={{ color: "grey", fontSize: "15px" }}>
                            Weight
                          </span>
                          <h5 style={{ color: "#000000", fontSize: "16px" }}>
                            {itm?.healthData?.weight?.value}{" "}
                            {itm?.healthData?.weight?.unit}
                          </h5>
                        </div>
                        <div>
                          <span style={{ color: "grey", fontSize: "15px" }}>
                            BMI
                          </span>
                          <h5 style={{ color: "#000000", fontSize: "16px" }}>
                            {parseInt(itm?.healthData?.BMI).toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!isloading && (
            <Buttton
              onClick={() => navigate("/Add-family-member")}
              style={{
                backgroundColor: Colors.blue,
                marginTop: "1rem",
                alignSelf: "center",
              }}
            >
              Add New Family Member
            </Buttton>
          )}
        </div>
      </section>
    </>
  );
};

export default FamilyMember;
