import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import { Colors } from "../Constant/Colors";
import topimage from "../Assets/Images/home_img@3x.png";
import { Icons } from "../Constant/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { buttons } from "../Constant/DummyData";
import { GlobalContext } from "../Context/GlabalContext";
import { store } from "../Redux/store";
import { KEY_USER_DATA } from "../Redux/Appconstant";
import { GoCreditCard } from "react-icons/go";

const Home = () => {
  const { setPatcutCall } = useContext(GlobalContext);
  const [selected, setselected] = useState(0);
  const [activeindex, setactiveindex] = useState(null);
  const { myappointment, setmyappointment, settokenexpire, notificationCount } =
    useContext(GlobalContext);
  const userdata = store.getState().session[KEY_USER_DATA];
  const location = useLocation();

  useEffect(() => {
    if (!userdata?.basicInfo) {
      if (location.pathname !== "/") {
        settokenexpire(true);
        // console.log("1");
      }
    } else {
      switch (userdata?.basicInfo?.role) {
        case "patient":
          if (location.pathname !== "/home-page") {
            navigate("/home-page");
            // console.log("2");
          }
          break;
        case "physician":
          if (location.pathname !== "/Home") {
            navigate("/Home");
            // console.log("3");
          }
          break;
        default:
          break;
      }
    }
  }, [userdata?.basicInfo, location.pathname]);

  const navigate = useNavigate();

  //   console.log(selected);
  useEffect(() => {
    setselected(0);
  }, []);

  const links = [
    "/Profile",
    "",
    "/FindMD",
    "/Settings",
    "/PrescriptionDetail",
    "/Health-CreditCard",
  ];

  useEffect(() => {
    setTimeout(() => {
      navigate(links[selected - 1]);
    }, 300);
  }, [selected]);

  const handlenavigate = (item, index) => {
    setactiveindex(index);
    setTimeout(() => {
      navigate(item.link);
    }, 300);
  };

  useEffect(() => {
    setPatcutCall(false);
  }, []);

  return (
    <>
      <div className="circalcontainer">
        <div className="homeheader">
          <div>
            <img
              style={{ objectFit: "cover", width: "50px", height: "50px" }}
              src={userdata?.basicInfo?.image}
              alt=""
            />
            <div>
              <span id="headername">Hello!</span>
              <p id="headername">{userdata?.basicInfo?.fullName}</p>
            </div>
          </div>
          {notificationCount > 0 && (
            <span
              style={{ backgroundColor: "#ffb802" }}
              onClick={() => navigate("/Notifications")}
            >
              {notificationCount}
            </span>
          )}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/Notifications")}
          >
            {Icons.bellicon}
          </span>
        </div>
        <div className="herosection">
          <img src={topimage} alt="" />
          {/* <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
            velit neque nemo laudantium architecto suscipit tempore quia
            incidunt id similique accusantium qui, reiciendis vero consequuntur
            beatae ea sint. Placeat, accusantium.
          </p> */}
        </div>
        <div className="circlediv">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            width="319"
            height="321"
            viewBox="0 0 319 321"
          >
            <path
              style={{ cursor: "pointer" }}
              d="M173.71,127.52L224.62,35.53C229.64,26.46 225.4,15.12 215.73,11.43C198.06,4.69 178.9,1 158.87,1C138.84,1 119.68,4.69 102.02,11.43C92.34,15.12 88.11,26.46 93.12,35.53L144.04,127.52C150.49,139.18 167.25,139.18 173.71,127.52"
              strokeWidth="1"
              fill={selected === 1 ? Colors.blue : "#FFFFFF"}
              stroke="#968BFF"
              fillRule="evenodd"
              onClick={() => setselected(1)}
            />
            <path
              style={{ cursor: "pointer" }}
              d="M193.14,157.64L300.55,157.84C310.87,157.86 318.79,148.73 317.37,138.51C311.42,95.57 288.39,58.09 255.35,33.15C247.14,26.95 235.33,29.51 230.35,38.51L178.34,132.48C172.09,143.77 180.24,157.62 193.14,157.64"
              strokeWidth="1"
              fill={selected === 2 ? Colors.blue : "#FFFFFF"}
              stroke="#968BFF"
              fillRule="evenodd"
              onClick={() => setselected(2)}
            />
            <path
              style={{ cursor: "pointer" }}
              d="M140.12,132.36L88.19,38.51C83.21,29.51 71.4,26.95 63.19,33.15C30.3,57.97 7.32,95.23 1.24,137.94C-0.21,148.15 7.68,157.3 18,157.32L125.26,157.52C138.19,157.54 146.38,143.67 140.12,132.36"
              strokeWidth="1"
              fill={selected === 3 ? Colors.blue : "#FFFFFF"}
              stroke="#968BFF"
              fillRule="evenodd"
              onClick={() => setselected(3)}
            />
            <path
              style={{ cursor: "pointer" }}
              d="M125.39,163.63L17.99,163.43C7.67,163.41 -0.25,172.54 1.16,182.77C7.12,225.71 30.15,263.18 63.18,288.12C71.39,294.32 83.21,291.76 88.19,282.76L140.19,188.79C146.44,177.5 138.29,163.65 125.39,163.63"
              strokeWidth="1"
              fill={selected === 4 ? Colors.blue : "#FFFFFF"}
              stroke="#968BFF"
              fillRule="evenodd"
              onClick={() => setselected(4)}
            />
            <path
              style={{ cursor: "pointer" }}
              d="M178.41,188.92L230.35,282.76C235.33,291.76 247.14,294.32 255.35,288.12C288.24,263.3 311.22,226.04 317.3,183.34C318.75,173.12 310.86,163.97 300.54,163.95L193.28,163.75C180.35,163.73 172.15,177.61 178.41,188.92"
              strokeWidth="1"
              fill={selected === 5 ? Colors.blue : "#FFFFFF"}
              stroke="#968BFF"
              fillRule="evenodd"
              onClick={() => setselected(5)}
            />
            <path
              style={{ cursor: "pointer" }}
              d="M144.04,193.74L93.12,285.74C88.11,294.8 92.34,306.15 102.02,309.84C119.68,316.58 138.84,320.27 158.87,320.27C178.9,320.27 198.06,316.58 215.73,309.84C225.4,306.15 229.64,294.8 224.62,285.74L173.71,193.74C167.25,182.09 150.49,182.09 144.04,193.74"
              strokeWidth="1"
              fill={selected === 6 ? Colors.blue : "#FFFFFF"}
              stroke="#968BFF"
              fillRule="evenodd"
              onClick={() => setselected(6)}
            />
            <path
              style={{ cursor: "pointer" }}
              d="M227.11,160.64C227.11,198.1 196.73,228.48 159.27,228.48C121.8,228.48 91.43,198.1 91.43,160.64C91.43,123.17 121.8,92.8 159.27,92.8C196.73,92.8 227.11,123.17 227.11,160.64"
              strokeWidth="1"
              fill={"#FFFFFF"}
              stroke="#968BFF"
              fillRule="evenodd"
              //   onClick={() => setselected(7)}
            />
            <path
              style={{ cursor: "pointer" }}
              d="M219.33,160.17C219.33,193.4 192.4,220.33 159.17,220.33C125.94,220.33 99,193.4 99,160.17C99,126.94 125.94,100 159.17,100C192.4,100 219.33,126.94 219.33,160.17"
              strokeWidth="1"
              fill={myappointment ? Colors.blue : "#FFFFFF"}
              fillRule="evenodd"
              stroke="#968BFF"
              onClick={() => setmyappointment(true)}
            ></path>
            <path
              style={{ cursor: "pointer" }}
              d="M148.07,133.26l-18.67,0l0,0l18.67,0l0,0"
              strokeWidth="1"
              fill="#242A2B"
              fillRule="evenodd"
              stroke="none"
            />
          </svg>
          <div onClick={() => setselected(1)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1566_166)">
                <path
                  d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                  fill={selected === 1 ? "#FFFFFF" : Colors.blue}
                />
              </g>
              <defs>
                <clipPath id="clip0_1566_166">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span style={{ color: selected === 1 ? "#ffffff" : Colors.blue }}>
              Profile
            </span>
          </div>
          <div>
            <a
              href="http://mytelemd.global/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", fontSize: "12px" }}
              className="d-flex flex-column align-items-center"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <title>svg/health_data_ic</title>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g id="Group" transform="translate(-243.000000, -71.000000)">
                    <g
                      id="Group-3"
                      transform="translate(233.000000, 71.000000)"
                    >
                      <g
                        id="health_data_ic"
                        transform="translate(10.000000, 0.000000)"
                      >
                        <polygon
                          id="Path"
                          points="0 0 24 0 24 24 0 24"
                        ></polygon>
                        <path
                          d="M8,20 L8,14 L16,14 L16,20 L19,20 L19,4 L5,4 L5,20 L8,20 Z M10,20 L14,20 L14,16 L10,16 L10,20 Z M21,20 L23,20 L23,22 L1,22 L1,20 L3,20 L3,3 C3,2.44771525 3.44771525,2 4,2 L20,2 C20.5522847,2 21,2.44771525 21,3 L21,20 Z M11,8 L11,6 L13,6 L13,8 L15,8 L15,10 L13,10 L13,12 L11,12 L11,10 L9,10 L9,8 L11,8 Z"
                          id="Shape"
                          fill={selected === 2 ? "#FFFFFF" : Colors.blue}
                          fillRule="nonzero"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span style={{ color: selected === 2 ? "#ffffff" : Colors.blue }}>
                How to Use
              </span>
              <span style={{ color: selected === 2 ? "#ffffff" : Colors.blue }}>
                {" "}
                App
              </span>
            </a>
          </div>
          <div onClick={() => setselected(4)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewportwidth="24"
              viewportheight="24"
            >
              <path
                d="M3.34,17C2.917,16.269 2.589,15.487 2.362,14.674C3.368,14.162 4.002,13.13 4.002,12.001C4.002,10.873 3.369,9.839 2.364,9.327C2.816,7.692 3.677,6.2 4.865,4.99C5.811,5.605 7.023,5.637 8,5.073C8.978,4.508 9.556,3.443 9.496,2.316C11.138,1.892 12.861,1.892 14.503,2.318C14.444,3.445 15.022,4.51 16,5.074C16.977,5.638 18.189,5.606 19.135,4.99C19.714,5.58 20.228,6.251 20.66,7C21.093,7.749 21.417,8.53 21.638,9.326C20.632,9.838 19.998,10.87 19.998,11.999C19.998,13.127 20.631,14.161 21.636,14.673C21.184,16.308 20.323,17.8 19.135,19.01C18.189,18.395 16.977,18.363 16,18.927C15.022,19.492 14.444,20.557 14.504,21.684C12.862,22.108 11.139,22.108 9.497,21.682C9.556,20.555 8.978,19.49 8,18.926C7.023,18.362 5.811,18.394 4.865,19.01C4.274,18.407 3.762,17.732 3.34,17ZM9,17.196C10.066,17.811 10.867,18.797 11.25,19.966C11.749,20.013 12.25,20.014 12.749,19.967C13.132,18.798 13.934,17.811 15,17.197C16.065,16.581 17.321,16.379 18.525,16.632C18.815,16.224 19.065,15.789 19.273,15.334C18.452,14.417 17.999,13.23 18,12C18,10.74 18.47,9.563 19.273,8.666C19.064,8.211 18.812,7.777 18.523,7.368C17.319,7.62 16.065,7.419 15,6.804C13.934,6.189 13.133,5.203 12.75,4.034C12.251,3.987 11.75,3.986 11.251,4.033C10.868,5.202 10.066,6.189 9,6.803C7.935,7.419 6.679,7.621 5.475,7.368C5.186,7.776 4.935,8.211 4.727,8.666C5.548,9.583 6.001,10.77 6,12C6,13.26 5.53,14.437 4.727,15.334C4.936,15.789 5.188,16.223 5.477,16.632C6.681,16.38 7.935,16.581 9,17.196ZM12,15C10.343,15 9,13.657 9,12C9,10.343 10.343,9 12,9C13.657,9 15,10.343 15,12C15,13.657 13.657,15 12,15ZM12,13C12.552,13 13,12.552 13,12C13,11.448 12.552,11 12,11C11.448,11 11,11.448 11,12C11,12.552 11.448,13 12,13Z"
                strokeWidth="1"
                fill={selected === 4 ? "#FFFFFF" : Colors.blue}
                fillRule="nonZero"
                stroke="#00000000"
              />
            </svg>

            <span style={{ color: selected === 4 ? "#ffffff" : Colors.blue }}>
              Settings
            </span>
          </div>
          <div onClick={() => setselected(3)}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <title>svg/find_md_ic</title>
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Group" transform="translate(-50.000000, -75.000000)">
                  <g id="Group-3" transform="translate(37.000000, 75.000000)">
                    <g
                      id="find_md_ic"
                      transform="translate(13.000000, 0.000000)"
                    >
                      <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M12,15 C16.08,15 19.446,18.054 19.938,22 L4.062,22 C4.554,18.054 7.92,15 12,15 Z M10.187,17.28 C8.753,17.734 7.546,18.713 6.8,20 L12,20 L10.187,17.28 Z M13.814,17.28 L12,20 L17.199,20 C16.454,18.713 15.247,17.734 13.814,17.28 Z M18,2 L18,8 C18,11.314 15.314,14 12,14 C8.686,14 6,11.314 6,8 L6,2 L18,2 Z M8,8 C8,10.21 9.79,12 12,12 C14.21,12 16,10.21 16,8 L8,8 Z M16,4 L8,4 L8,6 L16,6 L16,4 Z"
                        id="Shape"
                        fill={selected === 3 ? "#FFFFFF" : Colors.blue}
                        fillRule="nonzero"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span style={{ color: selected === 3 ? "#ffffff" : Colors.blue }}>
              Find MD
            </span>
          </div>
          <div onClick={() => setselected(5)}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <title>svg/prescription_renewal_ic</title>
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Group" transform="translate(-243.000000, -182.000000)">
                  <g
                    id="prescription_renewal_ic"
                    transform="translate(243.000000, 182.000000)"
                  >
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M19.778,4.222 C22.121,6.565 22.121,10.364 19.778,12.707 L12.708,19.778 C10.364,22.121 6.565,22.121 4.222,19.778 C1.879,17.435 1.879,13.636 4.222,11.293 L11.292,4.222 C13.636,1.879 17.435,1.879 19.778,4.222 Z M14.122,15.535 L8.465,9.878 L5.636,12.708 C4.074,14.269 4.074,16.802 5.636,18.364 C7.198,19.926 9.731,19.926 11.293,18.364 L14.122,15.534 L14.122,15.535 Z M18.364,5.636 C16.802,4.074 14.269,4.074 12.707,5.636 L9.88,8.464 L15.537,14.121 L18.365,11.293 C19.927,9.731 19.927,7.198 18.365,5.636 L18.364,5.636 Z"
                      id="Shape"
                      fill={selected === 5 ? "#FFFFFF" : Colors.blue}
                      fillRule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
            <span style={{ color: selected === 5 ? "#ffffff" : Colors.blue }}>
              Prescription
            </span>
            <span style={{ color: selected === 5 ? "#ffffff" : Colors.blue }}>
              {" "}
              Renewal
            </span>
          </div>
          <div onClick={() => setselected(6)}>
            <GoCreditCard
              className={
                selected === 6
                  ? "Health-main-card-span2"
                  : "Health-main-card-span"
              }
            />
            <span style={{ color: selected === 6 ? "#ffffff" : Colors.blue }}>
              Health Card
            </span>
            <span style={{ color: selected === 6 ? "#ffffff" : Colors.blue }}>
              {" "}
              Payment/Insurance
            </span>
          </div>
          <div onClick={() => setmyappointment(true)}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <title>svg/my_appoinetment_ic</title>
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="Group-9" transform="translate(-146.000000, -32.000000)">
                  <g id="Group-8" transform="translate(107.000000, 32.000000)">
                    <g
                      id="my_appoinetment_ic"
                      transform="translate(39.000000, 0.000000)"
                    >
                      <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M17,3 L21,3 C21.5522847,3 22,3.44771525 22,4 L22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 L2,4 C2,3.44771525 2.44771525,3 3,3 L7,3 L7,1 L9,1 L9,3 L15,3 L15,1 L17,1 L17,3 Z M15,5 L9,5 L9,7 L7,7 L7,5 L4,5 L4,9 L20,9 L20,5 L17,5 L17,7 L15,7 L15,5 Z M20,11 L4,11 L4,19 L20,19 L20,11 Z M6,14 L8,14 L8,16 L6,16 L6,14 Z M10,14 L18,14 L18,16 L10,16 L10,14 Z"
                        id="Shape"
                        fill={myappointment === true ? "#FFFFFF" : Colors.blue}
                        fillRule="nonzero"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span style={{ color: myappointment ? "#ffffff" : Colors.blue }}>
              My Appointment
            </span>
          </div>
        </div>
        {myappointment && (
          <div className="bottombuttons">
            {buttons.map((item, index) => {
              return (
                <button
                  onClick={() => handlenavigate(item, index)}
                  style={{
                    color: activeindex === index ? "#ffffff" : Colors.blue,
                    backgroundColor: activeindex === index ? Colors.blue : null,
                    border: "2px solid #dbd9e9 ",
                  }}
                  key={item.id}
                >
                  {/* <img src={item.icon} alt="" /> */}
                  <span>
                    {activeindex === index ? item.Activeicon : item.icon}
                  </span>
                  {item.name}
                </button>
              );
            })}
          </div>
        )}
      </div>

      {/* add */}
      {/* <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>svg/add_new_card_ic</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="My-Cards" transform="translate(-125.000000, -289.000000)" stroke="#3620FF" strokeWidth="1.6">
            <g id="Group-2" transform="translate(126.000000, 285.000000)">
                <g id="add_new_card_ic" transform="translate(0.000000, 5.000000)">
                    <circle id="Oval" cx="8" cy="8" r="8"></circle>
                    <line x1="8" y1="4.8" x2="8" y2="11.2" id="Path"></line>
                    <line x1="4.8" y1="8" x2="11.2" y2="8" id="Path"></line>
                </g>
            </g>
        </g>
    </g>
</svg> */}
    </>
  );
};

export default Home;
