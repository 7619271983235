import React from "react";
import "./CompletedAppointmentDoctor.css";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Health from "../../Assets/Images/HealthDataImg.avif";
import time from "../../Assets/Images/time_ic@3x.png";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Fetchdata from "../../Constant/Fetchdata";
import { GETCOMPLETEAPPOINTMENTAPI } from "../../Constant/Apiconstant";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
import { SimpleLoader } from "../../Constant/Loader";
const CompletedAppointmentDoctor = () => {
  const navigate = useNavigate();
  const { response, isloading } = Fetchdata(
    GETCOMPLETEAPPOINTMENTAPI,
    {},
    "POST"
  );
  // console.log(response);

  const handleDetail = (itm) => {
    navigate("/RatingCompletedAppointment", {
      state: {
        itm,
      },
    });
  };
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Completed appointment"}
        style={{ color: "white" }}
      />
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <section className="FindMD-main-section">
          {!response?.data ? (
            <h1>{response?.message}</h1>
          ) : (
            <div className="FindMD-main-div">
              {response?.data?.map((itm, index) => {
                const info = itm.patient_id.basicInfo;

                const healthdata = itm.patient_id.healthData;
                const formatedtime = Dateformat(itm.created_at);
                {
                  /* console.log(itm, "info"); */
                }
                return (
                  <div
                    onClick={() => handleDetail(itm)}
                    className="FindMD-img-contain"
                    key={itm._id}
                  >
                    <div>
                      <img className="Cardimg-docter" src={info.image} alt="" />
                      <div
                        className="Awy-div mt-3"
                        style={{
                          width: "max-content",
                          padding: "0 5px",
                          backgroundColor: itm.status
                            ? " rgb(255, 208, 0)"
                            : "green",
                        }}
                      >
                        <span className="Awy-btn-span mt">
                          {itm.appointment_type}
                        </span>
                      </div>
                    </div>
                    <div className="Dorothy-Lucas-sapn-div">
                      <div className="Height-awy-main-div">
                        <div className="Dorothy-extra-div">
                          <span className="Dorothy-sapn">{info.fullName}</span>
                        </div>
                        <div className="">
                          <img className="time-img-css" src={time} alt="" />
                          <span className="Am-pm-span"> {formatedtime}</span>
                        </div>
                        <div className="Height-w-bmi-div mt-2">
                          <div className="Height-Weight-main-div">
                            <span>Height</span>
                            <span className="healthdata-main-height-vealu">
                              {healthdata.height.value}
                              {""} m
                            </span>
                          </div>
                          <div className="Height-Weight-main-div">
                            <span>Weight</span>
                            <span className="healthdata-main-height-vealu">
                              {healthdata.weight.value}
                              {""} kg
                            </span>
                          </div>
                          <div className="Height-Weight-main-div">
                            <span>BMI</span>
                            <span className="healthdata-main-height-vealu">
                              {parseInt(healthdata.BMI).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default CompletedAppointmentDoctor;
