import React, { useContext, useEffect, useState } from "react";
import "./Splash.css";
// import Homrimg from "../Assets/Images/MTDLogin.png";
import Homrimg from "../Assets/Images/HomeImg (1).png";
import LOginLogo from "../Assets/Images/login_logo@3x.png";
// import Yallow from "../Assets/circle.svg";
import Yallow from "../Assets/Images/login_circle@3x.png";
import icon from "../Assets/Images/physician_ic@3x.png";
import icon2 from "../Assets/Images/patient_ic@3x.png";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../Context/GlabalContext";
import { store } from "../Redux/store";
import { KEY_USER_DATA } from "../Redux/Appconstant";
import CorporateLoginPopup from "../Patient/CorporateLoginPopup/CorporateLoginPopup";
import HelthCardPop from "../Patient/HelthCardPop/HelthCardPop";

const Splash = () => {
  const [Popup, setPopup] = useState(false);
  const { setrole } = useContext(GlobalContext);
  const userdata = store.getState().session[KEY_USER_DATA];
  const [showmodel, setshowmodel] = useState(false);
  const navigate = useNavigate();
  const handlenavigate = (item) => {
    setrole(item);
    setTimeout(() => {
      navigate("/Login");
    }, 100);
  };
  useEffect(() => {
    if (userdata?.basicInfo?.role == "patient") {
      navigate("/home-page");
    } else if (userdata?.basicInfo?.role == "physician") {
      navigate("/Home");
    } else if (!userdata?.basicInfo) {
      navigate("/");
    }
  }, []);

  const handlenavscreen = (num) => {
    if (num === 1) {
      setPopup(true);
      setshowmodel(false);
      setrole("physician");
    } else if (num === 2) {
      setPopup(true);
      setrole("patient");
      setshowmodel(false);
    }
  };

  return (
    <>
      <section className="Splash-main-section">
        {/* <img src={Homrimg} alt="" /> */}
        <div className="Splash-main-div">
          <div className="bac-garund-div">
            <img className="Homrimg-Splash-screen" src={Homrimg} alt="" />
            <div className="LOginLogo-main-div">
              <img className="LOgin-Logo" src={LOginLogo} alt="" />
              <span className="Are-you-a-span">Are you a</span>
              <div className="Yallow-main-potitomn">
                <img className="Yallow-color-carcil" src={Yallow} alt="" />
                <div
                  className="circle-icon-user"
                  onClick={() => handlenavigate("physician")}
                >
                  <img className="icon-main-circle" src={icon} alt="" />
                  <span className="Physician-span">Physician</span>
                </div>
                <div
                  className="circle-icon-Patient"
                  onClick={() => handlenavigate("patient")}
                >
                  <img className="icon-main-circle" src={icon2} alt="" />
                  <span className="Physician-span">Patient</span>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setshowmodel(true)}
                  className="Corporate-btn-main"
                >
                  Corporate Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {Popup && (
        <CorporateLoginPopup
          Popup={Popup}
          setPopup={setPopup}
          text={"Doctor Book updated Successfully"}
        />
      )}

      {showmodel && (
        <HelthCardPop
          btn1={"Physician"}
          btn2={"Patient"}
          handleClick={handlenavscreen}
          showmodel={showmodel}
          setshowmodel={setshowmodel}
        />
      )}
    </>
  );
};

export default Splash;
