import React, { useState, useEffect, useContext } from "react";
import "./Notes.css";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import icon1 from "../../Assets/Images/NotesIcon1.png";
import icon2 from "../../Assets/Images/Notesicon2.png";
import icon3 from "../../Assets/Images/Notesicon3.png";
import icon4 from "../../Assets/Images/Notesicon4.png";
import icon5 from "../../Assets/Images/Notesicon5.png";
import { GlobalContext } from "../../Context/GlabalContext";
import ProBono from "../ProBono/ProBono";

const Notes = () => {
  const { setIncompleteNot } = useContext(GlobalContext);
  const { IncompleteNot } = useContext(GlobalContext);
  const [Move, setMove] = useState(false);

  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  const navigate = useNavigate();
  const data = [
    {
      SelectProfileImg: icon1,
      Name: "Clinical Note",
      link: "/ClinicalNote",
    },
    {
      SelectProfileImg: icon2,
      Name: "Prescription",
      link: "/PrescriptionNotes",
    },
    {
      SelectProfileImg: icon3,
      Name: "Referral",
      link: "/ReferralDoctor",
    },
    {
      SelectProfileImg: icon4,
      Name: "Sick Note",
      link: "/SickNote",
    },
    {
      SelectProfileImg: icon5,
      Name: "Investigation",
      link: "/InvestigationDoctor",
    },
  ];

  const route = useLocation();
  const notedata = route?.state?.itm;

  const VideoPathName = route?.state?.VideoPath;
  // console.log(VideoPathName, "VideoPathName");
  const RefralId = route?.state?.DoctorEmailItam;
  const getBackpath = () => {
    return localStorage.getItem("BacPath");
  };

  // Usage
  const Pathnamein = getBackpath();

  // console.log(Pathnamein, "Pathnamein");
  // console.log(RefralId, "RefralId");
  setIncompleteNot(notedata?._id || RefralId?.IdPath);
  const handlenavigate = (item, index) => {
    if (index === 3) {
      setMove(true);
    } else {
      console.error("notedata or notedata._id is undefined");

      const defaultId = notedata;
      setTimeout(() => {
        navigate(item.link, { state: { id: defaultId } });
      }, 300);
    }
  };

  useEffect(() => {
    setEmailGo("");
  }, []);

  const sickid = notedata?._id || IncompleteNot || RefralId?.IdPath;
  // console.log(IncompleteNot, "IncompleteNot");
  // console.log(notedata, "notedata");
  return (
    <>
      <Header
        onClick={() => {
          Pathnamein === "/IncompleteNotes"
            ? navigate("/IncompleteNotes")
            : navigate(-1);
        }}
        text={"Notes"}
      />
      <section className="FindMD-main-section">
        <div className="SelectProfile-main-div">
          <div className="Profileimg-Pratt-div">
            {data.map((itm, index) => {
              return (
                <div
                  onClick={() => handlenavigate(itm, index)}
                  className="NotesIcon1-and-name"
                >
                  <img
                    className="NotesIcon1-main-img"
                    src={itm.SelectProfileImg}
                    alt=""
                  />
                  <span className="Lena-Pratt-span">{itm.Name}</span>
                </div>
              );
            })}
          </div>
        </div>
        {Move && (
          <ProBono
            Move={Move}
            setMove={setMove}
            sickid={sickid}
            text={
              "Doctor's/Sick note is an uninsured service that requires payment from patient .patient has been notified "
            }
          />
        )}
      </section>
    </>
  );
};

export default Notes;
