import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import "./GeneralForm.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import {
  Biochemistry,
  Hematology,
  Immunology,
  Sensitivities,
  SensitivitiesTo,
  SensitivitiesTherd,
  SensitivitiesForth,
  Viral,
  Prostate,
  Vitamin,
} from "../../Constant/DummyData";
import Signature from "../Signature/Signature";
import axios from "../../Constant/ApiUrl";
import { GeneralFormApi, SendtolebPostApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import SendtoLebModel from "../SendtoLebModel/SendtoLebModel";
import Header from "../../Components/Header/Header";

const initialValues = {
  Glucose: "",
  one: "",
  Childage: "",
  Childdays: "",
  Childhours: "",
  Clinician: "",
  patient: "",
  therapeutic: "",
  NameofDrug: "",
  NameofDrugTo: "",
  TimeCollected: "",
  TimeCollectedTo: "",
  TimeofLastDate: "",
  TimeofLastDateTo: "",
  TimeofNextDate: "",
  TimeofNextDateTo: "",
  chlamydiaspecify: "",
  gcspecify: "",
  woundspecify: "",
  otherswabspus: "",
  SpecimenTime: "",
  SpecimenDate: "",
  Totalpsa: false,
  Freepsa: false,
  SendtoPatient: false,
  SendtoLab: false,
};

const GeneralForm = () => {
  const location = useLocation();
  const itm = location?.state?.id;
  // console.log(itm?._id, "route id");
  const [IsotherSensitivities, setIsotherSensitivities] = useState(false);
  const [Isothersource, setIsothersource] = useState(false);
  const [IsothersourceTherd, setIsothersourceTherd] = useState(false);
  const [IsothersourceForth, setIsothersourceForth] = useState(false);
  const [IsBiochemistry, setIsBiochemistry] = useState(Biochemistry);
  const [IsHematology, setIsHematology] = useState(Hematology);
  // console.log(IsHematology, "IsHematology");
  const [IsImmunology, setIsImmunology] = useState(Immunology);
  const [IsSensitivities, setIsSensitivities] = useState(Sensitivities);
  const [IsSensitivitiesTo, setIsSensitivitiesTo] = useState(SensitivitiesTo);
  const [IsSensitivitiesTherd, setIsSensitivitiesTherd] =
    useState(SensitivitiesTherd);
  const [IsSensitivitiesForth, setIsSensitivitiesForth] =
    useState(SensitivitiesForth);
  const [loading, setloading] = useState(false);
  const [IsViral, setIsViral] = useState(Viral);
  const [IsProstate, setIsProstate] = useState(Prostate);
  const { SigUrl } = useContext(GlobalContext);
  // console.log(SigUrl, "SigUrl");
  const [IsVitamin, setIsVitamin] = useState(Vitamin);
  const [lgShow, setLgShow] = useState(false);
  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  // console.log(EmailGo, "EmailGo");
  const [updatedRowObject, setUpdatedRowObject] = useState({
    hematology: { immunology: {} },
  });
  // console.log(updatedRowObject, "updatedRowObject");
  const [Contarst, setContarst] = useState({
    random: false,
    fasting: false,
  });
  // console.log(Contarst, "Contarst");
  const [checkboxdata, setcheckboxdata] = useState([]);
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };
  const navigate = useNavigate();
  const [rowObject, setRowObject] = useState({
    Biochemistrymap: {},
    Sensitivitiesmap: {},
    SensitivitiesTomap: {},
    SensitivitiesTherdmap: {},
    SensitivitiesForthmap: {},
    Viralmap: {},
    Prostatemap: {},
    Vitaminmap: {},
  });
  // console.log(rowObject, "rowObject");
  useEffect(() => {
    const updatedRowObject = { ...rowObject };
    IsBiochemistry.forEach((item) => {
      updatedRowObject.Biochemistrymap[item.backEndName] = item.isSelected;
    });

    IsSensitivities.forEach((item) => {
      updatedRowObject.Sensitivitiesmap[item.backEndName] = item.isSelected;
    });

    IsSensitivitiesTo.forEach((item) => {
      updatedRowObject.SensitivitiesTomap[item.backEndName] = item.isSelected;
    });

    IsSensitivitiesTherd.forEach((item) => {
      updatedRowObject.SensitivitiesTherdmap[item.backEndName] =
        item.isSelected;
    });
    IsSensitivitiesForth.forEach((item) => {
      updatedRowObject.SensitivitiesForthmap[item.backEndName] =
        item.isSelected;
    });

    IsViral.forEach((item) => {
      updatedRowObject.Viralmap[item.backEndName] = item.isSelected;
    });

    IsProstate.forEach((item) => {
      updatedRowObject.Prostatemap[item.backEndName] = item.isSelected;
    });
    IsVitamin.forEach((item) => {
      updatedRowObject.Vitaminmap[item.backEndName] = item.isSelected;
    });

    setRowObject(updatedRowObject);
  }, [
    IsBiochemistry,
    IsSensitivities,
    IsSensitivitiesTo,
    IsSensitivitiesTherd,
    IsSensitivitiesForth,
    IsViral,
    IsProstate,
    IsVitamin,
  ]);

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  const CallID = localStorage.getItem("CallNoteId");
  const header = Headers();

  const LebtextApi = async () => {
    // console.log("hghghghgh...............");
    setloading(true);
    try {
      const res = await axios.post(
        SendtolebPostApi,
        {
          appointmentID: itm?._id || savedRatingId || CallID,
          email: EmailGo,
          formKry: "generalLabForm",
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const onSubmit = async () => {
    setloading(true);
    // console.log("ccccc");
    const data = {
      appointmentId: itm?._id || savedRatingId || CallID,
      generalLabForm: {
        biochemistry: {
          glucose: values.Glucose,
          ...Contarst,
          ...rowObject.Biochemistrymap,
          neonatal_bilirubin_age: values.Childage,
          neonatal_bilirubin_days: values.Childdays,
          neonatal_bilirubin_hours: values.Childhours,
          neonatal_bilirubin_clinician_practitioner_tel_no: values.Clinician,
          neonatal_bilirubin_Patient_24hr_telephone_no: values.patient,
          therapeutic_drug_monitoring: values.therapeutic,
          therapeutic_drug_monitoring_Name_of_drug1: values.NameofDrug,
          therapeutic_drug_monitoring_Name_of_drug2: values.NameofDrugTo,
          therapeutic_drug_monitoring_time_collected_1hr: values.TimeCollected,
          therapeutic_drug_monitoring_time_collected_2hr:
            values.TimeCollectedTo,
          therapeutic_drug_monitoring_time_of_last_dose_1hr:
            values.TimeofLastDate,
          therapeutic_drug_monitoring_time_of_last_dose_2hr:
            values.TimeofLastDateTo,
          therapeutic_drug_monitoring_time_of_next_dose_1hr:
            values.TimeofNextDate,
          therapeutic_drug_monitoring_time_of_next_dose_2hr:
            values.TimeofNextDateTo,
        },
        hematology: {
          ...updatedRowObject.hematology,
          Microbiology_ID_Sensitivities: {
            ...rowObject.Sensitivitiesmap,
            chlamydia_specify_source_text: values.chlamydiaspecify,
            ...rowObject.SensitivitiesTomap,
            gc_specify_source_text: values.gcspecify,
            ...rowObject.SensitivitiesTherdmap,
            wound_specify_source_text: values.woundspecify,
            ...rowObject.SensitivitiesForthmap,
            other_swabs_pus_note: values.otherswabspus,
          },
          specimen_collection: {
            time: values.SpecimenTime,
            date: values.SpecimenDate,
          },
        },
        viral_hepatitis_check_one_only: {
          ...rowObject.Viralmap,
          prostate_specifi_c_antigen_PSA: {
            total_PSA: values.Totalpsa,
            free_PSA: values.Freepsa,
            specify_one_below: {
              ...rowObject.Prostatemap,
            },
          },
          vitamin_D25_hydroxy: {
            ...rowObject.Vitaminmap,
          },
        },
        drSinature_image: SigUrl,
        sendPatient: values.SendtoPatient,
        sendLaboratory: values.SendtoLab,
      },
    };
    // console.log(data);
    try {
      // console.log("run");
      const res = await axios.post(GeneralFormApi, data, header);
      // console.log(res, "jvcccfh");

      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        LebtextApi();
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    const updatedExtremities = { ...updatedRowObject.hematology };

    IsHematology.forEach((item) => {
      updatedExtremities[item.backEndName] = item.isSelected;
    });

    const updatedToes = { ...updatedExtremities.immunology };

    IsImmunology.forEach((item) => {
      updatedToes[item.backEndName] = item.isSelected;
    });

    updatedExtremities.immunology = updatedToes;

    setUpdatedRowObject({
      hematology: updatedExtremities,
    });
  }, [IsHematology, IsImmunology]);

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const hendelElectro = () => {
    setLgShow(false);
  };
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"General form"}
        style={{ color: "white" }}
      />
      <section className="Cardiology-main-sec">
        <form onSubmit={handleSubmit} className="Cardiology-main-div">
          <div className="Reason-main-History-div">
            <div className="w-100">
              <div className="XRAY-main-span-div">
                <span className="ULTRASOUND-spn">Biochemistry</span>
              </div>
              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="Glucose"
                  value={values.Glucose}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="Cardiology-one-span">Glucose</span>
              </div>
              <div className="all-radio-and-span">
                <div className="radio-and-span">
                  <input
                    className="redio-ok-name"
                    name="one"
                    id="1"
                    type="radio"
                    value="Random"
                    onClick={() =>
                      setContarst({ random: true, fasting: false })
                    }
                  />
                  <span className="hors-taventh"> Random </span>
                </div>
                <div className="radio-and-span">
                  <input
                    className="redio-ok-name"
                    name="one"
                    id="1"
                    type="radio"
                    value="Fasting"
                    onClick={() =>
                      setContarst({ fasting: true, random: false })
                    }
                  />
                  <span className="hors-taventh"> Fasting </span>
                </div>
              </div>
              <div className="checkboxparent ">
                {IsBiochemistry.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className="checkdivmain">
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              setIsBiochemistry((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="mt-4">
                <div className="days-main-div">
                  <span className="Reason-main-span">Child's Age:</span>
                  <input
                    placeholder=""
                    className="in-put-days-type-Drug"
                    type="number"
                    name="Childage"
                    value={values.Childage}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="days-main-div">
                  <span className="type-days-span"> days</span>
                  <input
                    placeholder="days"
                    className="in-put-days-type"
                    type="number"
                    name="Childdays"
                    value={values.Childdays}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <span className="type-days-span"> hours</span>
                  <input
                    placeholder="hours"
                    className="in-put-days-type"
                    type="number"
                    name="Childhours"
                    value={values.Childhours}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="Practitioner-main">
                  <span className="type-days-span">
                    {" "}
                    Clinician/Practitioner's tel.no.
                  </span>
                  <input
                    placeholder=""
                    className="in-put-days-type"
                    type="number"
                    name="Clinician"
                    value={values.Clinician}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="Practitioner-main">
                  <span className="type-days-span">
                    {" "}
                    patient's 24 hr telephone no.
                  </span>
                  <input
                    placeholder=""
                    className="in-put-days-type"
                    type="number"
                    name="patient"
                    value={values.patient}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="checkdivmain">
                  <input
                    className="Pregnant-yes-no"
                    type="checkbox"
                    id="vehicle1"
                    name="therapeutic"
                    value={values.therapeutic}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <span className="Cardiology-one-span">
                    therapeutic Drug Monitoring
                  </span>
                </div>
                <div className="Practitioner-main">
                  <span className="type-days-span">Name of Drug #1</span>
                  <input
                    placeholder=""
                    className="in-put-days-type-Drug"
                    type="number"
                    name="NameofDrug"
                    value={values.NameofDrug}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="Practitioner-main">
                  <span className="type-days-span">Name of Drug #2</span>
                  <input
                    placeholder=""
                    className="in-put-days-type-Drug"
                    type="number"
                    name="NameofDrugTo"
                    value={values.NameofDrugTo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="Practitioner-main">
                  <span className="type-days-span">Time Collected</span>
                  <input
                    placeholder="#1hr"
                    className="in-put-days-type-Collected"
                    type="number"
                    name="TimeCollected"
                    value={values.TimeCollected}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <input
                    placeholder="#2hr"
                    className="in-put-days-type-Collected"
                    type="number"
                    name="TimeCollectedTo"
                    value={values.TimeCollectedTo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="Practitioner-main">
                  <span className="type-days-span">Time of Last Date</span>
                  <input
                    placeholder="#1hr"
                    className="in-put-days-type-Last"
                    type="number"
                    name="TimeofLastDate"
                    value={values.TimeofLastDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <input
                    placeholder="#2hr"
                    className="in-put-days-type-Last"
                    type="number"
                    name="TimeofLastDateTo"
                    value={values.TimeofLastDateTo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="Practitioner-main">
                  <span className="type-days-span">Time of Next Date</span>
                  <input
                    placeholder="#1hr"
                    className="in-put-days-type-Last"
                    type="number"
                    name="TimeofNextDate"
                    value={values.TimeofNextDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <input
                    placeholder="#2hr"
                    className="in-put-days-type-Last"
                    type="number"
                    name="TimeofNextDateTo"
                    value={values.TimeofNextDateTo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="w-100">
              <div className="Hematology-main-span-div">
                <span className="ULTRASOUND-spn">Hematology</span>
              </div>
              <div className="checkboxparent ">
                {IsHematology.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className="checkdivmain">
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              setIsHematology((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="Immunology-main-span-div mt-3">
                <span className="ULTRASOUND-spn">Immunology</span>
              </div>
              <div className="checkboxparent ">
                {IsImmunology.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className="checkdivmain">
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              setIsImmunology((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="Immunology-main-span-div mt-3">
                <span className="ULTRASOUND-spn">
                  Mircrobiology ID & Sensitivities (if warranted)
                </span>
              </div>
              {IsSensitivities.map((item, index) => {
                return (
                  <>
                    <div key={index}>
                      <div className="checkdivmain">
                        <input
                          className="Pregnant-yes-no"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          onClick={() => {
                            if (item.id === 190) {
                              setIsotherSensitivities(!IsotherSensitivities);
                            }
                            setIsSensitivities((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={item.isSelected ? true : false}
                          onChange={handlechange}
                        />
                        <span className="chack-in-span">{item.txt}</span>
                      </div>
                    </div>
                  </>
                );
              })}
              <div>
                {IsotherSensitivities ? (
                  <input
                    placeholder=""
                    type="number"
                    className="Date-of-Birth-input-LMNP mt-3"
                    name="chlamydiaspecify"
                    value={values.chlamydiaspecify}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                ) : null}
              </div>

              {IsSensitivitiesTo.map((item, index) => (
                <div key={index}>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        if (item.id === 191) {
                          setIsothersource(!Isothersource); // Corrected function call
                        }
                        setIsSensitivitiesTo((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                </div>
              ))}
              <div>
                {Isothersource ? (
                  <input
                    placeholder=""
                    type="number"
                    className="Date-of-Birth-input-LMNP mt-3"
                    name="gcspecify"
                    value={values.gcspecify}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                ) : null}
              </div>

              {IsSensitivitiesTherd.map((item, index) => (
                <div key={index}>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        if (item.id === 193) {
                          setIsothersourceTherd(!IsothersourceTherd); // Corrected function call
                        }
                        setIsSensitivitiesTherd((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                </div>
              ))}
              <div>
                {IsothersourceTherd ? (
                  <input
                    placeholder=""
                    type="number"
                    className="Date-of-Birth-input-LMNP mt-3"
                    name="woundspecify"
                    value={values.woundspecify}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                ) : null}
              </div>

              {IsSensitivitiesForth.map((item, index) => (
                <div key={index}>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        if (item.id === 197) {
                          setIsothersourceForth(!IsothersourceForth);
                        }
                        setIsSensitivitiesForth((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                </div>
              ))}
              <div>
                {IsothersourceForth ? (
                  <input
                    placeholder=""
                    type="number"
                    className="Date-of-Birth-input-LMNP mt-3"
                    name="otherswabspus"
                    value={values.otherswabspus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                ) : null}
              </div>
              <div className="Immunology-main-span-div mt-3">
                <span className="ULTRASOUND-spn">Specimen collection</span>
              </div>
              <div className="date-time-main-div">
                <div className="Hour-time-main-div mt-4">
                  <span className="chack-in-span">Time</span>
                  <input
                    className="Hour-time-main"
                    type="time"
                    name="SpecimenTime"
                    value={values.SpecimenTime}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <div className="Hour-time-main-div mt-4">
                  <span className="chack-in-span">Date</span>
                  <input
                    className="Hour-time-main"
                    type="date"
                    max="9999-12-31"
                    name="SpecimenDate"
                    value={values.SpecimenDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="Hepatitis-main-span-div">
                <span className="ULTRASOUND-spn">
                  Viral Hepatitis (check one only)
                </span>
              </div>

              <div className="checkboxparent">
                {IsViral.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className={`checkdivmain ${item.cardClass}`}>
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              setIsViral((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="m-2"></div>
                <span className="chack-in-span">
                  or order individual hepatitis tests in the" Other Tests"
                  section below
                </span>

                <div className="Immunology-main-span-div mt-3">
                  <span className="ULTRASOUND-spn">
                    Prostate Specifi c Antigen (PSA)
                  </span>
                </div>
                <div className="PSA-totel-main-div">
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Totalpsa"
                      value={values.Totalpsa}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Total PSA</span>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Freepsa"
                      value={values.Freepsa}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Free PSA</span>
                  </div>
                </div>
                <div className="mt-3">
                  <span className="chack-in-span">Specify one</span>
                </div>

                {IsProstate.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className={`checkdivmain ${item.cardClass}`}>
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              setIsProstate((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="Immunology-main-span-div mt-3">
                <span className="ULTRASOUND-spn">Vitamin D(25-Hydroxy)</span>
              </div>
              {IsVitamin.map((item, index) => {
                return (
                  <>
                    <div key={index}>
                      <div className={`checkdivmain ${item.cardClass}`}>
                        <input
                          className="Pregnant-yes-no"
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          onClick={() => {
                            setIsVitamin((preval) => {
                              let temp = [...preval];
                              let temp2 = temp[index];
                              temp2 = {
                                ...temp2,
                                isSelected: !temp2.isSelected,
                              };
                              temp[index] = temp2;
                              return temp;
                            });
                          }}
                          checked={item.isSelected ? true : false}
                          onChange={handlechange}
                        />
                        <span className="chack-in-span">{item.txt}</span>
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="SendtoPatient"
                  value={values.SendtoPatient}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="Cardiology-one-span">Send to Patient</span>
              </div>
              <div onClick={() => setLgShow(true)} className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="SendtoLab"
                  value={values.SendtoLab}
                  onBlur={handleBlur}
                  checked={values.SendtoLab ? true : false}
                  onChange={(e) => setFieldValue("SendtoLab", e.target.checked)}
                />
                <span className="Cardiology-one-span">Send to Lab</span>
              </div>
              <Signature />
              <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
                Submit
              </Button>
            </div>
          </div>
        </form>
      </section>
      <SendtoLebModel
        onHide={() => {
          setFieldValue("SendtoLab", false);
          setLgShow(false);
        }}
        lgShow={lgShow}
        setLgShow={setLgShow}
        hendelElectro={hendelElectro}
      />

      {loading && <Loader />}
    </>
  );
};

export default GeneralForm;
