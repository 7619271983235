import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import "./Ctscan.css";
import { CTSCANMRI, Following, Pleaseattach } from "../../Constant/DummyData";
import Signature from "../Signature/Signature";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../Constant/ApiUrl";
import { CTscanApi, SendtolebPostApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import SendtoLebModel from "../SendtoLebModel/SendtoLebModel";
import Header from "../../Components/Header/Header";

const initialValues = {
  DateOfLmnp: "",
  one: "",
  beanswered: "",
  Creatinine: "",
  Date: "",
  Contarst: "",
  CtScan: false,
  MRI: false,
  Specify: "",
  Otherarea: "",
  SendtoLab: false,
  SendtoPatient: false,
};

const Ctscan = () => {
  const navigate = useNavigate();
  const [IsotherHistory, setIsotherHistory] = useState(false);
  const [Dispose, setDispose] = useState(false);
  const [IsPregnant, setIsPregnant] = useState("");
  const [Contarst, setContarst] = useState("");
  const [loading, setloading] = useState(false);
  const [isCTSCANMRI, setisCTSCANMRI] = useState(CTSCANMRI);
  const [isFollowing, setisFollowing] = useState(Following);
  const [isPleaseattach, setisPleaseattach] = useState(Pleaseattach);
  const { SigUrl } = useContext(GlobalContext);
  const [lgShow, setLgShow] = useState(false);
  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  // console.log(EmailGo, "EmailGo");

  const location = useLocation();
  const itm = location?.state?.id;
  // console.log(itm?._id, "route id");

  const handleRadioClick = (name) => {
    setContarst(name);
  };

  const handleRadioClick2 = (name) => {
    setIsPregnant(name);
  };

  const [checkboxdata, setcheckboxdata] = useState([]);
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };

  const [rowObject, setRowObject] = useState({
    CTscenmap: {},
    Followingmap: {},
    Pleaseattachgmap: {},
  });
  // console.log(rowObject, "rowObject");
  useEffect(() => {
    const updatedRowObject = { ...rowObject };
    isCTSCANMRI.forEach((item) => {
      updatedRowObject.CTscenmap[item.backEndName] = item.isSelected;
    });
    isFollowing.forEach((item) => {
      updatedRowObject.Followingmap[item.backEndName] = item.isSelected;
    });
    isPleaseattach.forEach((item) => {
      updatedRowObject.Pleaseattachgmap[item.backEndName] = item.isSelected;
    });
    setRowObject(updatedRowObject);
  }, [isCTSCANMRI, isFollowing]);

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  const CallID = localStorage.getItem("CallNoteId");

  // console.log(CallID, "CallID");
  const header = Headers();

  const LebtextApi = async () => {
    // console.log("hghghghgh...............");
    setloading(true);
    try {
      const res = await axios.post(
        SendtolebPostApi,
        {
          appointmentID: itm?._id || savedRatingId || CallID,
          email: EmailGo,
          formKry: "ct_mri_Form",
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const onSubmit = async () => {
    setloading(true);
    // console.log("ccccc");
    try {
      // console.log("run");
      const res = await axios.post(
        CTscanApi,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          ct_mri_Form: {
            pregnant: Contarst,
            significant_cinical_history_clinical_questions_to_be_answered:
              values.beanswered,
            creatinine_level: values.Creatinine,
            date: values.Date,
            date_LMNP: values.DateOfLmnp,
            contarst_allergies: IsPregnant,
            CT_SCAN: values.CtScan,
            MRI: values.MRI,
            // abdomen: false,
            // chest: true,
            // prostate: true,
            // brain_head: true,
            // extremities_specify_location: true,
            extremities_specify_location_text: values.Specify,
            // Neck: true,
            // Pelvis: true,
            // TMJ: true,
            // breast: true,
            // c_spine: true,
            // l_spine: true,
            // t_spine: false,
            // CT_angiograms: true,
            // others_area_to_be_scanned: false,
            ...rowObject.CTscenmap,
            others_area_to_be_scanned_text: values.Otherarea,
            does_the_patient_have_any_of_the_following: rowObject.Followingmap,
            prevoius_revalant_exams: {
              please_attach_a_copy: rowObject.Pleaseattachgmap,
            },
            drSinature_image: SigUrl,
            sendLaboratory: values.SendtoLab,
            sendPatient: values.SendtoPatient,
          },
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        LebtextApi();
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }

      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const hendelElectro = () => {
    setLgShow(false);
  };
  // console.log(values.SendtoLab, "gbeuhcudej");
  // console.log(values.SendtoPatient, "gbeuhcudej");
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"CT Scan"}
        style={{ color: "white" }}
      />
      <section className="Cardiology-main-sec">
        <form onSubmit={handleSubmit} className="Cardiology-main-div mt-5">
          <div className="Reason-main-History-div">
            <div className="Ctscen-main-div">
              <div>
                <span className="Cardiology-one-span">CT Scan/MRI</span>
              </div>

              <div className="mt-3">
                <span className="hors-taventh">Date of LMNP</span>
                <input
                  placeholder="13 May 1995"
                  className="Date-of-Birth-input-LMNP mt-2"
                  type="date"
                  max="9999-12-31"
                  name="DateOfLmnp"
                  value={values.DateOfLmnp}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="mt-4">
                <span className="hors-taventh">Pregnant ?</span>

                <div className="all-radio-and-span">
                  <div className="radio-and-span">
                    <input
                      className="redio-ok-name"
                      name="one"
                      id="1"
                      type="radio"
                      value="yes"
                      onClick={() => handleRadioClick("yes")}
                    />
                    <span className="hors-taventh"> Yes </span>
                  </div>
                  <div className="radio-and-span">
                    <input
                      className="redio-ok-name"
                      name="one"
                      id="1"
                      type="radio"
                      value="no"
                      onClick={() => handleRadioClick("no")}
                    />
                    <span className="hors-taventh"> No </span>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="span-you-div">
                  <span className="hors-taventh">
                    Significatn Clinical History / Clinical questions to
                  </span>
                </div>
                <div className="Be-answered-area-main">
                  <span className="hors-taventh">be answered </span>
                  <textarea
                    id="w3review"
                    name="beanswered"
                    rows="4"
                    className="here-input-be-answered   mt-2"
                    cols="50"
                    placeholder="Write here"
                    value={values.beanswered}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="mt-3">
                <span className="hors-taventh">Creatinine Level</span>
                <input
                  placeholder="Creatinine Level"
                  className="Date-of-Birth-input-LMNP mt-2"
                  type="number"
                  name="Creatinine"
                  value={values.Creatinine}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="mt-3">
                <span className="hors-taventh">Date</span>
                <input
                  placeholder="13 May 1995"
                  className="Date-of-Birth-input-LMNP mt-2"
                  type="date"
                  max="9999-12-31"
                  name="Date"
                  value={values.Date}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="mt-4">
                <span className="hors-taventh">Contarst Allergies</span>

                <div className="all-radio-and-span">
                  <div className="radio-and-span">
                    <input
                      className="redio-ok-name"
                      name="Contarst"
                      id="1"
                      type="radio"
                      value="yes"
                      onClick={() => handleRadioClick2("yes")}
                    />
                    <span className="hors-taventh"> Yes </span>
                  </div>
                  <div className="radio-and-span">
                    <input
                      className="redio-ok-name"
                      name="Contarst"
                      id="1"
                      type="radio"
                      value="no"
                      onClick={() => handleRadioClick2("no")}
                    />
                    <span className="hors-taventh"> No </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="Ctscen-main-div">
              <div className="Ctscen-mri-div">
                <div className="checkdivMRI">
                  <input
                    className="Pregnant-yes-no"
                    type="checkbox"
                    id="vehicle1"
                    name="CtScan"
                    value={values.CtScan}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <span className="chack-in-span">Ct -Scan</span>
                </div>
                <div className="checkdivMRI">
                  <input
                    className="Pregnant-yes-no"
                    type="checkbox"
                    id="vehicle1"
                    name="MRI"
                    value={values.MRI}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <span className="chack-in-span">MRI</span>
                </div>
              </div>
              <div className="Reason-one-div mt-5">
                <div>
                  <span className="Reason-main-span">
                    Reason for test / History
                  </span>
                </div>

                {isCTSCANMRI.map((item, index) => {
                  return (
                    <div className="checkdivmain" key={index}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          if (item.id === 137) {
                            setIsotherHistory(!IsotherHistory);
                          } else if (item.id === 136) {
                            setDispose(!Dispose);
                          }
                          setisCTSCANMRI((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                        checked={item.isSelected ? true : false}
                        onChange={handlechange}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  );
                })}
                <div className="mt-3">
                  {Dispose ? (
                    <input
                      placeholder="Specify Location"
                      type="text"
                      className="Date-of-Birth-input-LMNP"
                      name="Specify"
                      value={values.Specify}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  ) : null}
                </div>
                <div className="mt-3">
                  {IsotherHistory ? (
                    <input
                      className="Date-of-Birth-input-LMNP"
                      placeholder="Other"
                      type="text"
                      name="Otherarea"
                      value={values.Otherarea}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  ) : null}
                </div>
                <div className="m-2"></div>
              </div>
            </div>
            <div className="Ctscen-main-div">
              <div className="contraindication-spn">
                <span className="hors-taventh">
                  Some of these may be contraindication to having an MRI/CT Scan
                </span>
              </div>
              <div>
                <span className="hors-taventh">
                  Does the patient have any of the Following ?
                </span>
              </div>
              <div className="checkboxparent mt-3">
                {isFollowing.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className={`checkdivmain ${item.cardClass}`}>
                          <input
                            className="Pregnant-yes-no"
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            onClick={() => {
                              setisFollowing((preval) => {
                                let temp = [...preval];
                                let temp2 = temp[index];
                                temp2 = {
                                  ...temp2,
                                  isSelected: !temp2.isSelected,
                                };
                                temp[index] = temp2;
                                return temp;
                              });
                            }}
                            checked={item.isSelected ? true : false}
                            onChange={handlechange}
                          />
                          <span className="chack-in-span">{item.txt}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="contraindication-spn">
              <span className="hors-taventh">Prevoius Revalant Exams</span>
            </div>
            <div>
              <span className="">Please attach a copy</span>
            </div>
          </div>
          <div className="checkboxparent mt-3">
            {isPleaseattach.map((item, index) => {
              return (
                <>
                  <div key={index}>
                    <div className={`checkdivmain ${item.cardClass}`}>
                      <input
                        className="Pregnant-yes-no"
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        onClick={() => {
                          setisPleaseattach((preval) => {
                            let temp = [...preval];
                            let temp2 = temp[index];
                            temp2 = {
                              ...temp2,
                              isSelected: !temp2.isSelected,
                            };
                            temp[index] = temp2;
                            return temp;
                          });
                        }}
                      />
                      <span className="chack-in-span">{item.txt}</span>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="checkdivmain">
              <input
                className="Pregnant-yes-no"
                type="checkbox"
                id="vehicle1"
                name="SendtoPatient"
                value={values.SendtoPatient}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Cardiology-one-span">Send to Patient</span>
            </div>
            <div onClick={() => setLgShow(true)} className="checkdivmain">
              <input
                className="Pregnant-yes-no"
                type="checkbox"
                id="vehicle1"
                name="SendtoLab"
                value={values.SendtoLab}
                onBlur={handleBlur}
                checked={values.SendtoLab ? true : false}
                onChange={(e) => setFieldValue("SendtoLab", e.target.checked)}
              />
              <span className="Cardiology-one-span">Send to Lab</span>
            </div>
            <Signature />
            <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
              Submit
            </Button>
          </div>
        </form>
      </section>
      <SendtoLebModel
        onHide={() => {
          setFieldValue("SendtoLab", false);
          setLgShow(false);
        }}
        lgShow={lgShow}
        setLgShow={setLgShow}
        hendelElectro={hendelElectro}
      />
      {loading && <Loader />}

      {loading && <Loader />}
    </>
  );
};

export default Ctscan;
