import React from "react";
import "./DoctorProfile.css";
// import Doctorimg from "../../Assets/Images/DoctorProfile.avif";
import sado from "../../Assets/Images/sado.png";
import time from "../../Assets/Images/time_ic@3x.png";
import locationimg from "../../Assets/Images/location_ic_sm@3x.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Icons } from "../../Constant/Icons";
const DoctorProfile = () => {
  const route = useLocation();
  const maindata = route?.state?.item;
  // console.log(maindata);
  const navigate = useNavigate();

  const handleclick = () => {
    navigate("/SelectProfile", {
      state: {
        maindata,
      },
    });
  };
  return (
    <>
      <section className="PrescriptionDetail-main-section">
        <div className="PrescriptionDetail-ma-div">
          <div className="main-po">
            <span
              style={{
                position: "absolute",
                left: "1rem",
                top: "1rem",
                zIndex: "100",
                padding: "10px 10px",
                borderRadius: "10px",
                backgroundColor: "grey",
              }}
              onClick={() => navigate(-1)}
            >
              {Icons?.backarrowwhite}
            </span>
            <img
              className="Doctorimg-main-profile"
              src={maindata?.basicInfo?.image}
              alt=""
            />
            <div className="kghjklk-main-po">
              <div>
                <img className="Doctorimg-main-sado" src={sado} alt="" />
              </div>
              <div className="Daniel-Norman-main">
                <span className="dr-main-name">
                  {maindata?.basicInfo?.fullName}
                </span>
                <div className="General-Medicine-span mt-3">
                  <span className="dr-main-name-General">General Medicine</span>
                  <div className="point-4-span-div">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 50 49"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_26_1451)">
                        <path
                          d="M24.5647 34.7084L12.5638 42.0379L15.8264 28.3588L5.14844 19.2121L19.1645 18.0892L24.5647 5.10419L29.9649 18.0892L43.983 19.2121L33.303 28.3588L36.5656 42.0379L24.5647 34.7084Z"
                          fill="#E4AE2F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_26_1451">
                          <rect
                            width="49"
                            height="49"
                            fill="white"
                            transform="translate(0.0625)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="font-3-2-2">
                      {maindata?.rating_details?.average_rating}.0
                    </span>
                    <span className="font-3-2-2-dot mr-5"></span>
                    <div>
                      <span className="font-3-2-2">
                        {maindata?.rating_details?.review_total_count}
                      </span>
                      <span className="dr-main-name-General px-1">Reviews</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="time-am-pam-main-div">
              <div className="time-am-pam-main-div-second">
                <img className="time-line-img" src={time} alt="" />
                {maindata?.consultationSchedule?.sessions?.length > 0 && (
                  <div>
                    <span className="Am-pm-span px-2">
                      {maindata?.consultationSchedule?.sessions[0]?.slotTime}
                    </span>
                    {maindata?.consultationSchedule?.sessions?.length > 1 && (
                      <span className="Am-pm-span">
                        {
                          maindata?.consultationSchedule?.sessions?.slice(-1)[0]
                            .slotTime
                        }
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="Toronto-main-line mt-3"></div>
            <div className="time-am-pam-main-div">
              <div className="time-am-pam-main-div-second">
                <img className="time-line-img" src={locationimg} alt="" />
                <span>{maindata?.basicInfo?.location}</span>
              </div>
            </div>
            <div className="Toronto-main-line mt-3"></div>
            <div className="time-am-pam-main-div">
              <div className="time-am-pam-main-div-About">
                <span className="Years-of-one-span"> About</span>
                <span className="consectetur-main-div">----------</span>

                <div className="Qualifications-main-span-div">
                  <span className="Years-of-one-span">Qualifications</span>
                  <span>{maindata?.qualification}</span>
                </div>
                <div className="Qualifications-main-span-div mb-5">
                  <span className="Years-of-one-span">Years of practice</span>
                  <span>
                    {maindata?.totalExperience}
                    {""} Years
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-center w-100">
                  <button
                    className="Book-DoctorProfile-btn"
                    onClick={handleclick}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorProfile;
