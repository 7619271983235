import React from "react";
import "./Input.css";

const Input = ({
  type,
  onChange,
  topicon,
  placeholder,
  style,
  name,
  bottomicon,
  onBlur,
  className,
  inputStyle,
  bottomiconClick,
  value,
  min,
  onFocus,
  required,
  topimg,
}) => {
  return (
    <>
      <div className="input-div" style={style}>
        {topimg ? <img src={topimg} alt="" /> : null}
        {topicon ? <span>{topicon}</span> : null}
        <input
          className={className}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          style={inputStyle}
          value={value}
          min={min}
          required={required}
        />
        <span onClick={bottomiconClick}>{bottomicon}</span>
      </div>
    </>
  );
};

export default Input;
