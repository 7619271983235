import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import { ADDHEALTHCARD, FAMILYMEMBERLIST } from "../../Constant/Apiconstant";
import Input from "../../Components/Input/Input";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { useFormik } from "formik";
import axios from "../../Constant/ApiUrl";
import Loader from "../../Constant/Loader";
import { toast } from "react-toastify";
import { AddhealthcardSchemas } from "../../Schemas";

const AddhealthCard = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const { response } = Fetchdata(FAMILYMEMBERLIST, {}, "GET");

  // console.log(response);
  const initialValues = {
    memberId: "",
    healthCardNumber: "",
    versionCode: "",
    relationShip: "self",
    cardHolderName: "",
  };

  const header = Headers();

  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        ADDHEALTHCARD,
        {
          memberId: values.memberId,
          healthCardNumber: values.healthCardNumber,
          versionCode: values.versionCode,
          relationShip: response?.data?.filter(
            (item) => item._id === values.memberId
          )[0]?.relationship,
          cardHolderName: values.cardHolderName,
        },
        header
      );
      if (res.data.status == "failed") {
        toast.error(res.data.message);
      } else if (res.status == 200 && res.data.status == "success") {
        toast.success(res.data.message);
      }
      // console.log(res);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
    useFormik({
      onSubmit,
      initialValues: initialValues,
      validationSchema: AddhealthcardSchemas,
    });
  return (
    <>
      <Header onClick={() => navigate(-1)} />
      <form onSubmit={handleSubmit} className="addhealthcardcontainer">
        <h1>Health Card Information</h1>
        <div className="fieldvalue">
          <span>Member</span>
          <select
            name="memberId"
            id=""
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="" selected disabled>
              Select
            </option>
            {response?.data?.map((item) => {
              return (
                <option key={item._id} value={item._id}>
                  {item.basicInfo.fullName}
                </option>
              );
            })}
          </select>
          {errors.memberId && touched.memberId ? (
            <p className="mastu-to-eroor">{errors.memberId}</p>
          ) : null}
        </div>
        <div className="fieldvalue">
          <span>Card Holder Name</span>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            name={"cardHolderName"}
            style={{ width: "100%" }}
            placeholder={"Enter Card Holder Name"}
          />
          {errors.cardHolderName && touched.cardHolderName ? (
            <p className="mastu-to-eroor">{errors.cardHolderName}</p>
          ) : null}
        </div>
        <div className="d-flex w-100 gap-3">
          <div className="fieldvalue">
            <span>Card Number</span>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              name={"healthCardNumber"}
              style={{ width: "100%" }}
              placeholder={"Card  Number"}
            />
            {errors.healthCardNumber && touched.healthCardNumber ? (
              <p className="mastu-to-eroor">{errors.healthCardNumber}</p>
            ) : null}
          </div>
          <div className="fieldvalue">
            <span>Version Code</span>
            <Input
              onChange={handleChange}
              name={"versionCode"}
              style={{ width: "100%" }}
              placeholder={"Version Code"}
            />
            {errors.versionCode && touched.versionCode ? (
              <p className="mastu-to-eroor">{errors.versionCode}</p>
            ) : null}
          </div>
        </div>
        <Buttton
          type={"submit"}
          style={{ backgroundColor: Colors.blue, alignSelf: "center" }}
        >
          Submit
        </Buttton>
      </form>
      {loading && <Loader />}
    </>
  );
};

export default AddhealthCard;
