import React from "react";
import Fetchdata from "../../Constant/Fetchdata";
import { CMSABOUTUSAPI } from "../../Constant/Apiconstant";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../Constant/Loader";

const AboutUs = () => {
  const { response, isloading } = Fetchdata(CMSABOUTUSAPI, {}, "GET");
  const navigate = useNavigate();
  //   console.log(response);
  return (
    <>
      <Header text={"About us"} onClick={() => navigate(-1)} />
      <div className="p-3">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <p
            style={{ fontSize: "20px", color: "#000000" }}
            dangerouslySetInnerHTML={{ __html: response?.data?.text }}
          ></p>
        )}
      </div>
    </>
  );
};

export default AboutUs;
