/* eslint-disable import/no-anonymous-default-export */
import { Set_Session_Field } from "./SessionAction";

const initial_state = {};

export default (state = initial_state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case Set_Session_Field:
      return { ...state, [action.payload.key]: action.payload.value };
  }
  return state;
};
