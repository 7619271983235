import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../../Context/GlabalContext";
import { AddfamilyMemberSchemas } from "../../Schemas";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../Constant/Icons";
import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import Input from "../../Components/Input/Input";
import { FamilyMemberlist } from "../../Constant/DummyData";
import "./FamilyMember.css";
// import HealthDataInformation from "../../Auth/HealthDataInformation/HealthDataInformation";
import Familymemberheathdata from "./Familymemberheathdata";
import { toast } from "react-toastify";

const AddfamilyMember = () => {
  const [Image, setImage] = useState(null);
  const { steps, setsteps, setregiaterdata } = useContext(GlobalContext);
  const [selectedgender, setselectedgender] = useState("");

  const initialValues = {
    FullName: "",
    LastName: "",
    image: "",
    Age: "",
    gender: "",
    familymember: "",
  };
  const calculateage = (dob) => {
    let today = new Date();
    let birthdatobj = new Date(dob);
    let age = today.getFullYear() - birthdatobj.getFullYear();
    const month = today.getMonth() - birthdatobj.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthdatobj.getDate())) {
      age--;
    }
    return age;
  };

  const onSubmit = () => {
    let age = calculateage(values.Age);
    if (age < 18) {
      toast.error("Age must be 18 +");
    } else if (age > 65) {
      toast.error("Age must be less then 65");
    } else {
      setregiaterdata({
        fullname: values.FullName,
        lastName: values.LastName,
        image: values.image,
        age: values.Age,
        gender: values.gender,
        relationship: values.familymember,
      });
      setsteps(2);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AddfamilyMemberSchemas,
    onSubmit,
  });

  // console.log(values);
  const navigate = useNavigate();
  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        {steps == 1 ? (
          <form
            onSubmit={handleSubmit}
            className="All-Containor-perfect-second-divv"
          >
            <div className="Profile-extra-div">
              <div className="Profile-main-Your">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  {Icons.backarrowblack}
                </span>
                <h6 className="mt-2 ">Step {steps} to 2</h6>
                <span className="Order-history-span">Profile data</span>
                <span className="Upload-profile">
                  Upload your profile to get better consultants from doctor.
                </span>
              </div>
            </div>
            <div className="second-div mt-4">
              <div className="d-flex flex-column align-items-center z-2">
                <div className="Ht-ml-for-imput align-self-center">
                  <label className="" htmlFor="mal">
                    <img src={profilePic} alt="" className="pic-on-add" />
                    <div className="SandIcon-addeting-div">
                      <img className="Cemara-te-img" src={SandIcon} alt="" />
                    </div>
                  </label>
                  <input
                    id="mal"
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setFieldValue("image", e.target.files[0]);
                    }}
                    className="Malte-pal-cls"
                  />
                  <img
                    src={Image ? URL.createObjectURL(Image) : null}
                    alt=""
                    width="100px"
                    height="100px"
                    className="add-kiya-muja"
                  />
                </div>
                <br />
                {/* {errors.image && touched.image ? (
                  <p className="mastu-to-eroor mt-2 ">{errors.image} </p>
                ) : null} */}
                <div className="mt-5 all-input-areya">
                  <div className="Main-full-name-and-last">
                    <div className="">
                      <span className="">Full name</span>
                      <div className="full-name-bug-div">
                        <div className="FullName-cover-fullname mt-2">
                          <span>{Icons.user}</span>
                          <input
                            className="i-t-a-host-itm"
                            type="text"
                            placeholder="Full name"
                            name="FullName"
                            value={values.FullName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {errors.FullName && touched.FullName ? (
                        <p className="FullName-to-eroor mt-2">
                          {errors.FullName}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <span className="">Last name</span>
                      <div className="full-name-bug-div">
                        <div className="FullName-cover-fullname  mt-2">
                          <span>{Icons.user}</span>
                          <input
                            className="i-t-a-host-itm"
                            type="text"
                            placeholder="Last name"
                            name="LastName"
                            value={values.LastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {errors.LastName && touched.LastName ? (
                        <p className="FullName-to-eroor mt-2">
                          {errors.LastName}{" "}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="handleBlur-green mt-4">
                  <span>Age</span>
                  <input
                    placeholder="13 May 1995"
                    className="Age-of-Birth-input-bg"
                    type="date"
                    max="9999-12-31"
                    name="Age"
                    value={values.Age}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{
                      backgroundColor: "#ffffff",
                      width: "420px",
                    }}
                  />
                  {errors.Age && touched.Age ? (
                    <p className="mastu-to-eroor">{errors.Age} </p>
                  ) : null}
                </div>
                <div className="Gender-Male-Female-div mt-4">
                  {/* <span>Gender</span> */}
                  <div className="d-flex align-items-start gap-2 w-100 mt-1  inline-and-male-f">
                    <button
                      className={
                        selectedgender == "male"
                          ? "Female-btn-mainactive-Addfemaliy"
                          : "Female-btn-main-Addfemaliy"
                      }
                      type="button"
                      onClick={() => {
                        setFieldValue("gender", "male");
                        setselectedgender("male");
                      }}
                    >
                      Male
                    </button>
                    <button
                      className={
                        selectedgender == "female"
                          ? "Female-btn-mainactive-Addfemaliy"
                          : "Female-btn-main-Addfemaliy"
                      }
                      type="button"
                      onClick={() => {
                        setFieldValue("gender", "female");
                        setselectedgender("female");
                      }}
                    >
                      Female
                    </button>
                  </div>
                  {errors.gender && touched.gender ? (
                    <p className="mastu-to-eroor">{errors.gender}</p>
                  ) : null}
                </div>
                <select
                  className="member-select-option-inl mt-4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="familymember"
                >
                  <option value="" selected disabled>
                    Select
                  </option>
                  {FamilyMemberlist.map((item) => {
                    return (
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errors.familymember && touched.familymember ? (
                  <p className="mastu-to-eroor">{errors.familymember}</p>
                ) : null}
                <div className="mt-4 mb-5">
                  <button type="submit" className="Btn-Register-karo">
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div>
              <img className="YourProfile-next-blue" src={Blue} alt="" />
            </div>
          </form>
        ) : steps == 2 ? (
          <Familymemberheathdata />
        ) : steps == 3 ? (
          {
            /* <OtherInformation /> */
          }
        ) : null}
      </div>
    </>
  );
};

export default AddfamilyMember;
