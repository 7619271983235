import React, { useContext, useState } from "react";
import "./SickNote.css";
import Header from "../../Components/Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import Signature from "../Signature/Signature";
import { useFormik } from "formik";
import { SickNoteApi } from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
import { Headers } from "../../Constant/Fetchdata";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";

const initialValues = {
  Patient: false,
  From: "",
  To: "",
  Patienthasbeen: false,
  EnterDays: "",
  reassessment: false,
  EnterCode: "",
  Dose: "",
  physiotherapy: false,
  advised: false,
  chiropractor: false,
  Others: false,
  Note: "",
};
const SickNote = () => {
  const [loading, setloading] = useState(false);
  const { SigUrl } = useContext(GlobalContext);
  // console.log(SigUrl, "SigUrl");
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.sickid;
  // console.log(id);
  const selectedValues = location.state;
  // console.log(selectedValues);
  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  const itm = selectedValues?.sickid;
  const CallID = localStorage.getItem("CallNoteId");
  // console.log(itm, "itm");
  const header = Headers();
  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SickNoteApi,
        {
          appointmentId: selectedValues?.sickid || savedRatingId || CallID,
          sickNote: {
            physiotherapy_therapy: values.physiotherapy,
            other: values.Others,
            being_sick_from: values.From,
            followUp_reassessment: values.reassessment,
            massage_therapy: values.advised,
            proBono: selectedValues.proBono == 0 ? true : false,
            being_sick: values.Patient,
            followUp_reassessment_days_value: values.EnterCode,
            being_sick_to: values.To,
            drSinature_image: SigUrl,
            chairopractor_therapy: values.chiropractor,
            bedrest_offwork_Day: values.EnterDays,
            note: values.Note,
            bedrest_offwork: values.Patienthasbeen,
            followUp_reassessment_days: values.Dose,
          },
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }

      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const { values, handleBlur, touched, handleChange, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Sick note"}
        style={{ color: "white" }}
      />
      <section className="Prescription-main-section">
        <div className="Prescription-main-div">
          <div className="SickNote-main-thard-div">
            <div className="main-input-chek-being">
              <input
                className="main-input-chek"
                type="checkbox"
                name="Patient"
                value={values.Patient}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Organization-sapn">
                Patient report’s being sick
              </span>
            </div>
            <div className="date-main-input-he">
              <div className="input-date-main-div mt-3">
                <span className="Organization-sapn">From</span>
                <input
                  placeholder="Select Date"
                  className="Date-From-Birth-input"
                  type="date"
                  max="9999-12-31"
                  name="From"
                  value={values.From}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="input-date-main-div mt-3">
                <span className="Organization-sapn">To</span>
                <input
                  placeholder="Select Date"
                  className="Date-From-Birth-input"
                  type="date"
                  max="9999-12-31"
                  name="To"
                  value={values.To}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="main-input-chek-being">
              <input
                className="main-input-chek"
                type="checkbox"
                name="Patienthasbeen"
                value={values.Patienthasbeen}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Organization-sapn">
                Patient has been advise on bedrest / off work
              </span>
            </div>
            <div className="BMI-MAin-input-div mt-3">
              <div className="BMI-MAin-input mt-2">
                <input
                  className="Bim-Md-main-input-css"
                  type="text"
                  placeholder="Enter Days"
                  name="EnterDays"
                  value={values.EnterDays}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="main-input-chek-being-reassessment">
              <input
                className="main-input-chek"
                type="checkbox"
                name="reassessment"
                value={values.reassessment}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Organization-sapn">
                Patient has been advised to follow up for reassessment
              </span>
            </div>
            <div className="Enter-Days-main-div">
              <div className="BMI-MAin-input-div">
                <div className="BMI-Enter-MAin-input mt-2">
                  <input
                    className="Bim-Enter-main-input-css"
                    type="number"
                    placeholder="Enter Code"
                    name="EnterCode"
                    value={values.EnterCode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* <div className="Unit-kg-selact">
                <select
                  className="Enter-Dose-Name-value-main mt-2"
                  name="Dose"
                  id="Dose"
                  value={values.Dose}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="Days">Days</option>
                  <option value="Month">Month</option>
                  <option value="Years">Years</option>
                </select>
              </div> */}
            </div>
            <div className="main-input-chek-being-reassessment">
              <input
                className="main-input-chek"
                type="checkbox"
                name="physiotherapy"
                value={values.physiotherapy}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Organization-sapn">
                Patient has been advised on physiotherapy therapy
              </span>
            </div>
            <div className="main-input-chek-being-reassessment mt-3">
              <input
                className="main-input-chek"
                type="checkbox"
                name="advised"
                value={values.advised}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Organization-sapn">
                Patient has been advised on massage therapy
              </span>
            </div>
            <div className="main-input-chek-being-reassessment mt-3">
              <input
                className="main-input-chek"
                type="checkbox"
                name="chiropractor"
                value={values.chiropractor}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Organization-sapn">
                Patient has been advised on chiropractor therapy
              </span>
            </div>
            <div className="main-input-chek-being-reassessment mt-4">
              <input
                className="main-input-chek"
                type="checkbox"
                name="Others"
                value={values.Others}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <span className="Organization-sapn">Others</span>
            </div>

            <div className="span-Subjective-div">
              <span className="Organization-sapn">Note</span>
            </div>
            <textarea
              id="w3review"
              rows="4"
              className="Sick-notes-input-2  mt-2"
              cols="50"
              placeholder="Enter Note"
              name="Note"
              value={values.Note}
              onBlur={handleBlur}
              onChange={handleChange}
            ></textarea>
            <div>
              <Signature />
            </div>
            <div className="mt-4">
              <button
                onClick={onSubmit}
                type="submit"
                className="Btn-Sick-notes-karo "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
      {loading && <Loader />}
    </>
  );
};

export default SickNote;
