import React, { useState, useEffect, useContext } from "react";
import "./ProfessionalDoctor.css";
import { useFormik } from "formik";
import { Doctorregisterschemas } from "../../Schemas/index";
// import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
// import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import { GlobalContext } from "../../Context/GlabalContext";
// import Location from "../../Assets/Images/location_ic@3x.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "../../Constant/ApiUrl";
import { DOCTORREGISTER, MASTERDATAAPI } from "../../Constant/Apiconstant";
import { Icons } from "../../Constant/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Colors } from "../../Constant/Colors";
import Loader from "../../Constant/Loader";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import { setSessionField } from "../../Redux/SessionAction";
import { store } from "../../Redux/store";

const ProfessionalDoctor = () => {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const { steps, setsteps, DoctorRegisterdata } = useContext(GlobalContext);
  // console.log(DoctorRegisterdata, "DoctorRegisterdata");
  const [masterdata, setmasterdata] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [LetCountry, setLetCountry] = useState("");
  // console.log(country, "country");
  const [timeZone, setTimeZone] = useState("");
  // console.log(timeZone, "timeZone");
  const Device_Token = localStorage.getItem("FCM");
  const [postalcode, setpostalcode] = useState("");
  const [loading, setloading] = useState(false);

  const getuniqueid = () => {
    return Math.random().toString(36).substring(2, 10);
  };
  const [licencedata, setlicencedata] = useState([
    {
      country: "",
      id: getuniqueid(),
      licenceAuthority: "",
      licensingNumber: "",
    },
  ]);
  // console.log(licencedata, "licencedata");
  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };
  const handleAddressBlur = () => {
    handleBlur("Address");
  };

  const initialValues = {
    country: LetCountry,
    speciality: "",
    firstName: DoctorRegisterdata.FullName,
    lastName: DoctorRegisterdata.LastName,
    email: DoctorRegisterdata.email,
    role: "physician",
    password: DoctorRegisterdata.password,
    gender: DoctorRegisterdata.gender,
    age: DoctorRegisterdata.age,
    dob: DoctorRegisterdata.date,
    faxNumber: DoctorRegisterdata.FaxNumber,
    phoneNumber: DoctorRegisterdata.Mobile,
    location: "",
    address: "",
    city: "",
    postalCode: "",
    state: "",
    lat: "",
    lng: "",
    image: DoctorRegisterdata.image,
    qualification: "",
    totalExperience: "",
    deviceType: "web",
    deviceToken: Device_Token,
    // licence: "",
    userTimeZone: "",
  };
  // console.log(
  //   "🚀 ~ ProfessionalDoctor ~ initialValues.register:",
  //   initialValues.userTimeZone
  // );

  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    for (const key of Object.keys(initialValues)) {
      if (!values.licence) {
        formdata.append(key, values[key]);
      }
    }
    formdata.append("licence", JSON.stringify(licencedata));
    // for (let [key, value] of formdata.entries()) {
    //   console.log(key, value, "hdhdhdhdhdhs");
    // }
    try {
      const res = await axios.post(DOCTORREGISTER, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(res, "sl");
      if (res.data.status == "success" && res.status == 200) {
        toast.success("register successful");
        store.dispatch(setSessionField(KEY_USER_DATA, res.data.data));
        navigate("/Otp");
        setsteps(1);
      } else if (res.data.status == "failed") {
        toast.error(res.data.message);
      }
      setloading(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Doctorregisterschemas,
    onSubmit,
  });

  // console.log(values.Speciality, "Speciality");
  // console.log(values, "values.country");

  const handleSelect = async (selectedAddress) => {
    setFieldValue("address", selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(results[0]);
      setFieldValue("lat", lat);
      setFieldValue("lng", lng);
      setAddress(selectedAddress);
      setFieldValue("location", selectedAddress);
      setFieldValue("country");
      // console.log(selectedAddress);
      const addressComponents = results[0].address_components;
      const cityComponent = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );
      const postalCodeComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );
      setCity(cityComponent?.long_name || "");
      setState(stateComponent?.long_name || "");
      setLetCountry(countryComponent?.long_name || "");
      setpostalcode(postalCodeComponent?.long_name || "");
      handleChange({
        target: {
          name: "City",
          value: cityComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "State",
          value: stateComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "country",
          value: countryComponent?.long_name || "",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(state);
  const Speciality = async () => {
    try {
      const res = await axios.get(MASTERDATAAPI, { hospital_id: "" });
      // console.log(res);
      if (res.status == 200 && res.data.status == "success") {
        setmasterdata(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // setFieldValue("country", country);
    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("postalCode", postalcode);
  }, [city, state, postalcode, LetCountry]);

  useEffect(() => {
    Speciality();
  }, [steps]);

  const handleadd = () => {
    setlicencedata((prev) => [
      ...prev,
      {
        id: getuniqueid(),
        country: "",
        licenceAuthority: "",
        licensingNumber: "",
      },
    ]);
  };

  const handledelete = (item) => {
    // console.log(item);
    let newdata = licencedata.filter((element) => element.id !== item.id);
    // console.log(newdata);
    setlicencedata(newdata);
  };

  const handleFieldChange = (index, fieldName, value) => {
    setlicencedata((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
      )
    );
  };
  const handleFieldChange2 = (index, fieldName, value) => {
    setlicencedata((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
      )
    );
  };
  const handleFieldChange3 = (index, fieldName, value) => {
    setlicencedata((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
      )
    );
  };

  useEffect(() => {
    const getUserTimeZone = () => {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneMapping = {
          "Asia/Calcutta": "Asia/Kolkata",
          // Add more mappings as needed
        };
        const formattedTimeZone = timeZoneMapping[timeZone] || timeZone;
        setTimeZone(formattedTimeZone);
        setTimeZone(timeZone);
        setFieldValue("userTimeZone", formattedTimeZone);
      } catch (error) {
        console.error("Error retrieving time zone:", error);
      }
    };
    getUserTimeZone();
  }, []);

  // country

  return (
    <>
      <div className="bug-solve-and-last">
        <div className="Profile-extra-div">
          <div className="Profile-main-Your">
            <span onClick={() => setsteps(2)}>{Icons.backarrowblack}</span>
            <h6 className="mt-2">Step {steps} to 3</h6>
            <span className="Order-history-span">Professional Information</span>
            <span className="Upload-profile">
              Enter your professional information below to create your profile
              profile.
            </span>
          </div>
        </div>
        <div className="">
          <div className="">
            <form onSubmit={handleSubmit} className="second-div mt-4">
              <div className="Health-input-div-main">
                <div className="hoooo-so-hoo mt-5">
                  <div>
                    <div className="Yes-and-No-name">
                      <div className="Unit-kg-selact">
                        <span className="">Speciality</span>
                        <select
                          className="Volvo-Speciality-value mt-2"
                          name="speciality"
                          id="speciality"
                          value={values.speciality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="" disabled selected>
                            Select Speciality
                          </option>
                          {masterdata?.specialities?.map((speciality) => {
                            return (
                              <option value={speciality.name}>
                                {speciality.specialityName}
                              </option>
                            );
                          })}
                        </select>
                        {errors.speciality && touched.speciality ? (
                          <p className="bmi-Weight mt-2">{errors.speciality}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="Unit-kg-selact mt-3">
                      <span className="">Qualification</span>
                      <select
                        className="Volvo-Speciality-value w-100 mt-2"
                        name="qualification"
                        id="Qualification"
                        value={values.qualification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled selected>
                          Select qualification
                        </option>
                        {masterdata?.qualification?.map((qualification) => {
                          return (
                            <option value={qualification.name}>
                              {qualification.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.qualification && touched.qualification ? (
                        <p className="bmi-Weight mt-2">
                          {errors.qualification}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="Unit-kg-selact w mt-3">
                      <span className="">Total experience</span>
                      <select
                        className="Volvo-Speciality-value mt-2"
                        name="totalExperience"
                        id="Qualification"
                        value={values.totalExperience}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled selected>
                          Enter Total Experience
                        </option>
                        {Array.from({ length: 50 }).map((element, index) => {
                          return <option value={index + 1}>{index + 1}</option>;
                        })}
                      </select>
                      {errors.totalExperience && touched.totalExperience ? (
                        <p className="bmi-Weight mt-2">
                          {errors.totalExperience}{" "}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <h4 className="mt-3">Current practice address</h4>
                    <div className=" mt-3">
                      <span>Location (Street Address)</span>
                      <div className="mt-2 Information-Street-main-div">
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleAddressChange}
                          onSelect={handleSelect}
                          onBlur={handleAddressBlur}
                          name="Address"
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="d-flex flex-column">
                              <input
                                className="mene-change-kiya-hai"
                                {...getInputProps({
                                  placeholder: "Enter Location",
                                })}
                              />
                              <div style={{ zIndex: 1000 }}>
                                {loading ? <div>Loading...</div> : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                  };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      {errors.location && touched.location ? (
                        <p className="bmi-Weight mt-2">{errors.location} </p>
                      ) : null}
                    </div>
                    <div className="BMI-MAin-input-div mt-4">
                      <span className="">
                        Address (Ex. Nuilding, Floor, Room, Cabin)
                      </span>
                      <div className="BMI-MAin-input mt-2">
                        <input
                          className="Bim-Md-main-input-css"
                          type="text"
                          placeholder="Enter Address"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="Unit-kg-selact mt-3">
                      <span className="">City</span>
                      <select
                        className="Volvo-Speciality-value mt-2"
                        name="city"
                        id="City"
                        value={city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value={city}>{city}</option>
                      </select>
                      {errors.city && touched.city ? (
                        <p className="bmi-Weight mt-2">{errors.city} </p>
                      ) : null}
                    </div>

                    <div className="BMI-MAin-input-div mt-4">
                      <span className="">Postal code</span>
                      <div className="BMI-MAin-input mt-2">
                        <input
                          className="Bim-Md-main-input-css"
                          type="text"
                          placeholder="Enter Code"
                          name="postalCode"
                          value={postalcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="Unit-kg-selact mt-3">
                      <span className="">Province/state</span>
                      <select
                        className="Volvo-Speciality-value mt-2"
                        name="state"
                        id="Province"
                        value={state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled selected>
                          Select province/state
                        </option>
                        <option value={state}>{state}</option>
                      </select>
                      {errors.state && touched.state ? (
                        <p className="bmi-Weight mt-2">{errors.state} </p>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <h4 className="mt-3">Add licence</h4>
                    {licencedata.map((item, index) => {
                      return (
                        <>
                          <div className="Unit-kg-selact mt-3">
                            <div className="w-100 d-flex justify-content-between">
                              <span className="">Country</span>
                              {index > 0 ? (
                                <span
                                  style={{
                                    backgroundColor: Colors.blue,
                                    color: "#ffffff",
                                    fontSize: "15px",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                  className="d-flex align-items-center justify-content-center"
                                  onClick={() => handledelete(item)}
                                >
                                  X
                                </span>
                              ) : null}
                            </div>
                            <select
                              className="Volvo-Speciality-value mt-2"
                              name="country"
                              id="Country"
                              value={item.country}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "country",
                                  e.target.value
                                )
                              }
                              onBlur={handleBlur}
                            >
                              <option value="" disabled selected>
                                Select country
                              </option>
                              {masterdata?.licence?.map((licence) => {
                                return (
                                  <option value={licence.countryName}>
                                    {licence.countryName}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.country && touched.country ? (
                              <p className="bmi-Weight mt-2">
                                {errors.country}{" "}
                              </p>
                            ) : null}
                          </div>
                          <div className="Unit-kg-selact mt-4">
                            <span className="">Licensing authority</span>
                            <select
                              className="Volvo-Speciality-value mt-2"
                              name="licenceAuthority"
                              id="Licensing"
                              value={item.licenceAuthority}
                              // onChange={handleChange}
                              onChange={(e) =>
                                handleFieldChange2(
                                  index,
                                  "licenceAuthority",
                                  e.target.value
                                )
                              }
                              onBlur={handleBlur}
                            >
                              <option value="" disabled selected>
                                Select licence country
                              </option>
                              {masterdata?.licence
                                ?.find((i) => i.countryName == item.country)
                                ?.authorityName?.map((authorityname) => {
                                  return (
                                    <option value={authorityname.name}>
                                      {authorityname.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.licence && touched.licence ? (
                              <p className="bmi-Weight mt-2">
                                {errors.licence}{" "}
                              </p>
                            ) : null}
                          </div>
                          <div className="Unit-kg-selact mt-4">
                            <span className="">Licence no.</span>
                            <div className="BMI-MAin-input mt-2">
                              <input
                                className="Bim-Md-main-input-css"
                                type="number"
                                placeholder="Enter Licence number"
                                name="licensingNumber"
                                onChange={(e) =>
                                  handleFieldChange3(
                                    index,
                                    "licensingNumber",
                                    e.target.value
                                  )
                                }
                                value={item.licensingNumber}
                                // onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            {errors.LicensingNo && touched.LicensingNo ? (
                              <p className="bmi-Weight mt-2">
                                {errors.LicensingNo}{" "}
                              </p>
                            ) : null}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="">
                  <div
                    className="LicensingNo-add-more "
                    onClick={() => handleadd()}
                  >
                    <span>{Icons.addbluebtn}</span>
                    <span
                      className="New-ard-add-span "
                      style={{ cursor: "pointer" }}
                    >
                      Add more
                    </span>
                  </div>
                </div>
                <div className="mt-4 mb-5">
                  <button type="submit" className="Submit-Main-btn-Bim">
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default ProfessionalDoctor;
