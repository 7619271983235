import React from "react";
import Header from "../../Components/Header/Header";
import "../FindMD/FindMD.css";
// import Cardimg from "../../Assets/Images/ForgotPasswordImg.avif";
import time from "../../Assets/Images/time_ic@3x.png";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { COMPLETEAPPOINTMENTLISTAPI } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
import { Dateformat } from "../../Components/Dateformat/Dateformat";

const CompleteAppointment = () => {
  const { response, isloading } = Fetchdata(
    COMPLETEAPPOINTMENTLISTAPI,
    {},
    "POST"
  );
  // console.log(response, "response");
  // console.log(response);
  const handleclick = (itm) => {
    // console.log(itm, "    onClick={() => handleclick(itm)}");
    navigate("/SickCompleteAppointment", {
      state: {
        itm,
      },
    });
  };

  const navigate = useNavigate();

  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Complete appointment"} />
      <section className="FindMD-main-section">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <>
            {!response?.data && response?.message ? (
              <div className="d-flex align-items-center justify-content-center mt-3">
                <h1 style={{ textAlign: "center" }}>{response?.message}</h1>
              </div>
            ) : (
              <div className="FindMD-main-div">
                {response?.data?.map((itm) => {
                  const formated = Dateformat(itm?.appointment?.utc_time);
                  {
                    /* console.log(formated, "formated"); */
                  }
                  const { appointment } = itm;
                  return (
                    <div
                      onClick={() => handleclick(itm)}
                      key={appointment._id}
                      className="FindMD-img-contain"
                    >
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={appointment?.doctor_id?.basicInfo?.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Lucas-awy-main-div">
                          <div className="Dorothy-extra-div">
                            <span className="Dorothy-sapn">
                              {appointment?.doctor_id?.basicInfo?.firstName}{" "}
                              {appointment?.doctor_id?.basicInfo?.lastName}
                            </span>
                            <span className="General-span">
                              {appointment?.doctor_id?.speciality}
                            </span>
                          </div>
                          <div
                            className="Awy-div"
                            style={{
                              width: "max-content",
                              padding: "0 5px",
                              backgroundColor:
                                appointment.isAppointmentCancelled
                                  ? "red"
                                  : "green",
                            }}
                          >
                            <span className="Awy-btn-span">
                              {appointment.status}
                            </span>
                          </div>
                        </div>
                        <div className="mt-2">
                          <img className="time-img-css" src={time} alt="" />
                          <span className="Am-pm-span"> {formated}</span>
                        </div>
                        <div className="">
                          <img className="time-img-css" src={location} alt="" />
                          <span className="Am-pm-span">
                            {" "}
                            {appointment?.doctor_id?.basicInfo?.address}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default CompleteAppointment;
