import { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { store } from "../../Redux/store";
import { setSessionField } from "../../Redux/SessionAction";
import { IS_LOGGED_IN, KEY_USER_DATA } from "../../Redux/Appconstant";
import { GlobalContext } from "../../Context/GlabalContext";

function SessionExpiryPopup() {
  const { tokenexpire, settokenexpire } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleLoginClick = () => {
    store.dispatch(setSessionField(IS_LOGGED_IN, false));
    store.dispatch(setSessionField(KEY_USER_DATA, {}));
    settokenexpire(false);
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <Dialog open={tokenexpire}>
        <DialogTitle>Session Expired</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session has expired. Please log in to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginClick} color="primary" autoFocus>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SessionExpiryPopup;
