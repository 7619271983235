import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import { useNavigate, useLocation } from "react-router";
// import { store } from "../../Redux/store";
// import { KEY_USER_DATA } from "../../Redux/Appconstant";
// import Buttton from "../../Components/Button/Buttton";
// import { Colors } from "../../Constant/Colors";
import "./CaountUpcomingAppointment.css";
import Countdwon from "../../Assets/Images/countdown_blue_ic@3x.png";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
// import { Icons } from "../../Constant/Icons";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { StopTimeGet, StopTimeSet } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";

const CaountUpcomingAppointment = () => {
  const location = useLocation();
  const UpdetData = location.state?.itm;

  // const Utctime = location.state?.timeDifference;

  // console.log(UpdetData, "itm");
  // console.log(Utctime, "Utctime");

  const HealthData = UpdetData?.patient_id?.healthData;

  // console.log(Mydata);
  const navigate = useNavigate();

  const [timeRemaining, setTimeRemaining] = useState(0);

  const formated = Dateformat(UpdetData?.utc_time);

  const curantdate = moment.utc().format();
  // const UtcTime = UpdetData?.utc_time;
  // const utcDate = new Date(UtcTime);
  // const newUtcDate = new Date(utcDate.getTime() + 30 * 60000);
  // console.log(newUtcDate, "newUtcDate");
  // console.log(utcDate, "utcDate");
  const endDatetime = moment.utc(UpdetData?.utc_time, "YYYY-MM-DD HH:mm:ss");
  const startDatetime = moment.utc(curantdate, "YYYY-MM-DD HH:mm:ss");

  const timeDifference = moment.duration(endDatetime.diff(startDatetime));
  const updatedTimeDifference = timeDifference.add(15, "minutes");
  const diffFormatted = `${updatedTimeDifference.days()} D : ${updatedTimeDifference.hours()} H : ${updatedTimeDifference.minutes()} M : ${updatedTimeDifference.seconds()} S:`;

  // console.log(diffFormatted);
  // console.log(updatedTimeDifference.minutes());
  // const [showBtn, setShowBtn] = useState(false);
  // console.log(showBtn);

  useEffect(() => {
    if (
      updatedTimeDifference.minutes() < 0 ||
      updatedTimeDifference.seconds() < 0
    ) {
      navigate("/Upcoming-Appointment");
      toast.error("Appointment time end");
    } else if (
      updatedTimeDifference.minutes() !== 0 ||
      updatedTimeDifference.seconds() !== 0
    ) {
      const intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1000);
        // if (curantdate >= utcDate && curantdate <= newUtcDate) {
        //   setShowBtn(true);
        // } else {
        //   setShowBtn(false);
        // }
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      navigate("/Upcoming-Appointment");
      toast.error("Appointment time end");
    }
  }, [updatedTimeDifference.seconds(), updatedTimeDifference.minutes()]);
  const header = Headers();

  const StoTimeApi = async (time, id) => {
    try {
      const res = await axios.post(
        StopTimeSet,
        {
          appointmentID: id,
          videoStopTime: time,
        },
        header
      );
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const TimegetApi = async (id) => {
    try {
      const res = await axios.post(
        StopTimeGet,
        {
          appointmentID: id,
        },
        header
      );
      if (res.data.data === "" || res.data.data === null) {
        // setTimeRemaining(res.data.data);
        // console.log("null");
        StoTimeApi("15", id);
        // console.log(res.data.data);
      } else {
        // console.log("success");
        StoTimeApi(res.data.data, id);

        // setTimeRemaining(formattedDate);
      }
      // console.log(res);
      // console.warn("minute he ye", res);
      // setTimeRemaining(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStart = (itm) => {
    TimegetApi(itm?._id);
    navigate("/meeting", {
      state: {
        itm,
      },
    });
  };

  return (
    <>
      <Header
        style={{ gap: "1rem" }}
        text={"Health data detail"}
        extrafield={<Profile UpdetData={UpdetData} />}
        onClick={() => navigate(-1)}
      />
      <section className="HealthDataDetail-main-section">
        <div className="HealthDataDetail-main-div py-3">
          <div className="UpcomingAppointment2-thord-div">
            <div className="Consultation-type-Video">
              <span className="Consultation-type-sapn">
                Consultation Type & Time
              </span>
              <span className="Today-main-date-span">{formated}</span>
              <div className="Video-Chat-Consult-div mt-4">
                <span className="Video-main-span">
                  {UpdetData?.appointmentType} Consult
                </span>
              </div>
            </div>
            <div className="d-h-m-s-div">
              <span>
                <img className="Countdwon-img-sapn" src={Countdwon} alt="" />
                {diffFormatted}
              </span>
            </div>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Payment-for-appointment-div mt-3">
            <div className="Method-of-Card">
              <span className="Consultation-type-sapn">Payment Type</span>
              <span>{UpdetData?.choose_payment_method}</span>
            </div>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Payment-for-appointment-div mt-3">
            <div className="Method-of-Card">
              <span className="Consultation-type-sapn">Status</span>
              <span>{UpdetData?.status}</span>
            </div>
          </div>
          <div className="Lorem-d-s-line"></div>

          <div className="unfamiliar-age-div mt-3">
            <span className="age-main-span-add">Questionnaire Submitted</span>
            <span className="consultation-Choose-main-span">
              Choose reason or consultation.
            </span>
            <span className="Payment-all-contint-span">
              {UpdetData?.reasonAppointment}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="unfamiliar-age-div mt-3">
            <span className="Payment-all-contint-span">
              Is your problem companied by new and unfamiliar symptoms such:
              {UpdetData?.symptoms.map((item, index) => {
                return (
                  <>
                    <div key={index}>
                      <span className="Payment-all-contint-span">{item}</span>
                    </div>
                  </>
                );
              })}
            </span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Heath Data</span>
            {UpdetData?.patient_id?.healthData?.medicalCondition}
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">
              Past Medical Condition
            </span>
            <span>
              {UpdetData?.patient_id?.healthData?.pastMedicalCondition || "N/A"}
            </span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Allergies</span>
            <span>{HealthData?.allergies}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Medication</span>
            <span>{HealthData?.marijuana}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Smoking</span>
            <span>{HealthData?.smoking}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Alcohol</span>
            <span>{HealthData?.alcohol}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Marijuana</span>
            <span>{HealthData?.marijuana}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          {updatedTimeDifference.minutes() !== 0 &&
            updatedTimeDifference.days() === 0 &&
            updatedTimeDifference._data.hours === 0 &&
            updatedTimeDifference._data.minutes <= 14 && (
              <button
                className="Reschedule-Start-btn mt-3"
                type="submit"
                onClick={() => handleStart(UpdetData)}
              >
                Start
              </button>
            )}
        </div>
      </section>
    </>
  );
};

export default CaountUpcomingAppointment;

const Profile = ({ UpdetData }) => {
  // console.log(UpdetData, "UpdetDatapr");
  return (
    <div className="d-flex w-100 align-items-start justify-content-start gap-4">
      <div style={{ width: "80px", height: "80px" }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
          src={UpdetData?.patient_id?.basicInfo?.image}
          alt=""
        />
      </div>
      <div className="profiledetail">
        <span>{UpdetData?.patient_id?.basicInfo?.fullName}</span>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>Height</span>
            <h5>{UpdetData?.patient_id?.healthData?.height?.value}</h5>
          </div>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>Weight</span>
            <h5>
              {UpdetData?.patient_id?.healthData?.weight?.value}{" "}
              {UpdetData?.patient_id?.healthData?.weight?.unit}
            </h5>
          </div>
          <div>
            <span style={{ color: "grey", fontSize: "15px" }}>BMI</span>
            <h5>
              {parseInt(UpdetData?.patient_id?.healthData?.BMI).toFixed(2)}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};
