import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegFilePdf } from "react-icons/fa6";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
import Header from "../../Components/Header/Header";
import { ConsultationHistoryAPI } from "../../Constant/Apiconstant";

import "./ConsultationHistory.css";
import Fetchdata from "../../Constant/Fetchdata";
const ConsultationHistory = () => {
  const [dataUrl, setDataUrl] = useState([]);
  const route = useLocation();
  const navigate = useNavigate();
  const maindata = route?.state?.maindataitm;
  // console.log(maindata, "maindata");

  const { response } = Fetchdata(ConsultationHistoryAPI, {}, "POST");

  // console.log(response, "responsehj");
  // console.log(dataUrl, "dataUrl");
  useEffect(() => {
    setDataUrl(response?.data);
  }, [response]);
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Consultation history"}
        style={{ color: "white" }}
        // extrafield={<Updata />}
      />
      <section className="ConsultationHistory-main-section">
        <div className="ConsultationHistory-Main-div">
          {dataUrl?.map((item, index) => {
            const formatDate = (dateString) => {
              const date = new Date(dateString);
              const options = {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
              };
              return date.toLocaleDateString("en-US", options);
            };
            const formattedDate = formatDate(item?.date);

            const info = item.clinicalNoteHistory;
            {
              /* console.log(formattedDate, "formattedDate"); */
            }
            return (
              <div className="formated-main-date mt-3">
                <span className="age-main-span-add">{formattedDate}</span>
                <div className="maindata-pdf-section">
                  {info?.map((item, index) => {
                    return (
                      <div className="FaRegFilePdf-FaRegFilePdf-span">
                        <>
                          <a href={item.clinicalNotePdf}>
                            <FaRegFilePdf className="Sick-NOte-mmain" />
                          </a>
                        </>
                      </div>
                    );
                  })}
                </div>
                <div className="Notifications-d-s-line mt-3"></div>
              </div>
            );
          })}
          <div className="Notifications-d-s-line mt-3"></div>
        </div>
      </section>
    </>
  );
};

export default ConsultationHistory;
