import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import "./Cardiology.css";
import { ReasonfortestHistory } from "../../Constant/DummyData";
import Signature from "../Signature/Signature";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../Constant/ApiUrl";
import { CardiologyApi, SendtolebPostApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import SendtoLebModel from "../SendtoLebModel/SendtoLebModel";
import Header from "../../Components/Header/Header";

const initialValues = {
  IsotherHistory: "",
  Holter: false,
  ok: "",
  monitor: false,
  Resting: false,
  Echocardiogram: false,
  Stress: false,
  Bubble: false,
  Myocardial: false,
  Ventricular: false,
  ViabilityStudy: false,
  SendtoPatient: false,
  SendtoLab: false,
};

const Cardiology = () => {
  const navigate = useNavigate();
  const [isHistory, setisHistory] = useState(ReasonfortestHistory);

  const [IsotherHistory, setIsotherHistory] = useState(false);

  //////////////////-- radio button --/////////////////////////////
  const [ItsOk, setItsOk] = useState("");
  const [loading, setloading] = useState(false);
  const [ItPersantine, setItPersantine] = useState("");
  const [ItExercise, setItExercise] = useState("");
  const [ItResting, setItResting] = useState("");
  const [ItRest, setItRest] = useState("");
  const [ItYes, setItYes] = useState("");
  const { SigUrl } = useContext(GlobalContext);
  const [lgShow, setLgShow] = useState(false);
  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  // console.log(EmailGo, "EmailGo");

  // console.log(SigUrl, "SigUrl");
  const location = useLocation();
  const itm = location?.state?.id;
  // console.log(itm?._id, "route id");

  const handleRadioClick = (name) => {
    setItYes(name);
  };

  const handleRadioClick2 = (name) => {
    setItPersantine(name);
  };
  const handleRadioClick3 = (name) => {
    setItResting(name);
  };

  const handleRadioClick4 = (name) => {
    setItsOk(name);
  };
  const handleRadioClick5 = (name) => {
    setItRest(name);
  };
  const handleRadioClick6 = (name) => {
    setItExercise(name);
  };
  const [checkboxdata, setcheckboxdata] = useState([]);
  const handlechange = (event) => {
    const value = event.target.checked;
    if (event.target.checked) {
      setcheckboxdata([...checkboxdata, value]);
    } else {
      setcheckboxdata(checkboxdata.filter((val) => val !== value));
    }
  };

  const [rowObject, setRowObject] = useState({
    cardiology: {},
  });

  useEffect(() => {
    const updatedRowObject = { ...rowObject };
    isHistory.forEach((item) => {
      updatedRowObject.cardiology[item.backEndName] = item.isSelected;
    });

    setRowObject(updatedRowObject);
  }, [isHistory]);

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");
  const CallID = localStorage.getItem("CallNoteId");

  const header = Headers();
  const LebtextApi = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SendtolebPostApi,
        {
          appointmentID: itm?._id || savedRatingId || CallID,
          email: EmailGo,
          formKry: "cardiology",
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        CardiologyApi,
        {
          appointmentId: itm?._id || savedRatingId || CallID,
          cardiology: {
            pregnant: ItExercise,
            resonForTest_history: rowObject.cardiology,
            otherNote: values.IsotherHistory,

            requestedTest: {
              sub_cardiology: {
                Holter_monitor_with12_lead_ECG: values.Holter,
                Holter_monitor_with12_lead_ECG_Value: ItYes,
                hr_BP_monitor: values.monitor,
                lead_resting_ECG: values.Resting,
                echocardiogram: values.Echocardiogram,
                stress_echocardiogram: values.Stress,
                stress_echocardiogram_value: ItPersantine,
                bubble_study_echo_with_saline_contrast: values.Bubble,
              },
              nuclearCardiology: {
                myocardial_perfusion: values.Myocardial,
                myocardial_perfusion_value: ItResting,
                ventricular_function: values.Ventricular,
                ventricular_function_value: ItsOk,
                viability_study: values.ViabilityStudy,
                viability_study_value: ItRest,
              },
            },
            drSinature_image: SigUrl,
            sendLaboratory: values.SendtoLab,
            sendPatient: values.SendtoPatient,
          },
        },
        header
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        LebtextApi();
        navigate("/Notes", {
          state: {
            itm,
          },
        });
      }

      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const hendelElectro = () => {
    setLgShow(false);
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Cardiology"}
        style={{ color: "white" }}
      />
      <section className="Cardiology-main-sec">
        <form onSubmit={handleSubmit} className="Cardiology-main-div">
          <div className="Reason-main-History-div">
            <div className="Reason-one-div">
              <div>
                <span className="Reason-main-span">
                  Reason for test / History
                </span>
              </div>
              {isHistory.map((item, index) => {
                return (
                  <div className="checkdivmain" key={index}>
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => {
                        if (item.id === 123) {
                          setIsotherHistory(!IsotherHistory);
                        }
                        setisHistory((preval) => {
                          let temp = [...preval];
                          let temp2 = temp[index];
                          temp2 = {
                            ...temp2,
                            isSelected: !temp2.isSelected,
                          };
                          temp[index] = temp2;
                          return temp;
                        });
                      }}
                      checked={item.isSelected ? true : false}
                      onChange={handlechange}
                    />
                    <span className="chack-in-span">{item.txt}</span>
                  </div>
                );
              })}
              <div>
                {IsotherHistory ? (
                  <textarea
                    id="w3review"
                    name="IsotherHistory"
                    rows="3"
                    className="here-input-2-answered   mt-2"
                    cols="50"
                    placeholder="Write here"
                    value={values.IsotherHistory}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  ></textarea>
                ) : null}
              </div>
              <div className="m-2"></div>
            </div>
            <div className="Reason-one-div">
              <div>
                <span className="Reason-main-span">REQUESTED TEST</span>
              </div>
              <div className="mt-2">
                <span className="Cardiology-one-span">Cardiology :</span>
                <div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Holter"
                      value={values.Holter}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">
                      Holter monitor with 12 -leadECG
                    </span>
                  </div>
                  <div className="Holter-monitor-with-div">
                    <div className="radio-and-span">
                      <input
                        className="redio-ok-name"
                        name="ok"
                        id="1"
                        type="radio"
                        value="24hr"
                        onClick={() => handleRadioClick("24hr")}
                      />

                      <span className="hors-taventh"> 24hr </span>
                    </div>
                    <div className="radio-and-span">
                      <input
                        className="redio-ok-name"
                        name="ok"
                        id="1"
                        type="radio"
                        value="48hr"
                        onClick={() => handleRadioClick("48hr")}
                      />

                      <span className="hors-taventh"> 48hr </span>
                    </div>
                    <div className="radio-and-span">
                      <input
                        className="redio-ok-name"
                        name="ok"
                        id="1"
                        type="radio"
                        value="72hr"
                        onClick={() => handleRadioClick("72hr")}
                      />
                      <span className="hors-taventh"> 72hr </span>
                    </div>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="monitor"
                      value={values.monitor}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">
                      24 hr BP monitor (Ambulatory BP)
                    </span>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Resting"
                      value={values.Resting}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">12 Lead Resting ECG</span>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Echocardiogram"
                      value={values.Echocardiogram}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Echocardiogram</span>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Stress"
                      value={values.Stress}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">Stress Echocardiogram</span>
                  </div>
                  <div className="all-radio-and-span">
                    <div className="radio-and-span">
                      <input
                        className="redio-ok-name"
                        name="Exercise"
                        id="1"
                        type="radio"
                        value="Exercise"
                        onClick={() => handleRadioClick2("Exercise")}
                      />
                      <span className="hors-taventh"> Exercise </span>
                    </div>
                    <div className="radio-and-span">
                      <input
                        className="redio-ok-name"
                        name="Exercise"
                        id="1"
                        type="radio"
                        value="Persantine"
                        onClick={() => handleRadioClick2("Persantine")}
                      />
                      <span className="hors-taventh"> Persantine </span>
                    </div>
                  </div>
                  <div className="checkdivmain">
                    <input
                      className="Pregnant-yes-no"
                      type="checkbox"
                      id="vehicle1"
                      name="Bubble"
                      value={values.Bubble}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="chack-in-span">
                      Bubble Study -Echo with saline contrast
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="Reason-one-div">
              <span className="Cardiology-one-span">Nuclear Cardiology :</span>
              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="Myocardial"
                  value={values.Myocardial}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="chack-in-span">Myocardial Perfusion</span>
              </div>
              <div className="all-radio-and-span">
                <div className="radio-and-span">
                  <input
                    className="redio-ok-name"
                    name="Persantine"
                    id="1"
                    type="radio"
                    value="Exercise"
                    onClick={() => handleRadioClick3("Exercise")}
                  />
                  <span className="hors-taventh"> Exercise </span>
                </div>
                <div className="radio-and-span">
                  <input
                    className="redio-ok-name"
                    name="Persantine"
                    id="1"
                    type="radio"
                    value="Persantine"
                    onClick={() => handleRadioClick3("Persantine")}
                  />
                  <span className="hors-taventh"> Persantine </span>
                </div>
              </div>

              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="Ventricular"
                  value={values.Ventricular}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="chack-in-span">Ventricular Function</span>
              </div>
              <div className="all-radio-and-span">
                <div className="radio-and-span">
                  <input
                    className="redio-ok-name"
                    name="Resting"
                    id="1"
                    type="radio"
                    value="Resting (MUGA)"
                    onClick={() => handleRadioClick4("Resting (MUGA)")}
                  />
                  <span className="hors-taventh"> Resting (MUGA) </span>
                </div>
                <div className="radio-and-span">
                  <input
                    className="redio-ok-name"
                    name="Resting"
                    id="1"
                    type="radio"
                    value="Dobutamine"
                    onClick={() => handleRadioClick4("Dobutamine")}
                  />
                  <span className="hors-taventh"> Dobutamine </span>
                </div>
              </div>
              <div className="checkdivmain">
                <input
                  className="Pregnant-yes-no"
                  type="checkbox"
                  id="vehicle1"
                  name="ViabilityStudy"
                  value={values.ViabilityStudy}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span className="chack-in-span">ViabilityStudy</span>
              </div>
              <div className="all-radio-and-span">
                <div className="radio-and-span">
                  <input
                    className="redio-ok-name"
                    name="tow"
                    id="1"
                    type="radio"
                    value="Rest"
                    onClick={() => handleRadioClick5("Rest")}
                  />
                  <span className="hors-taventh"> Rest </span>
                </div>
              </div>
              <div className="mt-3">
                <span className="Cardiology-one-span">Pregnant ?</span>

                <div className="all-radio-and-span">
                  <div className="radio-and-span">
                    <input
                      className="redio-ok-name"
                      name="Yes"
                      id="1"
                      type="radio"
                      value="Yes"
                      onClick={() => handleRadioClick6("Yes")}
                    />
                    <span className="hors-taventh"> Yes </span>
                  </div>
                  <div className="radio-and-span">
                    <input
                      className="redio-ok-name"
                      name="Yes"
                      id="1"
                      type="radio"
                      value="No"
                      onClick={() => handleRadioClick6("No")}
                    />
                    <span className="hors-taventh"> No </span>
                  </div>
                </div>
                <div className="checkdivmain">
                  <input
                    className="Pregnant-yes-no"
                    type="checkbox"
                    id="vehicle1"
                    name="SendtoPatient"
                    value={values.SendtoPatient}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <span className="Cardiology-one-span">Send to Patient</span>
                </div>
                <div onClick={() => setLgShow(true)} className="checkdivmain">
                  <input
                    className="Pregnant-yes-no"
                    type="checkbox"
                    id="vehicle1"
                    name="SendtoLab"
                    value={values.SendtoLab}
                    onBlur={handleBlur}
                    checked={values.SendtoLab ? true : false}
                    onChange={(e) =>
                      setFieldValue("SendtoLab", e.target.checked)
                    }
                  />
                  <span className="Cardiology-one-span">Send to Lab</span>
                </div>
                <Signature />
              </div>
            </div>
          </div>
          <Button type={"submit"} style={{ backgroundColor: Colors.blue }}>
            Submit
          </Button>
        </form>
      </section>

      <SendtoLebModel
        onHide={() => {
          setFieldValue("SendtoLab", false);
          setLgShow(false);
        }}
        lgShow={lgShow}
        setLgShow={setLgShow}
        hendelElectro={hendelElectro}
      />
      {loading && <Loader />}
    </>
  );
};

export default Cardiology;
