import React, { useContext, useState } from "react";
import "../ChangePassword/ChangePassword.css";
import { ChangePasswor } from "../../Schemas/index";
import Password from "../../Assets/Images/password_ic@3x.png";
import { useFormik } from "formik";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../Constant/Icons";
import axios from "../../Constant/ApiUrl";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import { GlobalContext } from "../../Context/GlabalContext";
import {
  DOCTERCHANGEPASS,
  PATIENTCHANGEPASS,
} from "../../Constant/Apiconstant";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";

const ChangePassword = () => {
  const navigate = useNavigate();

  const initialValues = {
    CurrentPassword: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  };

  // const { role } = useContext(GlobalContext);
  const userDATA = store.getState().session[KEY_USER_DATA];
  const userrole = userDATA.basicInfo.role;
  const [close, setclose] = useState(true);
  const [close2, setclose2] = useState(true);
  const [close3, setclose3] = useState(true);
  const [loading, setloading] = useState(false);
  // change passowrd api.............................

  const onSubmit = async (value, { resetForm }) => {
    setloading(true);
    let url =
      userrole === "patient"
        ? PATIENTCHANGEPASS
        : userrole === "physician"
        ? DOCTERCHANGEPASS
        : null;
    try {
      const res = await axios.post(
        url,
        {
          old_password: values.CurrentPassword,
          new_password: values.NewPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userDATA?.token}`,
          },
        }
      );
      if (res.data.status == "failed") {
        toast.error(res.data.message);
      } else if (res.data.status == "success") {
        toast.success(res.data.message);
        // navigate("/Settings");
        resetForm();
        setloading(false);
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setloading(false);
    }
  };

  // formik .........................................
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ChangePasswor,
    onSubmit,
  });
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Change password"} />
      <section className="Settings-main-section">
        <form onSubmit={handleSubmit} className="Settings-main-div">
          <div className="svg-chaing-thard-ChangePassword">
            <div className="svg-pasword">
              <span className="font-chang">Change password</span>
            </div>
            <div className="all-of-Password-fild">
              <div className="mt-4 Password-ek-or">
                <span>Old password</span>
              </div>
              <div className="svg-NewPassword-div ">
                <div>
                  <img
                    className="Password-icon-main-img"
                    src={Password}
                    alt=""
                  />
                </div>
                <input
                  className="i-t-a"
                  type={close ? "password" : "text"}
                  placeholder="Old password"
                  name="CurrentPassword"
                  value={values.CurrentPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span onClick={() => setclose(!close)}>
                  {close ? Icons.eyeclose : Icons.eyeopen}
                </span>
              </div>
              {errors.CurrentPassword && touched.CurrentPassword ? (
                <p className="mastu-to-eroor ">{errors.CurrentPassword} </p>
              ) : null}
            </div>
            <div className="all-of-Password-fild">
              <div className="mt-4  Password-ek-or">
                <span>New password</span>
              </div>
              <div className="svg-NewPassword-div ">
                <div>
                  <img
                    className="Password-icon-main-img"
                    src={Password}
                    alt=""
                  />
                </div>
                <input
                  className="i-t-a"
                  type={close2 ? "password" : "text"}
                  placeholder="New password"
                  name="NewPassword"
                  value={values.NewPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span onClick={() => setclose2(!close2)}>
                  {close2 ? Icons.eyeclose : Icons.eyeopen}
                </span>
              </div>
              {errors.NewPassword && touched.NewPassword ? (
                <p className="mastu-to-eroor">{errors.NewPassword} </p>
              ) : null}
            </div>
            <div className="all-of-Password-fild">
              <div className="mt-4  Password-ek-or">
                <span>Confirm new password</span>
              </div>
              <div className="svg-ConfirmNewPassword-div ">
                <div>
                  <img
                    className="Password-icon-main-img"
                    src={Password}
                    alt=""
                  />
                </div>
                <input
                  className="i-t-a"
                  type={close3 ? "password" : "text"}
                  placeholder="Confirm new password"
                  name="ConfirmNewPassword"
                  value={values.ConfirmNewPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <span onClick={() => setclose3(!close3)}>
                  {close3 ? Icons.eyeclose : Icons.eyeopen}
                </span>
              </div>
              {errors.ConfirmNewPassword && touched.ConfirmNewPassword ? (
                <p className="mastu-to-eroor ">{errors.ConfirmNewPassword} </p>
              ) : null}
            </div>

            <div className="mt-4">
              <button type="submit" className="Reset-main-screen-btn">
                Reset password
              </button>
            </div>
          </div>
          <div className="pasword-blue-img-div">
            <img className="blue-h-w-img" src={Blue} alt="" />
          </div>
        </form>
      </section>
      {loading && <Loader />}
    </>
  );
};

export default ChangePassword;
