import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import "./ProBono.css";
import SickNote from "../SickNote/SickNote";

const ProBono = ({ setMove, Move, text, sickid }) => {
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState({
    proBono: 0,
    lockedNote: 1,
  });
  //   const sicknotid = sickid;
  //   console.log(sicknotid, "sicknotid");
  const handleCheckboxChange = (name) => {
    // Create a copy of the selected values
    const updatedValues = { ...selectedValues };

    // Set the selected checkbox to true
    updatedValues[name] = true;

    // Set the other checkbox to false
    const otherCheckbox = name === "proBono" ? "lockedNote" : "proBono";
    updatedValues[otherCheckbox] = false;

    // Update the state with the new values
    setSelectedValues(updatedValues);
  };
  // console.log(sickid, "sickid");
  const handleSubmit = () => {
    const selectedCheckboxes = {
      proBono: selectedValues.proBono ? 0 : undefined,
      lockedNote: selectedValues.lockedNote ? 1 : undefined,
    };

    // Remove undefined values from the object
    const filteredCheckboxes = Object.fromEntries(
      Object.entries(selectedCheckboxes).filter(
        ([key, value]) => value !== undefined
      )
    );

    if (Object.keys(filteredCheckboxes).length > 0) {
      navigate("/SickNote", { state: { sickid, ...filteredCheckboxes } });
    } else {
      console.log("Please select at least one checkbox.");
    }
  };
  return (
    <>
      <Modal show={Move} size="md" centered>
        <Modal.Header
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-Sick-con-div-been">
              <div className="Physician-div">
                <span>{text}</span>
              </div>
            </div>
          </div>
          <div className="NO-main-btn-and">
            <div className="main-input-chek-being-reassessment mb-3">
              <input
                className="main-input-chek"
                type="checkbox"
                name="proBono"
                checked={selectedValues.proBono}
                onChange={() => handleCheckboxChange("proBono")}
              />
              <div className="Physician-div-waves">
                <span className="Organization-sapn">Pro-Bono</span>
                <span>
                  Physician waves the fee for the note - become available
                  immediately
                </span>
              </div>
            </div>
            <div className="main-input-chek-being-available mb-3">
              <input
                className="main-input-chek-Bono"
                type="checkbox"
                name="lockedNote"
                checked={selectedValues.lockedNote}
                onChange={() => handleCheckboxChange("lockedNote")}
              />
              <div className="Physician-div-waves ">
                <span className="Organization-sapn">Locked Note</span>
                <span>available to the patient after payment! </span>
              </div>
            </div>
            <div>
              <button
                className="available-mbtn-with-pop mt-3 mb-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProBono;
