import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./EnterMedicineName.css";
import { AddMedicineApi } from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
import { Headers } from "../../Constant/Fetchdata";
import { IoIosAlert } from "react-icons/io";

const EnterMedicineName = ({ text, PopupName, setPopupName, getdata }) => {
  const handleCloseee = () => {
    if (PopupName) {
      setPopupName(false);
    }
  };
  const [inputValue, setInputValue] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  // console.log(inputValue, "inputValue");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setShowAlert(false);
  };

  const header = Headers();
  const onSubmit = async () => {
    try {
      const res = await axios.post(
        AddMedicineApi,
        {
          medicineName: inputValue,
        },
        header
      );
      // console.log(res, "jvcccfh");
      if (res.data.status === "success") {
        getdata(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="Add-model-madicen">
        <Modal
          className="Add-model-madicen"
          show={PopupName || Error}
          size="md"
          centered
        >
          <Modal.Header
            style={{
              display: "grid",
              placeItems: "end",
              width: "100% ",
              justifyContent: "end",
              border: "0",
            }}
            closeButton
            onClick={() => setPopupName(false)}
          ></Modal.Header>
          <div onClick={onSubmit} className="expalin-div-sec">
            <div
              className="cardcontent-patient-Torso-main-div"
              style={{ borderRadius: "10px" }}
            >
              <div className="thard-Sick-con-div-been">
                <div className="Physician-div">
                  <span>{text}</span>
                </div>
              </div>
            </div>
            <div className="Write-main-input-Here">
              <input
                className="handleInputChange-main-inputValue"
                placeholder="Write Here"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
              />
              {showAlert && <IoIosAlert className="alert-icon" />}
            </div>
            <div>
              <button
                onClick={handleCloseee}
                disabled={!inputValue ? "disabled" : null}
                className="available-mbtn-with-pop mt-3 mb-3"
              >
                Add
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EnterMedicineName;
