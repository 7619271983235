import React, { useState } from "react";
import "./AvailabilityDoctor.css";
import Green from "../../Assets/Images/green_plus_ic@3x.png";
import close from "../../Assets/Images/red_close_ic@3x.png";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
const AvailabilityDoctor = () => {
  const navigate = useNavigate();
  // const [startDate, setStartDate] = useState(new Date());

  const [inputSets, setInputSets] = useState([{ id: 0 }]);

  const handleAddInputSet = () => {
    const newInputSet = { id: inputSets.length };
    setInputSets([...inputSets, newInputSet]);
  };

  const handleRemoveInputSet = (id) => {
    const updatedInputSets = inputSets.filter((inputSet) => inputSet.id != id);
    setInputSets(updatedInputSets);
  };
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Availability"}
        style={{ color: "white" }}
      />
      <section className="AvailabilityDoctor-main-section">
        <div className="AvailabilityDoctor-main-div">
          {/* <div>
            <div className="selact-deta-div">
              <span className="selt-span"> Select Dates</span>
            </div>
            <div className="mb-4">
              <span>4 Dec - 9 Dec</span>
            </div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              inline
            />
          </div> */}
          <div className="Wed-date-div-main-douo">
            <div className="Wed-date-div">
              <span className="fifth-span">15</span>
              <span className="wed-thas-tus-span">Wed</span>
            </div>
            <div>
              <span className="your-Availability-div">
                Add your Availability
              </span>
            </div>
          </div>
          <div className="mt-5">
            <span className="Open-time-port">Open Time</span>
          </div>
          <div className="from-to-main-div mt-3">
            <div className="From-all-time-div">
              <span>From</span>
              <input className="Open-Time-time" type="time" />
            </div>
            <div className="From-all-time-div">
              <span>To</span>
              <input className="Open-Time-time" type="time" />
            </div>
          </div>
          <div className="mt-3">
            <span className="Open-time-port">Break Time (Optional)</span>
          </div>
          <div className="from-to-main-div-index mt-3">
            {inputSets.map((inputSet, index) => (
              <div
                key={inputSet.id}
                className="From-handleRemoveInputSet-time-div"
              >
                <div className="index-div">
                  <span>From</span>
                  <input className="Open-Time-time" type="time" />
                </div>
                <div className="index-div">
                  <span>To</span>
                  <input className="Open-Time-time" type="time" />
                </div>
                {index === 0 ? (
                  <img
                    className="Green-main-plush-img"
                    src={Green}
                    alt=""
                    onClick={handleAddInputSet}
                  />
                ) : (
                  <img
                    onClick={() => handleRemoveInputSet(inputSet.id)}
                    className="Green-main-plush-img"
                    src={close}
                    alt=""
                  />
                )}
              </div>
            ))}
          </div>
          <div>
            <button className="Save-butn-main-save">Save</button>
          </div>
          <div className="dash-add-line"></div>
          <div className="Wed-date-div-PM--am-main-douo mt-4">
            <div className="Wed-date-div">
              <span className="fifth-span">15</span>
              <span className="wed-thas-tus-span">Wed</span>
            </div>
            <div className="add-date-time-Availability">
              <span className="">Add your Availability</span>
              <span>9 AM - 9 PM</span>
            </div>
          </div>
          <div className="Wed-date-div-PM--am-main-douo mt-3">
            <div className="Wed-date-div">
              <span className="fifth-span">15</span>
              <span className="wed-thas-tus-span">Wed</span>
            </div>
            <div className="add-date-time-Availability">
              <span className="">Add your Availability</span>
              <span>9 AM - 9 PM</span>
            </div>
          </div>
          <div className="Wed-date-div-PM--am-main-douo mt-3 mb-5">
            <div className="Wed-date-div">
              <span className="fifth-span">15</span>
              <span className="wed-thas-tus-span">Wed</span>
            </div>
            <div className="add-date-time-Availability">
              <span className="">Add your Availability</span>
              <span>9 AM - 9 PM</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AvailabilityDoctor;
