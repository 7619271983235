import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Header from "../../Components/Header/Header";
import { Icons } from "../../Constant/Icons";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { SendtolebApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
import "./SendtoLebModel.css";
import { GlobalContext } from "../../Context/GlabalContext";

const SendtoLebModel = ({ setLgShow, lgShow, onHide, hendelElectro }) => {
  const [loading, setloading] = useState(false);
  const { EmailGo, setEmailGo } = useContext(GlobalContext);
  const MoyaMoya = location?.state?.itm;
  const id = MoyaMoya?._id;

  const [selectedcard, setselectedcard] = useState({
    cardid: "",
    isSelected: null,
  });
  const navigate = useNavigate();
  const header = Headers();
  const { response } = Fetchdata(SendtolebApi, { header }, "GET");

  // console.log(response, "response");
  const toggleSelection = (currentIndex, cardId) => {
    // console.log(cardId, "cardId");
    setEmailGo(cardId.email);
    setselectedcard((prevSelectedCard) => {
      const isSelected =
        prevSelectedCard.isSelected === currentIndex ? null : currentIndex;

      return { isSelected, cardid: isSelected ? cardId : null };
    });
  };

  return (
    <>
      <Modal
        size="lg"
        show={lgShow}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!response?.data || response?.data === undefined ? (
            <h1 className="text-center">{response?.message}</h1>
          ) : (
            <div className="">
              {response?.data?.map((item, index) => {
                return (
                  <>
                    <div
                      key={index}
                      onClick={() => toggleSelection(index, item)}
                      className="Model-card-select-leb"
                    >
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={item.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Height-awy-main-div">
                          <div className="isSelected-main-div">
                            <span className="Dorothy-sapn">{item.labName}</span>
                            <div className="dustbin-logo-black">
                              <span onClick={() => toggleSelection(index)}>
                                {selectedcard.isSelected === index
                                  ? Icons.cardselecticon
                                  : null}
                              </span>
                            </div>
                          </div>
                          <div className="">
                            <img
                              className="time-img-css"
                              src={location}
                              alt=""
                            />
                            <span className="Am-pm-span">
                              {item.streetAddress}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Lorem-d-s-line"></div>
                    <div className="  Select-main-section">
                      <button
                        onClick={hendelElectro}
                        className="Add-search-New"
                      >
                        Select
                      </button>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SendtoLebModel;
