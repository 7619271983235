import React, { useState, useEffect, useContext } from "react";
import "../OtherInformation/OtherInformation.css";
import { useFormik } from "formik";
import { OtherInformationSchemas } from "../../Schemas/index";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import Pass from "../../Assets/Images/password_ic@3x.png";
import Location from "../../Assets/Images/location_ic@3x.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GlobalContext } from "../../Context/GlabalContext";
import { Icons } from "../../Constant/Icons";
const OtherInformation = () => {
  const [selected, setselected] = useState(null);
  const [close, setclose] = useState(true);
  const [close2, setclose2] = useState(true);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const initialValues = {
    email: "",
    phoneNumber: "",
    Password: "",
    ConfirmNewPassword: "",
    Location: "",
    gender: "",
    lat: "",
    lng: "",
  };
  const { setsteps, steps, setregiaterdata } = useContext(GlobalContext);

  const onSubmit = () => {
    const data = {
      email: values.email,
      phoneNumber: values.phoneNumber,
      Password: values.Password,
      location: address,
      gender: values.gender,
      lat: values.lat,
      lng: values.lng,
      country: country,
    };
    setregiaterdata((prev) => ({ ...prev, ...data }));
    setsteps(3);
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: OtherInformationSchemas,
    onSubmit,
  });

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
    setFieldValue("Location", newAddress);
  };
  const handleAddressBlur = () => {
    handleBlur("Address");
  };
  const handleSelect = async (selectedAddress) => {
    setFieldValue("Address", selectedAddress);
    setFieldValue("Country", selectedAddress);
    setFieldValue("State", selectedAddress);
    setFieldValue("City", selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(results[0]);
      setFieldValue("lat", lat);
      setFieldValue("lng", lng);
      setAddress(selectedAddress);
      const addressComponents = results[0].address_components;

      const cityComponent = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );
      setCity(cityComponent?.long_name || "");
      setState(stateComponent?.long_name || "");
      setCountry(countryComponent?.long_name || "");

      handleChange({
        target: {
          name: "City",
          value: cityComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "State",
          value: stateComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "Country",
          value: countryComponent?.long_name || "",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="All-OtherInformation-perfect-divv">
        <div className="All-OtherInformation-perfect-second-divv">
          <div className="Profile-extra-div">
            <div className="Profile-main-Your">
              <span style={{ cursor: "pointer" }} onClick={() => setsteps(1)}>
                {Icons.backarrowblack}
              </span>
              <h6 className="mt-2">Step {steps} to 3</h6>
              <span className="Order-history-span">Other information</span>
              <span className="Upload-profile">
                Upload your profile to get better consultants from doctor.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="second-div">
            <div className="all-input-div-main">
              <div className="all-input-areya">
                <div className="full-and-last-name">
                  <div className="all-input-form-div-main">
                    <div className="Gender-Male-Female-div">
                      <span>Gender</span>
                      <div className="Gender-main-div mt-2">
                        <button
                          onClick={() => {
                            setselected("male");
                            setFieldValue("gender", "male");
                          }}
                          type="button"
                          className={
                            selected == "male"
                              ? "Female-btn-mainactive "
                              : "Female-btn-main"
                          }
                        >
                          Male
                        </button>
                        <button
                          onClick={() => {
                            setselected("female");
                            setFieldValue("gender", "female");
                          }}
                          type="button"
                          className={
                            selected == "female"
                              ? "Female-btn-mainactive "
                              : "Female-btn-main"
                          }
                        >
                          Female
                        </button>
                      </div>
                      {errors.gender && touched.gender ? (
                        <p className="OtherInformation-error mt-2">
                          {errors.gender}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="Location-main-place-div w-100">
                      <span>Location</span>
                      <div className="mt-2 Information-main-div w-100">
                        <img
                          className="Location-main-img"
                          src={Location}
                          alt=""
                        />
                        <PlacesAutocomplete
                          value={address}
                          onChange={handleAddressChange}
                          onSelect={handleSelect}
                          onBlur={handleAddressBlur}
                          name="Address"
                          className="w-100"
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="main-place-ho">
                              <input
                                className="mene-change-kiya-hai"
                                {...getInputProps({ placeholder: "Location" })}
                              />
                              <div>
                                {loading ? <div>Loading...</div> : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                  };
                                  return (
                                    <div
                                      className="mene-change-kiya-hai"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      {errors.Location && touched.Location ? (
                        <p className="OtherInformation-error mt-2">
                          {errors.Location}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="full-Email-and-name-bug-div w-100">
                      <span className="">Email</span>
                      <div className="Information-main-div mt-2 w-100">
                        <svg
                          className="icon-mar-gi-n"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_18_660)">
                            <path
                              d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                              fill="#211B24"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_18_660">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <input
                          className="email-main-imnput-div w-100"
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.email && touched.email ? (
                        <p className="OtherInformation-error mt-2">
                          {errors.email}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="full-Email-and-name-bug-div w-100">
                      <span className="">Phone number</span>
                      <div className="Information-main-div mt-2 w-100">
                        <svg
                          className="icon-mar-gi-n"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_18_660)">
                            <path
                              d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                              fill="#211B24"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_18_660">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <input
                          className="email-main-imnput-div w-100"
                          type="number"
                          placeholder="Phone number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <p className="OtherInformation-error mt-2">
                          {errors.phoneNumber}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="Password-colum-div w-100">
                      <span className="">Password</span>
                      <div className="svg-p-div mt-2 w-100">
                        <div>
                          <img
                            className="Password-main-img"
                            src={Pass}
                            alt=""
                          />
                        </div>
                        <input
                          className="Password-main-imnput-div"
                          type={close ? "password" : "text"}
                          placeholder="Password"
                          name="Password"
                          value={values.Password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span onClick={() => setclose(!close)}></span>
                      </div>
                      {errors.Password && touched.Password ? (
                        <p className="OtherInformation-error mt-2">
                          {errors.Password}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="Password-colum-div w-100">
                      <span className="">Confirm password</span>
                      <div className="svg-p-div mt-2 w-100">
                        <div>
                          <img
                            className="Password-main-img"
                            src={Pass}
                            alt=""
                          />
                        </div>
                        <input
                          className="Password-main-imnput-div"
                          type={close2 ? "password" : "text"}
                          placeholder="Confirm password"
                          name="ConfirmNewPassword"
                          value={values.ConfirmNewPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <span onClick={() => setclose2(!close2)}>
                          {/* {close2 ? eyeclose : eyeopen} */}
                        </span>
                      </div>
                      {errors.ConfirmNewPassword &&
                      touched.ConfirmNewPassword ? (
                        <p className="OtherInformation-error mt-2">
                          {errors.ConfirmNewPassword}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="mt-4 mb-5">
                      <button type="submit" className="Next-Register-karo">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div>
            <img className="OtherInformation-next-blue" src={Blue} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherInformation;
