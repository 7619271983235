import React, { useState } from "react";
import "./RescheduleAppointment.css";
import Calender from "../Calender/Calender";
import Virtual from "../../Assets/Images/virtual_appointment_ic@3x.png";
import Person from "../../Assets/Images/in_person_appointment_ic@3x.png";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import axios from "../../Constant/ApiUrl";
import { RESCHEDULEAPPOINTMENTAPI } from "../../Constant/Apiconstant";
import { Colors } from "../../Constant/Colors";
import { Headers } from "../../Constant/Fetchdata";
import { toast } from "react-toastify";

const RescheduleAppointment = () => {
  const navigate = useNavigate();
  const [selecteddate, setselecteddate] = useState("");
  const [selectedtime, setselectedtime] = useState({});
  const [selected, setselected] = useState("");
  const [session, setsission] = useState("");

  // console.log(selectedtime);
  const header = Headers();
  const Reschaduleappointment = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        RESCHEDULEAPPOINTMENTAPI,
        {
          date: selecteddate,
          time: selectedtime.time,
          appointment_id: "641049a770f935b576638be4",
          session: session,
          utc_time: selectedtime.utc_time,
        },
        header
      );
      if (res.data.status == "failed") {
        toast.error(res.data.message);
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTimeChange = (e) => {
    const localTime = e.target.value;
    const localDate = new Date(`1970-01-01T${localTime}`);
    // console.log("Local Time:", localTime);
    const formattedLocalTime = localDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const hour = localDate.getHours();
    if (hour >= 0 && hour < 12) {
      setsission("morning");
    } else {
      setsission("evening");
    }
    // console.log("Formatted Local Time:", formattedLocalTime);
    // console.log("UTC Time:", localDate.toISOString().slice(11, 16));
    setselectedtime({ time: formattedLocalTime, utc_time: localTime });
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Reschedule appointment"}
        style={{ color: "white" }}
      />
      <Calender setselecteddate={setselecteddate} selecteddate={selecteddate} />
      <section className="BookAnAppointment1-main-section">
        <div className="BookAnAppointment1-main-div">
          <div
            className="Virtual-Appointment-main-div mt-4"
            id="inPersonAppointmentDiv"
          >
            <span className="Select-Appointment-type">
              Select Appointment Type
            </span>
            <div className="dono-ka-he-main-div mt-3">
              <div
                className="Virtual-and-Person-Appointment-divee"
                onClick={() => setselected("Virtual")}
                style={{
                  backgroundColor: selected === "Virtual" ? Colors.blue : "",
                }}
              >
                <img className="Person-main-img-Virtual" src={Virtual} alt="" />
                <span
                  style={{ color: selected === "Virtual" ? "#fff" : "#000" }}
                  className="Appointment-spacealitty"
                >
                  Virtual Appointment
                </span>
              </div>
              <div
                className="Virtual-and-Person-Appointment-divee"
                onClick={() => setselected("Person")}
                style={{
                  backgroundColor: selected === "Person" ? Colors.blue : "",
                }}
              >
                <img className="Person-main-img-Virtual" src={Person} alt="" />
                <span
                  style={{ color: selected === "Person" ? "#fff" : "#000" }}
                  className="Appointment-spacealitty"
                >
                  In Person Appointment
                </span>
              </div>
            </div>
          </div>
          <div className="Select-Appointment-time-div mt-5">
            <span className="Select-Appointment-type">
              {" "}
              Select Appointment Time
            </span>
            <input
              placeholder="Select Appointment Time"
              className="time-input-main-he"
              type="time"
              onChange={handleTimeChange}
              // onChange={(e) => {
              //   console.log(e.target.valueAsDate);
              //   setselectedtime(e.target.value);
              // }}
            />
          </div>
          <div className="Reschedule-main-div">
            <button
              onClick={
                selecteddate && selectedtime.time ? Reschaduleappointment : null
              }
              disabled={!selecteddate && !selectedtime.time ? "disable" : ""}
              className="Reschedule-main-btn"
            >
              Reschedule
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default RescheduleAppointment;
