import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import axios from "../../Constant/ApiUrl";
import { updateDoctarSchemas } from "../../Schemas/index";
import { TowAddDoctorInformationAPi } from "../../Constant/Apiconstant";
import DataSuccessfully from "../DataSuccessfully/DataSuccessfully";
import { Contenttype, Headers } from "../../Constant/Fetchdata";
import Loader from "../../Constant/Loader";
import Header from "../../Components/Header/Header";
const TowAddDoctorInformation = () => {
  const [Image, setImage] = useState(null);
  const [Popup, setPopup] = useState(false);
  const [loading, setloading] = useState(false);
  // console.log(Image, "bhaiyaImage");

  //   const [Popup, setPopup] = useState(false);
  //   console.log(Image, "Image");
  //   const route = useLocation();
  //   const maindata = route?.state?.item;
  //   console.log(maindata, "Okid");

  const initialValues = {
    FullName: "",
    Speciality: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
    Country: "",
    City: "",
    State: "",
    Postalcode: "",
    FaxNumber: "",
    Wabsite: "",
  };

  const navigate = useNavigate();

  const header = Headers(Contenttype.formdata);

  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("hospital_id", "");
    formdata.append("name", values.FullName);
    formdata.append("speciality", values.Speciality);
    formdata.append("phoneNumber", values.PhoneNumber);
    formdata.append("email", values.Email);
    formdata.append("country", values.Country);
    formdata.append("city", values.City);
    formdata.append("state", values.State);
    formdata.append("streetAddress", values.Address);
    formdata.append("pinCode", values.Postalcode);
    formdata.append("faxNumber", values.FaxNumber);
    formdata.append("website", values.Wabsite);
    formdata.append("image", Image);

    try {
      const res = await axios.post(
        TowAddDoctorInformationAPi,
        formdata,
        header
      );
      if (res.status === 200 && res.data.status === "success") {
        setPopup(true);
      }
      setloading(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: updateDoctarSchemas,
    onSubmit,
  });

  //   useEffect(() => {
  //     setFieldValue("hospital_id");
  //     setFieldValue("FullName", maindata.name);
  //     setFieldValue("Speciality", maindata.speciality);
  //     setFieldValue("PhoneNumber", maindata.phoneNumber);
  //     setFieldValue("Email", maindata.email);
  //     setFieldValue("Address", maindata.streetAddress);
  //     setFieldValue("Country", maindata.country);
  //     setFieldValue("City", maindata.city);
  //     setFieldValue("State", maindata.state);
  //     setFieldValue("Postalcode", maindata.pinCode);
  //     setFieldValue("FaxNumber", maindata.faxNumber);
  //     setFieldValue("Wabsite", maindata.website);
  //   }, [maindata]);
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Add Doctor"}
        style={{ color: "white" }}
      />
      <div className="All-Conatinor-perfect-divv ">
        <form
          onSubmit={handleSubmit}
          className="All-Containor-perfect-second-divv"
        >
          <div className="second-div-Doctor mt-4">
            <span className="Information-main-add">Add Doctor Information</span>
            <div className="all-input-div-main-Information">
              <div className="Ht-ml-for-imput">
                <label className="" htmlFor="mal">
                  <img src={profilePic} alt="" className="pic-on-add" />
                  <div className="SandIcon-addeting-div">
                    <img className="Cemara-te-img" src={SandIcon} alt="" />
                  </div>
                </label>
                <input
                  id="mal"
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setFieldValue("image", e.target.files[0]);
                  }}
                  className="Malte-pal-cls"
                />
                <img
                  src={Image ? URL.createObjectURL(Image) : null}
                  alt=""
                  width="100px"
                  height="100px"
                  className="add-kiya-muja"
                />
              </div>
              <br />

              <div className="">
                <div className="">
                  <div className=" all-input-areya">
                    <div className="full-and-last-name">
                      <div className="Full-all-Name-input">
                        <span className=""> Name</span>
                        <div className="full-name-bug-div">
                          <div className="BMI-MAin-input mt-2">
                            <input
                              className="Bim-Md-main-input-css"
                              type="text"
                              placeholder="Name"
                              name="FullName"
                              value={values.FullName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {errors.FullName && touched.FullName ? (
                          <p className="mastu-to-eroor mt-2">
                            {errors.FullName}{" "}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Speciality</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Speciality"
                      name="Speciality"
                      value={values.Speciality}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.Speciality && touched.Speciality ? (
                  <p className="mastu-to-eroor mt-2">{errors.Speciality} </p>
                ) : null}
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Phone Number</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="number"
                      placeholder="PhoneNumber"
                      name="PhoneNumber"
                      value={values.PhoneNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.PhoneNumber && touched.PhoneNumber ? (
                  <p className="mastu-to-eroor mt-2">{errors.PhoneNumber} </p>
                ) : null}
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Email</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="email"
                      placeholder="Email"
                      name="Email"
                      value={values.Email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.Email && touched.Email ? (
                  <p className="mastu-to-eroor mt-2">{errors.Email} </p>
                ) : null}
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Address</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Address"
                      name="Address"
                      value={values.Address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.Address && touched.Address ? (
                  <p className="mastu-to-eroor mt-2">{errors.Address} </p>
                ) : null}
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Country</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Country"
                      name="Country"
                      value={values.Country}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.Country && touched.Country ? (
                  <p className="mastu-to-eroor mt-2">{errors.Country} </p>
                ) : null}
              </div>

              <div className="Full-all-Name-input mt-3">
                <span className="">City</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="City"
                      name="City"
                      value={values.City}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.City && touched.City ? (
                  <p className="mastu-to-eroor mt-2">{errors.City} </p>
                ) : null}
              </div>

              <div className="Full-all-Name-input mt-3">
                <span className="">State</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="State"
                      name="State"
                      value={values.State}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.State && touched.State ? (
                  <p className="mastu-to-eroor mt-2">{errors.State} </p>
                ) : null}
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Postal code</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Postalcode"
                      name="Postalcode"
                      value={values.Postalcode}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.Postalcode && touched.Postalcode ? (
                  <p className="mastu-to-eroor mt-2">{errors.Postalcode} </p>
                ) : null}
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Fax Number</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="number"
                      placeholder="FaxNumber"
                      name="FaxNumber"
                      value={values.FaxNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {errors.FaxNumber && touched.FaxNumber ? (
                  <p className="mastu-to-eroor mt-2">{errors.FaxNumber} </p>
                ) : null}
              </div>
              <div className="Full-all-Name-input mt-3">
                <span className="">Wabsite</span>
                <div className="full-name-bug-div">
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Wabsite"
                      name="Wabsite"
                      value={values.Wabsite}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 ">
                <button type="submit" className="Update-Register-karo mb-5">
                  Update
                </button>
              </div>
            </div>
          </div>
          {/* <div>
            <img className="YourProfile-next-Update" src={Blue} alt="" />
          </div> */}
        </form>

        {Popup && (
          <DataSuccessfully
            Popup={Popup}
            handleCloseeee={() => navigate("/DoctorBook")}
            setPopup={setPopup}
            text={"Doctor Book updated Successfully"}
          />
        )}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default TowAddDoctorInformation;
