import React, { Fragment, useState } from "react";
import "./CompleteAppointmentDetail.css";
import { BsFillStarFill } from "react-icons/bs";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
// import Health from "../../Assets/Images/HealthDataImg.avif";

const CompleteAppointmentDetail = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const maindata = route?.state?.itm;

  // console.log(maindata, "maindata");

  const [selected, setSelected] = useState(0);
  const [maindataitm, setMaindataitm] = useState(maindata);
  // console.log(maindataitm, "maindataitm");

  const StarGenerator = (numofstar = 5) => {
    // console.log(selected, "seleted");
    return Array(numofstar)
      .fill()
      .map((item, i) => (
        <Star
          key={i}
          selected={selected > i}
          onSelected={() => setSelected(i + 1)}
        />
      ));
  };

  const Star = ({ selected, onSelected }) => {
    return (
      <BsFillStarFill
        className="fastar-mnb-CompleteAppointmentDetail"
        color={selected ? "goldenrod" : "#E0DBCE"}
        onClick={onSelected}
      />
    );
  };

  const Updata = () => {
    return (
      <>
        <div className="FindMD-img-contain mt-4">
          <div>
            <img
              className="Cardimg-docter"
              src={maindataitm?.patient_id?.basicInfo?.image}
              alt=""
            />
            <div></div>
          </div>
          <div className="Dorothy-Lucas-sapn-div">
            <div className="Height-awy-main-div">
              <div className="Dorothy-extra-div">
                <span
                  className="Dorothy-sapn"
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  {maindataitm?.patient_id?.basicInfo?.fullName}
                </span>
              </div>
              <div className="Height-w-bmi-div mt-2">
                <div className="Height-Weight-main-div">
                  <span>Height</span>
                  <span>
                    {maindataitm?.patient_id?.healthData?.height?.value}{" "}
                    {maindataitm?.patient_id?.healthData?.height?.unit}
                  </span>
                </div>
                <div className="Height-Weight-main-div">
                  <span>Weight</span>
                  {maindataitm?.patient_id?.healthData?.weight?.value}{" "}
                  {maindataitm?.patient_id?.healthData?.weight?.unit}
                </div>
                <div className="Height-Weight-main-div">
                  <span>BMI</span>
                  <span>
                    {" "}
                    {parseInt(maindataitm?.patient_id?.healthData?.BMI).toFixed(
                      2
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Appointment detail"}
        style={{ color: "white" }}
        extrafield={<Updata />}
      />
      <section className="BillingDoctor-main-section">
        <div className="BillingDoctor-main-div">
          <div className="Email-and-dorothy-price  mt-3">
            <div className="profile-age-div">
              <span className="age-main-span-add">Payment type</span>
              <span className="Payment-all-contint-span">Private payment</span>
            </div>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Status</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.status}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>

          <div className="profile-age-div mt-3">
            <span className="Payment-all-contint-span">
              Is your problem companied by new and unfamiliar symptoms such:
              {maindataitm?.symptoms.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <div>
                      <span className="Payment-all-contint-span">{item}</span>
                    </div>
                  </Fragment>
                );
              })}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Heath Data</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.patient_id?.healthData?.medicalCondition}{" "}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Past Medical Condition</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.patient_id?.healthData?.pastMedicalCondition}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Allergies</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.patient_id?.healthData?.allergies}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Medication</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.patient_id?.healthData?.medication}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Smoking</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.patient_id?.healthData?.smoking}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Alcohol</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.patient_id?.healthData?.alcohol}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Marijuana</span>
            <span className="Payment-all-contint-span">
              {maindataitm?.patient_id?.healthData?.marijuana}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
        </div>
      </section>
    </>
  );
};

export default CompleteAppointmentDetail;
