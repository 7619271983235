import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import "./ReferralDoctor.css";
import Header from "../../Components/Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import Signature from "../Signature/Signature";
import { useFormik } from "formik";
import { ReferralDoctorApi, UplodeImg } from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
// import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useDropzone } from "react-dropzone";
import { FiPlusCircle } from "react-icons/fi";
import { FaRegFileAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
import { Contenttype, Headers } from "../../Constant/Fetchdata";

const initialValues = {
  Reason: "",
  Doctors: "",
  HPI: "",
  Recreation: "",
};

const ReferralDoctor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const itm = location?.state?.id;
  const DoctarPath = location.pathname;
  // console.log(itm?._id, "route id");

  const DoctorEmailItam = location?.state?.IdPathMain;

  const getSavedRatingId = () => {
    return localStorage.getItem("RatingId");
  };
  const savedRatingId = getSavedRatingId();
  // console.log(savedRatingId, "maslevale");

  // console.log(DoctorEmailItam, "DoctorEmailItam");
  const [loading, setloading] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [currentPdfFile, setCurrentPdfFile] = useState([]);
  // console.log(currentPdfFile, "currentPdfFile");
  const { SigUrl } = useContext(GlobalContext);
  // console.log(SigUrl, "SigUrl");
  const [IDReferr, setIDReferr] = useState({
    Pathname: DoctarPath,
    IdPath: itm?._id || DoctorEmailItam?.IdPath,
  });

  // console.log(IDReferr, "IDReferr");
  const CallID = localStorage.getItem("CallNoteId");
  // console.log(pdfFiles, "pdfFiles");
  const header = Headers(Contenttype.formdata);
  const headerr = Headers();
  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        ReferralDoctorApi,
        {
          appointmentId:
            itm?._id || DoctorEmailItam?.IdPath || savedRatingId || CallID,
          reason_for_referral: values?.Reason,
          HPI: values?.HPI,
          recreational_drug_use_Hx: values?.Recreation,
          doctorEmail: DoctorEmailItam?.DocEmail,
          patientDocument: currentPdfFile,
          drSinature_image: SigUrl,
        },

        headerr
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.status);
      } else if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        navigate("/Notes", {
          state: {
            DoctorEmailItam,
          },
        });
      }

      // console.log(res, "jvcccfh");
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  // const params = {
  //   image: pdfFiles,
  //   // end_date: "",
  // };
  // const { response: Geturl, isloading } = Fetchdata(UplodeImg, params, "POST");

  const GetUrl = async (file) => {
    setloading(true);

    const formdata = new FormData();
    formdata.append("image", file);

    try {
      const res = await axios.post(UplodeImg, formdata, header);

      setloading(false);
      // console.log(res);

      setCurrentPdfFile((prev) => [...prev, res?.data?.image]);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const handleRemovePdf = (index) => {
    setCurrentPdfFile((prev) => {
      const updatedPdfFiles = [...prev];
      updatedPdfFiles.splice(index, 1);
      return updatedPdfFiles;
    });
  };

  // const GetUrl = async () => {
  //   setloading(true);
  //   const Pdflink = pdfFiles.map((item) => item.path);
  //   console.log(Pdflink, "Sakirrr");
  //   try {
  //     const res = await axios.post(
  //       UplodeImg,
  //       {
  //         image: Pdflink,
  //       },

  //       header
  //     );
  //     setloading(false);
  //     console.log(res, "jvcccfh");
  //   } catch (error) {
  //     console.log(error);
  //     setloading(false);
  //   }
  // };

  // useEffect(() => {
  //   GetUrl();
  // }, []);
  // console.log(Geturl, "jajajajaj");
  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  // useEffect(() => {
  //   if (pdfFiles.length > 0) {
  //     setCurrentPdfFile(pdfFiles[pdfFiles.length - 1]);
  //   }
  // }, [pdfFiles]);

  // useEffect(() => {
  //   GetUrl();
  // }, [pdfFiles]);

  // const onDrop = (acceptedFiles) => {
  //   const selectedPdfFile = acceptedFiles.find(
  //     (file) => file.type === "application/pdf"
  //   );

  //   if (selectedPdfFile) {
  //     setPdfFiles((prevPdfFiles) => [...prevPdfFiles, selectedPdfFile]);
  //   } else {
  //     alert("Please upload a valid PDF file.");
  //   }
  // };

  // console.log(pdfFiles, "devillllllll");
  // const handleRemovePdf = (index) => {
  //   setPdfFiles((prevPdfFiles) => {
  //     const updatedPdfFiles = [...prevPdfFiles];
  //     updatedPdfFiles.splice(index, 1);
  //     return updatedPdfFiles;
  //   });
  // };

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  //   accept: ".pdf",
  // });

  const handleDoctoer = () => {
    navigate("/DoctorBook", {
      state: { IDReferr },
    });
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Referral"}
        style={{ color: "white" }}
      />
      <section className="ReferralDoctor-main-section">
        <div className="ReferralDoctor-main-div">
          <form onSubmit={handleSubmit}>
            <div className="">
              <span className="Doctor-span-Skip">Reason for Consult</span>
              <div className="text-Bank-fullname mt-2">
                <input
                  className="i-t-a-Bank-itm"
                  type="text"
                  placeholder="Enter Reason"
                  name="Reason"
                  value={values.Reason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="">
              <div className="span-Subjective-div">
                <span className="Doctor-span-Skip">HPI</span>
              </div>
              <textarea
                id="w3review"
                name="HPI"
                rows="5"
                className="Subjective-HPI-2   mt-2"
                cols="40"
                placeholder="Enter HPI"
                value={values.HPI}
                onBlur={handleBlur}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="mt-3">
              <span className="Doctor-span-Skip">Doctor's Email</span>
              <div className="text-Bank-fullname  mt-2">
                <input
                  className="email-main-imnput-div"
                  type="email"
                  placeholder="Email"
                  name="Doctors"
                  value={DoctorEmailItam?.DocEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onClick={handleDoctoer}
                />
              </div>
            </div>
            {/* <div>
              <div className="mt-4">
                <input {...getInputProps()} />

                <div {...getRootProps()} className="FiPlusCircle-main-div">
                  <FiPlusCircle className="FiPlusCircle-amin" />
                </div>
              </div>

              {currentPdfFile && (
                <div>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist/build/pdf.worker.min.js`}
                  >
                    {({ data, error, loading }) => (
                      <div>
                        {loading && <p>Loading...</p>}
                        {error && <p>Error loading PDF: {error.message}</p>}
                        {data && <Viewer fileUrl={currentPdfFile.preview} />}
                      </div>
                    )}
                  </Worker>
                </div>
              )}

              {pdfFiles.length > 0 && (
                <div>
                  <div className="FiPlusCircle-and-FaRegFileAlt">
                    {pdfFiles.map((file, index) => (
                      <>
                        <div className="handleRemovePdf-main">
                          <div className="FiPlusCircle-main-div">
                            <FaRegFileAlt className="FaRegFileAlt-amin" />
                          </div>
                          <MdCancel
                            className="MdCancel-main"
                            onClick={() => handleRemovePdf(index)}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div> */}

            {/* <div className="FiPlusCircle-main-div">
              <FiPlusCircle className="FiPlusCircle-amin" />
            </div> */}

            <label className="FiPlusCircle-main-div mt-4">
              <FiPlusCircle className="FiPlusCircle-amin" />
              <input
                type="file"
                name="pdfFile"
                id="pdfFile"
                style={{ display: "none" }}
                onChange={(e) => GetUrl(e.target.files[0])}
              />
            </label>

            <div className="FiPlusCircle-and-FaRegFileAlt">
              {currentPdfFile.map((file, index) => (
                <>
                  <div className="handleRemovePdf-main">
                    <div className="FiPlusCircle-main-div">
                      <FaRegFileAlt className="FaRegFileAlt-amin" />
                    </div>
                    <MdCancel
                      className="MdCancel-main"
                      onClick={() => handleRemovePdf(index)}
                    />
                  </div>
                </>
              ))}
            </div>
            {/* {currentPdfFile?.map((image, index) => {
              // let imgURL = URL.createObjectURL(image);
              return (
                <div key={index} className="sab-img-ka-div">
                  <div className="FiPlusCircle-main-div">
                    <FaRegFileAlt className="FaRegFileAlt-amin" />
                  </div>
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Image-${index}`}
                    className="add-kiya-huva-pic"
                    style={{ width: "79.71px", height: "79.71px" }}
                  />
                  <button
                    className="Btn-dada-delet"
                    // onClick={() => handimgleDelete(index)}
                  >
                    <svg
                      className="Ma-svg-top"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_25_3172)">
                        <path
                          d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                          fill="#211B24"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_25_3172">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              );
            })} */}

            <div className="">
              <div className="span-Subjective-div">
                <span className="Doctor-span-Skip">Recreation Drug use Hx</span>
              </div>
              <textarea
                id="w3review"
                name="Recreation"
                rows="5"
                className="Subjective-HPI-2   mt-2"
                cols="40"
                placeholder="Enter Reason"
                value={values.Recreation}
                onBlur={handleBlur}
                onChange={handleChange}
              ></textarea>
            </div>
            <Signature />
            <div className="mt-5">
              <button
                onClick={onSubmit}
                type="submit"
                className="ReferralDoctor-Register-karo"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </section>
      {loading && <Loader />}
    </>
  );
};

export default ReferralDoctor;
