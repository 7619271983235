import React, { useEffect, useState } from "react";
import "./UpcomingAppointment2.css";
// import location from "../../Assets/Images/location_ic_sm@3x.png";
// import Cardimg from "../../Assets/Images/ForgotPasswordImg.avif";
import Countdwon from "../../Assets/Images/countdown_blue_ic@3x.png";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
import { toast } from "react-toastify";
import axios from "axios";
import { StopTimeGet, StopTimeSet } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";

const UpcomingAppointment2 = () => {
  const location = useLocation();
  const DetaUpcoming = location?.state?.itm;

  // console.log(DetaUpcoming);

  const PathSedul = location.pathname;
  // console.log(PathSedul, "PathSedul");
  const curantdate = moment.utc().format();
  const UtcTime = DetaUpcoming?.utc_time;
  const utcDate = new Date(UtcTime); // Convert the UTC time string to a Date object
  // const newUtcDate = new Date(utcDate.getTime() + 30 * 60000);
  // console.log(newUtcDate, "newUtcDate");
  // console.log(utcDate, "utcDate");

  const formated = Dateformat(DetaUpcoming?.utc_time);

  const endDatetime = moment.utc(DetaUpcoming?.utc_time, "YYYY-MM-DD HH:mm:ss");
  const startDatetime = moment.utc(curantdate, "YYYY-MM-DD HH:mm:ss");

  const timeDifference = moment.duration(endDatetime.diff(startDatetime));

  const updatedTimeDifference = timeDifference.add(15, "minutes");

  // console.log(updatedTimeDifference._data.minutes, "updatedTimeDifference");

  const diffFormatted = `${updatedTimeDifference.days()} D : ${updatedTimeDifference.hours()} H : ${updatedTimeDifference.minutes()} M : ${updatedTimeDifference.seconds()} S:`;

  // console.log(diffFormatted, "diffFormatted.hours");

  const [timeRemaining, setTimeRemaining] = useState(0);

  // const [showBtn, setShowBtn] = useState(false);
  // console.log(showBtn);
  // console.log(curantdate >= utcDate && curantdate <= newUtcDate);

  useEffect(() => {
    if (
      updatedTimeDifference.minutes() < 0 ||
      updatedTimeDifference.seconds() < 0
    ) {
      navigate("/Upcoming-Appointment");
      toast.error("Appointment time end");
    } else if (
      updatedTimeDifference.minutes() !== 0 ||
      updatedTimeDifference.seconds() !== 0
    ) {
      const intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1000);
        // if (curantdate >= utcDate && curantdate <= newUtcDate) {
        //   setShowBtn(true);
        // } else {
        //   setShowBtn(false);
        // }
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      navigate("/Upcoming-Appointment");
      toast.error("Appointment time end");
    }
  }, [updatedTimeDifference.seconds(), updatedTimeDifference.minutes()]);

  // console.log(showBtn);
  // const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  // const hours = Math.floor(
  //   (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  // );
  // const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  const navigate = useNavigate();

  const hendelData = () => {
    navigate("/BookandAppoinmentSlote", {
      state: {
        DetaUpcoming,
        PathSedul,
      },
    });
  };
  const Updata = () => {
    return (
      <>
        <div style={{ color: "white" }} className="mt-3 img-name-field-main">
          <div>
            <img
              className="Updata-main-img"
              src={DetaUpcoming?.doctor_id?.basicInfo?.image}
              alt=""
            />
          </div>
          <div className="General-span-Medicine-span">
            <span>{DetaUpcoming?.doctor_id?.basicInfo?.fullName}</span>
            <span>{DetaUpcoming?.doctor_id?.speciality}</span>
          </div>
        </div>
      </>
    );
  };
  const itm = DetaUpcoming;
  const header = Headers();
  const StoTimeApi = async (time, id) => {
    try {
      const res = await axios.post(
        StopTimeSet,
        {
          appointmentID: id,
          videoStopTime: time,
        },
        header
      );
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const TimegetApi = async (id) => {
    try {
      const res = await axios.post(
        StopTimeGet,
        {
          appointmentID: id,
        },
        header
      );
      if (res.data.data === "" || res.data.data === null) {
        // setTimeRemaining(res.data.data);
        // console.log("null");
        StoTimeApi("15", id);
        navigate("/meeting", {
          state: {
            itm,
          },
        });
        // console.log(res.data.data);
      } else {
        // console.log("success");
        StoTimeApi(res.data.data, id);
        navigate("/meeting", {
          state: {
            itm,
          },
        });
        // setTimeRemaining(formattedDate);
      }
      // console.log(res);
      // console.warn("minute he ye", res);
      // setTimeRemaining(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Upcoming appointment"}
        extrafield={<Updata />}
        style={{ color: "white" }}
      />
      <section className="UpcomingAppointment2-main-section">
        <div className="UpcomingAppointment2-main-div">
          <div className="UpcomingAppointment2-thord-div">
            <div className="Consultation-type-Video">
              <span className="Consultation-type-sapn">
                Consultation Type & Time
              </span>
              <span className="Today-main-date-span">{formated}</span>

              <div className="Video-Chat-Consult-div mt-4">
                <span className="Video-main-span">
                  {" "}
                  {DetaUpcoming?.appointmentType} Consult
                </span>
              </div>
            </div>
            <div className="d-h-m-s-div">
              <span>
                <img className="Countdwon-img-sapn" src={Countdwon} alt="" />
                {diffFormatted}
              </span>
            </div>
          </div>
          <div className="h-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">
              Reason for appointment
            </span>
            <span>{DetaUpcoming?.reasonAppointment}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Payment-for-appointment-div mt-3">
            <div className="Method-of-Card">
              <span className="Consultation-type-sapn">Payment Method</span>
              <span>{DetaUpcoming?.choose_payment_method}</span>
            </div>
            <div>
              <span className="thartin-span-div">
                {DetaUpcoming?.payment_currency}{" "}
                {DetaUpcoming?.consultation_fee}
              </span>
            </div>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Status</span>
            <span>{DetaUpcoming?.status}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Address</span>
            <span>{DetaUpcoming?.doctor_id?.basicInfo?.address}</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="mt-5 mb-3 Reschedule-Start-div">
            <button className="Reschedule-btn" onClick={hendelData}>
              Reschedule
            </button>
            {updatedTimeDifference.minutes() !== 0 &&
              updatedTimeDifference.days() === 0 &&
              updatedTimeDifference._data.hours === 0 &&
              updatedTimeDifference._data.minutes <= 14 && (
                <button
                  className="Reschedule-Start-btn"
                  type="submit"
                  onClick={() => TimegetApi(itm._id)}
                >
                  Start
                </button>
              )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UpcomingAppointment2;
