import React, { useEffect, useState } from "react";
import "./Faq.css";
import Accordion from "react-bootstrap/Accordion";
import { BiRightArrowAlt } from "react-icons/bi";
import { AiOutlineMinusSquare } from "react-icons/ai";
import { AiOutlinePlusSquare } from "react-icons/ai";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import axios from "../../Constant/ApiUrl";
import { FAQSAPI } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
const Faq = () => {
  const navigate = useNavigate();
  const [Faqsdata, setfaqsdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [accordionState, setAccordionState] = useState(
    Faqsdata.reduce((acc, item) => {
      acc[item._id] = false;
      return acc;
    }, {})
  );

  const Getfaqs = async () => {
    setloading(true);
    try {
      const res = await axios.get(FAQSAPI);
      setfaqsdata(res.data.data);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    Getfaqs();
  }, []);

  const handleToggle = (id) => {
    setAccordionState({
      ...accordionState,
      [id]: !accordionState[id],
    });
  };
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"FaQ"} />
      <section className="faq-main-section">
        <div className="faq-main-div">
          <h1>FAQ's</h1>
          {loading ? (
            <SimpleLoader style={{ height: "300px" }} />
          ) : (
            <div className="accordion_div_FAQ">
              {Faqsdata?.map((Item) => {
                return (
                  <Accordion style={{ width: "100%" }} key={Item._id}>
                    <Accordion.Item style={{ width: "100%" }} eventKey="0">
                      <Accordion.Header onClick={() => handleToggle(Item._id)}>
                        <div className="main_Qus_div">
                          <div className="Qusetion_text">{Item.question}</div>
                          <div>
                            <div>
                              {accordionState[Item._id] ? (
                                <AiOutlineMinusSquare className="AiOutlineMinusSquare-main-icon" />
                              ) : (
                                <AiOutlinePlusSquare className="AiOutlinePlusSquare-main-icon" />
                              )}
                            </div>
                          </div>
                        </div>
                      </Accordion.Header>
                      {accordionState[Item._id] && (
                        <Accordion.Body>
                          <BiRightArrowAlt /> {Item.answer}
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Faq;
