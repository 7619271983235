import React, { useState } from "react";
import card from "../../Assets/Images/cardpoint.png";
import Card2 from "../../Assets/Images/cardpoint2.png";
import "./MyCards.css";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
const MyCards = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"My cards"} />
      <section className="Notifications-main-section">
        <div className="Notifications-main-div">
          <div className="payment-span-divv-head">
            <span className="Payment-Options-span">My Card</span>
          </div>
          <div className="pymaint-div-thard">
            <div className="payment-input67">
              <img src={card} alt="" className="visaimg-img" />
              <span className="card-span-divv">XXXX XXXX XXXX3455</span>
              <div className="dustbin-logo-black">
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_25_2108)">
                    <path
                      d="M9.99935 18.3333C5.39685 18.3333 1.66602 14.6025 1.66602 10C1.66602 5.3975 5.39685 1.66667 9.99935 1.66667C14.6018 1.66667 18.3327 5.3975 18.3327 10C18.3327 14.6025 14.6018 18.3333 9.99935 18.3333ZM9.16852 13.3333L15.0602 7.44083L13.8818 6.2625L9.16852 10.9767L6.81102 8.61917L5.63268 9.7975L9.16852 13.3333Z"
                      fill="#3620FF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_25_2108">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_25_2090)">
                      <path
                        d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                        fill="#232B37"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_25_2090">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="payment-input67">
              <img src={Card2} alt="" className="visaimg-img" />
              <span className="card-span-divv">XXXX XXXX XXXX3455</span>
              <div className="dustbin-logo-black">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_25_2090)">
                      <path
                        d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                        fill="#232B37"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_25_2090">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="add-card-main-svg-div">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_25_3149)">
                <path
                  d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
                  fill="#3620FF"
                />
              </g>
              <defs>
                <clipPath id="clip0_25_3149">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span className="New-ard-add-span">Add New Card</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyCards;
