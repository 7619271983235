import React, { useContext, useEffect, useState } from "react";
import "../ForgotPassword/ForgotPassword.css";
import ForgotImg from "../../Assets/Images/ForgotPasswordImg.avif";
import { useFormik } from "formik";
import { ForgotPasswordSchemas } from "../../Schemas/index";
import Emailicon from "../../Assets/Images/emailicon.png";
import LoginLogo from "../../Assets/Images/login_logo@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import Input from "../../Components/Input/Input";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { GlobalContext } from "../../Context/GlabalContext";
import axios from "../../Constant/ApiUrl";
import {
  DOCTERRFORGOTPASS,
  PATIENTFORGOTPASS,
} from "../../Constant/Apiconstant";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Constant/Loader";
import { Icons } from "../../Constant/Icons";

const ForgotPassword = () => {
  const { role } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (role === "") {
      toast.error("Select Role");
      navigate("/");
    }
  }, [role]);

  const initialValues = {
    email: "",
  };

  const onSubmit = async (value, { resetForm }) => {
    setloading(true);
    let url =
      role === "patient"
        ? PATIENTFORGOTPASS
        : role === "physician"
        ? DOCTERRFORGOTPASS
        : null;
    try {
      const res = await axios.post(
        url,
        {
          email: values.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setloading(false);
      if (res.data.status == "failed") {
        toast.error(res.data.message);
      } else if (res.data.status == "success") {
        toast.success(res.data.message);
        // console.log("run");
        resetForm();
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ForgotPasswordSchemas,
    onSubmit,
  });

  return (
    <>
      <section className="ForgotPassword-main-section position-relative">
        <span
          style={{
            position: "absolute",
            left: "1rem",
            top: "1rem",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          {Icons.backarrowblack}
        </span>
        <form onSubmit={handleSubmit} className="ForgotPassword-main-div">
          <div>
            <img className="ForgotImg-img" src={ForgotImg} alt="" />
          </div>
          <div>
            <div className="width-percentage-div">
              <img className="ForgotImg-blue" src={Blue} alt="" />
              <div className="LoginLogo-div-main-foorget">
                <div className="LoginLogo-mtd-logo">
                  <img className="LOgin-Logo" src={LoginLogo} alt="" />
                </div>
                <div>
                  <span className="Forgot-Password-span12">
                    Forgot Password
                  </span>
                </div>
                <div className="Forgot-Password-span1244-div">
                  <span className="Forgot-Password-span1244">
                    Enter your registered email address we will send you link to
                    reset password.
                  </span>
                </div>
                <div className="fielddiv mb-3">
                  <Input
                    topimg={Emailicon}
                    placeholder={"Email"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={"email"}
                    value={values.email}
                    name={"email"}
                  />
                  {errors.email && touched.email ? (
                    <p className="mastu-to-eroor mt-2">{errors.email}</p>
                  ) : null}
                </div>
                <Buttton style={{ backgroundColor: Colors.blue }}>
                  Reset Password
                </Buttton>
              </div>
            </div>
          </div>
        </form>
      </section>
      {/* <div className="Login-section-main-div">
        <div className="Login-section-parent-div">
          <div className="login-img-img-div">
            <div className="dado-img-login-dono mb-2">
              <img src={ForgotImg} alt="" className="login-img-img" />
            </div>
          </div>
        </div>
        <form className="Blue-main-div">
          <img className="Blue-img" src={Blue} alt="" />
          <div className="login-section-all-input-div"></div>
        </form>
      </div>
       */}
      {loading && <Loader />}
    </>
  );
};

export default ForgotPassword;
