import React, { useEffect, useState } from "react";
import { Labtest } from "../../Constant/DummyData";
import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import { useLocation, useNavigate } from "react-router-dom";
import { OntarioApi } from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
import Header from "../../Components/Header/Header";

const LabNotes = () => {
  const header = Headers();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;
  // console.log(id, "route id");
  const [Ontario, setOntario] = useState(false);
  // console.log(Ontario, "Ontario");
  const handlenavigate = (item) => {
    const id = location.state?.id;
    setTimeout(() => {
      navigate(item.link, { state: { id } });
    }, 300);
  };

  const { response, isloading } = Fetchdata(
    OntarioApi,

    { header },
    "GET"
  );
  useEffect(() => {
    if (response?.data) {
      setOntario(response.data.isOntario);
    }
  }, [response]);
  // console.log(response, "response");

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Lab notes"}
        style={{ color: "white" }}
      />
      <section className="FindMD-main-section">
        <div className="SelectProfile-main-div">
          <>
            <div className="Profileimg-Pratt-div">
              {Labtest.map((itm, index) => {
                if (index !== 1 || Ontario) {
                  {
                    /* console.log(index, "a"); */
                  }
                  return (
                    <div
                      onClick={() => handlenavigate(itm, index)}
                      className="NotesIcon1-and-name"
                    >
                      {itm.icon}
                      <span className="Lena-Pratt-span">{itm.name}</span>
                    </div>
                  );
                }
              })}
            </div>
          </>
        </div>
      </section>
    </>
  );
};

export default LabNotes;
