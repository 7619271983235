import React, { useState, useContext, useEffect } from "react";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Health from "../../Assets/Images/HealthDataImg.avif";
import time from "../../Assets/Images/time_ic@3x.png";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Fetchdata from "../../Constant/Fetchdata";
import { IncompleteNotesApi } from "../../Constant/Apiconstant";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
import { SimpleLoader } from "../../Constant/Loader";
import { Headers } from "../../Constant/Fetchdata";
import { GlobalContext } from "../../Context/GlabalContext";

const IncompleteNotes = () => {
  const navigate = useNavigate();

  const header = Headers();
  const { response, isloading } = Fetchdata(
    IncompleteNotesApi,

    { header },
    "GET"
  );
  // console.log(response);
  const location = useLocation();
  const IncompletPath = location.pathname;

  // console.log(IncompletPath, "IncompletPath");
  const handleNote = async (itm) => {
    // console.log(itm, "itm");
    navigate("/Notes", {
      state: {
        itm,
      },
    });
    localStorage.setItem("BacPath", IncompletPath);
  };

  return (
    <>
      <Header
        onClick={() => navigate("/Home")}
        text={" Incomplete appointment"}
        style={{ color: "white" }}
      />
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <section className="FindMD-main-section">
          {!response?.data ? (
            <h1>{response?.message}</h1>
          ) : (
            <div className="FindMD-main-div">
              {response?.data?.map((itm, index) => {
                const info = itm.patient_id.basicInfo;
                const healthdata = itm.patient_id.healthData;
                const formatedtime = Dateformat(itm.date);
                return (
                  <div
                    onClick={() => handleNote(itm)}
                    className="FindMD-img-contain"
                    key={itm._id}
                  >
                    <div>
                      <img className="Cardimg-docter" src={info.image} alt="" />
                    </div>
                    <div className="Dorothy-Lucas-sapn-div">
                      <div className="Height-awy-main-div">
                        <div className="Dorothy-extra-div">
                          <span className="Dorothy-sapn">{info.fullName}</span>
                        </div>
                        <div className="">
                          <img className="time-img-css" src={time} alt="" />
                          <span className="Am-pm-span"> {formatedtime}</span>
                        </div>
                        <div className="Height-w-bmi-div mt-2">
                          <div className="Height-Weight-main-div">
                            <span>Height</span>
                            <span>{healthdata.height.value}</span>
                          </div>
                          <div className="Height-Weight-main-div">
                            <span>Weight</span>
                            <span>{healthdata.weight.value}</span>
                          </div>
                          <div className="Height-Weight-main-div">
                            <span>BMI</span>
                            <span>{parseInt(healthdata.BMI).toFixed(2)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default IncompleteNotes;
