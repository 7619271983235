import React, { useState, useContext, useEffect } from "react";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Header from "../../Components/Header/Header";
import { Icons } from "../../Constant/Icons";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { SendtolebApi, SendtolebPostApi } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
const Sendtoleb = () => {
  const [loading, setloading] = useState(false);

  const MoyaMoya = location?.state?.itm;
  const id = MoyaMoya?._id;

  const [selectedcard, setselectedcard] = useState({
    cardid: "",
    isSelected: null,
  });
  const navigate = useNavigate();
  const header = Headers();
  const { response } = Fetchdata(
    SendtolebApi,

    { header },
    "GET"
  );
  // console.log(response, "response");
  const toggleSelection = (currentIndex, cardId) => {
    setselectedcard((prevSelectedCard) => {
      const isSelected =
        prevSelectedCard.isSelected === currentIndex ? null : currentIndex;

      return { isSelected, cardid: isSelected ? cardId : null };
    });
  };

  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        SendtolebPostApi,
        {
          appointmentID: response?.data[0]?._id,
          email: response?.data[0]?.email,
        },
        header
      );
      setloading(false);
      // console.log(res, "jvcccfh");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const hendelPhae = (item) => {
    // console.log(item, "item");
    navigate("/Xrayultrasound", {
      state: {
        item,
        id,
      },
    });
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Select lab"}
        style={{ color: "white" }}
      />
      <section>
        <div className="">
          {response?.data?.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  onClick={() => toggleSelection(index, item.id)}
                  className="FindMD-img-contain"
                >
                  <div>
                    <img className="Cardimg-docter" src={item.image} alt="" />
                  </div>
                  <div className="Dorothy-Lucas-sapn-div">
                    <div className="Height-awy-main-div">
                      <div className="isSelected-main-div">
                        <span className="Dorothy-sapn">{item.labName}</span>
                        <div className="dustbin-logo-black">
                          <span onClick={() => toggleSelection(index)}>
                            {selectedcard.isSelected === index
                              ? Icons.cardselecticon
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <img className="time-img-css" src={location} alt="" />
                        <span className="Am-pm-span">{item.streetAddress}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="  Select-main-section">
                  <button
                    onClick={() => hendelPhae(item)}
                    className="Add-search-New"
                  >
                    Select
                  </button>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Sendtoleb;
