import { Backdrop, CircularProgress } from "@mui/material";
import { Colors } from "./Colors";
import { useState } from "react";

const Loader = () => {
  const [open, setopen] = useState(true);
  return (
    <Backdrop
      sx={{ color: Colors.blue, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      style={{ zIndex: "100000" }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;

export const SimpleLoader = ({ style }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center w-100 "
      style={style}
    >
      <CircularProgress style={{ color: Colors.pink }} />
    </div>
  );
};
