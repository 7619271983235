import React from "react";
import { OntarioFormData } from "../../Constant/DummyData";
import { useLocation, useNavigate } from "react-router-dom";

const OntarioForm = () => {
  const location = useLocation();
  const id = location.state?.id;
  // console.log(id, "route id");

  const navigate = useNavigate();
  const handlenavigate = (item) => {
    const id = location.state?.id;
    setTimeout(() => {
      navigate(item.link, { state: { id } });
    }, 300);
  };
  return (
    <>
      <section className="FindMD-main-section">
        <div className="SelectProfile-main-div">
          <>
            <div className="Profileimg-Pratt-div">
              {OntarioFormData.map((itm, index) => {
                return (
                  <div
                    onClick={() => handlenavigate(itm, index)}
                    className="NotesIcon1-and-name"
                  >
                    {itm.icon}
                    <span className="Lena-Pratt-span">{itm.name}</span>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </section>
    </>
  );
};

export default OntarioForm;
