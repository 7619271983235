import React, { useContext, useEffect, useState } from "react";
import "./WaitingRoom.css";
import Logo from "../../Assets/Images/login_logo@3x.png";
import Countdwon from "../../Assets/Images/countdown_blue_ic@3x.png";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import {
  WaitingRoomAPI,
  // WaitingRoomjoinigAPI,
  GetRoomTime,
  StopTimeGet,
  StopTimeSet,
} from "../../Constant/Apiconstant";
import DataSuccessfully from "../../Doctor/DataSuccessfully/DataSuccessfully";
import { GlobalContext } from "../../Context/GlabalContext";
import RateDoctor from "../RateDoctor/RateDoctor";
import { SimpleLoader } from "../../Constant/Loader";
// import CutVedioCallpopup from "../../Doctor/CutVedioCallpopup/CutVedioCallpopup";
import { store } from "../../Redux/store";
import { KEY_USER_DATA, Meetingdata } from "../../Redux/Appconstant";
import { Skeleton } from "@mui/material";
import { Icons } from "../../Constant/Icons";
import axios from "../../Constant/ApiUrl";

const WaitingRoom = () => {
  const navigate = useNavigate();
  // const route = useLocation();
  // const PationData = route?.state?.data;
  const maindata = store.getState().session[Meetingdata];
  // const userdata = store.getState().session[KEY_USER_DATA];
  const [timeRemaining, setTimeRemaining] = useState(0);
  const {
    PatcutCall,
    setPatcutCall,
    videocallstartbydoctor,
    setvideocallstartbydoctor,
    ratingpopup,
    setratingpopup,
    isCutcall,
    setisCutcall,
  } = useContext(GlobalContext);
  const [show, setShow] = useState(false);
  const { response, isloading, getdata } = Fetchdata(WaitingRoomAPI, {}, "GET");

  const { response: GetTime } = Fetchdata(GetRoomTime, {}, "GET");
  // console.log(GetTime, "GetTime");
  // console.log(response);
  const StartTiem = GetTime?.data?.endTime;
  const endTiem = GetTime?.data?.startTime;
  // console.log(GetTime);

  useEffect(() => {
    if (videocallstartbydoctor) {
      getdata();
      setvideocallstartbydoctor(false);
    }
  }, [videocallstartbydoctor]);

  // console.log(videocallstartbydoctor);
  useEffect(() => {
    getdata();
  }, [ratingpopup]);

  useEffect(() => {
    if (isCutcall) {
      getdata();
      setisCutcall(false);
    }
  }, [isCutcall]);

  // console.log(isCutcall);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remainingTime = getRemainingTime();
      setTimeRemaining(remainingTime);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [GetTime]);

  const getRemainingTime = () => {
    if (GetTime) {
      const currentDate = new Date();
      const startTime = new Date(currentDate.toDateString() + " " + StartTiem);
      const endTime = new Date(currentDate.toDateString() + " " + endTiem);
      let remainingTime = endTime - currentDate;
      if (remainingTime < 0) {
        // If end time is before current time, add 24 hours to end time
        endTime.setDate(endTime.getDate() + 1);
        remainingTime = endTime - currentDate;
      }
      return remainingTime;
    }
  };

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  const header = Headers();
  const StoTimeApi = async (time, id) => {
    // console.log(time, "time");
    try {
      const res = await axios.post(
        StopTimeSet,
        {
          appointmentID: id,
          videoStopTime: time,
        },
        header
      );
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const TimegetApi = async (id) => {
    try {
      const res = await axios.post(
        StopTimeGet,
        {
          appointmentID: id,
        },
        header
      );
      if (res.data.data === "" || res.data.data === null) {
        // setTimeRemaining(res.data.data);
        StoTimeApi("15", id);
        // console.log(res.data.data);
      } else {
        StoTimeApi(res.data.data, id);
        // setTimeRemaining(formattedDate);
      }
      // console.log(res);
      // console.warn("minute he ye", res);
      // setTimeRemaining(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(response?.data.waitingRoomId);
  const handleJoine = () => {
    setratingpopup(false);
    const itm = response?.data;
    TimegetApi(response?.data?.waitingRoomId);
    navigate("/meeting", {
      state: {
        itm,
      },
    });
  };

  // console.log(ratingpopup);

  return (
    <>
      <Header onClick={() => navigate("/home-page")} text={"Waiting room"} />
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <>
          {response?.data ? (
            <section className="WaitingRoom-main-section">
              <div className="WaitingRoom-main-div">
                <div className="WaitingRoom-thard-img-co">
                  <div className="img-logo-main-icon-conti">
                    <div>
                      <img
                        className="WaitingRoom-logo-main-img"
                        src={Logo}
                        alt=""
                      />
                    </div>
                    <div className="taime-date-Wating">
                      {!hours ? (
                        <Skeleton width={150} height={30} variant="text" />
                      ) : (
                        <div className="d-h-m-s-div">
                          <span>
                            <img
                              className="Countdwon-img-sapn"
                              src={Countdwon}
                              alt=""
                            />
                            {`${days}D: ${hours}H: ${minutes}M: ${seconds}S`}
                          </span>
                        </div>
                      )}
                      <span className="mt-2 Waiting-for-main-span">
                        Waiting for available doctor
                      </span>
                      <div className="mt-1">
                        <span> {Icons.user}</span>
                        <span className="Patient-Mark-span">
                          Patient : {""} {response?.data?.patientName}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="Chest-date-Wating mt-3">
                    <span className="Waiting-for-main-span">
                      Consultation For
                    </span>
                  </div>
                  <div className="fith-tin-div mt-3">
                    <div className="Serving-Number-div-span">
                      <span className="five-handred">
                        {response?.data?.servingNumber}
                      </span>
                      <span className="Number-span-one">Serving Number</span>
                    </div>
                    <div className="Your-Number-div-span">
                      <span className="five-handred">
                        {" "}
                        {response?.data?.patientNumber}
                      </span>
                      <span className="Number-span-one">Your Number</span>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  {response?.data?.servingNumber ===
                    response?.data?.patientNumber && (
                    <button
                      className="Submit-Main-btn-Bim"
                      onClick={handleJoine}
                    >
                      Join
                    </button>
                  )}
                </div>
              </div>
            </section>
          ) : (
            <h1 className="text-center pt-4">{response?.message}</h1>
          )}
        </>
      )}
      {PatcutCall && (
        <DataSuccessfully
          PatcutCall={PatcutCall}
          handleCloseeee={() => setShow(true)}
          setPatcutCall={setPatcutCall}
          text={"Your appointment is completed."}
        />
      )}
      {ratingpopup && (
        <RateDoctor
          show={ratingpopup}
          setShow={setratingpopup}
          handleCloseee={() => setShow(false)}
          // PationData={PationData}
          maindataitm={maindata}
        />
      )}
    </>
  );
};

export default WaitingRoom;
