import React, { Fragment, useEffect, useState } from "react";
import "./Notifications.css";
import Doctor from "../../Assets/Images/dr.avif";
import Delet from "../../Assets/Images/delete_blue_ic@3x.png";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import {
  DELETENOTIFICATIONAPI,
  DOCTORNOTIFICATIONAPI,
  DOCTORDELETENOTIFICATIONAPI,
  NOTIFICATIONAPI,
} from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
import axios from "../../Constant/ApiUrl";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
// import { Dateformat } from "../../Components/Dateformat/Dateformat";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { toast } from "react-toastify";

const Notifications = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [Notificationid, setnotificationid] = useState([]);
  const Mydata = store.getState().session[KEY_USER_DATA];
  const location = useLocation();
  const NotiPath = location.pathname;
  const Notpath = location.pathname;

  // console.log(Notpath, "Notpath");

  // getall notification api url.........................
  let url =
    Mydata?.basicInfo?.role == "patient"
      ? NOTIFICATIONAPI
      : Mydata?.basicInfo?.role == "physician"
      ? DOCTORNOTIFICATIONAPI
      : null;

  // etall notification api with custom hook
  const { response, isloading, getdata } = Fetchdata(url, {}, "GET");
  const [data, setData] = useState();
  useEffect(() => {
    setData(response?.data);
  }, [response]);

  const handleCheckboxChange = (item, index) => {
    if (Notificationid.includes(item._id)) {
      setnotificationid((prev) => prev.filter((idd) => idd !== item._id));
    } else {
      setnotificationid((prev) => [...prev, item._id]);
    }
  };

  useEffect(() => {
    if (Notificationid?.length !== data?.length) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  }, [Notificationid]);

  const handleselectall = () => {
    const idss = data?.map((item) => {
      return item._id;
    });
    if (!selectAll) {
      setSelectAll(true);
      setnotificationid(idss);
    } else {
      setnotificationid([]);
      setSelectAll(false);
    }
  };

  const navigate = useNavigate();
  const header = Headers();

  // delete api url..............
  let DELETURL =
    Mydata.basicInfo.role == "patient"
      ? DELETENOTIFICATIONAPI
      : Mydata.basicInfo.role == "physician"
      ? DOCTORDELETENOTIFICATIONAPI
      : null;

  // delete api call..........................
  const Deletenotification = async () => {
    const params = { ids: Notificationid, isallDeletedNotification: selectAll };
    try {
      const res = await axios.post(DELETURL, params, header);
      // console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        getdata();
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HendelView = (Notitm) => {
    navigate("/Prescription", {
      state: { NotiPath, Notitm },
    });
  };

  const PandigViwe = () => {
    navigate("/PendingRequests", {
      state: { Notpath },
    });
  };
  const RejectList = (Rejectitm) => {
    // console.log(Rejectitm, "Rejectitm");
    navigate("/PrescriptionRejectList", {
      state: { Rejectitm },
    });
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Notification"}
        secondtext={data && selectAll ? "UnSelect All" : "Select all"}
        secondClick={handleselectall}
      />
      <section className="Notifications-main-section">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <>
            {data ? (
              <div className="Notifications-main-div">
                {data?.map((itm, index) => {
                  const formattedDate = new Date(
                    itm.notificationSendAt
                  ).toLocaleString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  });

                  return (
                    <Fragment key={itm._id}>
                      <div
                        className={`Notifications-thord-contain ${
                          itm?.checked ? "red-background" : ""
                        }`}
                      >
                        <div className="extra-dr-div ">
                          <div>
                            <img
                              src={itm.senderId_image}
                              className="Doctor-main-dr-img"
                              alt=""
                            />
                          </div>

                          <div className="Appointment-schedule-dr">
                            <span
                              onClick={() => {
                                if (itm.notificationType === "appointment") {
                                  PandigViwe();
                                  return;
                                }
                                if (
                                  itm.notificationType === "prescription_reject"
                                ) {
                                  RejectList(itm);
                                  return;
                                }
                              }}
                              className="problem-span-Notifications"
                            >
                              {itm.notificationBody}
                            </span>

                            <span>{formattedDate}</span>
                            {itm.notificationType ===
                              "prescription_request" && (
                              <Buttton
                                // onClick={HendelView}
                                onClick={() => HendelView(itm)}
                                style={{
                                  backgroundColor: Colors.blue,
                                  width: "100px",
                                }}
                              >
                                View
                              </Buttton>
                            )}
                          </div>
                        </div>
                        <div>
                          <input
                            className="Sunita-main-check"
                            type="checkbox"
                            checked={Notificationid.includes(itm._id)}
                            // onClick={() => handleCheckboxChange(itm, index)}
                            onChange={() => handleCheckboxChange(itm, index)}
                          />
                        </div>
                      </div>
                      <div className="Notifications-d-s-line"></div>
                    </Fragment>
                  );
                })}
                <div
                  onClick={Deletenotification}
                  className="mb-5 delete-main-div-all mt-3"
                >
                  <img className="Delete-main-img" src={Delet} alt="" />
                  <span className="Delete-main-span">Delete</span>
                </div>
              </div>
            ) : (
              <h1>{response?.message}</h1>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default Notifications;
