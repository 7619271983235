import React, { useState } from "react";
import { BsFillStarFill } from "react-icons/bs";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Health from "../../Assets/Images/HealthDataImg.avif";
const DoctorWaitingroom = () => {
  return (
    <>
      <div className="FindMD-img-contain">
        <div>
          <img className="Cardimg-docter" src={Health} alt="" />
        </div>
        <div className="Dorothy-Lucas-sapn-div">
          <div className="Height-awy-main-div">
            <div className="Dorothy-extra-div">
              <span className="Dorothy-Raja-sapn">Raja</span>
            </div>

            <div className="Height-w-bmi-div mt-2">
              <div className="Height-Weight-main-div">
                <span>Height</span>
                <span>22</span>
              </div>
              <div className="Height-Weight-main-div">
                <span>Weight</span>
                <span>23.5</span>
              </div>
              <div className="Height-Weight-main-div">
                <span>BMI</span>
                <span>30</span>
              </div>
            </div>
            <div>
              <button>View Detail</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorWaitingroom;
