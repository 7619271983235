import React, { Fragment, useContext, useEffect, useState } from "react";

import Cardimg from "../../Assets/Images/Doctorsto.avif";
import "./PendingRequestsDoctor.css";
import Header from "../../Components/Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { PENDINGAPPOINTMENTAPI } from "../../Constant/Apiconstant";
import Loader, { SimpleLoader } from "../../Constant/Loader";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
import RejectionPopup from "../RejectionPopup/RejectionPopup";
import { GlobalContext } from "../../Context/GlabalContext";
import { Headers } from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
import { RejectionApi } from "../../Constant/Apiconstant";
import moment from "moment";

const PendingRequestsDoctor = () => {
  const location = useLocation();
  const { resion } = useContext(GlobalContext);
  // console.log(resion);
  const [PopupName, setPopupName] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const { response, isloading, getdata } = Fetchdata(
    PENDINGAPPOINTMENTAPI,
    {},
    "GET"
  );
  const [item2, setItem] = useState("");
  // console.log(response, "response");

  const [confirmationMessage, setConfirmationMessage] = useState("");

  // console.log(confirmationMessage, "confirmationMessage");
  const handleAcceptClick = () => {
    onSubmit();
  };

  const BackNot = location?.state?.Notpath;
  // console.log(BackNot, "  BackNot;");

  // const handleApicall = () => {
  //   setPopupName(false);
  //   onSubmit();
  // };

  const header = Headers();
  const onSubmit = async (name, item) => {
    // console.log(name);
    // console.log(item?._id, "djd");
    setloading(true);
    try {
      const res = await axios.post(
        RejectionApi,
        {
          appointmentId: item?._id || item2?._id,
          cancellationReason: resion,
          status: name,
        },

        header
      );
      setItem("");
      setloading(false);
      getdata();
      // console.log(res, "jvcccfh");
      setPopupName(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  return (
    <>
      <Header
        onClick={() => {
          if (BackNot === "/Notifications") {
            navigate(-1);
          } else {
            navigate("/Home");
          }
        }}
        text={"Pending Requests"}
        style={{ color: "white" }}
      />
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <section className="FindMD-main-section">
          {response?.data?.length == 0 ? (
            <h1>No Data Found</h1>
          ) : (
            <div className="FindMD-main-div">
              {response?.data?.map((itm, index) => {
                // console.log(itm, "itm");
                const formated = Dateformat(itm?.utc_time);
                // let convertedTime = moment
                //   .utc(itm?.utc_time)
                //   .local()
                //   .format("MMM D, YYYY, hh:mm A");
                const ItmData = itm?.patient_id?.basicInfo;
                return (
                  <Fragment key={index}>
                    <div className="FindMD-img-contain">
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={ItmData.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Lucas-awy-main-div">
                          <div className="Dorothy-extra-div">
                            <span className="Dorothy-sapn">
                              {ItmData.fullName}
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <span className="Am-pm-span">Preferred Time at</span>
                          <span className="Am-pm-span"> {formated}</span>
                        </div>
                        <div className="Reject-Accept-div mt-3">
                          <button
                            onClick={() => {
                              setItem(itm);
                              setPopupName(true);
                            }}
                            className="Reject-sand-btn"
                          >
                            Reject
                          </button>
                          <button
                            className="Accept-sand-btn"
                            onClick={() => onSubmit("confirmed", itm)}
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          )}
        </section>
      )}
      <RejectionPopup
        PopupName={PopupName}
        setPopupName={setPopupName}
        placetext={"Enter Note"}
        handleCloseeee={() => onSubmit("cancelled")}
        text={"Enter Name of Medicine"}
      />
      {loading && <Loader />}
    </>
  );
};

export default PendingRequestsDoctor;
