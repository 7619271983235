import { createContext, useState } from "react";

export const GlobalContext = createContext();
export const GlobalContextProvider = ({ children }) => {
  const [steps, setsteps] = useState(1);
  const [role, setrole] = useState("");
  const [Consultation, setConsultation] = useState("Head & Neck");
  const [myappointment, setmyappointment] = useState(false);
  const [tokenexpire, settokenexpire] = useState(false);
  const [notificationCount, setnotificationcount] = useState();
  const [Registerdata, setregiaterdata] = useState([]);
  const [DoctorRegisterdata, setDoctorRegisterdata] = useState([]);
  const [selectedsymtom, setselectedsymtom] = useState([]);
  const [Docdata, setDocdata] = useState([]);
  const [precription, setprecription] = useState([]);
  const [IncompleteNot, setIncompleteNot] = useState([]);
  const [Medicine, setMedicine] = useState([]);
  const [SigPadSaved, setSigPadSaved] = useState();
  const [resion, setresion] = useState();
  const [RejactId, setRejactId] = useState();
  const [UpdetDataProfile, setUpdetDataProfile] = useState([]);
  const [OtherData, setOtherData] = useState([]);
  const [FindMDFemaliy, setFindMDFemaliy] = useState();
  const [CutVedio, setCutVedio] = useState(false);
  const [PatcutCall, setPatcutCall] = useState(false);
  const [isCutcall, setisCutcall] = useState(false);
  const [BothCut, setBothCut] = useState(false);
  const [EmailGo, setEmailGo] = useState(false);
  const [room, setRoom] = useState(null);
  const [response, setresponse] = useState();
  const [videocallstartbydoctor, setvideocallstartbydoctor] = useState(false);
  const [videocallroomid, setvideocallroomid] = useState();
  const [SigUrl, setSigUrl] = useState();
  const [precriptionselecteddata, setprecriptionselecteddata] = useState({
    appointmentID: "",
    doctor_id: "",
    cardID: "",
    prescriptionIds: [],
    renewalPrescriptionAmount: "",
    renewalPrescriptionCurrency: "CAD",
  });
  const [SickCompleteId, setSickCompleteId] = useState({
    prescriptionIds: [],
  });
  const [ratingpopup, setratingpopup] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        steps,
        setsteps,
        selectedsymtom,
        setselectedsymtom,
        tokenexpire,
        precriptionselecteddata,
        setprecriptionselecteddata,
        SickCompleteId,
        setSickCompleteId,
        DoctorRegisterdata,
        setDoctorRegisterdata,
        settokenexpire,
        notificationCount,
        setnotificationcount,
        Registerdata,
        setregiaterdata,
        role,
        setrole,
        UpdetDataProfile,
        setUpdetDataProfile,
        Consultation,
        setConsultation,
        myappointment,
        setmyappointment,
        Docdata,
        setDocdata,
        IncompleteNot,
        setIncompleteNot,
        Medicine,
        setMedicine,
        precription,
        setprecription,
        SigPadSaved,
        setSigPadSaved,
        setSigUrl,
        SigUrl,
        setresion,
        resion,
        RejactId,
        setRejactId,
        setOtherData,
        OtherData,
        FindMDFemaliy,
        setFindMDFemaliy,
        CutVedio,
        setCutVedio,
        PatcutCall,
        setPatcutCall,
        BothCut,
        setBothCut,
        EmailGo,
        setEmailGo,
        room,
        setRoom,
        response,
        setresponse,
        videocallstartbydoctor,
        setvideocallstartbydoctor,
        isCutcall,
        setisCutcall,
        videocallroomid,
        setvideocallroomid,
        ratingpopup,
        setratingpopup,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
