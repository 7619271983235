import React from "react";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Cardimg from "../../Assets/Images/Doctorsto.avif";
import "./PendingInsurance.css";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { PendingInsuranceApi } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
const PendingInsurance = () => {
  const navigate = useNavigate();
  const { response, isloading } = Fetchdata(PendingInsuranceApi, {}, "GET");
  // console.log(response, "response");

  return (
    <>
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <section className="FindMD-main-section">
          {response?.data?.length == 0 ? (
            <h1>No Data Found</h1>
          ) : (
            <div className="FindMD-main-div">
              {response?.data?.map((itm, index) => {
                return (
                  <div key={index} className="FindMD-img-contain">
                    <div>
                      <img className="Cardimg-docter" src={itm.image} alt="" />
                    </div>
                    <div className="Dorothy-Lucas-sapn-div">
                      <div className="PatientName-awy-main-div">
                        <div className="Dorothy-extra-div">
                          <span className="Dorothy-sapn">
                            {itm.PatientName}
                          </span>
                          <span>
                            Company Name : {""}
                            {itm.PatientName}
                          </span>

                          <span>
                            Insurance Number : {""}
                            {itm.insuranceNumber}
                          </span>
                          <span>
                            Exp Date : {""}
                            {itm.exp_date}
                          </span>
                        </div>
                        <div className="Pending-main-div-complet">
                          <span>{itm.isInsuranceApprove}</span>
                        </div>
                      </div>
                      <div className="">
                        <span className="Am-pm-span"> {itm.loction}</span>
                      </div>
                      {/* <div className="Reject-Accept-div mt-3">
                        <button className="Reject-sand-btn">Reject</button>
                        <button className="Accept-sand-btn">Accept</button>
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default PendingInsurance;
