import React, { useEffect, useState } from "react";
import "./UpcomingAppointmentDetailDoctor.css";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Cardimg from "../../Assets/Images/ForgotPasswordImg.avif";
import Countdwon from "../../Assets/Images/countdown_blue_ic@3x.png";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";

const UpcomingAppointmentDetailDoctor = () => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
  const navigate = useNavigate();
  const Updata = () => {
    return (
      <>
        <div style={{ color: "white" }} className="mt-3 img-name-field-main">
          <div>
            <img className="Updata-main-img" src={Cardimg} alt="" />
          </div>
          <div className="General-span-Medicine-span">
            <span>Dorothy Lucas</span>
            <div className="Height-w-bmi-div mt-2">
              <div className="Height-Weight-main-div">
                <span className="Dorothy-main-span">Height</span>
                <span className="hagghit-Weight-span-and">23</span>
              </div>
              <div className="Height-Weight-main-div">
                <span className="Dorothy-main-span">Weight</span>
                <span className="hagghit-Weight-span-and">23.5</span>
              </div>
              <div className="Height-Weight-main-div">
                <span className="Dorothy-main-span">BMI</span>
                <span className="hagghit-Weight-span-and">30</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Upcoming appointment"}
        extrafield={<Updata />}
        style={{ color: "white" }}
      />
      <section className="UpcomingAppointment2-main-section">
        <div className="UpcomingAppointment2-main-div">
          <div className="UpcomingAppointment2-thord-div">
            <div className="Consultation-type-Video">
              <span className="Consultation-type-sapn">
                Consultation Type & Time
              </span>
              <span className="Today-main-date-span">Today, 12/10/2022</span>

              <div className="Video-Chat-Consult-div mt-4">
                <span className="Video-main-span">Video Chat Consult</span>
              </div>
            </div>
            <div className="d-h-m-s-div">
              <span>
                <img className="Countdwon-img-sapn" src={Countdwon} alt="" />
                {`${days}D: ${hours}H: ${minutes}M: ${seconds}S`}
              </span>
            </div>
          </div>
          <div className="h-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Payment Method</span>
            <span>Private payment</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Payment-for-appointment-div mt-3">
            <div className="Method-of-Card">
              <span className="Consultation-type-sapn">Status</span>
              <span>Upcoming</span>
            </div>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">
              Questionnaire Submitted
            </span>
            <span>Choose reason or consultation. Head & Neck</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span>
              Is your problem companied by new and unfamiliar symptoms such:
              Headache, Dizziness, Ear arch
            </span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Heath Data</span>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              bibendum arcu arcu, at hendrerit augue mattis ac. Quisque vel
              metus sagittis ligula mattis rutrum.
            </span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">
              Past Medical Condition
            </span>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              bibendum arcu arcu, at hendrerit augue mattis ac. Quisque vel
              metus sagittis ligula mattis rutrum.
            </span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Allergies</span>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              bibendum arcu arcu, at hendrerit augue mattis ac. Quisque vel
              metus sagittis ligula mattis rutrum.
            </span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Medication</span>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              bibendum arcu arcu, at hendrerit augue mattis ac. Quisque vel
              metus sagittis ligula mattis rutrum.
            </span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Smoking</span>
            <span>Yes</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Alcohol</span>
            <span>Yes</span>
          </div>
          <div className="Lorem-d-s-line"></div>
          <div className="Reason-for-appointment-div mt-3">
            <span className="Consultation-type-sapn">Marijuana</span>
            <span>Yes</span>
          </div>
          <div className="Lorem-d-s-line"></div>

          <div className="mt-5 Reschedule-Start-div mb-5">
            <button type="submit" className="Btn-Sick-notes-karo ">
              Start
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpcomingAppointmentDetailDoctor;
