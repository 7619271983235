import "./DoctorRegister.css";
import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import { DoctorRegisterSchemas } from "../../Schemas/index";
import Passwordicon from "../../Assets/Images/password_ic@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import Input from "../../Components/Input/Input";
import Emailicon from "../../Assets/Images/emailicon.png";
import { GlobalContext } from "../../Context/GlabalContext";
import { Icons } from "../../Constant/Icons";
import Pass from "../../Assets/Images/password_ic@3x.png";
import { useNavigate } from "react-router-dom";
import CreateProfileDoctor from "../CreateProfileDoctor/CreateProfileDoctor";
import ProfessionalDoctor from "../ProfessionalDoctor/ProfessionalDoctor";

const DoctorRegister = () => {
  const [close, setclose] = useState(true);
  const { steps, setsteps, setDoctorRegisterdata } = useContext(GlobalContext);
  const [close2, setclose2] = useState(true);
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const onSubmit = () => {
    setDoctorRegisterdata({ email: values.email, password: values.password });
    setsteps(2);
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: DoctorRegisterSchemas,
    onSubmit,
  });
  // console.log(steps);
  const navigate = useNavigate();
  return (
    <>
      <div className="All-Conatinor-perfect-divv-andstate">
        {steps == 1 ? (
          <div className="All-Containor-perfect-second-divv">
            <div className="Profile-extra-div">
              <div className="Profile-main-Your">
                <span onClick={() => navigate(-1)}>{Icons.backarrowblack}</span>
                <h6 className="mt-2">Step {steps} to 3</h6>
                <span className="Order-history-span">Sign Up</span>
                <span className="Upload-profile">
                  Enter your information below to sign up.
                </span>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="second-div mt-4">
              <div className="all-input-div-main-DoctorRegister">
                <div className="mt-5 all-input-areya w-100">
                  <div className="full-and-last-name w-100">
                    <div className="w-100">
                      <span>Email</span>
                      <Input
                        topimg={Emailicon}
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={"email"}
                        name={"email"}
                        style={{ backgroundColor: "#FFFFFF" }}
                      />
                      {errors.email && touched.email ? (
                        <p className="mastu-to-eroor mt-2">{errors.email} </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-4 w-100">
                    <span>Password</span>
                    <Input
                      topimg={Passwordicon}
                      placeholder={"Password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"password"}
                      type={close ? "password" : "text"}
                      bottomicon={close ? Icons.eyeclose : Icons.eyeopen}
                      bottomiconClick={() => setclose(!close)}
                      style={{ backgroundColor: "#FFFFFF" }}
                    />
                    {errors.password && touched.password ? (
                      <p className="mastu-to-eroor mt-2">{errors.password} </p>
                    ) : null}
                  </div>
                  <div className="mt-4 w-100">
                    <span className="">Confirm Password</span>
                    <Input
                      topimg={Passwordicon}
                      placeholder={"Confirm Password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"confirmPassword"}
                      type={close2 ? "password" : "text"}
                      bottomicon={close2 ? Icons.eyeclose : Icons.eyeopen}
                      bottomiconClick={() => setclose2(!close2)}
                      style={{ backgroundColor: "#FFFFFF" }}
                    />
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <p className="mastu-to-eroor mt-2">
                        {errors.confirmPassword}{" "}
                      </p>
                    ) : null}
                  </div>
                </div>
                <button type="submit" className="all-input-custum-btn">
                  Next
                </button>
              </div>
            </form>
            <div>
              <img className="YourProfile-next-blue" src={Blue} alt="" />
            </div>
          </div>
        ) : steps == 2 ? (
          <CreateProfileDoctor />
        ) : steps == 3 ? (
          <ProfessionalDoctor />
        ) : null}
      </div>
    </>
  );
};

export default DoctorRegister;
