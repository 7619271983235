import React, { Fragment, useContext, useState } from "react";
import "./PrescriptionDetail.css";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { PRESCRIPTIONLIST } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { GlobalContext } from "../../Context/GlabalContext";
import { monthNames } from "../../Constant/DummyData";

const PrescriptionDetail = () => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [selected, setselected] = useState(0);
  const [precriptiondata, setprecriptiondata] = useState([]);

  const [Idss, setIdss] = useState([]);
  // console.log(Idss, "Idss");
  const { response, isloading } = Fetchdata(PRESCRIPTIONLIST, {}, "GET");

  // console.log(response, "response");
  const { precriptionselecteddata, setprecriptionselecteddata } =
    useContext(GlobalContext);
  // console.log(precriptionselecteddata, "precriptionselecteddata");
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    let hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    const formattedDate = `${month} ${day}, ${hour}:${
      minute < 10 ? "0" : ""
    }${minute} ${ampm}`;

    return formattedDate;
  }

  // console.log(precriptiondata);
  const handlenavigate = () => {
    if (show) {
      setshow(false);
    } else {
      navigate(-1);
    }
  };

  const handleclick = (item) => {
    // console.log(item)
    setprecriptionselecteddata((prev) => {
      const currentPrescriptionIds =
        prev && prev.prescriptionIds ? prev.prescriptionIds : [];

      const newPrescriptionIds = currentPrescriptionIds.includes(item._id)
        ? currentPrescriptionIds.filter((id) => id !== item._id)
        : [...currentPrescriptionIds, item._id];

      setIdss(newPrescriptionIds);
      return {
        ...prev,
        prescriptionIds: newPrescriptionIds,
      };
    });
  };

  return (
    <>
      <Header onClick={handlenavigate} text={"Prescription detail"} />
      <section className="PrescriptionDetail-main-section">
        {show ? (
          <div className="mainrpecriptiondetail">
            {precriptiondata?.map((item, index) => {
              // console.log(precriptiondata, "precriptiondata");
              const formattedResult = formatTimestamp(item.prescriptionDate);
              return (
                <div className="d-flex flex-column mb-3 w-100 " key={index}>
                  <h5>{formattedResult}</h5>
                  <div className="d-flex flex-column gap-2">
                    {item?.prescriptionData?.map((_item) => {
                      return (
                        <div key={_item._id}>
                          <label
                            htmlFor="check"
                            className="d-flex align-items-center gap-2"
                          >
                            <input
                              type="checkbox"
                              // id="check"
                              onClick={() => handleclick(_item)}
                              className="checkboxpre"
                            />
                            <span
                              className="Lorem-main-span"
                              id="check"
                            >{`${_item.nameOfMedicine} ${_item.duration} ${_item.doseUnit} ${_item.frequency} x ${_item.duration} ${_item.durationUnit}`}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <Buttton
              // disable={
              //   precriptionselecteddata?.prescriptionIds?.length === 0
              //     ? "disable"
              //     : ""
              // }
              onClick={() => navigate("/PaymentMethod")}
              style={{
                backgroundColor: Colors.blue,
              }}
            >
              Renew
            </Buttton>
          </div>
        ) : (
          <div className="PrescriptionDetail-main-div">
            {isloading ? (
              <SimpleLoader style={{ height: "300px" }} />
            ) : (
              <>
                {!response?.data ? (
                  <h1>{response?.message}</h1>
                ) : (
                  response?.data?.map((itm, index) => {
                    {
                      /* console.log(itm, "iddss"); */
                    }
                    const formattedResult = formatTimestamp(itm.utc_time);
                    return (
                      <Fragment key={index}>
                        <label
                          className="PrescriptionDetail-thard"
                          onClick={() => {
                            setshow(true);
                            setselected(index);
                            setprecriptiondata(itm.prescriptions);
                            setprecriptionselecteddata({
                              appointmentID: itm.appointmentId,
                              doctor_id: itm.doctor_id,
                              prescriptionIds: Idss,
                              renewalPrescriptionAmount:
                                itm.renewalPrescriptionAmount,
                              renewalPrescriptionCurrency:
                                itm.renewalPrescriptionCurrency,
                            });
                          }}
                        >
                          <input
                            type="radio"
                            name="appointment"
                            // checked={selected}
                            defaultChecked={selected}
                          />
                          <span className="Lorem-main-span">{`Appointment with ${itm.doctorName} on ${formattedResult}`}</span>
                        </label>
                        <div className="PrescriptionDetail-main-line mt-4"></div>
                      </Fragment>
                    );
                  })
                )}
              </>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default PrescriptionDetail;
