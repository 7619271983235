import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./SicNotepay.css";
import { useNavigate } from "react-router-dom";
const SicNotepay = ({
  text,
  payPopup,
  setpayPopup,
  handleCloseeee,
  //   handlePay,
  maindataitm,
}) => {
  const navigate = useNavigate();
  // console.log(maindataitm, "maindataitm");

  const handlePay = (itm) => {
    navigate("/SickNotePayment", {
      state: {
        maindataitm,
      },
    });
  };

  return (
    <>
      <Modal show={payPopup} size="md" centered>
        <Modal.Header
          // onClick={() => setChangepassShow(false)}
          style={{
            display: "grid",
            placeItems: "flex-end",
            width: "100% ",
            justifyContent: "flex-end",
            border: "0",
          }}
        ></Modal.Header>
        <div className="expalin-div-sec">
          <div
            className="cardcontent-patient-Torso-main-div"
            style={{ borderRadius: "10px" }}
          >
            <div className="thard-Sick-con-div-been">
              <div className="Physician-div">
                <span>{text}</span>
              </div>
            </div>
          </div>
          <div className="handleCloseeee-main-hendel">
            <button
              onClick={handleCloseeee}
              className="Main-Cancel-and-Pay mt-3 mb-3"
            >
              Cancel
            </button>
            <button
              onClick={handlePay}
              className="Main-Cancel-and-Pay mt-3 mb-3"
            >
              Pay
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SicNotepay;
