import React from "react";
import { CMSPRIVACYPOLICYAPI } from "../../Constant/Apiconstant";
import Fetchdata from "../../Constant/Fetchdata";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { SimpleLoader } from "../../Constant/Loader";

const PrivacyPolicy = () => {
  const { response, isloading } = Fetchdata(CMSPRIVACYPOLICYAPI, {}, "GET");
  const navigate = useNavigate();
  return (
    <>
      <Header text={"Privacy policy"} onClick={() => navigate(-1)} />
      <div className="p-3">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <p dangerouslySetInnerHTML={{ __html: response?.data?.text }}></p>
        )}
      </div>
    </>
  );
};

export default PrivacyPolicy;
