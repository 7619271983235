import React, { Fragment, useContext } from "react";
import { Consultationdata } from "../../Constant/DummyData";
import { Colors } from "../../Constant/Colors";
import Button from "../../Components/Button/Buttton";
import bodyimage from "../../Assets/Images/male_body@3x.png";
import bodyimagefemale from "../../Assets/Images/female_body@3x.png";
import Header from "../../Components/Header/Header";
import "./BookAnAppointment.css";
import { GlobalContext } from "../../Context/GlabalContext";
import { useNavigate } from "react-router-dom";
// import { store } from "../../Redux/store";
// import { KEY_USER_DATA } from "../../Redux/Appconstant";

const ReasonForConsultation = () => {
  const { Consultation, setConsultation } = useContext(GlobalContext);
  const { setOtherData } = useContext(GlobalContext);
  const storedId = JSON.parse(localStorage.getItem("selectedProfileId"));
  // console.log(storedId.basicInfo.gender, "storedId");
  // const Mydata = store.getState().session[KEY_USER_DATA];
  const navigate = useNavigate();
  const handlenavigate = () => {
    if (Consultation == "Other") {
      navigate("/BookAnAppointment/Reasonforconsultation/Other");
    } else {
      navigate("/BookAnAppointment/Reasonforconsultation/select-symtoms");
    }
  };

  // useEffect(() => {
  //   setConsultation("Head & Neck");
  // }, []);

  return (
    <div className="bookappointmentthree">
      <Header text={"Book an appointment"} onClick={() => navigate(-1)} />
      <div className="bookappointmentchild px-3">
        <h6 style={{ alignSelf: "flex-start", fontSize: "12px" }}>
          {Consultation}
        </h6>
        <p style={{ padding: "0" }}>
          Now choose the reason for your <br /> consultation?
        </p>
        <div className="reasonbuttons">
          <div>
            {Consultationdata.map((item, i) => {
              return (
                <Fragment key={i}>
                  <div
                    style={{
                      backgroundColor:
                        Consultation == item.name ? Colors.blue : null,
                    }}
                    onClick={() => {
                      setOtherData(item.name);
                      setConsultation(item.name);
                    }}
                    key={item.id}
                  >
                    <div>
                      {Consultation == item.name ? item.iconwhite : item.icon}
                    </div>
                    <span
                      style={{
                        color: Consultation == item.name ? "#ffffff" : null,
                      }}
                    >
                      {item.name}
                    </span>
                    <p
                      style={{
                        color: Consultation == item.name ? "#ffffff" : null,
                      }}
                    >
                      {item.content}
                    </p>
                  </div>
                </Fragment>
              );
            })}
          </div>
          <div className="consultation">
            {storedId.basicInfo.gender == "female" ? (
              <img src={bodyimagefemale} alt="" />
            ) : storedId.basicInfo.gender == "male" ? (
              <img src={bodyimage} alt="" />
            ) : null}
          </div>
        </div>
      </div>
      <div className="px-2 w-100">
        <Button
          style={{ backgroundColor: Colors.blue }}
          onClick={handlenavigate}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ReasonForConsultation;
