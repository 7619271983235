import React, { useContext, useEffect, useState } from "react";
import "../HealthDataInformation/HealthDataInformation.css";
import { useFormik } from "formik";
import { HealthDataInformationSchemas } from "../../Schemas/index";
// import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
// import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import { GlobalContext } from "../../Context/GlabalContext";
import axios from "../../Constant/ApiUrl";
import {
  DOCTORRESENDOTP,
  PATIENTREGISTERAPI,
  RESENDOTP,
} from "../../Constant/Apiconstant";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Icons } from "../../Constant/Icons";
import Loader from "../../Constant/Loader";
import { store } from "../../Redux/store";
import { IS_LOGGED_IN, KEY_USER_DATA } from "../../Redux/Appconstant";
import { setSessionField } from "../../Redux/SessionAction";

const HealthDataInformation = () => {
  const { steps, setsteps, Registerdata, setregiaterdata, role } =
    useContext(GlobalContext);

  const route = useLocation();
  const Hospitalid = route?.state?.HospetalId;

  // console.log(Hospitalid);
  // console.log(Registerdata, "Registerdata");
  const [smoking, setsmmoking] = useState();
  const [alcohol, setalcohol] = useState();
  const [marijauna, setmarijauna] = useState();
  const [loading, setloading] = useState(false);

  const initialValues = {
    Weight: "",
    unitone: "",
    Height: "",
    unittow: "",
    bmi: "",
    MedicalCondition: "",
    Allergies: "",
    Medication: "",
    smoking: "",
    alcohol: "",
    marijauna: "",
  };

  // useEffect(() => {
  //   setFieldValue("smoking", smoking);
  //   setFieldValue("alcohol", alcohol);
  //   setFieldValue("marijauna", marijauna);
  // }, [alcohol, smoking, marijauna]);
  const Resendotp = async (role, email) => {
    let url =
      role == "patient"
        ? RESENDOTP
        : role == "physician"
        ? DOCTORRESENDOTP
        : null;
    try {
      const res = await axios.post(url, { email: email });
      if (res.data.status == "success" && res.status == 200) {
        navigate("/Otp");
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  let image = Registerdata.image;
  // console.log(image);
  const navigate = useNavigate();
  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("firstName", Registerdata.fullname);
    formdata.append("lastName", Registerdata.lastName);
    formdata.append("gender", Registerdata.gender);
    formdata.append("image", image);
    formdata.append("address", Registerdata.location);
    formdata.append("role", role || "patient");
    formdata.append("email", Registerdata.email);
    formdata.append("password", Registerdata.Password);
    formdata.append("age", Registerdata.age);
    formdata.append("phoneNumber", null);
    formdata.append("weight", values.Weight);
    formdata.append("weightUnit", values.unitone);
    formdata.append("height", values.Height);
    formdata.append("heightUnit", values.unittow);
    formdata.append("BMI", values.bmi);
    formdata.append("medicalCondition", values.MedicalCondition);
    formdata.append("medication", values.Medication);
    formdata.append("allergies", values.Allergies);
    formdata.append("smoking", smoking);
    formdata.append("alcohol", alcohol);
    formdata.append("marijuana", marijauna);
    formdata.append("deviceType", "web");
    formdata.append("deviceToken", "");
    formdata.append("country", Registerdata.country);
    formdata.append("pastMedicalCondition", "test");
    formdata.append("lat", Registerdata.lat);
    formdata.append("lng", Registerdata.lng);
    formdata.append("hospital_id", Hospitalid ? Hospitalid : "");
    // for (const pair of formdata.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`);
    // }
    try {
      const res = await axios.post(PATIENTREGISTERAPI, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setloading(false);
      if (res.status === 200 && res.data.status === "success") {
        store.dispatch(setSessionField(KEY_USER_DATA, res.data.data));
        store.dispatch(setSessionField(IS_LOGGED_IN, true));
        if (res.data.data.basicInfo.isEmailVerified === false) {
          Resendotp(
            res.data.data.basicInfo.role,
            res.data.data.basicInfo.email
          );
        } else {
          if (res.data.data.basicInfo.role == "patient") {
            navigate("/home-page");
          } else if (res.data.data.basicInfo.role == "physician") {
            navigate("/Home");
          }
        }
        toast.success(res.data.message);
      } else if (res.data.status === "failed") {
        toast.error(res.data.message);
      }
      // if (res.data.status == "failed") {
      //   toast.error(res.data.message);
      // } else if (res.data.status == "success" && res.status == 200) {
      //   toast.success(res.data.message);
      //   setregiaterdata({});
      //   navigate("/login");
      // }
      // console.log(res);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: HealthDataInformationSchemas,
    onSubmit,
  });

  const calculateBMI = () => {
    const weight = parseFloat(values.Weight); // Weight in kilograms
    const height = parseFloat(values.Height) / 100; // Height in meters (convert cm to m)

    if (!isNaN(height) && !isNaN(weight) && height > 0 && weight > 0) {
      const bmi = (weight / Math.pow(height, 2)).toFixed(8); // Calculate BMI and round to 8 decimal places
      setFieldValue("bmi", bmi);
    } else {
      setFieldValue("bmi", ""); // Clear BMI if either height or weight is invalid
    }
  };

  useEffect(() => {
    calculateBMI();
  }, [values.Height, values.Weight]);

  return (
    <>
      <div className="All-OtherInformation-perfect-divv">
        <div className="All-OtherInformation-perfect-second-divv">
          <div className="Profile-extra-div">
            <div className="Profile-main-Your">
              <span style={{ cursor: "pointer" }} onClick={() => setsteps(2)}>
                {Icons.backarrowblack}
              </span>
              <h6 className="mt-2">Step {steps} to 3</h6>
              <span className="Order-history-span">
                Health data information
              </span>
              <span className="Upload-profile">
                Type in your health data to get better consultants from doctor.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="second-div mt-4 w-100">
            <div className="Health-input-div-main">
              <div className="hoooo mt-5">
                <div className="Yes-and-No-name">
                  <div className="Full-all-Name-input">
                    <span className="">Weight</span>
                    <div className="full-name-bug-div">
                      <div className="rectangle-Weight mt-2">
                        <input
                          className="Weight-input-css"
                          type="number"
                          placeholder="Weight"
                          name="Weight"
                          value={values.Weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    {errors.Weight && touched.Weight ? (
                      <p className="bmi-Weight mt-2">{errors.Weight} </p>
                    ) : null}
                  </div>
                  <div className="Unit-kg-selact">
                    <span className="">Unit</span>
                    <select
                      className="Volvo-selact-value mt-2"
                      name="unitone"
                      id="cars"
                      value={values.unitone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Kg</option>
                      <option value="kg">Kg</option>
                    </select>
                    {errors.unitone && touched.unitone ? (
                      <p className="bmi-Weight mt-2">{errors.unitone} </p>
                    ) : null}
                  </div>
                </div>
                <div className="Yes-and-No-name mt-4">
                  <div className="Full-all-Name-input">
                    <span className="">Height</span>
                    <div className="full-name-bug-div">
                      <div className="rectangle-Weight mt-2">
                        <input
                          className="Weight-input-css"
                          type="number"
                          placeholder="Height"
                          name="Height"
                          value={values.Height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    {errors.Height && touched.Height ? (
                      <p className="bmi-Weight mt-2">{errors.Height} </p>
                    ) : null}
                  </div>
                  <div className="Unit-kg-selact">
                    <span className="">Unit</span>
                    <select
                      className="Volvo-selact-value mt-2"
                      name="unittow"
                      id="cars"
                      value={values.unittow}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">m</option>
                      <option value="m">m</option>
                    </select>
                    {errors.unittow && touched.unittow ? (
                      <p className="bmi-Weight mt-2">{errors.unittow} </p>
                    ) : null}
                  </div>
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">BMI</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="kg/m2"
                      name="bmi"
                      value={values.bmi}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.bmi && touched.bmi ? (
                    <p className="bmi-Weight mt-2">{errors.bmi} </p>
                  ) : null}
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">Medical condition (optional)</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Medical condition"
                      name="MedicalCondition"
                      value={values.MedicalCondition}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">Allergies</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Allergies"
                      name="Allergies"
                      value={values.Allergies}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.Allergies && touched.Allergies ? (
                    <p className="bmi-Weight mt-2">{errors.Allergies}</p>
                  ) : null}
                </div>
                <div className="BMI-MAin-input-div mt-4">
                  <span className="">Medication (optional)</span>
                  <div className="BMI-MAin-input mt-2">
                    <input
                      className="Bim-Md-main-input-css"
                      type="text"
                      placeholder="Medication"
                      name="Medication"
                      value={values.Medication}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="condition-div-yes-no">
                  <div className="Gender-Male-Female-div mt-3">
                    <span>Smoking</span>
                    <div className="Gender-main-div mt-2">
                      <button
                        style={{
                          backgroundColor:
                            smoking === "yes" ? "#FFB802" : "white",
                          color: smoking === "yes" ? "#ffffff" : "",
                        }}
                        type="button"
                        name="smoking"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("smoking", "yes");
                          setsmmoking("yes");
                        }}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            smoking === "no" ? "#FFB802" : "white",
                          color: smoking === "no" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("smoking", "no");
                          setsmmoking("no");
                        }}
                      >
                        No
                      </button>
                    </div>
                    {errors.smoking ? (
                      <p className="bmi-Weight mt-2">{errors.smoking} </p>
                    ) : null}
                  </div>
                  <div className="Gender-Male-Female-div mt-3">
                    <span>Alcohol</span>
                    <div className="Gender-main-div mt-2">
                      <button
                        style={{
                          backgroundColor:
                            alcohol === "yes" ? "#FFB802" : "white",
                          color: alcohol === "yes" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("alcohol", "yes");
                          setalcohol("yes");
                        }}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            alcohol === "no" ? "#FFB802" : "white",
                          color: alcohol === "no" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        name="alcohol"
                        onClick={() => {
                          setFieldValue("alcohol", "no");
                          setalcohol("no");
                        }}
                      >
                        No
                      </button>
                    </div>
                    {errors.alcohol ? (
                      <p className="bmi-Weight mt-2">{errors.alcohol}</p>
                    ) : null}
                  </div>
                  <div className="Gender-Male-Female-div mt-3">
                    <span>Marijuana</span>
                    <div className="Gender-main-div mt-2">
                      <button
                        style={{
                          backgroundColor:
                            marijauna === "yes" ? "#FFB802" : "white",
                          color: marijauna === "yes" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("marijauna", "yes");
                          setmarijauna("yes");
                        }}
                      >
                        Yes
                      </button>
                      <button
                        style={{
                          backgroundColor:
                            marijauna === "no" ? "#FFB802" : "white",
                          color: marijauna === "no" ? "#ffffff" : "",
                        }}
                        type="button"
                        className="Yes-btn-me-main"
                        onClick={() => {
                          setFieldValue("marijauna", "no");
                          setmarijauna("no");
                        }}
                        name="marijauna"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        No
                      </button>
                    </div>
                    {errors.marijauna ? (
                      <p className="bmi-Weight mt-2">{errors.marijauna} </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-5">
                <button type="submit" className="Submit-Main-btn-Bim">
                  Submit
                </button>
              </div>
            </div>
            <div>
              <img className="Medical-next-blue" src={Blue} alt="" />
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default HealthDataInformation;
