// import React, { useContext, useEffect, useState } from "react";
import card from "../../Assets/Images/cardpoint.png";
// import Card2 from "../../Assets/Images/cardpoint2.png";
import "./HelthCardPayment.css";
// import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { PATIENTCARDLISTAPI } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";

const HelthCardPayment = () => {
  // const navigate = useNavigate();
  const { response, isloading } = Fetchdata(PATIENTCARDLISTAPI);
  return (
    <>
      <section className="Notifications-main-section">
        <div className="Notifications-main-div">
          {/* <div className="payment-span-divv-head">
            <span className="Payment-Options-span">Credit/Debit Cards</span>
          </div> */}
          {isloading ? (
            <SimpleLoader style={{ height: "300px" }} />
          ) : (
            <div className="pymaint-div-thard">
              {response?.data?.map((item, index) => {
                return (
                  <div key={item.id} className="payment-XXXX-input67">
                    <img src={card} alt="" className="visaimg-img" />
                    <span className="last4-span-divv">
                      XXXX XXXX XXXX{item.last4}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default HelthCardPayment;
