import React, { useContext, useEffect, useState } from "react";
import "./UpcomingAppointmentDoctor.css";
// import location from "../../Assets/Images/location_ic_sm@3x.png";
// import Health from "../../Assets/Images/HealthDataImg.avif";
import Countdwon from "../../Assets/Images/countdown_blue_ic@3x.png";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Fetchdata from "../../Constant/Fetchdata";
import { UPCOMINGAPPOINTMENTAPI } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
// import { Dateformat } from "../../Components/Dateformat/Dateformat";
import moment from "moment";
import CutVedioCallpopup from "../CutVedioCallpopup/CutVedioCallpopup";
import { GlobalContext } from "../../Context/GlabalContext";
import { store } from "../../Redux/store";
import { KEY_USER_DATA, Meetingdata } from "../../Redux/Appconstant";

const UpcomingAppointmentDoctor = () => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const { CutVedio, setCutVedio, setisCutcall, isCutcall } =
    useContext(GlobalContext);
  const maindata = store.getState().session[Meetingdata];
  const userdata = store.getState().session[KEY_USER_DATA];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  // const hours = Math.floor(
  //   (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  // );
  // const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  const navigate = useNavigate();
  const { response, isloading, getdata } = Fetchdata(
    UPCOMINGAPPOINTMENTAPI,
    {},
    "GET"
  );
  // console.log(response, "Upcoming-Appointment");

  // const formated = Dateformat(response?.utc_time);

  // console.log(formated, "formated");

  useEffect(() => {
    if (isCutcall) {
      getdata();
      setisCutcall(false);
    }
  }, [isCutcall]);

  const handleclick = (itm) => {
    navigate("/CountUpcomingAppointment", {
      state: {
        itm,
      },
    });
  };

  return (
    <>
      <Header onClick={() => navigate("/Home")} text={"Upcoming appointment"} />
      {isloading ? (
        <SimpleLoader style={{ height: "300px" }} />
      ) : (
        <section className="FindMD-main-section">
          {!response?.data ? (
            <h1 className="text-center">{response?.message}</h1>
          ) : (
            <div className="FindMD-main-div">
              {response?.data?.map((itm, index) => {
                const basicInfo = itm?.patient_id?.healthData;
                const basicInfooo = itm?.patient_id?.basicInfo;

                const curantdate = moment.utc();

                const endDatetime = moment.utc(
                  itm?.utc_time,
                  "YYYY-MM-DD HH:mm:ss"
                );
                const startDatetime = moment.utc(
                  curantdate,
                  "YYYY-MM-DD HH:mm:ss"
                );
                const timeDifference = moment.duration(
                  endDatetime.diff(startDatetime)
                );
                const updatedTimeDifference = timeDifference.add(15, "minutes");
                const diffFormatted = `${updatedTimeDifference.days()} D : ${updatedTimeDifference.hours()} H : ${updatedTimeDifference.minutes()} M : ${updatedTimeDifference.seconds()} S:`;

                return (
                  <div
                    onClick={() => handleclick(itm)}
                    className="FindMD-img-contain"
                    key={index}
                  >
                    <div>
                      <img
                        className="Cardimg-docter"
                        src={basicInfooo.image}
                        alt=""
                      />
                    </div>
                    <div className="Dorothy-Lucas-sapn-div">
                      <div className="Lucas-awy-main-div">
                        <div className="Dorothy-extra-div">
                          <div className="d-h-m-s-div">
                            <span>
                              <img
                                className="Countdwon-img-sapn"
                                src={Countdwon}
                                alt=""
                              />

                              {diffFormatted}
                            </span>
                          </div>
                          <span className="Dorothy-sapn">
                            {basicInfooo.fullName}
                          </span>
                        </div>
                      </div>
                      <div className="Height-w-bmi-div mt-2">
                        <div className="Height-Weight-main-div">
                          <span>Height</span>
                          <div className="calqulation-h-m">
                            <span className=""> {basicInfo.height.value}</span>
                            <span className=""> {basicInfo.height.unit}</span>
                          </div>
                        </div>
                        <div className="Height-Weight-main-div">
                          <span>Weight</span>
                          <div className="calqulation-h-m">
                            <span className=""> {basicInfo.weight.value}</span>
                            <span className=""> {basicInfo.weight.unit}</span>
                          </div>
                        </div>
                        <div className="Height-Weight-main-div">
                          <span>BMI</span>
                          <span className="">
                            {" "}
                            {parseInt(basicInfo?.BMI).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </section>
      )}
      {CutVedio && (
        <CutVedioCallpopup
          CutVedio={CutVedio}
          setCutVedio={setCutVedio}
          isSimpleCall={maindata?.waitingRoomAppointment ? false : true}
          waitingRoomId={
            userdata?.basicInfo?.role === "physician"
              ? maindata?.waitingRoomAppointment?._id
              : maindata?.waitingRoomId
          }
          appointmentID={maindata?._id}
          text={"Are you sure your appoinment is completed ?"}
        />
      )}
    </>
  );
};

export default UpcomingAppointmentDoctor;
