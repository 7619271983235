import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import {Navigation, Thumbs} from "swiper/react";
import "./Calender.css";
import { Icons } from "../../Constant/Icons";
import { monthNames } from "../../Constant/DummyData";
import { Colors } from "../../Constant/Colors";

const Calender = ({ setselecteddate, selecteddate }) => {
  const [image, setimage] = useState([]);
  const [current, setcurrent] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const totalSlides = 15;
  const btnref = useRef(null);

  const handleNextSlide = () => {
    const nextSlide = currentSlide + 1;
    if (nextSlide < totalSlides) {
      setCurrentSlide(nextSlide);
    } else {
      setIsDisabled(true);
    }
  };

  const length = image.length;
  const nextslide = () => {
    btnref.current.swiper.slideNext();
    handleNextSlide();
    setcurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevslide = () => {
    btnref.current.swiper.slidePrev();
    setcurrent(current === 0 ? length - 1 : current - 1);
  };

  const currentdate = new Date();
  const currentmonth = currentdate.getMonth();
  const currentyear = currentdate.getFullYear();
  const monthyearstring = currentdate.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  });
  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const generateCalendar = (year, month) => {
    const calendar = [];
    const totalDays = daysInMonth(year, month);
    for (let i = 1; i <= totalDays; i++) {
      const date = new Date(year, month, i);
      const dayName = dayNames[date.getDay()];
      const currentDateWithoutTime = new Date(currentdate);
      currentDateWithoutTime.setHours(0, 0, 0, 0);
      const isDisabled = date < currentDateWithoutTime;
      const fullDate = `${year}-${(month + 1).toString().padStart(2, "0")}-${i
        .toString()
        .padStart(2, "0")}`;
      calendar.push({
        date: i.toString().padStart(2, "0"),
        dayName,
        month: monthNames[month],
        isDisabled,
        fullDate,
      });
    }
    return calendar;
  };

  const calendarData = generateCalendar(currentyear, currentmonth);
  const handledate = (item) => {
    setselecteddate(item.fullDate);
  };

  return (
    <div className="Calender_main_divv">
      <div className="Swiper_Calender_Month_secondary_divv p-3">
        <span className="Feed_span_text_divv">{monthyearstring}</span>
        <div className="sliderdiv">
          <button className="left-swiper-btn mt-4" onClick={prevslide}>
            <span>{Icons.swiperarrowleft}</span>
          </button>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            initialSlide={new Date()}
            ref={btnref}
            breakpoints={{
              1280: {
                slidesPerView: 12,
                allowSlideNext: true,
                allowSlidePrev: true,
              },
              540: {
                slidesPerView: 6,
                allowSlideNext: true,
                allowSlidePrev: true,
              },
              360: {
                slidesPerView: 4,
                allowSlideNext: true,
                allowSlidePrev: true,
              },
            }}
          >
            <div className="overflowdiv">
              {calendarData.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <button
                      className="Twent_july_spann_divv_swiper mt-4"
                      onClick={() => handledate(item)}
                      disabled={item.isDisabled ? "disable" : ""}
                      style={{
                        backgroundColor:
                          selecteddate === item.fullDate ? Colors.blue : "",
                      }}
                    >
                      <div className="twenty_spann_divv">
                        <span
                          style={{
                            color: selecteddate === item.fullDate ? "#fff" : "",
                          }}
                          className="twenty_spann_span"
                        >
                          {item.date}
                        </span>
                      </div>
                      <div className="JULY_spann_divv">
                        <span
                          style={{
                            color: selecteddate === item.fullDate ? "#fff" : "",
                          }}
                          className="July_spann_span"
                        >
                          {item.dayName}
                        </span>
                      </div>
                    </button>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
          <button className="left-swiper-btn mt-4" onClick={nextslide}>
            <span>{Icons.swiperarrowright}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calender;
